import React, { useState } from 'react'
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledDropdown
} from 'reactstrap'
import { Link } from 'react-router-dom'
import {
  badgeColor,
  calculateEmployersSuperannuation,
  calculatePensionablePay,
  convertTimeToMinute,
  EDIT_INVOICE_INITIAL_STATE,
  getCookie,
  INVOICES_TABS,
  INVOICE_INITIAL_STATE
} from '../../utils/constants'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import classnames from 'classnames'
import { invoiceServices } from '../../services/invoiceServices'
import { useEffect } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Flatpickr from 'react-flatpickr'
import ConfirmationModal from '../../Components/Common/ConfirmationModal'
import { saveAs } from 'file-saver'
import PaginationV2 from '../../Components/Common/PaginationV2'
import GenerateInvoiceConfirmation from '../../Components/Common/GenerateInvoiceConfirmation'
import SpinnerComponent from '../../Components/Common/Spinner'

import { accountServices } from '../../services/accountServices'
import fetchPDF from '../../common/fetchPDF'
import SessionListViewInfoModal from '../../Components/Common/SessionListViewInfoModal'

const InvoiceTableRow = ({
  invoice,
  topBorderjustifyTab,
  index,
  tog_standard,
  setSelectedSessionId,
  fetchUpdateStatusToPaid,
  expenseAmount = 0
}) => {
  const token = localStorage.getItem('token')
  const [isLoading, setIsLoading] = useState(false)

  const handleDownloadDocument = (pdf_uuid, status, originalFileName) => {
    setIsLoading(true)
    accountServices.downloadDocument(pdf_uuid, status, token)
      .then(resp => fetchPDF(resp, originalFileName))
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }
  return (
    <>
      {isLoading && <SpinnerComponent />}
      <tr key={invoice?.session?.uuid}>
        <td></td>
        <td>
          {/* <Link to='#'
          onClick={() =>
          history.push(`/session-details/${invoice?.uuid}/manual`)
          } */}
          {/* > */}
            #{invoice?.session?.uuid.substring(0, 5) || 'NA'}
          {/* </Link> */}
        </td>
        <td className='text-truncate' style={{maxWidth: 220}}>

                {invoice?.session?.organisation?.name || 'NA'}
        <p className='text-muted small mb-0'>
        {invoice?.session?.organisation?.postcode || 'NA'}

        </p>
        </td>
        <td>
                {`${moment(invoice?.session?.start_time).format(
                  'ddd, Do MMMM YYYY'
                )}` || 'NA'}
              <p className='text-muted mb-0 small'>
                <span className='fw-medium'>
                  {`${moment(invoice?.session?.start_time).format('LT')}` || 'NA'}{' '}
                  -{`${moment(invoice?.session?.end_time).format('LT')}` || 'NA'}{' '}
                  ({' '}{invoice?.session?.unpaid_break_duration || ' No break'}{' '}
                  {invoice?.session?.unpaid_break_duration !== 0 ? "mins break" : ""}{' '})
                </span>
              </p>
        </td>
        <td>
            {moment(invoice?.createdAt).format('L')}
            <p className='text-muted small mb-0'>
            {moment(invoice?.createdAt).fromNow()}
            </p>
        </td>
        <td>
                £
                {invoice?.session?.status === 'withdrawn'
                  ? (
                    ((invoice?.session?.hourly_rate / 60) *
                      convertTimeToMinute(
                        invoice?.session?.start_time,
                        invoice?.session?.end_time,
                        invoice?.session?.unpaid_break_duration
                      ) *
                      invoice?.session?.withdrawal_percentage) /
                    100
                  )?.toFixed(2)
                  : (
                    (invoice?.session?.hourly_rate / 60) *
                    convertTimeToMinute(
                      invoice?.session?.start_time,
                      invoice?.session?.end_time,
                      invoice?.session?.unpaid_break_duration
                    ) +
                    Number(invoice?.session?.client_bonus_amount) + expenseAmount
                  )?.toFixed(2)}
              <h5 className='text-muted mb-0'>
                <span
                  className={`fw-medium ${invoice?.session?.status === 'withdrawn' &&
                    'text-decoration-line-through'
                    } `}
                />
              </h5>
              <p className='text-muted small mb-0'>
                <span
                  className={`fw-medium ${invoice?.session?.status === 'withdrawn' &&
                    'text-decoration-line-through'
                    } `}
                >
                  £
                  {(
                    (invoice?.session?.hourly_rate / 60) *
                    convertTimeToMinute(
                      invoice?.session?.start_time,
                      invoice?.session?.end_time,
                      invoice?.session?.unpaid_break_duration
                    )
                  ).toFixed() || 'NA'}{' '}
                  (£
                  {invoice?.session?.hourly_rate || 'NA'} /hour)
                  {Number(invoice?.session?.client_bonus_amount)?.toFixed() > 0 && (
                     <> + £
                  {Number(invoice?.session?.client_bonus_amount)?.toFixed(2)} bonus
                     </>
                   )}
                  {expenseAmount !== 0 ? " " + "+" + ` £${expenseAmount}` + " expenses" : ""}

                  {invoice?.session?.pensionable === 1 ? (
                    <i title='Pensionable' className="ri-file-ppt-line ms-1"></i>
                  ) : (
                    ''
                  )}
                </span>
              </p>
        </td>
        {topBorderjustifyTab === 2 && <td>{invoice?.paid_date?.length ? moment(invoice?.paid_date).format("L") : "NA"}</td>}
        <td>
          {/* <span className={`badge rounded-pill badge-soft-${badgeColor(invoice?.session?.status || 'NA')}`}>{invoice?.session?.status || 'NA'}</span> */}
          <Badge
            color={badgeColor(invoice?.session?.status || 'NA')}
            className='ms-auto'
          >
            {invoice?.session?.status || 'NA'}
          </Badge>
        </td>
        {topBorderjustifyTab === 1 && (
          <td>
            {invoice?.session?.session_source === 'manual' && (
              <UncontrolledDropdown>
                <DropdownToggle
                  href='#'
                  className='btn-soft-primary btn-sm dropdown'
                  tag='button'
                >
                  <i className='ri-more-fill align-middle'></i>
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-end'>
                  <DropdownItem
                    onClick={() => {
                      tog_standard()
                      setSelectedSessionId(invoice?.uuid)
                    }}
                  >
                    <i className='ri-clipboard-fill align-bottom me-2 text-muted'></i>{' '}
                    Mark As Paid
                  </DropdownItem>{' '}
                  <DropdownItem
                    onClick={() =>
                      handleDownloadDocument(invoice?.uuid, "invoice", `invoice-${invoice?.pdf_copy.split('/')[2]}`)
                    }
                  >
                    <i className='ri-download-line align-bottom me-2 text-muted'></i>{' '}
                    Download Invoice
                  </DropdownItem>{' '}
                  <DropdownItem
                    onClick={() =>
                      fetchUpdateStatusToPaid(
                        invoice?.uuid,
                        'cancelled',
                        '',
                        token
                      )
                    }
                  >
                    <i className='ri-scissors-fill align-bottom me-2 text-muted'></i>{' '}
                    Cancel Invoice
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}

            {invoice?.session?.session_source === 'market_place' && (
              <UncontrolledDropdown>
                <DropdownToggle
                  href='#'
                  className='btn-soft-primary btn-sm dropdown'
                  tag='button'
                >
                  <i className='ri-more-fill align-middle'></i>
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-end'>
                  {/* <DropdownItem
                onClick={() => {
                  tog_standard()
                  setSelectedSessionId(
                    item?.uuid
                  )
                }}
              >
                <i className='ri-clipboard-fill align-bottom me-2 text-muted'></i>{' '}
                Mark As Paid
              </DropdownItem> */}
                  <DropdownItem
                    onClick={() =>
                      handleDownloadDocument(invoice?.uuid, "invoice", `invoice-${invoice?.pdf_copy.split('/')[2]}`)
                    }
                  >
                    <i className='ri-download-line align-bottom me-2 text-muted'></i>{' '}
                    Download Invoice
                  </DropdownItem>{' '}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </td>
        )}
        {topBorderjustifyTab === 2 && (
          <td>
            <UncontrolledDropdown>
              <DropdownToggle
                href='#'
                className='btn-soft-primary btn-sm dropdown'
                tag='button'
              >
                <i className='ri-more-fill align-middle'></i>
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-end'>
                {invoice?.session?.session_source !== 'market_place' && (
                  <DropdownItem
                    onClick={() =>
                      fetchUpdateStatusToPaid(invoice?.uuid, 'unpaid', '', token)
                    }
                  >
                    <i className='ri-clipboard-fill align-bottom me-2 text-muted'></i>{' '}
                    Mark As Unpaid
                  </DropdownItem>
                )}
                <DropdownItem
                  onClick={() =>
                    handleDownloadDocument(invoice?.uuid, "invoice", `invoice-${invoice?.pdf_copy.split('/')[2]}`)
                  }
                >
                  <i className='ri-download-line align-bottom me-2 text-muted'></i>{' '}
                  Download Invoice
                </DropdownItem>{' '}
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        )}
      </tr>
    </>
  )
}

// **Mobile view**
const InvoiceTableCard = ({
  invoice,
  topBorderjustifyTab,
  index,
  tog_standard,
  setSelectedSessionId,
  fetchUpdateStatusToPaid,
  expenseAmount = 0
}) => {
  const token = localStorage.getItem('token')
  const [isLoading, setIsLoading] = useState(false)

  const handleDownloadDocument = (pdf_uuid, status, originalFileName) => {
    setIsLoading(true)
    accountServices.downloadDocument(pdf_uuid, status, token)
      .then(resp => fetchPDF(resp, originalFileName))
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }
  return (
    <>
      {isLoading && <SpinnerComponent />}
      <Card className='d-xl-none rounded-1' key={invoice?.session?.uuid}>
        <CardBody className='text-dark'>








          <Row className='pb-2'>
            <Col xs={8} className='fw-semibold text-dark'>
              {invoice?.session?.organisation?.name || 'NA'}
            </Col>
            <Col xs={4} className='text-end'>
              <span className={`badge rounded-pill badge-soft-${badgeColor(invoice?.session?.status || 'NA')}`}>{invoice?.session?.status || 'NA'}</span>
            </Col>
          </Row>


          <Row>
            <Col>
              {`${moment(invoice?.session?.start_time).format('dddd')}` || 'NA'}{' '}
            </Col>
            <Col className='text-end fw-semibold text-primary fs-20 lh-1'>
              £       {invoice?.session?.status === 'withdrawn'
                ? (
                  ((invoice?.session?.hourly_rate / 60) *
                    convertTimeToMinute(
                      invoice?.session?.start_time,
                      invoice?.session?.end_time,
                      invoice?.session?.unpaid_break_duration
                    ) *
                    invoice?.session?.withdrawal_percentage) /
                  100
                )?.toFixed(2)
                : (
                  (invoice?.session?.hourly_rate / 60) *
                  convertTimeToMinute(
                    invoice?.session?.start_time,
                    invoice?.session?.end_time,
                    invoice?.session?.unpaid_break_duration
                  ) +
                  Number(invoice?.session?.client_bonus_amount) + expenseAmount
                )?.toFixed(2)}
            </Col>
          </Row>


          <Row>
            <Col xs={9}>
              {`${moment(invoice?.session?.start_time).format('Do MMMM YYYY')}` || 'NA'}

            </Col>
            <Col xs={3} className={`text-muted text-end ${invoice?.session?.status === 'withdrawn' &&
              'text-decoration-line-through'
              }`}>
              £
              {(
                (invoice?.session?.hourly_rate / 60) *
                convertTimeToMinute(
                  invoice?.session?.start_time,
                  invoice?.session?.end_time,
                  invoice?.session?.unpaid_break_duration
                )
              ).toFixed() || 'NA'}

            </Col>
          </Row>


          <Row>
            <Col>
              {`${moment(invoice?.session?.start_time).format('LT')}` || 'NA'}
              -{`${moment(invoice?.session?.end_time).format('LT')}` || 'NA'}
            </Col>
            <Col className={`text-muted text-end ${invoice?.session?.status === 'withdrawn' && 'text-decoration-line-through'}`}>
              £
              {invoice?.session?.hourly_rate || 'NA'} /h
            </Col>
          </Row>


          <Row>
            <Col>
              {invoice?.session?.unpaid_break_duration || ' No break'}
              {invoice?.session?.unpaid_break_duration !== 0 ? " mins break" : ""}
            </Col>

            <Col className={`text-muted text-end ${invoice?.session?.status === 'withdrawn' && 'text-decoration-line-through'}`}>
            {Number(invoice?.session?.client_bonus_amount)?.toFixed() > 0 && (
                     <> £
                  {Number(invoice?.session?.client_bonus_amount)?.toFixed(2)} bonus
                     </>
                   )}
            </Col>
          </Row>


          <Row>
            <Col>

            </Col>

            <Col className={`text-muted text-end ${invoice?.session?.status === 'withdrawn' && 'text-decoration-line-through'}`}>
              {expenseAmount !== 0 ? " " + "+" + ` £${expenseAmount}` + " expenses" : ""}
            </Col>
          </Row>

          <Row><Col>
            <div className='d-flex flex-row justify-content-end small gap-1 my-1'>
            <div> <span className='badge rounded-pill badge-soft-primary'>Invoice date: {moment(invoice?.createdAt).format('L')}</span></div>
              {invoice?.session?.pensionable === 1 ? (
                <div> <span className='badge rounded-pill badge-soft-success'>Pensionable</span></div>
              ) : (
                ''
              )}
            </div>
          </Col></Row>
          <Row><Col>
            <div className='d-flex flex-row justify-content-end small gap-1 my-1'>

              {topBorderjustifyTab === 2 && <div> <span className='badge rounded-pill badge-soft-primary'>Invoice paid: {invoice?.paid_date?.length ? moment(invoice?.paid_date).format("L") : "NA"}</span></div>}
              
              <div> <span className='badge rounded-pill badge-soft-danger'>#{invoice?.session?.uuid.substring(0, 6) || 'NA'}</span></div>
            </div>
          </Col></Row>



        </CardBody>




        {/* 
        <td>
          <Link
            to='#'
            className='fw-medium'
          >
            #{invoice?.session?.uuid.substring(0, 6) || 'NA'}
          </Link>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='flex-grow-1'>
              <h5 className='fs-14 mb-1'>
                {invoice?.session?.organisation?.name || 'NA'}
              </h5>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='flex-grow-1'>
              <h5 className='fs-14 mb-1'>
                {`${moment(invoice?.session?.start_time).format(
                  'dddd, MMMM Do YYYY'
                )}` || 'NA'}
              </h5>
              <p className='text-muted mb-0'>
                <span className='fw-medium'>
                  {`${moment(invoice?.session?.start_time).format('LT')}` || 'NA'}{' '}
                  -{`${moment(invoice?.session?.end_time).format('LT')}` || 'NA'}{' '}
                  ({' '}{invoice?.session?.unpaid_break_duration || ' No break'}{' '}
                  {invoice?.session?.unpaid_break_duration !== 0 ? "mins break" : ""}{' '})
                </span>
              </p>
            </div>
          </div>
        </td>
        <td>
          <h5 className='fs-14 mb-1'>
            {moment(invoice?.createdAt).format('MMMM Do YYYY')}
          </h5>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='flex-grow-1'>
              <h5 className='mb-1 text-primary fw-semibold '>£
                {invoice?.session?.status === 'withdrawn'
                  ? (
                    ((invoice?.session?.hourly_rate / 60) *
                      convertTimeToMinute(
                        invoice?.session?.start_time,
                        invoice?.session?.end_time,
                        invoice?.session?.unpaid_break_duration
                      ) *
                      invoice?.session?.withdrawal_percentage) /
                    100
                  )?.toFixed(2)
                  : (
                    (invoice?.session?.hourly_rate / 60) *
                    convertTimeToMinute(
                      invoice?.session?.start_time,
                      invoice?.session?.end_time,
                      invoice?.session?.unpaid_break_duration
                    ) +
                    Number(invoice?.session?.client_bonus_amount) + expenseAmount
                  )?.toFixed(2)}

              </h5>
              <h5 className='text-muted mb-0'>
                <span
                  className={`fw-medium ${invoice?.session?.status === 'withdrawn' &&
                    'text-decoration-line-through'
                    } `}
                />
              </h5>
              <p className='text-muted mb-0'>
                <span
                  className={`fw-medium ${invoice?.session?.status === 'withdrawn' &&
                    'text-decoration-line-through'
                    } `}
                >
                  £
                  {(
                    (invoice?.session?.hourly_rate / 60) *
                    convertTimeToMinute(
                      invoice?.session?.start_time,
                      invoice?.session?.end_time,
                      invoice?.session?.unpaid_break_duration
                    )
                  ).toFixed() || 'NA'}{' '}
                  (£
                  {invoice?.session?.hourly_rate || 'NA'} /hour) + £
                  {Number(invoice?.session?.client_bonus_amount)?.toFixed()} Bonus

                  {expenseAmount !== 0 ? " " + "+" + ` £${expenseAmount}` + " expenses" : ""}
                  
                  {invoice?.session?.pensionable === 1 ? (
                    <span
                    title='pensionable'
                    className='ms-2 fs-10 badge rounded-pill badge-soft-success'
                    >P</span>
                  ) : (
                    ''
                  )}
                </span>
              </p>
            </div>
          </div>
        </td>

        <td>
        <span className={`badge rounded-pill badge-soft-${badgeColor(invoice?.session?.status || 'NA')}`}>{invoice?.session?.status || 'NA'}</span>
        </td> */}
        <CardFooter>


          {topBorderjustifyTab === 1 && (
            <div className='hstack gap-2 pt-1 float-end'>
              {invoice?.session?.session_source === 'manual' && (
                <UncontrolledDropdown>
                  <DropdownToggle
                    href='#'
                    className='btn-soft-primary btn-sm dropdown'
                    tag='button'
                  >
                    <i className='ri-more-fill align-middle'></i>
                  </DropdownToggle>
                  <DropdownMenu className='dropdown-menu-end'>
                    <DropdownItem
                      onClick={() => {
                        tog_standard()
                        setSelectedSessionId(invoice?.uuid)
                      }}
                    >
                      <i className='ri-clipboard-fill align-bottom me-2 text-muted'></i>{' '}
                      Mark As Paid
                    </DropdownItem>{' '}
                    <DropdownItem
                      onClick={() =>
                        handleDownloadDocument(invoice?.uuid, "invoice", `invoice-${invoice?.pdf_copy.split('/')[2]}`)
                      }
                    >
                      <i className='ri-download-line align-bottom me-2 text-muted'></i>{' '}
                      Download Invoice
                    </DropdownItem>{' '}
                    <DropdownItem
                      onClick={() =>
                        fetchUpdateStatusToPaid(
                          invoice?.uuid,
                          'cancelled',
                          '',
                          token
                        )
                      }
                    >
                      <i className='ri-scissors-fill align-bottom me-2 text-muted'></i>{' '}
                      Cancel Invoice
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}

              {invoice?.session?.session_source === 'market_place' && (
                <UncontrolledDropdown>
                  <DropdownToggle
                    href='#'
                    className='btn-soft-primary btn-sm dropdown'
                    tag='button'
                  >
                    <i className='ri-more-fill align-middle'></i>
                  </DropdownToggle>
                  <DropdownMenu className='dropdown-menu-end'>
                    {/* <DropdownItem
                onClick={() => {
                  tog_standard()
                  setSelectedSessionId(
                    item?.uuid
                  )
                }}
              >
                <i className='ri-clipboard-fill align-bottom me-2 text-muted'></i>{' '}
                Mark As Paid
              </DropdownItem> */}
                    <DropdownItem
                      onClick={() =>
                        handleDownloadDocument(invoice?.uuid, "invoice", `invoice-${invoice?.pdf_copy.split('/')[2]}`)
                      }
                    >
                      <i className='ri-download-line align-bottom me-2 text-muted'></i>{' '}
                      Download Invoice
                    </DropdownItem>{' '}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </div>
          )}
          {topBorderjustifyTab === 2 && (
            <div className='hstack gap-2 pt-1 float-end'>
            <UncontrolledDropdown>
              <DropdownToggle
                href='#'
                className='btn-soft-primary btn-sm dropdown'
                tag='button'
              >
                <i className='ri-more-fill align-middle'></i>
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-end'>
                {invoice?.session?.session_source !== 'market_place' && (
                  <DropdownItem
                    onClick={() =>
                      fetchUpdateStatusToPaid(invoice?.uuid, 'unpaid', '', token)
                    }
                  >
                    <i className='ri-clipboard-fill align-bottom me-2 text-muted'></i>{' '}
                    Mark As Unpaid
                  </DropdownItem>
                )}
                <DropdownItem
                  onClick={() =>
                    handleDownloadDocument(invoice?.uuid, "invoice", `invoice-${invoice?.pdf_copy.split('/')[2]}`)
                  }
                >
                  <i className='ri-download-line align-bottom me-2 text-muted'></i>{' '}
                  Download Invoice
                </DropdownItem>{' '}
              </DropdownMenu>
            </UncontrolledDropdown>
            </div>
          )}
        </CardFooter>
      </Card>
    </>
  )
}


const InvoiceList = () => {
  const [editInvoiceData, setEditInvoiceData] = useState(
    EDIT_INVOICE_INITIAL_STATE
  )
  const [originalEditInvoiceData, setOriginalEditInvoiceData] = useState(EDIT_INVOICE_INITIAL_STATE);
  const [topBorderjustifyTab, settopBorderjustifyTab] = useState(0)
  const [selectedSessionId, setSelectedSessionId] = useState()
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [markPaid, setMarkPaid] = useState(false)
  const [page, setPage] = useState(1)
  const [showSessionEditModal, setShowSessionEditModal] = useState(false)
  const [completedSessions, setCompletedSessions] = useState([])
  const [showGenerateInvoiceModal, setShowGenerateInvoiceModal] =
    useState(false)
  const [expenseData, setExpenseData] = useState([INVOICE_INITIAL_STATE])
  const [editExpenseData, setOriginalEditExpenceData] = useState({
    amount: '',
    expenseType: ''
  })
  
  const [expenseTypes, setExpenseTypes] = useState([])
  const [selectedDate, setSelectedDate] = useState()
  const [selectedSession, setSelectedSession] = useState()
  const [selectedSessionInfo, setSelectedSessionInfo] = useState()
  const [selectedBonusFeePercentage, setSelectedBonusFeePercentage] = useState('')
  const [pensionable, setPensionable] = useState(false)
  const [expenseTotal, setExpensesTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
 
  useEffect(() => {
    setOriginalEditInvoiceData(editInvoiceData);
  }, [editInvoiceData]);

  useEffect(() => {
    if (selectedSession?.expenses !== 0) {
      let amount = 0
      selectedSession?.expenses?.map(_ => {
        amount += +_?.amount
      })
      setExpensesTotal(amount)
    }
  }, [selectedSession])

  const history = useHistory()
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (topBorderjustifyTab === 0) fetchWorkedSessionAPI('', 20, page, token)
    if (topBorderjustifyTab === 1)
      fetchInvoicesByStatus('Unpaid', '', 20, page, token)
    if (topBorderjustifyTab === 2)
      fetchInvoicesByStatus('Paid', '', 20, page, token)
    if (topBorderjustifyTab === 3)
      fetchInvoicesByStatus('Cancelled', '', 20, page, token)
    fetchExpenseTypes(token)
  }, [topBorderjustifyTab, page])

  const fetchWorkedSessionAPI = (orgId, limit, page, token) => {
    setIsLoading(true)
    invoiceServices
      .getWorkedSessions(orgId, limit, page, token)
      .then(resp => {
        if (resp.success) {
          setCompletedSessions(resp.data)
          setTotalPageCount(resp?.totalPages === 0 ? 1 : resp?.totalPages)
        }else{
          if(resp?.message === "Unauthorised"){
            localStorage.clear();
            history.push('/login')
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchInvoicesByStatus = (status, orgId, limit, page, token) => {
    setCompletedSessions([])
    setIsLoading(true)
    invoiceServices
      .getInvoicesFromStatus(status, orgId, limit, page, token)
      .then(resp => {
        setCompletedSessions(resp.data)
        setTotalPageCount(resp?.totalPages === 0 ? 1 : resp?.totalPages)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function tog_standard() {
    setMarkPaid(!markPaid)
  }

  const fetchUpdateStatusToPaid = (id, status, data, token) => {
    invoiceServices
      .postUpdateInvoiceStatus(id, status, data, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          setMarkPaid(false)
          if (status === 'paid') settopBorderjustifyTab(2)
          if (status === 'unpaid') settopBorderjustifyTab(1)
          if (status === 'cancelled') settopBorderjustifyTab(3)
        } else {
          toast.error(resp.message)
          setMarkPaid(false)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchExpenseTypes = token => {
    setIsLoading(true)
    invoiceServices
      .getExpenseTypes(token)
      .then(resp => {
        if (resp.success) {
          setExpenseTypes(resp.data)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const handlePlusClick = () => {
    let cED = [...expenseData]
    if (cED.length < 4) {
      cED.push({
        amount: '',
        expenseType: ''
      })
      setExpenseData(cED)
    }
  }

  const handleMinusClick = index => {
    let cED = [...expenseData]
    cED.splice(index, 1)
    setExpenseData(cED)
  }

  const handleEditInvoiceChangeData = ({ target: { value, name } }) => {
    let copiedObj = { ...originalEditInvoiceData }
    copiedObj[name] = value
    setOriginalEditInvoiceData(copiedObj)
  }

  const isEditSession = () => {
    return JSON.stringify(editInvoiceData) !== JSON.stringify(originalEditInvoiceData);
  };

  const isEditExpenceData = () => {
    return JSON.stringify(editExpenseData) !== JSON.stringify(expenseData);
  }

  const handleExpenceTableChange = ({ target: { value, name } }, idx) => {
    let copiedExpenseData = [...expenseData]
    const newAmount = parseFloat(value);

    if (name === 'amount' && isNaN(value)) {
      value = 0;
    }
    copiedExpenseData[idx][name] = value
    setExpenseData(copiedExpenseData)
  }

  const fetchAmendSessionAPI = () => {
    const data = {
      session_id: completedSessions[selectedSessionId].uuid,
      start_time: `${moment(
        completedSessions[selectedSessionId].start_time
      ).format('YYYY-MM-DD')} ${originalEditInvoiceData.startTime}:00`,
      end_time: `${moment(completedSessions[selectedSessionId].end_time).format(
        'YYYY-MM-DD'
      )} ${originalEditInvoiceData.endTime}:00`,
      break_duration: originalEditInvoiceData.breakDuration,
      hourly_rate: originalEditInvoiceData.hourlyRate,
      expence: expenseData.filter(item => item.amount > 0) || []
    }

    if(isEditSession() || isEditExpenceData()){
      invoiceServices
      .postAmendSession(data, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          setShowSessionEditModal(false)
          fetchWorkedSessionAPI('', 20, page, token)
        } else {
          toast.error(resp.message)
          setShowSessionEditModal(false)
        }
      })
      .catch(err => console.log(err))
    }
    else{
      setShowSessionEditModal(false)
    }
    
  }

  const fetchGenerateInvoice = (pensionStatus) => {
    const durationInMinutes = convertTimeToMinute(
      completedSessions[selectedSessionId].start_time,
      completedSessions[selectedSessionId].end_time,
      completedSessions[selectedSessionId].unpaid_break_duration
    )

    const data = {
      org_id: completedSessions[selectedSessionId].organisation.uuid,
      session_id: completedSessions[selectedSessionId].uuid,
      total_amount:
        selectedSession?.status === 'withdrawn'
          ? +(
            ((selectedSession?.hourly_rate / 60) *
              convertTimeToMinute(
                selectedSession?.start_time,
                selectedSession?.end_time,
                selectedSession?.unpaid_break_duration
              ) *
              selectedSession?.withdrawal_percentage) /
            100
          )?.toFixed(0)
          : Number(
            (
              (completedSessions[selectedSessionId]?.hourly_rate / 60) *
              durationInMinutes
            ).toFixed(2)
          ),
      email_me_copy: false,
      expence:
        selectedSession?.status === 'withdrawn'
          ? []
          : selectedSession?.expenses,
      gp_locum_basic_fee:
        selectedSession?.status === 'withdrawn'
          ? '0'
          : `${((
            (completedSessions[selectedSessionId]?.hourly_rate / 60) *
            durationInMinutes
          ) + expenseTotal).toFixed(2)}`,
      pensionable_pay:
        (selectedSession?.users[0]?.pension_client[0]?.claim_pension === 1 && pensionStatus) ? (selectedSession?.status === 'withdrawn'
          ? '0'
          : calculatePensionablePay(
            (selectedSession?.hourly_rate / 60) *
            convertTimeToMinute(
              selectedSession?.start_time,
              selectedSession?.end_time,
              selectedSession?.unpaid_break_duration
            ),
            expenseTotal
          )) : '0',

      employers_contribution:
        selectedSession?.status === 'withdrawn'
          ? '0'
          : `${calculateEmployersSuperannuation(
            (selectedSession?.hourly_rate / 60) *
            convertTimeToMinute(
              selectedSession?.start_time,
              selectedSession?.end_time,
              selectedSession?.unpaid_break_duration
            ),
            expenseTotal,
            // selectedSession?.users[0]?.pension_client[0]
            //   ?.tier_contribution_rate?.type
            14.38
            || 0
          ) || 0
          }`,
      pensionClaim: pensionStatus || null
    }

    setIsLoading(true)
    invoiceServices
      .postGenerateInvoice(data, token, "invoice")
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          setPensionable(false)
          setShowGenerateInvoiceModal(false)
          fetchWorkedSessionAPI('', 20, page, token)
        } else {
          toast.error(resp.message)
          setPensionable(false)
          setShowGenerateInvoiceModal(false)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const handleDownloadDocument = (pdf_uuid, status) => {
    setIsLoading(true)
    accountServices.downloadDocument(pdf_uuid, status, token)
      .then(resp => fetchPDF(resp))
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const handleEditInvoiceClick = index => {
    setSelectedSessionId(index)
    setShowSessionEditModal(true)

    if(completedSessions[index]?.expenses.length > 0){
    const expensesWithTypes = completedSessions[index]?.expenses.map(expense => ({
      ...expense,
      expenseType: expense.invoice_expense_types[0]?.type
    }));
    setExpenseData(expensesWithTypes)
    }
    
    let copiedObj = { ...editInvoiceData }
    copiedObj.startTime = moment(completedSessions[index]?.start_time).format(
      'HH:mm'
    )
    copiedObj.endTime = moment(completedSessions[index]?.end_time).format(
      'HH:mm'
    )
    copiedObj.breakDuration = completedSessions[index]?.unpaid_break_duration
    copiedObj.hourlyRate = completedSessions[index]?.hourly_rate

    setEditInvoiceData(copiedObj)
  }

  const calculateSelectedInfo = item => {
    setSelectedSessionInfo({
      session: {
        ...item,
        Session_features: item?.features?.map((item) => {
          return {
            Feature: item
          }
        })
      }
    })
    setSelectedBonusFeePercentage(item?.users[0]?.User_profile?.bonus_fee_percentage)
  }

  document.title = 'Invoices | airGP'

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {isLoading && <SpinnerComponent />}
          <BreadCrumb
            title='Invoice List'
            pageTitle='Invoices'
          // to={'/'}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader className='mb-0 pb-0'>
                  <Nav tabs className='nav-tabs'>
                    {INVOICES_TABS.map((tab, index) => {
                      return (
                        <NavItem key={index}>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: topBorderjustifyTab === index
                            })}
                            onClick={() => {
                              settopBorderjustifyTab(index)
                              setPage(1)
                            }}
                          >
                            {tab}
                          </NavLink>
                        </NavItem>
                      )
                    })}
                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent
                    activeTab={topBorderjustifyTab}
                    className='text-muted '
                  >
                    <TabPane
                      tabId={topBorderjustifyTab}
                      id='nav-border-top-home'
                    >
                      {/* Desktop View */}
                      <div className='live-preview  d-none d-xl-block'>
                        <div className='table-responsive table-card '>
                          <Table className='align-middle table-hover table-nowrap mb-0'>
                            <thead className='text-muted'>
                              <tr>
                                <th></th>
                                <th scope='col'>ID</th>
                                <th scope='col'>Organisation</th>
                                <th scope='col'>Date & Time</th>
                                {topBorderjustifyTab !== 0 && <th scope='col'>Invoice Date</th>}
                                <th scope='col'>Fee</th>
                                {topBorderjustifyTab == 2 && <th scope='col'>Paid Date</th>}
                                <th scope='col'>Status</th>
                                <th scope='col'>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {completedSessions?.length !== 0 ? (
                                completedSessions?.map((item, index) => {

                                  let expenceTotalAmt = 0

                                  for (let i = 0; i < item?.session?.expenses?.length; i++) {
                                    if(item.session.expenses[i].active){
                                      expenceTotalAmt += +item.session.expenses[i].amount
                                    }
                                  }

                                  return (
                                    <React.Fragment key={index}>
                                      {topBorderjustifyTab === 0 && (
                                        <tr key={item?.uuid}>
                                          <td></td>
                                          <td>
                                            <Link
                                              to='#'
                                              onClick={() => {
                                                // history.push(
                                                //   `/session-details/${item?.uuid}/manual`
                                                // )
                                                calculateSelectedInfo(item);
                                                setShowInfoModal(true)
                                              }}
                                            >
                                              #
                                              {item?.uuid.substring(0, 5) ||
                                                'NA'}
                                            </Link>
                                          </td>
                                          <td className='text-truncate' style={{maxWidth: 220}}>

                                                  {item?.organisation
                                                    ?.name || 'NA'}
                                                  <p className='text-muted small mb-0'>

                                                  {item?.organisation
                                                    ?.postcode || 'NA'}
                                                  </p>
                                          </td>
                                          <td>
                                                  {`${moment(
                                                    item?.start_time
                                                  ).format(
                                                    'ddd, Do MMMM YYYY'
                                                  )}` || 'NA'}

                                                <p className='text-muted small mb-0'>
                                                  <span className='fw-medium'>
                                                    {`${moment(
                                                      item?.start_time
                                                    ).format('LT')}` ||
                                                      'NA'}{' '}
                                                    -
                                                    {`${moment(
                                                      item?.end_time
                                                    ).format('LT')}` ||
                                                      'NA'}{' '}
                                                    ({' '}{item?.unpaid_break_duration || ' No break'}{' '}
                                                    {item?.unpaid_break_duration !== 0 ? "mins break" : ""}{' '})
                                                  </span>
                                                </p>
                                          </td>
                                          <td>
                                                  £
                                                  {item.status ===
                                                    'withdrawn'
                                                    ? (
                                                      ((item?.hourly_rate /
                                                        60) *
                                                        convertTimeToMinute(
                                                          item?.start_time,
                                                          item?.end_time,
                                                          item?.unpaid_break_duration
                                                        ) *
                                                        item?.withdrawal_percentage) /
                                                      100
                                                    )?.toFixed(2)
                                                    : (
                                                      (item?.hourly_rate /
                                                        60) *
                                                      convertTimeToMinute(
                                                        item?.start_time,
                                                        item?.end_time,
                                                        item?.unpaid_break_duration
                                                      ) +
                                                      Number(
                                                        item?.client_bonus_amount
                                                      )
                                                    )?.toFixed(2)}

                                                <p className='text-muted small mb-0'>
                                                  <span
                                                    className={`fw-medium ${item.status ===
                                                      'withdrawn' &&
                                                      'text-decoration-line-through'
                                                      } `}
                                                  >
                                                    £
                                                    {(
                                                      (item?.hourly_rate /
                                                        60) *
                                                      convertTimeToMinute(
                                                        item?.start_time,
                                                        item?.end_time,
                                                        item?.unpaid_break_duration
                                                      )
                                                    ).toFixed(2) ||
                                                      'NA'}{' '}
                                                    (£
                                                    {item?.hourly_rate ||
                                                      'NA'}{' '}
                                                    /hour)
                                                    {Number(
                                                      item?.client_bonus_amount
                                                      )?.toFixed() > 0 && (
                                                      <> + £
                                                    {Number(item?.client_bonus_amount)?.toFixed(2)} {' '}
                                                    bonus
                                                      </>
                                                    )}
                                                    {item?.pensionable ===
                                                      1 ? (
                                                      <i
                                                        title='Pensionable'
                                                        className='ms-1 ri-file-ppt-line'
                                                      ></i>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </span>
                                                </p>
                                          </td>
                                          <td>
                                            {/* <span className={`badge rounded-pill badge-soft-${badgeColor(
                                              item?.invoice_status ? item?.invoice_status : item?.status || 'NA'
                                            )}`}>{item?.invoice_status ? item?.invoice_status : item?.status || 'NA'}</span> */}
                                            <Badge
                                                  color={badgeColor(
                                                    item?.invoice_status ? item?.invoice_status : item?.status || 'NA'
                                                  )}
                                                  className='ms-auto'
                                                >
                                                  {item?.invoice_status ? item?.invoice_status : item?.status || 'NA'}
                                                </Badge>
                                          </td>
                                          <td>
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                href='#'
                                                className='btn-soft-primary btn-sm dropdown'
                                                tag='button'
                                              >
                                                <i className='ri-more-fill align-middle'></i>
                                              </DropdownToggle>
                                              <DropdownMenu className='dropdown-menu-end'>
                                                <DropdownItem
                                                  onClick={() => {
                                                    document.body.style.overflow = 'visible'
                                                  
                                                    handleEditInvoiceClick(
                                                      index
                                                      
                                                    )
                                                    
                                                    
                                                  }}
                                                >
                                                  <i className='ri-edit-line align-bottom me-2 text-muted'></i>{' '}
                                                  Edit
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    item?.pensionable === 1
                                                      ? setPensionable(true)
                                                      : setShowGenerateInvoiceModal(
                                                        true
                                                      )

                                                    setSelectedSessionId(
                                                      index
                                                    )

                                                    setSelectedSession(item)
                                                    document.body.style.overflow = 'visible'
                                                  }}
                                                >
                                                  <i className='ri-file-edit-line align-bottom me-2 text-muted'></i>{' '}
                                                  Create Invoice
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </td>
                                        </tr>
                                      )}
                                      {topBorderjustifyTab === 1 && (
                                        <InvoiceTableRow
                                          invoice={item}
                                          topBorderjustifyTab={
                                            topBorderjustifyTab
                                          }
                                          index={index}
                                          tog_standard={tog_standard}
                                          setSelectedSessionId={
                                            setSelectedSessionId
                                          }
                                          fetchUpdateStatusToPaid={
                                            fetchUpdateStatusToPaid
                                          }
                                          expenseAmount={expenceTotalAmt}
                                        />
                                      )}
                                      {topBorderjustifyTab === 2 && (
                                        <InvoiceTableRow
                                          invoice={item}
                                          topBorderjustifyTab={
                                            topBorderjustifyTab
                                          }
                                          index={index}
                                          tog_standard={tog_standard}
                                          fetchUpdateStatusToPaid={
                                            fetchUpdateStatusToPaid
                                          }
                                          expenseAmount={expenceTotalAmt}
                                        />
                                      )}
                                      {topBorderjustifyTab === 3 && (
                                        <tr key={index}>
                                          <td>
                                            <div className='form-check'>

                                            </div>
                                          </td>
                                          <td>
                                            <Link
                                              to='#'
                                            >
                                              #{index + 1 || 'NA'}
                                            </Link>
                                          </td>

                                          <td>
                                            {item?.session?.organisation
                                              ?.name || 'NA'}
                                          </td>
                                          <td>{item?.private_note}</td>
                                          <td>
                                            {`${moment(
                                              item?.session?.start_time
                                            ).format('ll')}` || 'NA'}
                                          </td>
                                          <td>
                                            {`${moment(
                                              item?.session?.start_time
                                            ).format('LT')}` || 'NA'}
                                          </td>
                                          <td>
                                            {' '}
                                            {`${moment(
                                              item?.session?.end_time
                                            ).format('LT')}` || 'NA'}
                                          </td>

                                          <td>
                                            {item?.session?.hourly_rate ||
                                              '0'}
                                          </td>
                                          <td>
                                            {item?.session
                                              ?.session_source === 'manual'
                                              ? `${item?.session?.fee}`
                                              : (
                                                (item?.session
                                                  ?.hourly_rate /
                                                  60) *
                                                convertTimeToMinute(
                                                  item?.session
                                                    ?.start_time,
                                                  item?.session?.end_time,
                                                  item?.session
                                                    ?.unpaid_break_duration
                                                )
                                              ).toFixed() || 'NA'}
                                          </td>
                                          <td>
                                            <Badge
                                              color={badgeColor(
                                                item?.session
                                                  ?.session_source || 'NA'
                                              )}
                                              className='ms-auto'
                                            >
                                              {item?.session
                                                ?.session_source || 'NA'}
                                            </Badge>
                                          </td>
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td colSpan={9} className='text-muted text-center'>No data found</td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>

                      {/* Mobile View */}
                      <div className='live-preview  d-xl-none'>
                        {completedSessions?.length !== 0 ? (
                          completedSessions?.map((item, index) => {

                            let expenceTotalAmt = 0

                            for (let i = 0; i < item?.session?.expenses?.length; i++) {
                              expenceTotalAmt += +item.session.expenses[i].amount
                            }

                            return (
                              <React.Fragment key={index}>
                                {topBorderjustifyTab === 0 && (
                                  <Card className='d-xl-none rounded-1' key={item?.uuid}>
                                    <CardBody className='text-dark'>


                                      <Row className='pb-2'>
                                        <Col xs={8} className='fw-semibold text-dark'>
                                          {item?.organisation?.name || 'NA'}
                                        </Col>
                                        <Col xs={4} className='text-end'>
                                          <span className={`badge rounded-pill badge-soft-${badgeColor(
                                            item?.invoice_status ? item?.invoice_status : item?.status || 'NA'
                                          )}`}>{item?.invoice_status ? item?.invoice_status : item?.status || 'NA'}</span>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col>
                                          {`${moment(
                                            item?.start_time
                                          ).format(
                                            'dddd'
                                          )}` || 'NA'}

                                        </Col>
                                        <Col className='text-end fw-semibold text-primary fs-20 lh-1'>
                                          £
                                          {item.status ===
                                            'withdrawn'
                                            ? (
                                              ((item?.hourly_rate /
                                                60) *
                                                convertTimeToMinute(
                                                  item?.start_time,
                                                  item?.end_time,
                                                  item?.unpaid_break_duration
                                                ) *
                                                item?.withdrawal_percentage) /
                                              100
                                            )?.toFixed(2)
                                            : (
                                              (item?.hourly_rate /
                                                60) *
                                              convertTimeToMinute(
                                                item?.start_time,
                                                item?.end_time,
                                                item?.unpaid_break_duration
                                              ) +
                                              Number(
                                                item?.client_bonus_amount
                                              )
                                            )?.toFixed(2)}
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col xs={8}>
                                          {`${moment(
                                            item?.start_time
                                          ).format(
                                            'Do MMMM YYYY'
                                          )}` || 'NA'}
                                        </Col>
                                        <Col xs={4} className={`text-muted text-end ${item.status ===
                                          'withdrawn' &&
                                          'text-decoration-line-through'
                                          }`}>

                                          £
                                          {(
                                            (item?.hourly_rate /
                                              60) *
                                            convertTimeToMinute(
                                              item?.start_time,
                                              item?.end_time,
                                              item?.unpaid_break_duration
                                            )
                                          ).toFixed(2) ||
                                            'NA'}
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col>
                                          {`${moment(
                                            item?.start_time
                                          ).format('LT')}` ||
                                            'NA'}{' '}
                                          -
                                          {`${moment(
                                            item?.end_time
                                          ).format('LT')}` ||
                                            'NA'}{' '}

                                        </Col>
                                        <Col className={`text-muted text-end ${item.status ===
                                          'withdrawn' &&
                                          'text-decoration-line-through'
                                          }`}>
                                          £
                                          {item?.hourly_rate ||
                                            'NA'}
                                          /h
                                        </Col>
                                      </Row>



                                      <Row>
                                        <Col>
                                          {item?.unpaid_break_duration || ' No break'}{' '}
                                          {item?.unpaid_break_duration !== 0 ? "mins break" : ""}
                                        </Col>
                                        <Col className={`text-muted text-end ${item.status ===
                                          'withdrawn' &&
                                          'text-decoration-line-through'
                                          }`}>
                                        {Number(
                                          item?.client_bonus_amount
                                          )?.toFixed() > 0 && (
                                          <>  £
                                        {Number(item?.client_bonus_amount)?.toFixed(2)} {' '}
                                        bonus
                                          </>
                                        )}
                                        </Col>
                                      </Row>
                                      <div className='d-flex flex-row justify-content-end small gap-1 my-1'>

                                        <Link
                                          to='#'
                                          onClick={() => {
                                            calculateSelectedInfo(item);
                                            setShowInfoModal(true)
                                          }}
                                        >
                                          <span className='badge rounded-pill badge-soft-danger'>
                                            #{item?.uuid.substring(0, 5) || 'NA'}
                                          </span>
                                        </Link>

                                        {item?.pensionable ===
                                          1 ? (
                                          <div><span className='badge rounded-pill badge-soft-success'>Pensionable</span></div>
                                        ) : (
                                          ''
                                        )}
                                      </div>


                                    </CardBody>
                                    <CardFooter>
                                      <div className='hstack gap-2 pt-1 float-end'>
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            href='#'
                                            className='btn-soft-primary btn-sm dropdown'
                                            tag='button'
                                          >
                                            <i className='ri-more-fill align-middle'></i>
                                          </DropdownToggle>
                                          <DropdownMenu className='dropdown-menu-end'>
                                            <DropdownItem
                                              onClick={() =>
                                                handleEditInvoiceClick(
                                                  index
                                                )
                                              }
                                            >
                                              <i className='ri-edit-line align-bottom me-2 text-muted'></i>{' '}
                                              Edit
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() => {
                                                item?.pensionable === 1
                                                  ? setPensionable(true)
                                                  : setShowGenerateInvoiceModal(
                                                    true
                                                  )

                                                setSelectedSessionId(
                                                  index
                                                )

                                                setSelectedSession(item)
                                              }}
                                            >
                                              <i className='ri-file-edit-line align-bottom me-2 text-muted'></i>{' '}
                                              Create Invoice
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </div>
                                    </CardFooter>
                                  </Card>
                                )}
                                {topBorderjustifyTab === 1 && (
                                  <InvoiceTableCard
                                    invoice={item}
                                    topBorderjustifyTab={
                                      topBorderjustifyTab
                                    }
                                    index={index}
                                    tog_standard={tog_standard}
                                    setSelectedSessionId={
                                      setSelectedSessionId
                                    }
                                    fetchUpdateStatusToPaid={
                                      fetchUpdateStatusToPaid
                                    }
                                    expenseAmount={expenceTotalAmt}
                                  />
                                )}
                                {topBorderjustifyTab === 2 && (
                                  <InvoiceTableCard
                                    invoice={item}
                                    topBorderjustifyTab={
                                      topBorderjustifyTab
                                    }
                                    index={index}
                                    tog_standard={tog_standard}
                                    fetchUpdateStatusToPaid={
                                      fetchUpdateStatusToPaid
                                    }
                                    expenseAmount={expenceTotalAmt}
                                  />
                                )}
                                {topBorderjustifyTab === 3 && (
                                  <tr key={index}>
                                    <td>
                                      <div className='form-check'>
                                        <Label
                                          className='form-check-label'
                                          htmlFor='cardtableCheck01'
                                        ></Label>
                                      </div>
                                    </td>
                                    <td>
                                      <Link
                                        to='#'
                                        className='fw-medium'
                                      >
                                        #{index + 1 || 'NA'}
                                      </Link>
                                    </td>

                                    <td>
                                      {item?.session?.organisation
                                        ?.name || 'NA'}
                                    </td>
                                    <td>{item?.private_note}</td>
                                    <td>
                                      {`${moment(
                                        item?.session?.start_time
                                      ).format('ll')}` || 'NA'}
                                    </td>
                                    <td>
                                      {`${moment(
                                        item?.session?.start_time
                                      ).format('LT')}` || 'NA'}
                                    </td>
                                    <td>
                                      {' '}
                                      {`${moment(
                                        item?.session?.end_time
                                      ).format('LT')}` || 'NA'}
                                    </td>

                                    <td>
                                      {item?.session?.hourly_rate ||
                                        '0'}
                                    </td>
                                    <td>
                                      {item?.session
                                        ?.session_source === 'manual'
                                        ? `${item?.session?.fee}`
                                        : (
                                          (item?.session
                                            ?.hourly_rate /
                                            60) *
                                          convertTimeToMinute(
                                            item?.session
                                              ?.start_time,
                                            item?.session?.end_time,
                                            item?.session
                                              ?.unpaid_break_duration
                                          )
                                        ).toFixed() || 'NA'}
                                    </td>
                                    <td>
                                      <Badge
                                        color={badgeColor(
                                          item?.session
                                            ?.session_source || 'NA'
                                        )}
                                        className='ms-auto'
                                      >
                                        {item?.session
                                          ?.session_source || 'NA'}
                                      </Badge>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            )
                          })
                        ) : (
                          <div className='d-xl-none text-center mt-4'>
                            <p>No result found</p>
                          </div>
                        )}

                      </div>

                    </TabPane>
                  </TabContent>
                </CardBody>
                <PaginationV2
                  totalPageCount={totalPageCount === 0 ? 1 : totalPageCount}
                  previousDisabled={page === 1}
                  nextDisabled={totalPageCount === page ? true : false}
                  previousPageCall={() => setPage(page - 1)}
                  currentPage={page}
                  nextPageCalls={() => setPage(page + 1)}
                  onPageInputChange={({ target: { value } }) => {
                    var regex = new RegExp(`^[1-${totalPageCount}]$`)
                    if (value <= totalPageCount && value !== 0) {
                      setPage(value)
                    }
                  }}
                />
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={showSessionEditModal}
            toggle={() => {
              setShowSessionEditModal(!showSessionEditModal)
            }}
            className='modal-dialog-scrollable'
          >
            <ModalHeader
              className='p-3 bg-airgp modal-title'
              toggle={() => {
                setShowSessionEditModal(!showSessionEditModal)
              }}
            >
              <div className="modal-title text-white h5">
                Edit session
              </div>
            </ModalHeader>
            <ModalBody>
            <Alert color='info' className='text-center'>
              Any changes will be sent to the GP surgery for approval
            </Alert>
              <div className='d-flex justify-content-between'>
                <div className='mb-3 add-session-modal'>
                  <Label htmlFor='start-time' className='form-label'>Start Time</Label>
                  <Flatpickr
                    id='start-time'
                    name='startTime'
                    className='form-control'
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      time_24hr: true
                    }}
                    placeholder='Start Time'
                    onChange={e => {
                      const copiedObj = { ...originalEditInvoiceData }
                      copiedObj.startTime = moment(e[0]).format('HH:mm')
                      setOriginalEditInvoiceData(copiedObj)
                    }}
                    value={originalEditInvoiceData.startTime || '00:00'}
                  />
                </div>
                <div className='mb-3'>
                  <Label htmlFor='end-time' className='form-label'>End Time</Label>
                  <Flatpickr
                    id='end-time'
                    name='endTime'
                    className='form-control'
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      time_24hr: true
                    }}
                    placeholder='End Time'
                    onChange={e => {
                      const copiedObj = { ...originalEditInvoiceData }
                      copiedObj.endTime = moment(e[0]).format('HH:mm')
                      setOriginalEditInvoiceData(copiedObj)
                    }}
                    value={originalEditInvoiceData.endTime || '00:00'}
                  />
                </div>
              </div>
              <div className='mb-3'>
                <Label htmlFor='break-duration'>Unpaid Break Duration</Label>
                <Col lg={12}>
                  <div className='input-group'>

                    <Input
                      type='number'
                      className={'form-control d-block'}
                      name='breakDuration'
                      id='break-duration'
                      placeholder='Enter Break Duration in minutes'
                      onChange={handleEditInvoiceChangeData}
                      value={originalEditInvoiceData.breakDuration}
                    />                  <span className='input-group-text'>
                      Minutes
                    </span>
                  </div>
                </Col>
              </div>
              <div className='mb-3'>
                <Label htmlFor='hourly-rate'>Hourly rate</Label>
                <Col lg={12}>
                  <div className='input-group'>
                    <span className='input-group-text' id='basic-addon1'>
                      £
                    </span>
                    <Input
                      type='number'
                      className={'form-control d-block'}
                      name='hourlyRate'
                      id='hourly-rate'
                      placeholder='Enter Hourly rate in Pounds'
                      onChange={handleEditInvoiceChangeData}
                      value={originalEditInvoiceData.hourlyRate}
                    />
                  </div>
                </Col>
              </div>
              <div>
                {expenseData?.map((_, index) => {
                  return (
                    <Row key={index}>
                      <Col lg={6} md={6}>
                        <div className='mb-3'>
                          <div className='mb-2'>
                            <Label htmlFor={`expenses-type-${index}`}>Expense</Label>
                            <select
                              id={`expenses-type-${index}`}
                              className='form-select'
                              onChange={event =>
                                handleExpenceTableChange(event, index)
                              }
                              name='expenseType'
                              value={_?.expenseType}
                            >
                              <option>Type</option>
                              {expenseTypes?.map((_, index) => (
                                <option key={index}>{_?.type}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Label htmlFor={`expenses-amount-${index}`}>Amount</Label>
                        <div className='input-group'>
                          <span className='input-group-text'>
                            £
                          </span>
                          <Input
                            type='number'
                            className={'form-control d-block'}
                            name='amount'
                            id={`expenses-amount-${index}`}
                            placeholder='Amount'
                            onChange={event =>
                              handleExpenceTableChange(event, index)
                            }
                            value={_?.amount !== 0 ? _.amount : ''}
                          />
                        </div>
                      </Col>
                      {index === 0 ? (
                        <Col lg={2}>
                          <Label htmlFor='expense-add'>Add</Label>
                          <div>
                            <button
                              id='expense-add'
                              className='btn btn-light'
                              onClick={handlePlusClick}
                            >
                              <i className='ri-add-line align-bottom '></i>
                            </button>
                          </div>
                        </Col>
                      ) : (
                        <Col lg={2}>
                          
                          <Label htmlFor='event-location'>&nbsp;</Label>
                          <div>
                            <button
                              className='btn btn-light'
                              onClick={() => handleMinusClick(index)}
                            >
                              <i className='ri-subtract-line align-bottom '></i>
                            </button>
                          </div>
                        </Col>
                      )}
                    </Row>
                  )
                })}
              </div>
            </ModalBody>
            <div className='modal-footer'>

              <Button color='primary' onClick={fetchAmendSessionAPI}>
                Edit
              </Button>
              <Button
                color='light'
                onClick={() => {
                  setShowSessionEditModal(!showSessionEditModal)
                }}
              >
                Close
              </Button>
            </div>
          </Modal>

          <Modal
            isOpen={markPaid}
            toggle={() => {
              tog_standard()
            }}
          >
            <ModalHeader
              className='p-3 bg-soft-info modal-title'
              toggle={() => {
                tog_standard()
              }}
            >
              Mark as paid
            </ModalHeader>
            <ModalBody>
              <div className='mt-3'>
                <Label className='form-label mb-0'>Please select a date</Label>
                <Flatpickr
                  className='form-control'
                  options={{
                    inline: true,
                    dateFormat: 'd M, Y'
                  }}
                  onValueUpdate={e => setSelectedDate(e)}
                />
              </div>
            </ModalBody>
            <div className='modal-footer'>
              <Button
                color='light'
                onClick={() => {
                  tog_standard()
                }}
              >
                Close
              </Button>
              <Button
                color='primary'
                onClick={() =>
                  fetchUpdateStatusToPaid(
                    selectedSessionId,
                    'paid',
                    {
                      paidDate: moment(selectedDate[0]).format('YYYY-MM-DD')
                    },
                    token
                  )
                }
              >
                Paid
              </Button>
            </div>
          </Modal>
        </Container>
        <GenerateInvoiceConfirmation
          show={pensionable}
          onEditClick={() => {
            setPensionable(false)
            handleEditInvoiceClick(selectedSessionId)
          }}
          onDeleteClick={fetchGenerateInvoice}
          onCloseClick={() => {
            setPensionable(false)
            document.body.style.overflow = 'visible'
          }}
          // deleteMsg='Generate invoice and pension form for this session ?'
          btnName='Generate'
          sessionData={selectedSession}
        />

        <SessionListViewInfoModal
          open={showInfoModal}
          sessionDetails={selectedSessionInfo}
          bonusPercentage={selectedBonusFeePercentage}
          handleClose={() => setShowInfoModal(!showInfoModal)}
        />

        <ConfirmationModal
          show={showGenerateInvoiceModal}
          onDeleteClick={() => fetchGenerateInvoice()}
          onCloseClick={() => {
            setShowGenerateInvoiceModal(false);
            document.body.style.overflow = 'visible'
            
          }}
          deleteMsg='Generate invoice for this session ?'
          btnName='Generate'
          sessionData={selectedSession}
        />
      </div>
    </React.Fragment>
  )
}

export default InvoiceList
