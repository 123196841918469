import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Form,
  Table,
  UncontrolledTooltip,
  Badge,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  ModalFooter,
  Row,
  Col
} from "reactstrap";

import parse from "html-react-parser"

import { scheduleServices } from '../../services/scheduleServices';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link, useHistory, useParams } from 'react-router-dom';
import io from 'socket.io-client';
import ConfirmationModal from './ConfirmationModal';
import { convertTimeToMinute, calculateEmployersContribution, tokenDecode } from '../../utils/constants';
import classnames from 'classnames';

import SimpleBar from 'simplebar-react';
import fetchPDF from '../../common/fetchPDF';
import { socket } from '../../socket/socket';
import { accountServices } from '../../services/accountServices'



//const socket = io.connect(process.env.REACT_APP_SOCKET_BASE_URL);

const SessionDetailsModal = ({
  open,
  handleClose,
  toggle,
  sessionDetails,
  userSessionData,
  sessionId,
  fetchUserSessions,
  bonusPercantage,
  monthStartDate,
  monthEndDate,
  session
}) => {
  const token = localStorage.getItem("token");
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [appliedModal, setShowAppliedModal] = useState(false);
  const [showWithdrawn, setShowWithdrawnModal] = useState(false);
  const [showApplyModal, setShowApplyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();

  useEffect(()=>{
    fetchUserProfileData(token);
  }, []);
  

  const toggleModal = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const [justifyTab, setjustifyTab] = useState("1");
  const justifyToggle = (tab) => {
    if (justifyTab !== tab) {
      setjustifyTab(tab);
    }
  };

  const handleDeleteEvent = () => {
    setIsLoading(true);
    scheduleServices
      .deleteUserSession(sessionId, token)
      .then((resp) => {
        if (resp === 204) {
          toast.success("Session deleted");
          // history.push("/");
          setDeleteModal(false);
          handleClose();
          fetchUserSessions(monthStartDate, monthEndDate, token);
        } else {
          toast.error("session does not exist");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const fetchCancelApi = () => {
    scheduleServices
      .cancelAppliedSession(sessionId, token)
      .then((resp) => {
        if (resp.success) {
          toast.success(resp.message);
          // history.push("/");
          setShowAppliedModal(false);
          // handleClose()
          fetchUserSessions(monthStartDate, monthEndDate, token);
        } else {
          toast.error(resp.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchUserProfileData = token => {
    accountServices
      .getUserProfileDetails(token)
      .then(resp => {
        if (resp.data && resp.data.user) {
          // setProfileImg(resp?.data?.profile_photo_link)
          setUser(resp?.data?.user)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchWithdrawnSessionClient = () => {
    socket.emit("withdraw-session", {
      pm_id: userSessionData?.session?.user_id,
      withdrawn_by_pm: false,
      withdrawn_by_client: true,
      content: "withdrawn",
      sender: {
        firstName: tokenDecode(token).firstName,
        lastName: tokenDecode(token).lastName,
        title: tokenDecode(token).title
      },
      session: {
        start_time: userSessionData?.session?.start_time,
        end_time: userSessionData?.session?.end_time,
      },
      orgId: userSessionData?.session?.organisation ? userSessionData?.session?.organisation?.uuid : null,
    });

    scheduleServices
      .deleteSessionClient({ sessionId: sessionDetails.uuid }, token)
      .then((resp) => {
        if (resp.success) {
          toast.success(resp.message);
          setShowWithdrawnModal(false);
          // history.push("/");
          handleClose();
          fetchUserSessions(monthStartDate, monthEndDate, token);
        } else {
          setShowWithdrawnModal(false);
          toast.error(resp.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchApplyBtnHandler = () => {
    scheduleServices
      .postApplySession(
        {
          session_uuid: sessionDetails.uuid,
        },
        token
      )
      .then((resp) => {
        if (resp.success) {
          toast.success(resp.message);
          setShowApplyModal(false);
          handleClose();
          fetchUserSessions(monthStartDate, monthEndDate, token);
        } else {
          setShowApplyModal(false);
          toast.error(resp.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchDownloadOrgDocument = (documentId, fileName, token) => {
    scheduleServices.downloadOrganisationDocument(documentId, token).then(resp => {
      if (resp.ok) {
        fetchPDF(resp, fileName)
      }
    }).catch(err => console.log(err))
  }

  return (
    <>
      <Modal isOpen={open} id="event-modal">
        <ModalHeader toggle={() => {
          justifyToggle('1')
          toggle()
        }} className="p-3 bg-airgp modal-title">
          <div className="modal-title text-white h5">
            Session Details
          </div>
        </ModalHeader>
        <ModalBody>
          <Form
            className={"needs-validation view-event"}
            name="event-form"
            id="form-event"
            onSubmit={(e) => e.preventDefault()}
          >





              <Row>
                    <Col xs={9}>
                        <h5 className='fw-semibold'>{sessionDetails?.organisation?.name || "NA"}</h5>
                    </Col>
                    <Col xs={3} className='text-end h3 text-primary fw-semibold'>
                    £
                    {(
                      (sessionDetails?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      ) +
                      (sessionDetails?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      ) *
                      +sessionDetails?.organisation?.service_fee_percentage *
                      bonusPercantage
                      // +(sessionDetails?.pensionable===1?Number(calculateEmployersContribution((sessionDetails?.hourly_rate / 60) * convertTimeToMinute(sessionDetails?.start_time,
                      //   sessionDetails?.end_time,
                      //   sessionDetails?.unpaid_break_duration
                      // ))):Number(0))
                    ).toFixed()}
                    </Col>
                </Row>



                <Row>
                    <Col xs={9}>
                    {moment(sessionDetails?.start_time).format(
                    "dddd, Do MMMM YYYY"
                  )}
                    </Col>
                    <Col xs={3} className='text-end text-muted'>
                    £
                    {(
                      (sessionDetails?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      )
                    ).toFixed() || "NA"}{" "}
                    </Col>
                </Row>






                <Row>
                    <Col xs={7}>
                    {moment(sessionDetails?.start_time).format("HH:mm") ||
                    "00:00"}{" "}
                      to {moment(sessionDetails?.end_time).format("HH:mm") || "NA"}
                    </Col>
                    <Col xs={5} className='text-end text-muted'>
                    £{sessionDetails?.hourly_rate || "NA"}/h
                    </Col>
                </Row>

                <Row>
                    <Col xs={7}>
                    {sessionDetails?.unpaid_break_duration || 'no break'}
                    {sessionDetails?.unpaid_break_duration !== 0 ? " mins break" : ""}
                    </Col>
                {((sessionDetails?.hourly_rate / 60) *
                convertTimeToMinute(
                    sessionDetails?.start_time,
                    sessionDetails?.end_time,
                    sessionDetails?.unpaid_break_duration
                ) *
                +sessionDetails?.organisation?.service_fee_percentage *
                bonusPercantage
                 ).toFixed(0) > 0 ? (
                    <Col xs={5} className='text-end text-muted'>
                    £
                    {bonusPercantage
                      ? (
                        (sessionDetails?.hourly_rate / 60) *
                        convertTimeToMinute(
                          sessionDetails?.start_time,
                          sessionDetails?.end_time,
                          sessionDetails?.unpaid_break_duration
                        ) *
                        +sessionDetails?.organisation
                          ?.service_fee_percentage *
                        bonusPercantage
                      ).toFixed(0)
                      : "0"}
                    {' '}bonus
                    </Col>
            ) : null}
                </Row>






                <div className='d-flex flex-row justify-content-end small gap-1 my-1'>
                    <div>
                    <span className='badge rounded-pill badge-soft-info'>{sessionDetails?.length !== 0 && sessionDetails?.organisation?.organisation_profiles[0]?.gp_soc?.name}</span>
                    </div>
                    {(sessionDetails?.pensionable  === 1 ?<div> <span className='badge rounded-pill badge-soft-success'>Pensionable</span></div> : '')}
                    </div>
                    <div className='d-flex flex-row justify-content-end small gap-1 my-1'>

                    <div className='text-truncate'>
                    <span className='badge rounded-pill badge-soft-primary'>{sessionDetails?.session_type?.type || "NA"}</span>
                    </div>
                    <div>
                    <span className='badge rounded-pill badge-soft-danger'>#{sessionDetails?.uuid?.slice(0, 5) || 'NA'}</span>
                    </div>
                </div>







{/* 

            <div className="d-flex fs-12 float-end align-items-center">
              <Badge color='primary'>{sessionDetails?.length !== 0 && sessionDetails?.organisation?.organisation_profiles[0]?.gp_soc?.name}</Badge>
            </div>
            <div className="mt-1 fw-semibold h5">
              {sessionDetails?.organisation?.name || "NA"}
            </div>

            <div className="hstack d-flex small align-items-start gap-2 mb-3">
              <div className="">
                <p className="mb-0 text-muted">
                  {moment(sessionDetails?.start_time).format(
                    "dddd, Do MMMM YYYY"
                  )}
                </p>
                <p className="mb-0 text-muted">
                  {moment(sessionDetails?.start_time).format("HH:mm") ||
                    "00:00"}{" "}
                  to {moment(sessionDetails?.end_time).format("HH:mm") || "NA"}
                </p>
                <p className="mb-0 text-muted">
                  {''}{sessionDetails?.unpaid_break_duration || 'No break'}{' '}
                  {sessionDetails?.unpaid_break_duration !== 0 ? "mins break" : ""}{''}
                </p>
              </div>
              <div className="text-primary ms-auto">
                <div className="d-flex flex-column align-items-end text-primary  ms-auto">
                  <div className="text-primary h3 fw-semibold">
                    £
                    {(
                      (sessionDetails?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      ) +
                      (sessionDetails?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      ) *
                      +sessionDetails?.organisation?.service_fee_percentage *
                      bonusPercantage
                      // +(sessionDetails?.pensionable===1?Number(calculateEmployersContribution((sessionDetails?.hourly_rate / 60) * convertTimeToMinute(sessionDetails?.start_time,
                      //   sessionDetails?.end_time,
                      //   sessionDetails?.unpaid_break_duration
                      // ))):Number(0))
                    ).toFixed(0)}
                  </div>
                  <div className="text-muted  small p mb-0 float-end">
                    £
                    {(
                      (sessionDetails?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      )
                    ).toFixed() || "NA"}{" "}
                    (£{sessionDetails?.hourly_rate || "NA"}/h) + £
                    {bonusPercantage
                      ? (
                        (sessionDetails?.hourly_rate / 60) *
                        convertTimeToMinute(
                          sessionDetails?.start_time,
                          sessionDetails?.end_time,
                          sessionDetails?.unpaid_break_duration
                        ) *
                        +sessionDetails?.organisation
                          ?.service_fee_percentage *
                        bonusPercantage
                      ).toFixed(0)
                      : "0"}{" "}
                    Bonus */}
                    {/* {sessionDetails?.pensionable === 1 ? (
                      <i
                        title="Pensionable"
                        className="ms-1 ri-file-ppt-line"
                      ></i>
                    ) : (
                      ""
                    )} */}
                    {/* {(sessionDetails?.pensionable === 1 ? */}
                      {/* // ` + £${Number(calculateEmployersContribution((sessionDetails?.hourly_rate / 60) * convertTimeToMinute(sessionDetails?.start_time, */}
                      {/* //   sessionDetails?.end_time,
                      //   sessionDetails?.unpaid_break_duration
                      // )))} 
                      // ` + Pension` : '')} */}
                  {/* </div>
                </div>
              </div>
            </div> */}

            <Nav
              tabs
              className="nav nav-tabs nav-tabs-custom  nav-primary nav-justified mb-3 mx-n3"
            >
              <NavItem  className='text-truncate'>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: justifyTab === "1" })}
                  onClick={() => {
                    justifyToggle("1");
                  }}
                >
                  At Glance
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: justifyTab === "2" })}
                  onClick={() => {
                    justifyToggle("2");
                  }}
                >
                  Description
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: justifyTab === "3" })}
                  onClick={() => {
                    justifyToggle("3");
                  }}
                >
                  Address
                </NavLink>
              </NavItem>
              {
                session?.Session_statuses?.some(item => item.status === "Booked") &&
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: justifyTab === "4" })}
                    onClick={() => {
                      justifyToggle("4");
                    }}
                  >
                    Files
                  </NavLink>
                </NavItem>
              }
            </Nav>

            <TabContent activeTab={justifyTab} className="text-muted">
              <TabPane tabId="1" id="description">
                {/* {sessionDetails?.session_type?.type && (
                  <Badge color='primary' className="float-end">
                    {sessionDetails?.session_type?.type || "NA"}
                  </Badge>
                )} */}
                <div className="flex-grow-1">
                  {sessionDetails?.features?.map((_, index) => (
                    <div className="d-flex  align-items-center" key={index}>
                      <div className="flex-shrink-0">
                        <i className=" ri-check-fill fs-18 pe-1 text-success"></i>
                      </div>
                      <div className="flex-grow">{_?.name || "NA"}</div>
                    </div>
                  ))}
                </div>
              </TabPane>

              <TabPane tabId="2">
                <SimpleBar style={{ maxHeight: "250px" }}>
                  {sessionDetails?.session_description ? parse(sessionDetails?.session_description) : "NA"}
                </SimpleBar>
              </TabPane>

              <TabPane tabId="3">
                <p>
                  <span>{sessionDetails?.organisation?.address_line_1}
                    <br /></span>
                  <span>
                    {sessionDetails?.organisation?.address_line_2}
                    <br /></span>
                  {sessionDetails?.organisation?.address_line_3 && <span>
                    {sessionDetails?.organisation?.address_line_3}
                    <br /></span>}
                  {sessionDetails?.organisation?.address_line_4 && <span>
                    {sessionDetails?.organisation?.address_line_4}
                    <br /></span>}
                  <span>
                    {sessionDetails?.organisation?.postcode}
                    <br /></span>
                </p>

                <div className="d-grid gap-2">
                  <Link to={{ pathname: `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${sessionDetails?.organisation?.name},${sessionDetails?.organisation?.postcode}`)}` }} target='_blank' className='btn btn-soft-primary btn-sm'>
                    {" "}
                    Open in Google Maps{" "}
                  </Link>
                </div>
              </TabPane>

              <TabPane tabId="4">

              <div className='table-responsive small m-n3'>
                                <Table className='table-hover align-content-middle table-nowrap table-sm mb-0'>
                                    <thead className='table-light text-muted'>
                                        <tr>
                                            <th scope='col'>File Name</th>
                                            <th scope='col'>Uploaded Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(sessionDetails?.organisation?.Organisation_documents || []).map((_, index) => (
                                            <tr key={index}>
                                                <td><span  className='d-inline-block text-truncate' style={{ maxWidth: '350px' }}>
                                                    <Link
                                                        to='#'
                                                        onClick={() => fetchDownloadOrgDocument(_?.uuid, _?.original_file_name, token)}
                                                    >
                                                    <i className="ri-file-3-fill"></i>&nbsp;
                                                    {_?.original_file_name}
                                                    </Link>
                                                    </span></td>
                                                <td>{moment(_?.createdAt).format('ll')}</td>
                                            </tr>
                                        ))}
                                        {sessionDetails?.session?.organisation?.Organisation_documents?.length == 0 && <tr colSpan="2">
                                            <td>Documents not found</td>
                                            <td></td>
                                        </tr>}
                                    </tbody>
                                </Table>
                            </div>
              </TabPane>
            </TabContent>
          </Form>
        </ModalBody>
        <ModalFooter>
          {userSessionData?.Session_statuses?.slice(-1)[0]?.status ===
            "Manual" && (
              <div className="margin-top">
                <Link
                  to="#"
                  className="btn btn-primary margin-right"
                  onClick={() => {
                    toggleModal();
                    handleClose();
                    justifyToggle('1')
                  }}
                  id="tooltipBottom"
                >
                  <i className="ri-download-2-line align-bottom "></i> Edit
                </Link>
                <Link
                  to="#"
                  className="btn btn-danger mr-2"
                  id="tooltipDel"
                  onClick={() => {
                    setDeleteModal(true);
                    handleClose();
                    justifyToggle('1')
                  }}
                >
                  <i className="ri-delete-bin-6-line align-bottom me-1"></i>{" "}
                  Delete
                </Link>

                <UncontrolledTooltip placement="bottom" target="tooltipBottom">
                  Edit Current Session
                </UncontrolledTooltip>

                <UncontrolledTooltip placement="bottom" target="tooltipDel">
                  Delete Current Session
                </UncontrolledTooltip>
              </div>
            )}

          {userSessionData?.Session_statuses?.slice(-1)[0]?.status ===
            "Applied" && (
              <div className="margin-top">
                <Link
                  to="#"
                  className="btn btn-danger"
                  id="tooltipDel"
                  onClick={() => {
                    setShowAppliedModal(true);
                    handleClose();
                    justifyToggle('1')
                    document.body.style.overflow = 'visible'
                  }}
                >
                  <i className="ri-delete-bin-6-line align-bottom me-1"></i>{" "}
                  Cancel
                </Link>
                <UncontrolledTooltip placement="bottom" target="tooltipDel">
                  Cancel Current Session
                </UncontrolledTooltip>
              </div>
            )}

          {userSessionData?.Session_statuses?.slice(-1)[0]?.status ===
            "Booked" && (
              <div className="margin-top">
                <Link
                  to="#"
                  className="btn btn-danger"
                  id="tooltipDel"
                  onClick={() => {
                    setShowWithdrawnModal(true);
                    handleClose();
                    justifyToggle('1')
                    document.body.style.overflow = 'visible'
                  }}
                >
                  <i className="ri-delete-bin-6-line align-bottom me-1"></i>{" "}
                  Withdraw
                </Link>
                <UncontrolledTooltip placement="bottom" target="tooltipDel">
                  Withdraw Current Session
                </UncontrolledTooltip>
              </div>
            )}

          <Button color="light" onClick={() => {
            justifyToggle('1')
            handleClose()
          }} className="margin-top">
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <ConfirmationModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
        deleteMsg="Are you sure you want to delete this session ?"
      />

      <ConfirmationModal
        show={appliedModal}
        onDeleteClick={fetchCancelApi}
        onCloseClick={() => setShowAppliedModal(false)}
        deleteMsg="Are you sure you want to cancel this session ?"
        btnName="Cancel"
      />

      <ConfirmationModal
        show={showWithdrawn}
        onDeleteClick={fetchWithdrawnSessionClient}
        onCloseClick={() => setShowWithdrawnModal(false)}
        deleteMsg="Are you sure you want to withdraw this session ?"
        btnName="Withdraw"
      />

      <ConfirmationModal
        show={showApplyModal}
        onDeleteClick={fetchApplyBtnHandler}
        onCloseClick={() => setShowApplyModal(false)}
        deleteMsg="Are you sure you want to Apply this session ?"
        btnName="Apply"
      />
    </>
  );
};

export default SessionDetailsModal;
