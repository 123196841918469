import React, { useEffect, useState } from 'react'

import { Container, Row, Nav, NavItem, NavLink, CardHeader } from 'reactstrap'

import classnames from 'classnames'
import { scheduleServices } from '../../services/scheduleServices'
import { PUBLIC_BOOK_PAGE_TABS } from '../../utils/constants'
import { useHistory, useLocation } from 'react-router-dom'
import SuccessConfirmationModal from '../../Components/Common/SuccessConfirmationModal'
import PaginationV2 from '../../Components/Common/PaginationV2'
import SpinnerComponent from '../../Components/Common/Spinner'

import BookingList from '../../pages/book/BookingList'
import Footer from '../../pages/Landing/footer'
import Navbar from '../../pages/Landing/navbar'

const LIMIT = 20

const BookPageNew = () => {
  const [pmsessionsData, setPmsessionsData] = useState([])
  const [page, setPage] = useState(1)
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [showApplyModal, setShowApplyModal] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [bonusPercantage, setBonusPercantage] = useState()

  const history = useHistory()
  let org_id = new URLSearchParams(useLocation().search).get('org_id')

  useEffect(() => {
    if (org_id) FetchGetBookSessions(org_id)
  }, [org_id])

  const FetchGetBookSessions = org_id => {
    setIsLoading(true)

    scheduleServices
      .getPublicBookSession(org_id)
      .then(resp => {
        if (resp.success) {
          setBonusPercantage(resp?.user_profile)
          setPmsessionsData(resp?.data || resp?.sessions)
          setTotalPageCount(resp?.totalPages === 0 ? 1 : resp?.totalPages)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchApplyBtnHandler = item => { }

  document.title = 'Book | airGP'

  return (
    <div className='page-content mt-3'>
      {isLoading && <SpinnerComponent />}
      <Container>
        <Row>
          <div className='col-xl-12 col-lg-12'>
            <div>
              <Navbar />
            </div>
            <div>
              <div className='card'>
                <CardHeader className='mb-0 pb-0'>
                  <div className='col'>
                    <Nav className='nav-tabs' role='tablist'>
                      {PUBLIC_BOOK_PAGE_TABS?.map((_, index) => (
                        <NavItem key={index}>
                          <NavLink
                            className={classnames(
                              { active: index === 0 },
                              ''
                            )}
                            href='#'
                          >
                            {_}
                            <span className='badge badge-soft-danger align-middle rounded-pill ms-1'>
                              {pmsessionsData?.length || 0}
                            </span>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </div>
                </CardHeader>

                <div className='card-body'>
                  <BookingList
                    pmsessionsData={pmsessionsData}
                    setShowApplyModal={setShowApplyModal}
                    setSelectedId={setSelectedId}
                    bonusPercantage={bonusPercantage}
                    handleApplyClick={() => history.push('/book')}
                    advertisingSessions={true}
                  />
                </div>

                <PaginationV2
                  totalPageCount={totalPageCount === 0 ? 1 : totalPageCount}
                  previousDisabled={page === 1}
                  nextDisabled={totalPageCount === page ? true : false}
                  previousPageCall={() => setPage(page - 1)}
                  currentPage={page ? page : 1}
                  nextPageCalls={() => setPage(page + 1)}
                  onPageInputChange={({ target: { value } }) => {
                    var regex = new RegExp(`^[1-${totalPageCount}]$`)
                    if (value <= totalPageCount && value !== 0) {
                      setPage(value)
                    }
                  }}
                />
              </div>
            </div>
            <div className='fixed-bottom'>
              <Footer />
            </div>
          </div>
        </Row>
        <SuccessConfirmationModal
          show={showApplyModal}
          onDeleteClick={() => fetchApplyBtnHandler(selectedId)}
          onCloseClick={() => setShowApplyModal(false)}
          deleteMsg='Are you sure you want to Apply this session ?'
          btnName='Apply'
        />
      </Container>
    </div>
  )
}

export default BookPageNew
