import React, { useState } from 'react';
import { toast } from 'react-toastify'

import { accountServices } from '../../services/accountServices';

import profile from '../../assets/images/person-circle.svg'
import { Label, Input } from 'reactstrap';
import fetchPDF from '../../common/fetchPDF';


const ImageUploader = () => {
    const [profileUrl, setProfileUrl] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const token = localStorage.getItem('token')

    const fetchProfileUpload = resizedImage => {
        setIsLoading(true)
        if (!resizedImage) {
            return
        }
        const formData = new FormData();
        formData.append('avatar', resizedImage,'avatar.jpg');
        accountServices
            .patchUploadProfilePhoto(formData, token,"profilephoto")
            .then(resp => {
                if (resp.success) {
                    toast.success(resp.message)
                    fetchProfilePhoto()
                } else {
                    toast.error(resp.message)
                }
            })
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))
    }

    const fetchProfilePhoto = () => {
        accountServices
            .getProfilePhoto(token)
            .then(resp => {
                if (resp.success) {
                    accountServices.downloadDocument(resp?.data?.uuid,"profilephoto",token)
                    .then(resp=> resp.blob())
                    .then(resp=>URL.createObjectURL(resp))
                    .then(resp=>setProfileUrl(resp))
                    .catch(err => console.log(err))
                    
                }
            })
            .catch(err => console.log(err))
    }

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        const name = event.target.files[0].name;
        let reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {

                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 400;
                var MAX_HEIGHT = 400;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                    const file = new File([blob], name, {
                        type: 'image/jpeg',
                        lastModified: Date.now()
                    });
                    fetchProfileUpload(file)
                }, 'image/jpeg', 1);
            };
            img.src = e.target.result;
        };
        reader.readAsDataURL(file);

    };

    return (
        <div className='avatar-xl'>
            <div className='profile-user  position-relative d-inline-block mx-auto mt-1 mb-4'>
                <img
                    src={profileUrl || profile}
                    className='rounded-circle avatar-xl opacity-50 img-thumbnail user-profile-image'
                    alt='user-profile'
                />
                <div className='avatar-xs p-0 rounded-circle profile-photo-edit'>
                    <Input
                        id='profile-img-file-input'
                        type='file'
                        className='profile-img-file-input'
                        onChange={handleImageUpload}
                        accept='image/*'
                        name='avatar'
                    />
                    <Label
                        htmlFor='profile-img-file-input'
                        className='profile-photo-edit avatar-xs'
                    >
                        <span className='avatar-title fs-18 border-warning shadow-lg rounded-circle bg-light text-body'>
                            <i className=' ri-camera-fill'></i>
                        </span>
                    </Label>
                </div>
            </div>
        </div>
    );
};

export default ImageUploader;

