import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, ButtonGroup,UncontrolledDropdown, DropdownItem ,DropdownToggle, DropdownMenu, Col, Container, Row } from 'reactstrap';

// Import Images
import img4 from "../../assets/images/landing/features/devicemockup.webp";
import img5 from "../../assets/images/landing/features/img-5.webp";
const Features = () => {
    return (
        <React.Fragment>

            {/* <section className="section bg-light py-5" id="features">
                <Container>
                    <Row className="align-items-center gy-4">
                        <Col lg={6} sm={7} className="mx-auto">
                            <div>
                                <img src={img1} alt="" className="img-fluid mx-auto" />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="text-muted">
                                <div className="avatar-sm icon-effect mb-4">
                                    <div className="avatar-title bg-transparent rounded-circle text-success h1">
                                        <i className="ri-collage-line fs-36"></i>
                                    </div>
                                </div>
                                <h3 className="mb-3 fs-20">Huge collection of features</h3>
                                <p className="mb-4 ff-secondary fs-16">Book, Invoice, Pension, Payments</p>

                                <Row className="pt-3">
                                    <Col className="col-3">
                                        <div className="text-center">
                                            <h4>5</h4>
                                            <p>Dashboards</p>
                                        </div>
                                    </Col>
                                    <Col className="col-3">
                                        <div className="text-center">
                                            <h4>150+</h4>
                                            <p>Pages</p>
                                        </div>
                                    </Col>
                                    <Col className="col-4">
                                        <div className="text-center">
                                            <h4>7+</h4>
                                            <p>Functional Apps</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            <section className="section landing-join-us py-4">
                {/* <div className="bg-overlay bg-overlay-pattern"></div> */}
                <Container>
                    <Row className="align-items-center gy-4">
                        <Col className="col-sm">
                            <div>
                                <h5 className="mb-0 ">Join our community</h5>
                            </div>
                        </Col>
                        <Col className="col-sm-auto">
                            <div>
                                <ButtonGroup className='float-end'>
                                    <UncontrolledDropdown>
                                    <DropdownToggle tag="button" className="btn btn-primary">
                                        Sign up <i className="ri ri-arrow-down-s-line"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdownmenu-primary">
                                        <Link className="dropdown-item" to="/register">GP</Link>
                                        <DropdownItem divider />
                                        <a className="dropdown-item" href="https://org.airgp.co.uk/register" >Organisation</a>
                                    </DropdownMenu>
                                    </UncontrolledDropdown>
                                </ButtonGroup>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section className="section" id="GPs">
                <Container>
                    <Row className="align-items-center gy-4">
                        <Col lg={6} className="order-2 order-lg-1">
                            <div className="text-muted">
                                <h5 className="fs-12 text-uppercase text-primary">Simplified</h5>
                                <h4 className="mb-3">For GPs</h4>
                                <p className="mb-4 ff-secondary">Platform is secure, fast, intuitive</p>

                                <Row>
                                    <Col sm={5}>
                                        <div className="vstack gap-2 ">
                                            {/* <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 me-2">
                                                    <div className="avatar-xs">
                                                        <div
                                                            className="avatar-title bg-transparent text-primary h2">
                                                            <i className="ri-check-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="mb-0">Bonus Payments</p>
                                                </div>
                                            </div> */}
                                            <div className="d-flex align-items-center ">
                                                <div className="flex-shrink-0 me-2">
                                                    <div className="avatar-xs">
                                                        <div
                                                            className="avatar-title bg-transparent text-primary h2">
                                                            <i className="ri-check-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ">
                                                    <p className="mb-0">Schedule</p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 me-2">
                                                    <div className="avatar-xs">
                                                        <div
                                                            className="avatar-title bg-transparent text-primary h2">
                                                            <i className="ri-check-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="mb-0">Find & Book</p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 me-2">
                                                    <div className="avatar-xs">
                                                        <div
                                                            className="avatar-title bg-transparent text-primary h2">
                                                            <i className="ri-check-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="mb-0">Pension</p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 me-2">
                                                    <div className="avatar-xs">
                                                        <div
                                                            className="avatar-title bg-transparent text-primary h2">
                                                            <i className="ri-check-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="mb-0">Profile</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={5}>
                                        <div className="vstack gap-2">

                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 me-2">
                                                    <div className="avatar-xs">
                                                        <div
                                                            className="avatar-title bg-transparent text-primary h2">
                                                            <i className="ri-check-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="mb-0">Tax report</p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 me-2">
                                                    <div className="avatar-xs">
                                                        <div
                                                            className="avatar-title bg-transparent text-primary h2">
                                                            <i className="ri-check-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="mb-0">Messaging</p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 me-2">
                                                    <div className="avatar-xs">
                                                        <div
                                                            className="avatar-title bg-transparent text-primary h2">
                                                            <i className="ri-check-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <p className="mb-0">Digital Locum Packs</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="mt-4">
                                    {/* <Link to="/index" className="btn btn-primary">Learn More <i className="ri-arrow-right-line align-middle ms-1"></i></Link> */}
                                    {/* <h6 className="mb-0 text-muted">...and so much more</h6> */}
                                </div>
                            </div>
                        </Col>

                        <Col lg={6} sm={7} className="col-10 mx-auto order-1 order-lg-2">
                            <div>
                                <img src={img4} alt="" className="img-fluid" />
                            </div>
                        </Col>
                    </Row>

                    {/* <Row className="align-items-center mt-5 pt-lg-5 gy-4">
                        <Col lg={6} sm={7} className="col-10 mx-auto">
                            <div>
                                <img src={img5} alt="" className="img-fluid" />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="text-muted ps-lg-5">
                                <h5 className="fs-12 text-uppercase text-success">Trusted</h5>
                                <h4 className="mb-3">For Surgeries</h4>
                                <p className="mb-4">Our locum GPs are GMC & Performers list registered, with full compliance checks to ensure quality and safety.</p>

                                <div className="vstack gap-2">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <div className="avatar-xs icon-effect">
                                                <div className="avatar-title bg-transparent text-success rounded-circle h2">
                                                    <i className="ri-check-fill"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <p className="mb-0">Find locums</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <div className="avatar-xs icon-effect">
                                                <div className="avatar-title bg-transparent text-success rounded-circle h2">
                                                    <i className="ri-check-fill"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <p className="mb-0">Invoices and direct debit</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <div className="avatar-xs icon-effect">
                                                <div className="avatar-title bg-transparent text-success rounded-circle h2">
                                                    <i className="ri-check-fill"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <p className="mb-0">Pension</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                </Container>
            </section>


            <section className="section landing-faq" id="organisations">
                <Container>
                    <Row className="align-items-center gy-4">
                        <Col lg={6} sm={7} className="col-10 mx-auto">
                            <div>
                                <img src={img5} alt="" className="img-fluid" />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="text-muted ps-lg-5">
                                <h5 className="fs-12 text-uppercase text-success">Trusted</h5>
                                <h4 className="mb-3">For Surgeries</h4>
                                <p className="mb-4">Our locum GPs are GMC & Performers list registered, with full compliance checks to ensure quality and safety.</p>

                                <div className="vstack gap-2">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <div className="avatar-xs">
                                                <div className="avatar-title bg-transparent text-success h2">
                                                    <i className="ri-check-fill"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <p className="mb-0">Find locums</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <div className="avatar-xs">
                                                <div className="avatar-title bg-transparent text-success h2">
                                                    <i className="ri-check-fill"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <p className="mb-0">Simplifed payments</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <div className="avatar-xs">
                                                <div className="avatar-title bg-transparent text-success h2">
                                                    <i className="ri-check-fill"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <p className="mb-0">Invoices</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <div className="avatar-xs">
                                                <div className="avatar-title bg-transparent text-success h2">
                                                    <i className="ri-check-fill"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <p className="mb-0">Pension</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-2">
                                            <div className="avatar-xs">
                                                <div className="avatar-title bg-transparent text-success h2">
                                                    <i className="ri-check-fill"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <p className="mb-0">Messaging</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
 
        </React.Fragment>
    );
};

export default Features;