import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const Navdata = () => {
  const history = useHistory()
  //state data
  const [isDashboard, setIsDashboard] = useState(false)
  const [isApps, setIsApps] = useState(false)
  const [isAuth, setIsAuth] = useState(false)
  const [isPages, setIsPages] = useState(false)
  const [isBaseUi, setIsBaseUi] = useState(false)
  const [isAdvanceUi, setIsAdvanceUi] = useState(false)
  const [isForms, setIsForms] = useState(false)
  const [isTables, setIsTables] = useState(false)
  const [isCharts, setIsCharts] = useState(false)
  const [isIcons, setIsIcons] = useState(false)
  const [isMaps, setIsMaps] = useState(false)
  const [isMultiLevel, setIsMultiLevel] = useState(false)
  const [isLanding, setIsLanding] = useState(false)
  const [iscurrentState, setIscurrentState] = useState('Dashboard')

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu')
      const iconItems = ul.querySelectorAll('.nav-icon.active')
      let activeIconItems = [...iconItems]
      activeIconItems.forEach(item => {
        item.classList.remove('active')
        var id = item.getAttribute('subitems')
        if (document.getElementById(id))
          document.getElementById(id).classList.remove('show')
      })
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel')
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false)
    }
    if (iscurrentState !== 'Apps') {
      setIsApps(false)
    }
    if (iscurrentState !== 'Auth') {
      setIsAuth(false)
    }
    if (iscurrentState !== 'Pages') {
      setIsPages(false)
    }
    if (iscurrentState !== 'BaseUi') {
      setIsBaseUi(false)
    }
    if (iscurrentState !== 'AdvanceUi') {
      setIsAdvanceUi(false)
    }
    if (iscurrentState !== 'Forms') {
      setIsForms(false)
    }
    if (iscurrentState !== 'Tables') {
      setIsTables(false)
    }
    if (iscurrentState !== 'Charts') {
      setIsCharts(false)
    }
    if (iscurrentState !== 'Icons') {
      setIsIcons(false)
    }
    if (iscurrentState !== 'Maps') {
      setIsMaps(false)
    }
    if (iscurrentState !== 'MuliLevel') {
      setIsMultiLevel(false)
    }
    if (iscurrentState === 'Widgets') {
      history.push('/widgets')
      document.body.classList.add('twocolumn-panel')
    }
    if (iscurrentState !== 'Landing') {
      setIsLanding(false)
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel
  ])

  const menuItems = [
    {
      label: 'Menu',
      isHeader: true
    },
    {
      id: 'Schedule',
      label: 'Schedule',
      icon: 'ri-calendar-2-line',
      link: '/',
      click: function (e) {
        e.preventDefault()
        setIsApps(!isApps)
        setIscurrentState('Apps')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'calendar',
          label: 'Calendar',
          link: '/calendar',
          parentId: 'Schedule'
        },
        {
          id: 'Session List',
          label: 'Session List',
          link: '/session-list',
          parentId: 'Schedule'
        },
        // {
        //   id: 'Chats',
        //   label: 'Chats',
        //   link: '/chat',
        //   parentId: 'Schedule'
        // }
      ],
      stateVariables: isApps
    },
    {
      id: 'Book',
      label: 'Book',
      icon: 'ri-search-line',
      link: '/book',
      click: function (e) {
        e.preventDefault()
        setIsAuth(!isAuth)
        setIscurrentState('Auth')
        updateIconSidebar(e)
      },
      stateVariables: isAuth
    },

    {
      id: 'Invoices',
      label: 'Invoices',
      icon: 'ri-file-copy-2-line',
      link: '/invoices',
      click: function (e) {
        e.preventDefault()
        setIsForms(!isForms)
        setIscurrentState('Forms')
        updateIconSidebar(e)
      },
      stateVariables: isForms
    },
    {
      id: 'Pension',
      label: 'Pension',
      icon: 'ri-file-ppt-2-line',
      link: '/client/pension',
      click: function (e) {
        e.preventDefault()
        setIsTables(!isTables)
        setIscurrentState('Tables')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'form_a',
          label: 'Form A',
          link: '/form-a',
          parentId: 'Pension'
        },
        {
          id: 'form_b',
          label: 'Form B',
          link: '/form-b',
          parentId: 'Pension'
        }
      ],
      stateVariables: isTables
    },
    {
      id: 'Account',
      label: 'Account',
      icon: ' ri-user-3-line',
      link: '/profile-info',
      click: function (e) {
        e.preventDefault()
        setIsTables(!isTables)
        setIscurrentState('Tables')
        updateIconSidebar(e)
      },
      stateVariables: isTables
    }
  ]
  return <React.Fragment>{menuItems}</React.Fragment>
}
export default Navdata
