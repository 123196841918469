import React from "react";
import {Alert, Button, Modal, Input, Label, Row, Col, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";

const handleFocus = (event) => event.target.select()
const SyncCalenderLinkModal = ({ open, link, tog_standard }) => {
    return (
        <div>
            <Modal
                id="myModal"
                isOpen={open}
                toggle={tog_standard}
                size="lg"
            >
                <ModalHeader
                    className="p-3 bg-airgp modal-title"
                    id="myModalLabel"
                    toggle={tog_standard}
                >
                    <div className="modal-title text-white h5">Sync calendar</div>
                </ModalHeader>
                <ModalBody>
                    <Label htmlFor="calendar-link" className="form-label">Calendar subscription link</Label>
                    <div className="input-group">
                    <Input type="text" className="form-control user-select-all" id="calendar-link" defaultValue={link} onFocus={handleFocus}   readOnly />
                    <button type="button" id="copy-to-clipboard-calendar" className="btn btn-primary" onClick={() => {navigator.clipboard.writeText(link)}}> <i className="ri-file-copy-line"></i> </button>
                    </div>
                    <div className="mt-4 small text-dark border-top">Anyone who has access to this link is able to see your session list, please treat it with care.</div>

                    <div>
                        {/* <p className="mt-4">&nbsp;</p> */}
                        <p className="text-center mt-4 mb-0 text-muted">How to add a calendar subscription:</p>
                        <Row>
                        <Col className="text-center"><i className="ri-apple-fill fs-22"></i><br></br>&nbsp;<a href="https://support.apple.com/en-gb/102301" target="_blank" rel="noopener noreferrer">iOS guide</a></Col>
                        <Col className="text-center"><i className="ri-android-fill fs-22 text-success"></i><br></br>&nbsp;<a href="https://support.google.com/calendar/answer/37100" target="_blank" rel="noopener noreferrer">Android</a></Col>
                        </Row>
                        
    
                        
                    </div>

                    {/* <Alert color="info" className="alert-borderless small mt-4"> */}



                    {/* </Alert> */}

                </ModalBody>
                <div className="modal-footer">
                    <Button
                        color="light"
                        onClick={tog_standard}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default SyncCalenderLinkModal;
