import React, { useEffect, useState } from 'react'
import { scheduleServices } from '../../services/scheduleServices'
import { toast } from 'react-toastify'

const LikeButton = ({ session, handleSuccess }) => {
  const [liked, setLiked] = useState(false)
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (session?.organisation?.favorite_organisations[0]?.active == 1) {
      setLiked(true)
    } else {
      setLiked(false)
    }
  }, [session])

  const fetchAddFavouriteOrg = (org_id, active, token) => {
    scheduleServices
      .patchAddFavouriteOrganisation(org_id, active, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          handleSuccess()
        } else {
          toast.error(resp.message)
        }
      })
      .catch(err => console.log(err))
  }

  const handleUnLikeClick = () => {
    setLiked(!liked)
    fetchAddFavouriteOrg(session?.organisation?.uuid, 0, token)
  }

  const handleLikeClick = () => {
    setLiked(!liked)
    fetchAddFavouriteOrg(session?.organisation?.uuid, 1, token)
  }

  return (
    <>
      {liked ? (
        <a href="#" ><i
          className='ri-heart-fill fs-16 mb-1 ms-1 text-danger'
          onClick={handleUnLikeClick}
          title='Remove from following'
        />    
        </a>
        
        
      ) : (
        <a href="#" ><i className='ri-heart-line fs-16 mb-1 ms-1 text-muted' onClick={handleLikeClick} title='Follow'></i>
        </a>
      )}
    </>
  )
}

export default LikeButton
