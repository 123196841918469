import { headerContent } from '.'
import { getCookie } from '../utils/constants'
import {
  GET_PERFORMER_INFO,
  GET_USER_TYPES,
  PATCH_UPDATE_PASSWORD,
  POST_EMAIL_VERIFICATION,
  POST_FORGOT_PASSWORD,
  POST_LOGIN_CRED,
  POST_REGISTER_CRED,
  POST_RESET_PASSWORD
} from './endpoints'

var token = localStorage.getItem('token')

export const headerContentWithAuthorization = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`
}

export const customHeaders = token => {
  let copiedHeaderContentWithAuthorization = {
    ...headerContentWithAuthorization
  }
  copiedHeaderContentWithAuthorization['Authorization'] = `Bearer ${token}`
  return copiedHeaderContentWithAuthorization
}

export const authServices = {
  // Login super admin
  postLoginForClient: data =>
    fetch(POST_LOGIN_CRED.endpoint, {
      method: POST_LOGIN_CRED.method,
      headers: headerContent,
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // forgot password super admin
  postForgotPasswordClient: data =>
    fetch(POST_FORGOT_PASSWORD.endpoint, {
      method: POST_FORGOT_PASSWORD.method,
      headers: headerContent,
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // forgot password super admin
  postResetPasswordForClient: (data, id, token) =>
    fetch(POST_RESET_PASSWORD.endpoint(id, token), {
      method: POST_RESET_PASSWORD.method,
      headers: headerContent,
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  postRegisterNewUser: data =>
    fetch(POST_REGISTER_CRED.endpoint, {
      method: POST_REGISTER_CRED.method,
      headers: headerContent,
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        if (resp.status !== POST_REGISTER_CRED.successStatusCode) {
          return resp.json()
        } else {
          return resp.json()
        }
      })
      .catch(err => console.log(err)),

  getUserTypes: () =>
    fetch(GET_USER_TYPES.endpoint, {
      method: GET_USER_TYPES.method,
      headers: headerContent
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  patchUpdatePassword: (data, token) =>
    fetch(PATCH_UPDATE_PASSWORD.endpoint, {
      method: PATCH_UPDATE_PASSWORD.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  getPerformerInfo: id =>
    fetch(GET_PERFORMER_INFO.endpoint(id), {
      method: GET_PERFORMER_INFO.method,
      headers: headerContent
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  postEmailVerification: id =>
    fetch(POST_EMAIL_VERIFICATION.endpoint(id), {
      method: POST_EMAIL_VERIFICATION.method,
      headers: headerContent
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err))
}
