import PropTypes from 'prop-types'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, ModalHeader, ModalBody, Input, ModalFooter, ListGroup, ListGroupItem } from 'reactstrap'
import {
  calculateEmployersContribution,
  calculateEmployersSuperannuation,
  convertTimeToMinute
} from '../../utils/constants'

const GenerateInvoiceConfirmation = ({
  show,
  onDeleteClick,
  onCloseClick,
  deleteMsg,
  btnName,
  sessionData,
  onEditClick
}) => {
  const [expenseTotal, setExpensesTotal] = useState(0)
  const [pensionClaim, setPensionClaim] = useState()

  useEffect(() => {
    if (sessionData?.expenses !== 0) {
      let amount = 0
      sessionData?.expenses?.map(_ => {
        amount += +_?.amount
      })
      setExpensesTotal(amount)
      setPensionClaim(sessionData?.users[0]?.pension_client[0]?.claim_pension === 1 && sessionData?.users[0]?.User_profile?.employment_type !== "limited_company" ? true : false)
    }
  }, [sessionData])

  const closeBtn = (
    <button
      className="btn-close  text-white"
      onClick={onCloseClick}
      type="button"
    ></button>
  );

  return (
    <Modal isOpen={show}>
      <ModalHeader
        // toggle={onCloseClick}
        className="p-3 bg-airgp modal-title"
        close={closeBtn}

      >
        <div className="modal-title text-white h5">
          {`Create invoice ${pensionClaim ? 'and pension form' : ""}`}
        </div>
      </ModalHeader>

      <ModalBody className=' px-0'>
        <div>
          {/* <h4> */}
          {/* {`Create invoice ${pensionClaim?'and pension form':""} for this session ?`} */}
          {/* {deleteMsg || 'Are you sure you want to remove this record ?'} */}
          {/* </h4> */}


          <ListGroup flush>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              Session Fee: <span>£
                {sessionData?.status === 'withdrawn'
                  ? (
                    ((sessionData?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionData?.start_time,
                        sessionData?.end_time,
                        sessionData?.unpaid_break_duration
                      ) *
                      sessionData?.withdrawal_percentage) /
                    100
                  )?.toFixed(2)
                  : (
                    (sessionData?.hourly_rate / 60) *
                    convertTimeToMinute(
                      sessionData?.start_time,
                      sessionData?.end_time,
                      sessionData?.unpaid_break_duration
                    )
                  ).toFixed(2) || 0}
              </span>
            </ListGroupItem>
            {expenseTotal !== 0 &&
              <ListGroupItem className="d-flex justify-content-between align-items-center">
                Expenses: <span>£ {sessionData?.status === 'withdrawn' ? 0 : expenseTotal.toFixed(2) || 0}
                </span>
              </ListGroupItem>}

            {Number(+sessionData?.client_bonus_amount).toFixed(2) > 0 && (
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              Bonus:
              <span>£
                {sessionData?.status === 'withdrawn'
                  ? 0
                  : Number(+sessionData?.client_bonus_amount).toFixed(2) || 0}
              </span>
            </ListGroupItem>
            )}
            {(sessionData?.users[0]?.pension_client[0]?.claim_pension !== 0 && pensionClaim && sessionData?.users[0]?.User_profile?.employment_type !== "limited_company") &&
              <ListGroupItem className="d-flex justify-content-between align-items-center">
                Pension:
                <span>£
                  {sessionData?.status === 'withdrawn'
                    ? 0
                    : Number(calculateEmployersContribution(
                      (sessionData?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionData?.start_time,
                        sessionData?.end_time,
                        sessionData?.unpaid_break_duration
                      ),
                      expenseTotal
                    )).toFixed(2) || 0}
                </span>
              </ListGroupItem>}
            <ListGroupItem className="d-flex justify-content-between align-items-center border-top border-dashed border-dark fw-semibold fs-16" >
              Total Invoice: <span>£
                {sessionData?.status === 'withdrawn'
                  ? Number(
                    ((sessionData?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionData?.start_time,
                        sessionData?.end_time,
                        sessionData?.unpaid_break_duration
                      ) *
                      sessionData?.withdrawal_percentage) /
                    100
                  )?.toFixed(2)
                  : Number(Number(((
                    (sessionData?.hourly_rate / 60) *
                    convertTimeToMinute(
                      sessionData?.start_time,
                      sessionData?.end_time,
                      sessionData?.unpaid_break_duration
                    )
                  ).toFixed(2) || 0)) + ((sessionData?.users[0]?.pension_client[0]?.claim_pension !== 0 && pensionClaim && sessionData?.users[0]?.User_profile?.employment_type !== "limited_company") ? (Number(calculateEmployersContribution((sessionData?.hourly_rate / 60) * convertTimeToMinute(sessionData?.start_time,
                    sessionData?.end_time,
                    sessionData?.unpaid_break_duration
                  ), expenseTotal) || 0)) : 0) + (Number(sessionData?.client_bonus_amount || 0)) + (Number(expenseTotal.toFixed(2) || 0)))?.toFixed(2)}
              </span>
            </ListGroupItem>
          </ListGroup>
          {sessionData?.users[0]?.pension_client[0]?.claim_pension === 1 && sessionData?.users[0]?.User_profile?.employment_type !== "limited_company" &&
            <div className='form-switch form-switch-lg mt-2 d-flex justify-content-end pe-3'>
              <Input
                id='claim-pension-check'
                type='checkbox'
                role='switch'
                checked={pensionClaim}
                onChange={() => {
                  setPensionClaim(() => !pensionClaim)
                }}
              />
              <label className='form-check-label ms-1' htmlFor='claim-pension-check'>
                <span>{'  '} Claim Pension</span>
              </label>
            </div>
          }

        </div>
        <div className='d-flex gap-2 justify-content-center mt-4 mb-2'>

        </div>
      </ModalBody>
      <ModalFooter>
      <button
          type='button'
          className='btn w-sm btn-primary '
          id='delete-record'
          onClick={() => onDeleteClick(pensionClaim)}
        >
          {btnName || ' Yes!'}
        </button>
        <button
          type='button'
          className='btn w-sm btn-outline-primary'
          onClick={()=>{
            onEditClick()
            document.body.style.overflow = 'visible'
          }}
        >
          Edit
        </button>

        <button
          type='button'
          className='btn w-sm btn-light'
          data-bs-dismiss='modal'
          onClick={()=>{
            onCloseClick()
            // document.body.style.overflow = 'visible'
          }}
        >
          Close
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default GenerateInvoiceConfirmation
