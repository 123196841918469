import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Button
} from 'reactstrap'

// Formik Validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { ToastContainer, toast } from 'react-toastify'


// action
import { registerUser, apiError, resetRegisterFlag } from '../../store/actions'

//redux
import { useSelector, useDispatch } from 'react-redux'

import { Link, useHistory, useParams } from 'react-router-dom'

//import images
import logoLight from '../../assets/images/logo-light.png'
import ParticlesAuth from './ParticlesAuth'
import Select from 'react-select'
import { authServices } from '../../services/authServices'
import { TITLE_MENU } from '../../utils/constants'

const Register = props => {
  const [userType, setUserType] = useState([])
  const [performer, setPerformer] = useState()
  const history = useHistory()
  const dispatch = useDispatch()

  let id = new URLSearchParams(document.location.search).get('invitation_id')

  useEffect(() => {
    fetchUserTypes()
  }, [])

  useEffect(() => {
    if (id) fetchPerformerInfo(id)
  }, [id])

  const fetchPerformerInfo = id => {
    authServices
      .getPerformerInfo(id)
      .then(resp => {
        if (resp.success) {
          setPerformer(resp.performerData)
        }
      })
      .catch(err => console.log(err))
  }

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  function toLowerCase(str){
    return str.toLowerCase();
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: performer?.performers_list_metadata?.invitation_email || '',
      first_name: performer ? performer['ForeName(s)'] : '' || '',
      last_name: performer?.Surname || '',
      password: '',
      confirm_password: '',
      role: 'GP',
      title: '',
      GMC: performer ? performer["Professional Registration Number"] : '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        // .matches(
        //   /@(airgp.co.uk)$/i, //|gmail.com|nhs.net|nhs.uk|example.com
        //   'Email address must end with "@nhs.net" or "@nhs.uk"'
        // )
        .required('Email address is required'),
      first_name: Yup.string().required('Please enter your first name'),
      last_name: Yup.string().required('Please enter your last name'),
      role: Yup.string().required('Please select your role'),
      title: Yup.string().required('Please select title'),
      GMC: Yup.string().required('Please enter your 7 digits GMC number'),
      password: Yup.string().required('Please enter your password'),
      confirm_password: Yup.string()
        .required('Please enter your password!')
        .when('password', {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            "Passwords do not match"
          )
        })
    }),
    onSubmit: values => {
      const data = {
        firstName: capitalize(values.first_name),
        lastName: capitalize(values.last_name),
        email: toLowerCase(values.email),
        title: values.title,
        GMC: values.GMC,
        role: 'Client',
        user_type: values.role,
        password: values.password,
        confirm_password: values.confirm_password,
        ...(id && { invitation_id: id })
      }

      authServices
        .postRegisterNewUser(data)
        .then(res => {
          if (res.success) {
            history.push('/login')
            toast.success(res.message)
          } else {
              toast.error(
                (res.message)?.replace(/["\\]/g, '') || (res.error[0].message)?.replace(/["\\]/g, '') || 'Something went wrong, Please try again.'
              )
            }
        })
        .catch(err => console.log(err))
    }
  })

  const { error, registrationError, success } = useSelector(state => ({
    registrationError: state.Account.registrationError,
    success: state.Account.success,
    error: state.Account.error
  }))

  useEffect(() => {
    dispatch(apiError(''))
  }, [dispatch])

  useEffect(() => {
    if (success) {
      setTimeout(() => history.push('login'), 3000)
    }

    setTimeout(() => {
      dispatch(resetRegisterFlag())
    }, 3000)
  }, [dispatch, success, error, history])

  document.title = 'Registration | airGP'

  const fetchUserTypes = () => {
    authServices
      .getUserTypes()
      .then(resp => {
        if (resp.success) {
          const filteredUserType = resp.user_types.filter(
            item =>
              item.name !== 'Admin' &&
              item.name !== 'Practice Manager' &&
              item.name !== 'Super Admin'
          )
          setUserType(filteredUserType)
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className='auth-page-content'>
          <Container>
            <Row>
              <Col lg={12}>
                <div className='text-center mt-sm-5 mb-4 text-white'>
                  <div>
                    <Link to='/' className='d-inline-block auth-logo'>
                      <img src={logoLight} alt='logo' height='40' />
                    </Link>
                  </div>
                  <p className='mt-3 fs-15 fw-medium'>GP Registration</p>
                </div>
              </Col>
            </Row>

            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='mt-1'>
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <h5 className='text-primary'>Create New Account</h5>
                      <p className='text-muted'>
                        Get your free airGP account now
                      </p>
                    </div>
                    <div className='p-2 mt-4'>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                        className='needs-validation'
                        action='#'
                      >
                        {success && success ? (
                          <>
                            {toast('Your Redirect To Login Page...', {
                              position: 'top-right',
                              hideProgressBar: false,
                              className: 'bg-success text-white',
                              progress: undefined,
                              toastId: ''
                            })}
                            <Alert color='success'>
                              Successful registration, redirecting you to login page
                            </Alert>
                          </>
                        ) : null}

                        {error && error ? (
                          <Alert color='danger'>
                            <div>
                              {/* {registrationError} */}
                              Email already in use, Please use another
                              Email Address...{' '}
                            </div>
                          </Alert>
                        ) : null}

                        <div className='mb-3'>
                          <Label
                            htmlFor='title'
                            className='form-label'
                          >
                            Title <span className='text-danger'>*</span>
                          </Label>
                          <Input
                            name='title'
                            type='select'
                            id='title'
                            placeholder='Please select your title'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.title &&
                                validation.errors.title
                                ? true
                                : false
                            }
                          >
                            <option>Select your title </option>
                            {TITLE_MENU.map(_ => (
                              <option key={_} value={_}>
                                {_ || 'NA'}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.title &&
                            validation.errors.title ? (
                            <FormFeedback type='invalid'>
                              <div>{validation.errors.title}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className='mb-3'>
                          <Label htmlFor='first-name' className='form-label'>
                            First Name <span className='text-danger'>*</span>
                          </Label>
                          <Input
                            name='first_name'
                            id='first-name'
                            autoComplete='given-name'
                            type='text'
                            placeholder='First Name'
                            readOnly={id ? true : false}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.first_name || ''}
                            invalid={
                              validation.touched.first_name &&
                                validation.errors.first_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.first_name &&
                            validation.errors.first_name ? (
                            <FormFeedback type='invalid'>
                              <div>{validation.errors.first_name}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className='mb-3'>
                          <Label htmlFor='last-name' className='form-label'>
                            Last Name <span className='text-danger'>*</span>
                          </Label>
                          <Input
                            id='last-name'
                            name='last_name'
                            autoComplete='family-name'
                            type='text'
                            placeholder='Last Name'
                            readOnly={id ? true : false}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.last_name || ''}
                            invalid={
                              validation.touched.last_name &&
                                validation.errors.last_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.last_name &&
                            validation.errors.last_name ? (
                            <FormFeedback type='invalid'>
                              <div>{validation.errors.last_name}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-3'>
                          <Label htmlFor='GMC' className='form-label'>
                            GMC Number <span className='text-danger'>*</span>
                          </Label>
                          <Input
                            id='GMC'
                            name='GMC'
                            className='form-control'
                            placeholder='Your GMC number'
                            type='text'
                            readOnly={id ? true : false}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.GMC || ''}
                            invalid={
                              validation.touched.GMC && validation.errors.GMC
                                ? true
                                : false
                            }
                          />
                          {validation.touched.GMC && validation.errors.GMC ? (
                            <FormFeedback type='invalid'>
                              <div>{validation.errors.GMC}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-3'>
                          <Label htmlFor='email' className='form-label'>
                            Email <span className='text-danger'>*</span>
                          </Label>
                          <Input
                            id='email'
                            name='email'
                            className='form-control'
                            placeholder='Email address'
                            autoComplete='email'
                            type='email'
                            readOnly={id ? true : false}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={
                              validation.touched.email &&
                                validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                            validation.errors.email ? (
                            <FormFeedback type='invalid'>
                              <div>{validation.errors.email}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                        {/* <div className='mb-3'>
                          <Label
                            for='exampleSelect'
                            htmlFor='role'
                            className='form-label'
                          >
                            Role <span className='text-danger'>*</span>
                          </Label>
                          <Input
                            name='role'
                            type='select'
                            id='exampleSelect'
                            placeholder='Please select your role'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.role && validation.errors.role
                                ? true
                                : false
                            }
                          >
                            <option>Select your role </option>
                            {userType.map(_ => (
                              <option key={_?.uuid} value={_?.name}>
                                {_?.name || 'NA'}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.role && validation.errors.role ? (
                            <FormFeedback type='invalid'>
                              <div>{validation.errors.role}</div>
                            </FormFeedback>
                          ) : null}
                        </div> */}

                        <div className='mb-3'>
                          <Label htmlFor='password-field' className='form-label'>
                            Password <span className='text-danger'>*</span>
                          </Label>
                          <Input
                            id='password-field'
                            name='password'
                            autoComplete='new-password'
                            type='password'
                            placeholder='Enter Password'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ''}
                            invalid={
                              validation.touched.password &&
                                validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                            validation.errors.password ? (
                            <FormFeedback type='invalid'>
                              <div>{validation.errors.password}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-2'>
                          <Label
                            htmlFor='password-confirm'
                            className='form-label'
                          >
                            Confirm Password{' '}
                            <span className='text-danger'>*</span>
                          </Label>
                          <Input
                            id='password-confirm'
                            name='confirm_password'
                            type='password'
                            autoComplete='new-password'
                            placeholder='Confirm Password'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirm_password || ''}
                            invalid={
                              validation.touched.confirm_password &&
                                validation.errors.confirm_password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.confirm_password &&
                            validation.errors.confirm_password ? (
                            <FormFeedback type='invalid'>
                              <div>{validation.errors.confirm_password}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-4'>
                          <p className='mb-0 fs-12 text-muted fst-italic'>
                            By registering you agree to the airGP &nbsp;
                            <a href='/terms' className='text-primary fst-normal text-decoration-underline' target='_blank'>Terms of Use</a>
                            {/* <Link
                              to='/terms'
                              className='text-primary text-decoration-underline fst-normal fw-medium'
                            >
                              Terms of Use
                            </Link> */}
                          </p>
                        </div>

                        <div className='mt-4'>
                          <button
                            className='btn btn-primary w-100'
                            type='submit'
                          >
                            Sign Up
                          </button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className='mt-4 text-center'>
                  <p className='mb-0'>
                    Already have an account ?{' '}
                    <Link
                      to='/login'
                      className='fw-semibold text-primary text-decoration-underline'
                    >
                      {' '}
                      Sign in{' '}
                    </Link>{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  )
}

export default Register
