import { Button, Col, Input, Row, Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const PaginationV2 = ({
  previousDisabled,
  previousPageCall,
  currentPage,
  totalPageCount,
  nextDisabled,
  nextPageCalls,
  onPageInputChange
}) => {
  return (


<>
    <Pagination className='m-2 p-2 d-flex justify-content-end '>
    <PaginationItem disabled={previousDisabled} className='justify-content-end' active>
        <PaginationLink             
        onClick={previousPageCall}
        // disabled={previousDisabled}
        
        >
        <i className="ri-arrow-left-s-line "></i> 
        </PaginationLink>
    </PaginationItem>

    {/* <b>Page </b>
    <b>
      {currentPage} of {totalPageCount}
    </b> */}

    <PaginationItem disabled>
      <PaginationLink>
    Page {currentPage} of {totalPageCount}
      </PaginationLink>
    </PaginationItem>



    <PaginationItem disabled={nextDisabled} active>
        <PaginationLink             
        onClick={nextPageCalls}
        // disabled={previousDisabled}
        >
        <i className="ri-arrow-right-s-line"></i> 
        </PaginationLink>
    </PaginationItem>

  </Pagination>
  

</>








    // <Row className='justify-content-md-end justify-content-center align-items-center m-2 p-2'>
    //   <Col className='col-md-auto'>
    //     <div className='d-flex gap-1'>
    //       <Button
    //         color='primary'
    //         onClick={previousPageCall}
    //         disabled={previousDisabled}
    //         className='btn-icon'
    //       ><i className="ri-arrow-left-s-line fs-24"></i>
    //       </Button>
    //     </div>
    //   </Col>
    //   <Col className='col-md-auto d-md-block'>
    //     <b>Page </b>
    //     <b>
    //       {currentPage} of {totalPageCount}
    //     </b>
    //   </Col>
      // {/* <Col className='col-md-auto'>
      //   <Input
      //     type='number'
      //     style={{ width: 40 }}
      //     defaultValue={currentPage}
      //     onChange={onPageInputChange}
      //   />
      // </Col> */}

    //   <Col className='col-md-auto'>
    //     <div className='d-flex gap-1'>
    //       <Button
    //         color='primary'
    //         onClick={nextPageCalls}
    //         disabled={nextDisabled}
    //         className='btn-icon'
    //       ><i className="ri-arrow-right-s-line fs-24"></i>
    //       </Button>
    //     </div>
    //   </Col>
    // </Row>
  )
}

export default PaginationV2
