import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Form,
  Table,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap';
import classnames from 'classnames';
import parse from "html-react-parser"

import SimpleBar from 'simplebar-react';

import { scheduleServices } from "../../services/scheduleServices";
import moment from "moment";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { calculateEmployersContribution, convertTimeToMinute } from "../../utils/constants";

const AdvertisingSessionModal = ({
  open,
  handleClose,
  toggle,
  sessionDetails,
  bonusPercantage,
  handleApplyClick,
  advertisingSessions,
  showWithDrawnModal
}) => {
  const closeBtn = (
    <button
      className="btn-close  text-white"
      onClick={handleClose}
      type="button"
    ></button>
  );

  const [justifyTab, setjustifyTab] = useState("1");
  const [isApplied, setIsApplied] = useState([]);

  useEffect(() => {
    if(sessionDetails){
      const statusValue = sessionDetails?.Session_clients?.flatMap((record) => {
        const sessionStatuses = record.Session_statuses || [];
        return sessionStatuses?.map((innerRecord) => innerRecord.status);
      });
      setIsApplied(statusValue || []);
    }
  }, [sessionDetails])

  const justifyToggle = (tab) => {
    if (justifyTab !== tab) {
      setjustifyTab(tab);
    }
  };

  return (
    <Modal isOpen={open} id="event-modal">
      <ModalHeader
        toggle={toggle}
        className="p-3 bg-airgp modal-title"
        close={closeBtn}
      >
        <div className="modal-title text-white h5" id="myModalLabel">
          Session Details
        </div>
      </ModalHeader>
      <ModalBody>
        <Form
          className={"needs-validation view-event"}
          name="event-form"
          id="form-event"
          onSubmit={(e) => e.preventDefault()}
        >




        <Row>
          <Col xs={9}>
            <h5 className='fw-semibold'>{sessionDetails?.organisation?.name || "NA"}</h5>
          </Col>
          <Col xs={3} className='text-end'>
          {!advertisingSessions ? <>
            <h3 className='fw-semibold text-primary'>
              £
                    {(
                      (sessionDetails?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      ) +
                      (sessionDetails?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      ) *
                      +sessionDetails?.organisation?.service_fee_percentage *
                      bonusPercantage?.bonus_fee_percentage
                    ).toFixed(0)}
              </h3>
              </> : <>
                  <h3 className='fw-semibold text-primary'>
                    £
                    {(
                      (sessionDetails?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      )
                    ).toFixed()}
                  </h3>
                </>}
          </Col>
        </Row>


        <Row>
          <Col xs={9}>
          {moment(sessionDetails?.start_time).format(
                  "dddd, Do MMMM YYYY"
                )}
          </Col>
          {!advertisingSessions ? <>
          <Col xs={3} className='text-end text-muted'>

                  £
                    {(
                      (sessionDetails?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      )
                    ).toFixed() || "NA"}{" "}

          </Col>
          </>:<></>}
        </Row>

        <Row>
          <Col xs={7}>
          {moment(sessionDetails?.start_time).format("HH:mm") || "NA"}{" "}
                to {moment(sessionDetails?.end_time).format("HH:mm") || "NA"}
          </Col>
          <Col xs={5} className='text-end text-muted'>
          £{sessionDetails?.hourly_rate || "NA"}/h
          </Col>
        </Row>
        <Row>
          <Col xs={7}>
          {sessionDetails?.unpaid_break_duration || 'no break'}
          {sessionDetails?.unpaid_break_duration !== 0 ? " mins break" : ""}
          </Col>
          {bonusPercantage?.bonus_fee_percentage > 0 && (
          <Col xs={5} className='text-end text-muted'>
            {!advertisingSessions && (
              <>
                {(
                  (sessionDetails?.hourly_rate / 60) *
                  convertTimeToMinute(
                    sessionDetails?.start_time,
                    sessionDetails?.end_time,
                    sessionDetails?.unpaid_break_duration
                  ) *
                  +sessionDetails?.organisation?.service_fee_percentage *
                  bonusPercantage?.bonus_fee_percentage
                ).toFixed(0) > 0 && (
                  <>
                    £
                    {(
                        (sessionDetails?.hourly_rate / 60) *
                        convertTimeToMinute(
                          sessionDetails?.start_time,
                          sessionDetails?.end_time,
                          sessionDetails?.unpaid_break_duration
                        ) *
                        +sessionDetails?.organisation
                          ?.service_fee_percentage *
                        bonusPercantage?.bonus_fee_percentage
                      ).toFixed(0)
                    }{" "}bonus
                  </>
                )}
              </>
            )}
          </Col>
        )}
        </Row>






        <div className='d-flex flex-row justify-content-end small gap-1 my-1'>

          <div>
          <span className='badge rounded-pill badge-soft-info'>{sessionDetails?.length !== 0 && sessionDetails?.organisation?.organisation_profiles[0]?.gp_soc?.name}</span>
          </div>
          
          {(sessionDetails?.pensionable === 1 ?<div> <span className='badge rounded-pill badge-soft-success'>Pensionable</span></div> : '')}
        </div>

        <div className='d-flex flex-row justify-content-end small gap-1 my-1'>
        {!advertisingSessions ? <>
          <div>
          <span className='badge rounded-pill badge-soft-secondary'><i className="ri-map-pin-line"></i>{" "}{sessionDetails?.miles?.toFixed(0)} Miles</span>
          </div>
          </>:<></>}
          <div className='text-truncate'>
              <span className='badge rounded-pill badge-soft-primary'>{sessionDetails?.session_type?.type || "NA"}</span>
            </div>
          <div>
          <span className='badge rounded-pill badge-soft-danger'>#{sessionDetails?.uuid?.slice(0, 5) || 'NA'}</span>
          </div>
        </div>



        <Row>
          <Col xs={9}>

          </Col>
          <Col xs={3} className='text-end'>
            
          </Col>
        </Row>










          {/* <div className="d-flex small float-end align-items-center">
            <span className='badge rounded-pill badge-soft-primary'>{sessionDetails?.length !== 0 && sessionDetails?.organisation?.organisation_profiles[0]?.gp_soc?.name}</span>
          </div>

          <div className="mt-1 fw-semibold h5">
            {sessionDetails?.organisation?.name || "NA"}
          </div>

          <div className="hstack d-flex small align-items-start gap-2 mb-3">
            <div className="">
              <p className="mb-0 text-muted">
                {moment(sessionDetails?.start_time).format(
                  "dddd, Do MMMM YYYY"
                )}
              </p>
              <p className="mb-0 text-muted">
                {moment(sessionDetails?.start_time).format("HH:mm") || "00:00"}{" "}
                to {moment(sessionDetails?.end_time).format("HH:mm") || "NA"}
              </p>
              <p className="mb-0 text-muted">
                {''}{sessionDetails?.unpaid_break_duration || 'no break'}{' '}
                {sessionDetails?.unpaid_break_duration !== 0 ? "mins break" : ""}{''}
              </p>
            </div>
            <div className="text-primary  ms-auto">
              <div className="d-flex flex-column align-items-end text-primary  ms-auto">
                {!advertisingSessions ? <>
                  <div className="text-primary h3 fw-semibold">
                    £
                    {(
                      (sessionDetails?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      ) +
                      (sessionDetails?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      ) *
                      +sessionDetails?.organisation?.service_fee_percentage *
                      bonusPercantage?.bonus_fee_percentage */}
                      {/* // + (sessionDetails?.pensionable === 1 ? Number(calculateEmployersContribution((sessionDetails?.hourly_rate / 60) * convertTimeToMinute(sessionDetails?.start_time,
                      //   sessionDetails?.end_time,
                      //   sessionDetails?.unpaid_break_duration
                      // ))) 
                      // : Number(0)) */}
                    {/* ).toFixed(0)}
                  </div>
<<<<<<< HEAD
<<<<<<< HEAD
                  <div className="text-muted small p mb-0 float-end">
=======
                  <div className="hstack d-flex small align-items-end ms-auto  mb-3 float-end">
                    <div className='text-end'>
>>>>>>> dev
=======
                  <div className="hstack d-flex small align-items-end ms-auto  mb-3 float-end">
                    <div className='text-end'>
>>>>>>> 0e594fde0550e16826d67af680b149f18ca30845
                    £
                    {(
                      (sessionDetails?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      )
                    ).toFixed() || "NA"}{" "}
                    </div>
                    <div className='text-end'>
                    (£{sessionDetails?.hourly_rate || "NA"} /hour) + £
                    {bonusPercantage?.bonus_fee_percentage
                      ? (
                        (sessionDetails?.hourly_rate / 60) *
                        convertTimeToMinute(
                          sessionDetails?.start_time,
                          sessionDetails?.end_time,
                          sessionDetails?.unpaid_break_duration
                        ) *
                        +sessionDetails?.organisation
                          ?.service_fee_percentage *
                        bonusPercantage?.bonus_fee_percentage
                      ).toFixed(0)
                      : "0"}{" "}

                    Bonus</div>
                    <div className='text-end'> */}
                    {/* {sessionDetails?.pensionable === 1 ? (
                      <i
                        title="Pensionable"
                        className="ms-1 ri-file-ppt-line"
                      ></i>
                    ) : (
                      ""
                    )} */}
                    {/* {(sessionDetails?.pensionable === 1 ?
                      ` + £${Number(calculateEmployersContribution((sessionDetails?.hourly_rate / 60) * convertTimeToMinute(sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      )))}
                      ` + Pension` : '')}</div>
                  </div>
                </> : <>
                  <div className="text-primary h3 fw-semibold">
                    £
                    {(
                      (sessionDetails?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      )
                    ).toFixed()}
                  </div>
                  <div className="text-muted p mb-0 float-end">
                    £
                    {(
                      (sessionDetails?.hourly_rate / 60) *
                      convertTimeToMinute(
                        sessionDetails?.start_time,
                        sessionDetails?.end_time,
                        sessionDetails?.unpaid_break_duration
                      )
                    ).toFixed() || "NA"}{" "}
                    (£{sessionDetails?.hourly_rate || "NA"} /hour)
                  </div>
                </>}

              </div>
            </div>
          </div> */}

          <Nav
            tabs
            className="nav nav-tabs nav-tabs-custom  nav-primary nav-justified mb-3 mx-n3"
          >
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: justifyTab === "1" })}
                onClick={() => {
                  justifyToggle("1");
                }}
              >
                At Glance
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: justifyTab === "2" })}
                onClick={() => {
                  justifyToggle("2");
                }}
              >
                Description
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: justifyTab === "3" })}
                onClick={() => {
                  justifyToggle("3");
                }}
              >
                Address
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={justifyTab} className="text-muted">
            <TabPane tabId="1" id="description">
              <div className="flex-grow-1">
                {sessionDetails?.Session_features?.map((_, index) => (
                  <div className="d-flex  align-items-center" key={index}>
                    <div className="flex-shrink-0">
                      <i className=" ri-check-fill fs-18 pe-1 text-success"></i>
                    </div>
                    <div className="flex-grow">{_?.Feature?.name || "NA"}</div>
                  </div>
                ))}
              </div>
            </TabPane>

            <TabPane tabId="2">
              <SimpleBar style={{ maxHeight: "250px" }}>
                {sessionDetails?.session_description ? parse(sessionDetails?.session_description) : "NA"}
              </SimpleBar>
            </TabPane>

            <TabPane tabId="3">
              <p>
                <span>{sessionDetails?.organisation?.address_line_1}<br /></span>
                <span>{sessionDetails?.organisation?.address_line_2}<br /></span>
                {sessionDetails?.organisation?.address_line_3 &&
                  <span>
                    {sessionDetails?.organisation?.address_line_3}<br />
                  </span>}
                {sessionDetails?.organisation?.address_line_4 &&
                  <span>
                    {sessionDetails?.organisation?.address_line_4}<br />
                  </span>}
                <span>{sessionDetails?.organisation?.postcode}<br /></span>
              </p>
              <div className="d-grid gap-2">
                <Link to={{ pathname: `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${sessionDetails?.organisation?.name},${sessionDetails?.organisation?.postcode}`)}` }} target='_blank' className='btn btn-soft-primary btn-sm'>
                  {" "}
                  Open in Google Maps{" "}
                </Link>
              </div>
            </TabPane>
          </TabContent>
        </Form>
      </ModalBody>
      <ModalFooter>
        {(isApplied && isApplied.length > 0) ? (
          <div>
          {isApplied.map((status, index) => (
            <div key={index}>
              {(status === "Applied" || status === "Booked") && (
            <div>
              <Link
                to="#"
                className="btn btn-danger"
                id="tooltipDel"
                onClick={() => {
                  showWithDrawnModal();
                }}
              >
                Withdraw
              </Link>
            </div>
          )}
          </div>
          ))}
        </div>
        ) : (
          <div>
            <Button color="primary" onClick={handleApplyClick}>
              Apply
            </Button>
          </div>
        )}
        <Button color="light" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AdvertisingSessionModal;
