import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledTooltip
} from 'reactstrap'
import classnames from 'classnames'

//Images
// import profileBg from '../../../assets/images/profile-bg.jpg'
import profile from '../../../assets/images/person-circle.svg'

// import { document } from '../../../common/data'
import { accountServices } from '../../../services/accountServices'
import { getCookie, PROFILE_STATE } from '../../../utils/constants'
import moment from 'moment'
import { toast } from 'react-toastify'
import SpinnerComponent from '../../../Components/Common/Spinner'
import AccountDocuments from './AccountDocuments'
import TaxReport from './TaxReport'
import ProfileOverview from './ProfileOverview'

const ProfileInformationPage = () => {
  const [activeTab, setActiveTab] = useState('1')
  const [profileInfo, setProfileInfo] = useState(PROFILE_STATE)
  const [profileAvailable, setProfileAvailable] = useState(false)
  const [profileInfoAvailable, setProfileInfoAvailable] = useState(true)
  const [documentVerified, setDocumentVerified] = useState(false)
  const [paymentInformation, setPaymentInformation] = useState(false)
  const [onPerformersList, setOnPerformersList] = useState(false)
  const [GMCStatus, setGMCStatus] = useState(false)
  const [userProfileInformationComplete, setUserProfileInformationComplete] = useState(false)
  const [progressCount, setProgressCount] = useState(0)
  const [profileUrl, setProfileUrl] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)

  const history = useHistory()
  const token = localStorage.getItem('token')

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
  }

  useEffect(() => {
    fetchUserProfileDetails(token)
  }, [])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const countProgressNumber = (
    documentVerified,
    paymentVerified,
    profileVerified,
    gmcStatus,
    performerList,
    userProfileComplete
  ) => {
    const argsArray = [
      documentVerified,
      paymentVerified,
      profileVerified,
      gmcStatus,
      performerList,
      userProfileComplete
    ]

    let increseWith = 100 / argsArray.length || 0
    let progressNo = 0
    argsArray.map(_ => {
      if (_) {
        progressNo += increseWith
      }
    })
    setProgressCount(progressNo)
  }

  const fetchUserProfileDetails = token => {
    setIsLoading(true)
    accountServices
      .getUserProfileDetails(token)
      .then(resp => {
        if(resp.message ===  "Unauthorised"){
          localStorage.clear();
          history.push('/login');
        }
        setProfileAvailable(resp.profilePhoto)
        setDocumentVerified(resp.documentsVerified)
        setPaymentInformation(resp.bankDetails)
        setOnPerformersList(resp.onPerformerList)
        setGMCStatus(resp.GMCStatusCheckAvailable)
        setUserProfileInformationComplete(resp.userProfileInformationComplete)
        countProgressNumber(
          resp.documentsVerified,
          resp.bankDetails,
          resp.profilePhoto,
          resp.GMCStatusCheckAvailable,
          resp.onPerformerList,
          resp.userProfileInformationComplete
        )
        const copyedProfileState = { ...profileInfo }
        copyedProfileState.name = `${resp?.data?.user?.title} ${resp?.data?.user?.firstname} ${resp?.data?.user?.lastname}`
        copyedProfileState.regulatorType = resp?.data?.regulator_type
        copyedProfileState.regulatorNumber = resp?.data?.regulator_number
        copyedProfileState.smartCardNumber = resp?.data?.smartcard_number
        copyedProfileState.gender = resp?.data?.gender
        copyedProfileState.dob = moment(resp?.data?.dob).format(
          'L'
        )
        copyedProfileState.bio = resp?.data?.bio
        copyedProfileState.address1 = resp?.data?.address_line_4
        copyedProfileState.address2 = resp?.data?.address_line_5
        let lang = []
        let itSys = []
        let qual = []

        resp?.data?.user?.languages?.map(_ => {
          lang.push(_.language)
        })

        resp?.data?.user?.qualification?.map(_ => {
          qual.push(_.qualification)
        })

        resp?.data?.user?.gp_soc?.map(_ => {
          itSys.push(_.name)
        })
        if (!resp.profilePhoto && !resp.userProfileInformationComplete && !resp.bankDetails) {
          tog_backdrop()
        }

        copyedProfileState.language = lang
        copyedProfileState.qualification = qual
        copyedProfileState.itSystem = itSys

        setProfileInfo(copyedProfileState)
        if (resp.success) {
          setProfileInfoAvailable(true)
        } else {
          toast.error(resp.message)
          setProfileInfoAvailable(false)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))

    fetchProfilePhoto(token)
  }

  const fetchProfilePhoto = token => {
    setIsLoading(true)
    accountServices
      .getProfilePhoto(token)
      .then(resp => {
        if (resp.success) {
          setTimeout(()=>{
            accountServices.downloadDocument(resp?.data?.uuid,"profilephoto",token)
            .then(resp=> resp.blob())
            .then(resp=>URL.createObjectURL(resp))
            .then(resp=>setProfileUrl(resp))
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))
          },300)
          
        }
      })
      .catch(err => console.log(err))
  }

  const handleCompleteProfileClick = () => {
    history.push('/profile-settings')
    tog_backdrop()
  }

  document.title = 'Profile | airGP'

  return (
    <React.Fragment>
      <div className='page-content'>
        {isLoading && <SpinnerComponent />}
        <Container fluid>
          <Row>
            <Col>
              <Card className='mt-n4 mx-n2 rounded-bottom border-top-0 rounded-0'>
                <div className='bg-airgp rounded-bottom border-top-0'>
                  <CardBody className='pb-0 px-4'>


                  <div className='pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper'>
                    <div className='g-4 align-items-center row'>
                        <div className='col-sm-auto'>

                        <div className='avatar-xl'>
                              <div className='profile-user  position-relative d-inline-block mx-auto mt-1 mb-4'>
                                <img
                                  src={profileUrl || profile}
                                  alt='profile image'
                                  className='img-thumbnail rounded-circle avatar-xl'
                                  style={{ objectFit: "cover" }}
                                />
                                {/* <img className="img-thumbnail rounded-circle avatar-xl" alt="200x200" src={profile} /> */}
                              </div>
                            </div>

                        </div>

                    <div className='col'>
                    <div className="hstack gap-2 h2 text-capitalize text-light fw-semibold">
                                  {profileInfo?.name}
                                  {progressCount > 99 ? (

                                    // <div className="bg-light border p-1 px-2">Third item</div>
                                    <div className="avatar-xs ">
                                      <div className="avatar-title ">
                                        <i className="ri-verified-badge-fill text-warning fs-24" id='verified-icon'></i>
                                        <UncontrolledTooltip placement="top" target="verified-icon" > Verified Profile</UncontrolledTooltip>
                                      </div>
                                    </div>

                                  ) : (
                                    ''
                                  )}
                                </div>

                    </div>
                    <div className='col-lg-auto order-last order-lg-0 col-12 text-end'>
                    <Link
                            to='/profile-settings'
                            className='btn btn-light btn-label'
                          >
                            <i className='ri-edit-line label-icon align-middle fs-16 me-2'></i>{' '}
                            Edit Profile
                    </Link>
                    </div>
                    </div>
                </div>





                    {/* <Row className='mb-3'>
                      <Col>
                        <Row className='align-items-center g-3'>
                          <div className='col-md-auto '>
                            <div className='avatar-xl'>
                              <div className='profile-user  position-relative d-inline-block mx-auto mt-1 mb-4'>
                                <img
                                  src={profileUrl || profile}
                                  alt='profile image'
                                  className='img-thumbnail rounded-circle avatar-xl'
                                  style={{ objectFit: "cover" }}
                                />
                                <img className="img-thumbnail rounded-circle avatar-xl" alt="200x200" src={profile} />
                              </div>
                            </div>
                          </div> */}
                          {/* <div className='col'>

                            <div>
                                <div className="hstack gap-2 h2 text-light fw-semibold">
                                  {profileInfo?.name}
                                  {progressCount > 99 ? (

                                    <div className="bg-light border p-1 px-2">Third item</div>
                                    <div className="avatar-xs ">
                                      <div className="avatar-title ">
                                        <i className="ri-verified-badge-fill text-warning fs-24" id='verified-icon'></i>
                                        <UncontrolledTooltip placement="top" target="verified-icon" > Verified Profile</UncontrolledTooltip>
                                      </div>
                                    </div>

                                  ) : (
                                    ''
                                  )}
                                </div>
                            </div>

                          </div>
                        </Row>
                      </Col>
                      <div className='col-auto d-flex align-items-end'>
                        <div className=' '>
                          <Link
                            to='/profile-settings'
                            className='btn btn-light btn-label'
                          >
                            <i className='ri-edit-line label-icon align-middle fs-16 me-2'></i>{' '}
                            Edit Profile
                          </Link>
                        </div>
                      </div>
                    </Row> */}

                    <Nav className='nav-tabs' role='tablist'>
                      <NavItem>
                        <NavLink
                          href='#overview-tab'
                          className={classnames({ active: activeTab === '1' })}
                          onClick={() => {
                            toggleTab('1')
                          }}
                        >
                          <i className='ri-account-circle-line fs-20 d-inline-block d-md-none'></i>{' '}
                          <span className='d-none d-md-inline-block fs-14'>
                            Overview
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href='#documents'
                          className={classnames({ active: activeTab === '4' })}
                          onClick={() => {
                            toggleTab('4')
                          }}
                        >
                          <i className='ri-folder-2-line fs-20 d-inline-block d-md-none'></i>{' '}
                          <span className='d-none d-md-inline-block fs-14'>
                            Documents
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href='#tax'
                          className={classnames({ active: activeTab === '5' })}
                          onClick={() => {
                            toggleTab('5')
                          }}
                        >
                          <i className='ri-money-pound-box-line  fs-20 d-inline-block d-md-none'></i>{' '}
                          <span className='d-none d-md-inline-block fs-14'>
                            Tax
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div>
                <TabContent activeTab={activeTab} className='pt-1'>
                  <TabPane tabId='1'>
                    <ProfileOverview
                      profileInfo={profileInfo}
                      progressCount={progressCount}
                      documentVerified={documentVerified}
                      toggleTab={toggleTab}
                      paymentInformation={paymentInformation}
                      profileAvailable={profileAvailable}
                      performerVerified={onPerformersList}
                      GMCStatus={GMCStatus}
                      userProfileInformationComplete={userProfileInformationComplete}
                    />
                  </TabPane>

                  <TabPane tabId='4'>
                    <AccountDocuments setIsLoading={setIsLoading} />
                  </TabPane>

                  <TabPane tabId='5'>
                    <TaxReport />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
          <Modal
            isOpen={modal_backdrop}
            toggle={() => {
              tog_backdrop()
            }}
            backdrop={'static'}
            id='staticBackdrop'
            
          >
            <ModalHeader
                className="p-3 bg-airgp modal-title"
              >
            <div className="modal-title text-white h5" id="myModalLabel">
              Complete Profile
            </div>
            </ModalHeader>
            <ModalBody className="py-3 px-5">
              <div className="mt-2 text-center">
                <div className="">
                  <h4>Almost there!</h4>
                  <p className="text-muted mx-4 mb-0">
                    Please complete your profile to start booking sessions
                  </p>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <Link
                    to='#'
                    className='btn btn-primary'
                    onClick={handleCompleteProfileClick}
                  >
                    Go to Profile
                  </Link>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProfileInformationPage
