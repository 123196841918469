import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {AccordionBody, AccordionHeader, AccordionItem, Alert, Card, CardHeader, Col, Container, Row, UncontrolledAccordion, Nav, NavItem, NavLink, TabContent,TabPane, CardBody } from 'reactstrap';
import classnames from "classnames";

import Navbar from '../Landing/navbar'
import Footer from '../Landing/footer'

// Import Images
import faqImg from "../../assets/images/faq-img.png";

  

const Support = () => {

    // Justify Tabs
    const [justifyTab, setjustifyTab] = useState("1");
    const justifyToggle = (tab) => {
        if (justifyTab !== tab) {
            setjustifyTab(tab);
        }
    };

  document.title = "airGP | Support";
  return (
    
    <React.Fragment>
      <Navbar />
      <div className="page-content">
        <Container className='mt-3'>
          <Row>
                <Col>
                    <Card className="rounded bg-soft-primary">
                        <div className="px-4">
                            <Row>
                                <Col xxl={5} className="align-self-center">
                                    <div className="py-4">
                                        <h4 className="display-6 coming-soon-text">Support</h4>
                                        <p className="text-primary opacity-75 fs-15 mt-3">If you can not find answer to your question in our support section, you can always contact us.</p>
                                        <div className="hstack flex-wrap gap-2">
                                            {/* <button type="button" className="btn btn-primary btn-label rounded-pill"><i className="ri-chat-3-line label-icon align-middle rounded-pill fs-16 me-2"></i> Contact Us</button> */}
                                            <a type="button" className="btn btn-primary btn-label rounded-pill" href="https://salesiq.zohopublic.eu/signaturesupport.ls?widgetcode=siqa4899ae98cc7389a42ed0330b9af1dcd973592c3268a1c1c969e1fb46ef44f7e" target="_blank" rel="noopener noreferrer"><i className="ri-chat-3-line label-icon align-middle rounded-pill fs-16 me-2"></i>Contact Us</a>
                                            {/* <button type="button" className="btn btn-info btn-label rounded-pill"><i className="ri-twitter-line label-icon align-middle rounded-pill fs-16 me-2"></i> Send Us Tweet</button> */}
                                        </div>
                                    </div>
                                </Col>
                                <div className="col-xxl-4 ms-auto">
                                    <div className="mb-n4 pb-1 faq-img d-none d-xxl-block">
                                        <img src={faqImg} alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </Row>
                        </div>                                    
                    </Card>

                    <Card>
                    <CardHeader className='mb-0 pb-0'>
                        <h4 className='text-light mb-3'>Help Guides</h4>

                    <Nav tabs className='nav-tabs nav-justified'>
                        <NavItem >
                            <NavLink  style={{ cursor: "pointer" }} className={classnames({ active: justifyTab === "1", })} onClick={() => { justifyToggle("1"); }} >
                            <i className="ri-user-line me-1"></i>
                                GPs

                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: justifyTab === "2", })} onClick={() => { justifyToggle("2"); }} >
                            <i className="ri-home-3-line me-1"></i>
                                GP Surgeries
                            </NavLink>
                        </NavItem>
                    </Nav>
                    </CardHeader>
                    <CardBody>

                    <TabContent activeTab={justifyTab} className="text-muted">
                        <TabPane tabId="1">
                   
                        <Row>
                        <Col>
                            <div className="mt-3">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-shrink-0 me-1">
                                        <i className="ri-user-add-line fs-24 align-middle text-primary me-1"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-16 mb-0 fw-semibold">General</h5>
                                    </div>
                                </div>
                                {/* <UncontrolledAccordion defaultOpen={['1']}> */}
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                    <AccordionHeader targetId="1">How do I register?</AccordionHeader>
                                    <AccordionBody accordionId="1">
                                    <p>
                                    To register for airGP as a GP, follow these steps:
                                    </p>
                                    {/* <br></br> */}
                                    <ol>
                                        <li>
                                        Click on "Sign up" in the top right corner of the page and select "GP".
                                        </li>
                                        <li>
                                        Enter your email address, GMC registration number, and password.
                                        </li>
                                        <li>
                                        Complete the registration form with your personal information.
                                        </li>
                                        <li>
                                        Click "Submit" to complete your registration.
                                        </li>
                                    </ol>
                                    <p className='mb-0'>Once you've completed these steps, you'll receive an email to confirm your email address.</p>
                                    <p className='mb-0'>Upon successful registration, please complete your profile and upload mandatory documents whilst we do our verfications and checks.</p>
                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="2">Eligibility requirements?</AccordionHeader>
                                    <AccordionBody accordionId="2">
                                    <p>
                                    To be eligible to use airGP's platform, you must meet the following requirements:
                                    </p>
                                    <ol>
                                        <li>
                                        Be a registered General Practitioner (GP) with the General Medical Council (GMC) in the UK.

                                        </li>
                                        <li>
                                        Be on the Performers List with goodstanding

                                        </li>
                                        <li>
                                        Be eligible to work in the UK & NHS.

                                        </li>
                                        <li>
                                        Upload all the relevant documents and real life photo.

                                        </li>
                                        <li>
                                        Meet any additional requirements set by an individual surgery or organisation.

                                        </li>
                                    </ol>
                                    <p className='mb-0'>By meeting these eligibility requirements, you'll be able to search for and book locum shifts on airGP's platform. </p>
                                    <p className='mb-0'>If you have any questions about your eligibility, please contact airGP's customer support team for further assistance.</p>
                                    <br></br>
                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="3">Documents & Verification</AccordionHeader>
                                    <AccordionBody accordionId="3">
                                    <p className='mb-0'>To use airGP's platform, you are required to provide certain documents to verify your identity and eligibility as a GP locum.</p>
                                    <p>list of mandatory documents:</p>
                                    <ol>
                                    <li>Passport or national ID</li>
                                    <li>Disclosure & Barring Service (DBS)</li>
                                    <li>CV</li>
                                    </ol>

                                    <p className='mb-0'>It's important to ensure that you have all the necessary documents before booking any shifts on airGP's platform to avoid any delays or issues with your bookings.</p>
                                    <p className='mb-0'>If you have any questions about the required documents, please contact airGP's customer support team for further assistance</p>

                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="4">Is there an app?</AccordionHeader>
                                    <AccordionBody accordionId="4">
                                    
                                    <p className='mb-1'>AirGP's platform is designed to be mobile-friendly, which means you can access it from your smartphone or tablet's web browser.</p>
                                    <p className='mb-1'>This means that you don't need to download an app to use the platform, simply visit the airGP website on your mobile device and log in to start searching for locum shifts.</p>
                                    <p className='mb-1'>To make it even easier to use the platform on your mobile device, airGP allows you to add the platform to your home screen. </p>
                                    <p className='mb-1'>This creates a shortcut that looks like an app icon on your home screen, making it easy to access the platform without having to type in the website's URL every time you want to use it.</p>
                                    
                                    <p className='mb-0'>So, whether you prefer using a desktop computer or a mobile device, airGP's platform is designed to be user-friendly and accessible from any device with an internet connection.</p>
                                    </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                        </Col>
                        </Row>    
                        <Row>
                        <Col>
                            <div className="mt-3">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-shrink-0 me-1">
                                        <i className="ri-calendar-2-line fs-24 align-middle text-primary me-1"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-16 mb-0 fw-semibold">Schedule</h5>
                                    </div>
                                </div>
                                {/* <UncontrolledAccordion defaultOpen={['1']}> */}
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                    <AccordionHeader targetId="1">Calendar</AccordionHeader>
                                    <AccordionBody accordionId="1">
                                    <p className='mb-1'>When you book a shift on airGP, the system will automatically add it to your calendar. The shift will be marked as "Booked" on the calendar.</p>
                                    <p className='mb-1'>You can click on each event to view more details such as shift time, organisation address..etc</p>
                                    <p className='mb-1'>By using airGP's calendar feature, you'll have a clear overview of your available shifts and any bookings you've made on the platform. This will help you manage your schedule more effectively.
                                    </p>
                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="2">Set unavailable dates</AccordionHeader>
                                    <AccordionBody accordionId="2">
                                    <p className='mb-1'></p>
                                    You can block dates in which you're not available from the calender "set unavailablity" button.
                                    <p>By using airGP's unavailablity feature, you'll be able to avoid conflict with any bookings you make on the platform.</p>
                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="3">Calendar syncing</AccordionHeader>
                                    <AccordionBody accordionId="3">
                                    <p className=''>This feature is still in development, but it should be available soon. Once it's live, you'll be able to log in to your airGP account and sync your bookings with your chosen calender app.</p>
                                    </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                        </Col>
                        </Row>                         
                        <Row>
                        <Col>
                            <div className="mt-3">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-shrink-0 me-1">
                                        <i className="ri-search-line fs-24 align-middle text-primary me-1"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-16 mb-0 fw-semibold">Booking</h5>
                                    </div>
                                </div>
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                    <AccordionHeader targetId="1">How do I book sessions?</AccordionHeader>
                                    <AccordionBody accordionId="1">
                                    <Alert color="warning" className="alert-borderless">
                                       You must have completed all the required profile and registration information.
                                    </Alert>
                                    <p className='mb-2'>Here's how you can book a session on airGP:</p>
                                    <ol>
                                        <li>
                                        Log in to your airGP account and choose the "Book" menu item.

                                        </li>
                                        <li>
                                        Use the search function to find available shifts or use the filters to narrow down the search criteria (e.g. by location).

                                        </li>
                                        <li>
                                        Once you've found an available shift, click "Apply" to send your application to the practice.

                                        </li>
                                    </ol>
                                    <p className='mb-1'>
                                    You will recieve a confirmation email once your application has been accepted by the practice manager.
                                    </p>
                                    <p>By following these steps, you can easily book a session on airGP. If you have any questions or need further assistance, feel free to ask</p>

                                    <p className='mb-0 small'>Please note the distance is an approximation and the fee is rounded up, the final fee will be shown on your invoice</p>
                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="2">Can I withdraw from a session?</AccordionHeader>
                                    <AccordionBody accordionId="2">
                                    <p className='mb-2'>Here's how you can withdraw from a shift on airGP:</p>
                                    <ol>
                                        <li>
                                        Log in to your airGP account and visit the Calender-><a href="/session-list">Session List</a> menu item.
                                        </li>
                                        <li>
                                        Find the shift you want to withdraw from and click the 3 dots Actions button and select Withdraw
                                        </li>
                                        <li>
                                        You will recieve an email to confirm your withdrawal and the session will disappear from your calender
                                        </li>
                                    </ol>
                                    <p className='mb-1'>By following these steps, you can easily withdraw from a shift on airGP if needed. </p>
                                    <p className='mb-2'>Remember, it's important to communicate with us and the practice if you decided to withdraw, especially if it's a short notice to minimise disruption.</p>
                                    <p className='mb-0 small'>Repeated withdrawals, especially at a short notice may lead to a review of your account.</p>

                                    </AccordionBody>
                                    </AccordionItem>
                                    {/* <AccordionItem>
                                    <AccordionHeader targetId="3">Account deletion</AccordionHeader>
                                    <AccordionBody accordionId="3">
                                        <strong>This is the third item&#39;s accordion body.</strong>
                                        You can modify any of this with custom CSS or overriding our default
                                        variables. It&#39;s also worth noting that just about any HTML can
                                        go within the <code>.accordion-body</code>, though the transition
                                        does limit overflow.
                                    </AccordionBody>
                                    </AccordionItem> */}
                                </UncontrolledAccordion>
                            </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col>
                            <div className="mt-3">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-shrink-0 me-1">
                                        <i className="ri-file-copy-2-line fs-24 align-middle text-primary me-1"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-16 mb-0 fw-semibold">Invoicing & Payments</h5>
                                    </div>
                                </div>
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                    <AccordionHeader targetId="1">How do I invoice?</AccordionHeader>
                                    <AccordionBody accordionId="1">

                                    <p className='mb-1'>Here's how you can create an invoice following a completion of a shift on airGP:</p>
                                    <p className='mb-1'></p>
                                    <ol>
                                        <li>
                                        Log in to your airGP account and visit the "Invoices" from the top navigation menu.
                                        </li>
                                        <li>
                                        Under "Invoice" tab, click the 3 dot action button and click "Create invoice".
                                        </li>
                                        <li>
                                        You may edit the invoice prior to creating it if you want to add expenses or change duration or hourly rate.
                                        </li>
                                        <li>
                                        You will recieve an email when the surgery accept or reject your proposed changes.
                                        </li>
                                        <li>
                                        Any amendments must have been agreed prior with the practice manager.
                                        </li>
                                        <li>
                                        Review your invoice before submitting it to the practice.
                                        </li>
                                        <li>
                                        Once it's been paid, you will recieve a confirmation email.
                                        </li>
                                    </ol>

                                    <p className='mb-0'>By following these steps, you can easily create an invoice on airGP and get paid for your services. If you have any questions or need further assistance, feel free to ask.</p>

                                    </AccordionBody>
                                    </AccordionItem>
                                    {/* <AccordionItem>
                                    <AccordionHeader targetId="2">How do I add expenses?</AccordionHeader>
                                    <AccordionBody accordionId="2">
                                    <p className='mb-0'>You can edit the session hours and fee, and add expenses prior to generate a session</p>
                                    <p className='mb-0'>Any changes you make must be approved the surgery</p>
                                    <p className='mb-0'>You will recieve an email if the surgery accept or reject your proposed changes</p>
                                    <p className='mb-0'>A status will be shown on the invoice list, if it's accept, you can then generate the invoice</p>
                                    </AccordionBody>
                                    </AccordionItem> */}
                                    {/* <AccordionItem>
                                    <AccordionHeader targetId="3">Account deletion</AccordionHeader>
                                    <AccordionBody accordionId="3">
                                        <strong>This is the third item&#39;s accordion body.</strong>
                                        You can modify any of this with custom CSS or overriding our default
                                        variables. It&#39;s also worth noting that just about any HTML can
                                        go within the <code>.accordion-body</code>, though the transition
                                        does limit overflow.
                                    </AccordionBody>
                                    </AccordionItem> */}
                                </UncontrolledAccordion>
                            </div>
                        </Col>
                        </Row>
 
                        <Row>
                        <Col>
                            <div className="mt-3">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-shrink-0 me-1">
                                        <i className="ri-file-ppt-2-line fs-24 align-middle text-primary me-1"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-16 mb-0 fw-semibold">Pension</h5>
                                    </div>
                                </div>
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                    <AccordionHeader targetId="1">How do I claim pension?</AccordionHeader>
                                    <AccordionBody accordionId="1">
                                    <Alert color="warning" className="alert-borderless">
                                       This feature is currently in beta.
                                    </Alert>
                                    <p className='mb-2'>Here's how you can claim pension on shifts on airGP:</p>
                                    <ol>
                                        <li>
                                        You must complete your Pension details by visiting Account-><a href="/profile-settings">Edit Profile</a>->Pension tab.
                                        </li>
                                        <li>
                                        The session must be pensionable at the descretion of the individual practice.
                                        </li>
                                        <li>
                                        Upon generate the invoice, you will have the option to claim pension.
                                        </li>
                                        <li>
                                        Form A will be generated, which you will find in the Pension menu.
                                        </li>
                                        <li>
                                        You must complete the rest of the process according to PCSE instructions.
                                        </li>
                                    </ol>

                                    <p className='mb-0'>By following these steps, you can easily claim pension on shifts on airGP. If you have any questions or need further assistance, feel free to ask.</p>

                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="2">Pension eligibility</AccordionHeader>
                                    <AccordionBody accordionId="2">
                                    
                                    <p className='mb-1'>You must have your pension claim number(SD number) and be eligible for NHS Pension</p>
                                    <p className='mb-1'>If you are working as a limited company, you cannot claim pension as per NHS England pension rules</p>
                                    {/* <p className='mb-0'>You can edit the session hours and fee, and add expenses prior to generate a session</p> */}
                                    </AccordionBody>
                                    </AccordionItem>
                                    {/* <AccordionItem>
                                    <AccordionHeader targetId="3">Account deletion</AccordionHeader>
                                    <AccordionBody accordionId="3">
                                        <strong>This is the third item&#39;s accordion body.</strong>
                                        You can modify any of this with custom CSS or overriding our default
                                        variables. It&#39;s also worth noting that just about any HTML can
                                        go within the <code>.accordion-body</code>, though the transition
                                        does limit overflow.
                                    </AccordionBody>
                                    </AccordionItem> */}
                                </UncontrolledAccordion>
                            </div>
                        </Col>
                        </Row>
 
                        <Row>
                        <Col>
                            <div className="mt-3">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-shrink-0 me-1">
                                        <i className="ri-user-3-line fs-24 align-middle text-primary me-1"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-16 mb-0 fw-semibold">Account & Profile</h5>
                                    </div>
                                </div>
                                <UncontrolledAccordion>
                                    {/* <AccordionItem>
                                    <AccordionHeader targetId="1">How do I update my information?</AccordionHeader>
                                    <AccordionBody accordionId="1">
                                    <p className='mb-0'>Head to account section, you will be prompted to update your information</p>
                                    </AccordionBody>
                                    </AccordionItem> */}
                                    <AccordionItem>
                                    <AccordionHeader targetId="2">What if I don't have a smartcard number?</AccordionHeader>
                                    <AccordionBody accordionId="2">
                                    <p className="mb-1">If you don't have a smartcard, you can still use airGP to book sessions and manage your schedule, as most practices have a locum card you can use.</p>

                                    <p className="mb-1">Please contact airGP support if you don't have a smartcard, we may able to help you set you up with one.</p>

                                    </AccordionBody>
                                    </AccordionItem>

                                    <AccordionItem>
                                    <AccordionHeader targetId="4">Account deletion</AccordionHeader>
                                    <AccordionBody accordionId="4">
                                    <p className='mb-2'>Here's how you can delete your account on airGP:</p>
                                    <ol>
                                        <li>
                                        Log in to your airGP account and visit the Account->Profile Settings->Account Settings Tab.
                                        </li>
                                        <li>
                                        Click on "Delete Account" at the bottom of the page.

                                        </li>
                                        <li>
                                        Enter your password to confirm the action.

                                        </li>
                                        <li>
                                        Click "Delete Account" to permanently delete your airGP account.

                                        </li>
                                    </ol>

                                    <p className='mb-1'>Please note that deleting your account is permanent and cannot be undone. Once you've deleted your account, you won't be able to access your airGP profile or any bookings or invoices associated with it. If you're sure you want to delete your account, go ahead and click the "Delete My Account" button.</p>
                                    <p className='mb-0 small'>There is a cooling period to allow you to export your data before all your data is deleted.</p>
                                    </AccordionBody>
                                    </AccordionItem>
                                    {/* <AccordionItem>
                                    <AccordionHeader targetId="3">Account deletion</AccordionHeader>
                                    <AccordionBody accordionId="3">
                                        <strong>This is the third item&#39;s accordion body.</strong>
                                        You can modify any of this with custom CSS or overriding our default
                                        variables. It&#39;s also worth noting that just about any HTML can
                                        go within the <code>.accordion-body</code>, though the transition
                                        does limit overflow.
                                    </AccordionBody>
                                    </AccordionItem> */}
                                </UncontrolledAccordion>
                            </div>
                        </Col>
                        </Row>
 
                        </TabPane>

                        <TabPane tabId="2">
                   
                        <Row>
                        <Col>
                            <div className="mt-3">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-shrink-0 me-1">
                                        <i className="ri-user-add-line fs-24 align-middle text-primary me-1"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-16 mb-0 fw-semibold">Registration</h5>
                                    </div>
                                </div>
                                {/* <UncontrolledAccordion defaultOpen={['1']}> */}
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                    <AccordionHeader targetId="1">How do I register my GP surgery?</AccordionHeader>
                                    <AccordionBody accordionId="1">
                                    <p className='mb-2'>Here's how you can register your GP surgery on airGP:</p>
                                    <ol>
                                        <li>
                                        Click on "Sign up" in the top right corner of the page and select "Organisation".
                                        </li>
                                        <li>
                                        Enter your email address.
                                        </li>
                                        <li>
                                        Complete the registration form with your personal information.
                                        </li>
                                        <li>
                                        Click "Submit" to complete your registration.
                                        </li>
                                    </ol>
                                    <p className='mb-1'>Once you've completed these steps, you'll receive an email to confirm your email address.</p>
                                    <p className='mb-1'>Upon successful approval, we will send you a confirmation email.</p>
                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="2">Eligibility requirements?</AccordionHeader>
                                    <AccordionBody accordionId="2">
                                    <p className='mb-2'>Registration is currently open only to NHS GP Practices providing in-hours community services</p>
                                    </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                        </Col>
                        </Row>    
                        <Row>
                        <Col>
                            <div className="mt-3">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-shrink-0 me-1">
                                        <i className="ri-calendar-2-line fs-24 align-middle text-primary me-1"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-16 mb-0 fw-semibold">Schedule</h5>
                                    </div>
                                </div>
                                {/* <UncontrolledAccordion defaultOpen={['1']}> */}
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                    <AccordionHeader targetId="1">How do I register?</AccordionHeader>
                                    <AccordionBody accordionId="1">

                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="2">Eligibility requirements?</AccordionHeader>
                                    <AccordionBody accordionId="2">

                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="3">Account deletion</AccordionHeader>
                                    <AccordionBody accordionId="3">

                                    </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                        </Col>
                        </Row>                         
                        <Row>
                        <Col>
                            <div className="mt-3">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-shrink-0 me-1">
                                        <i className="ri-group-line fs-24 align-middle text-primary me-1"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-16 mb-0 fw-semibold">Staff</h5>
                                    </div>
                                </div>
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                    <AccordionHeader targetId="1">How do I register?</AccordionHeader>
                                    <AccordionBody accordionId="1">

                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="2">Eligibility requirements?</AccordionHeader>
                                    <AccordionBody accordionId="2">

                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="3">Account deletion</AccordionHeader>
                                    <AccordionBody accordionId="3">

                                    </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col>
                            <div className="mt-3">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-shrink-0 me-1">
                                        <i className="ri-file-copy-2-line fs-24 align-middle text-primary me-1"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-16 mb-0 fw-semibold">Invoicing & Payments</h5>
                                    </div>
                                </div>
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                    <AccordionHeader targetId="1">How do I register?</AccordionHeader>
                                    <AccordionBody accordionId="1">

                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="2">Eligibility requirements?</AccordionHeader>
                                    <AccordionBody accordionId="2">

                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="3">Account deletion</AccordionHeader>
                                    <AccordionBody accordionId="3">

                                    </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                        </Col>
                        </Row>
 
                        <Row>
                        <Col>
                            <div className="mt-3">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-shrink-0 me-1">
                                        <i className="ri-file-ppt-2-line fs-24 align-middle text-primary me-1"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-16 mb-0 fw-semibold">Pension</h5>
                                    </div>
                                </div>
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                    <AccordionHeader targetId="1">How do I register?</AccordionHeader>
                                    <AccordionBody accordionId="1">

                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="2">Eligibility requirements?</AccordionHeader>
                                    <AccordionBody accordionId="2">

                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="3">Account deletion</AccordionHeader>
                                    <AccordionBody accordionId="3">

                                    </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                        </Col>
                        </Row>
 
                        <Row>
                        <Col>
                            <div className="mt-3">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-shrink-0 me-1">
                                        <i className="ri-user-3-line fs-24 align-middle text-primary me-1"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="fs-16 mb-0 fw-semibold">Account & Profile</h5>
                                    </div>
                                </div>
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                    <AccordionHeader targetId="1">How do I register?</AccordionHeader>
                                    <AccordionBody accordionId="1">

                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="2">Eligibility requirements?</AccordionHeader>
                                    <AccordionBody accordionId="2">

                                    </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                    <AccordionHeader targetId="3">Account deletion</AccordionHeader>
                                    <AccordionBody accordionId="3">
                                    <p className='mb-2'>Please send us a message if you would like to delete your account.</p>
                                    </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                        </Col>
                        </Row>
 
                        </TabPane>

                    </TabContent>


                    </CardBody>
                    </Card>

                </Col>
          </Row>           
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Support;
