import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'

const SetUnavailibilityModal = ({
  open,
  tog_standard,
  onClick,
  selectedDate
}) => {
  function getDatesInRange (startDate, endDate) {
    const date = new Date(startDate.getTime())

    const dates = []

    while (date <= endDate) {
      dates.push(moment(date).format('YYYY-MM-DD'))
      date.setDate(date.getDate() + 1)
    }

    return dates
  }

  return (
    <div>
      <Modal
        id='myModal'
        isOpen={open}
        toggle={() => {
          tog_standard()
        }}
      >
        <ModalHeader
          className="p-3 bg-airgp modal-title"
          id='myModalLabel'
          toggle={() => {
            tog_standard()
          }}
        >
        <div className="modal-title text-white h5">
        Set your unavailable dates
        </div>
          
        </ModalHeader>
        <ModalBody>
          <div className='mt-3'>
            {/* <Label className='form-label mb-0'>Selecting Multiple Dates</Label> */}
            <Flatpickr
              className='form-control'
              options={{
                mode: 'range',
                inline: true,
                locale: {
                  ...'GB',
                  firstDayOfWeek: 1
                },
                minDate: "today"
              }}
              onChange={function (selectedDates) {
                const dates = getDatesInRange(
                  selectedDates[0],
                  selectedDates[1]
                )
                selectedDate(dates)
              }}
            />
          </div>
        </ModalBody>
        <div className='modal-footer'>
          <Button
            color='light'
            onClick={() => {
              tog_standard()
            }}
          >
            Close
          </Button>
          <Button color='primary' onClick={onClick}>
            Apply
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default SetUnavailibilityModal
