import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { toast } from 'react-toastify'
import { Card, CardBody } from 'reactstrap'
import DateDropdown from '../../../Components/Common/DateDropdown'
import { accountServices } from '../../../services/accountServices'
import { invoiceServices } from '../../../services/invoiceServices'
import {
  calculateEmployersContribution,
  convertTimeToMinute
} from '../../../utils/constants'

const TaxReport = () => {
  const [taxDetails, setTaxDetails] = useState([])
  const [selectedYear, setSelectedYear] = useState({ value: '', label: '' })
  const [years, setYears] = useState([])
  const token = localStorage.getItem('token')

  useEffect(() => {
    fetchWorkedTaxableYear(token)
  }, [])

  const fetchTaxDetails = year => {
    accountServices
      .getTaxReportDetails(year, token)
      .then(resp => {
        if (resp.success) {
          if (resp.sessions !== 0) {
            let csvdata = resp.sessions?.map(_tax => {
              let obj = {}

              obj['Organisation name'] = _tax?.session?.organisation?.name || ''

              obj['Start Time'] = _tax?.session?.start_time
                ? moment(_tax?.session?.start_time).format(
                  'LLL'
                )
                : ''

              obj['End Time'] = _tax?.session?.end_time
                ? moment(_tax?.session?.end_time).format('LLL')
                : ''

              obj['Session Fee'] =
                (
                  (_tax?.session?.hourly_rate / 60) *
                  convertTimeToMinute(
                    _tax?.session?.start_time,
                    _tax?.session?.end_time,
                    _tax?.session?.unpaid_break_duration
                  )
                )?.toFixed(2) || 0

              obj['Hourly Rate'] = _tax?.session?.hourly_rate || 0

              obj['Expenses'] =
                calculateExpenseTotal(_tax?.session?.expenses) || 0

              obj['Employer Pension Contribution'] =
                (_tax?.session?.pensionable !== 0 && _tax?.users?.pension_client[0]?.claim_pension && _tax?.session?.status !== "withdrawn") ?
                  calculateEmployersContribution(
                    (_tax?.session?.hourly_rate / 60) *
                    convertTimeToMinute(
                      _tax?.session?.start_time,
                      _tax?.session?.end_time,
                      _tax?.session?.unpaid_break_duration
                    ),
                    calculateExpenseTotal(_tax?.session?.expenses) || 0
                  ) : "0"

              // obj['Bonus'] = +_tax?.session?.client_bonus_amount || 0

              obj['Total'] =
                (
                  (_tax?.session?.hourly_rate / 60) *
                  convertTimeToMinute(
                    _tax?.session?.start_time,
                    _tax?.session?.end_time,
                    _tax?.session?.unpaid_break_duration
                  ) +
                  Number(_tax?.session?.client_bonus_amount) +
                  Number(calculateExpenseTotal(_tax?.session?.expenses))
                )?.toFixed(2) || 0

              obj['Paid Date'] = _tax?.session?.invoice?.paid_date
                ? moment(_tax.session?.invoice.paid_date).format('L')
                : ''

              return obj
            })
            setTaxDetails(csvdata)
          }
        }
      })
      .catch(err => console.log(err))
  }

  const calculateExpenseTotal = expence => {
    if (expence?.length > 0) {
      let expenceTotal = 0
      expence?.map(_ => {
        expenceTotal += +_?.amount
      })
      return expenceTotal
    } else {
      return 0
    }
  }

  const fetchWorkedTaxableYear = token => {
    invoiceServices
      .getTaxYear(token)
      .then(resp => {
        if (resp.success) {
          let curDate = moment().format('YYYY-MM-DD')
          let curYear = new Date().getFullYear()
          
          if (curDate >= `${curYear}-04-05`) {
            const year = resp?.sessions.map(data => {
              return {
                value: data,
                label: `${data}-${Number(data) + 1}`
              }
            })
            setYears(year)
            setSelectedYear({ value: resp?.sessions[0], label: year[0]?.label })
            fetchTaxDetails(year[0]?.value)
          } else {
            // const year = resp?.sessions?.filter(item => +item !== +curYear)
            
            let filteredArr = resp?.sessions.map(data => {
              if(+data == +(new Date().getFullYear())){
                return {
                  value: Number(data) - 1,
                  label: `${Number(data) - 1}-${data}`
                }
              }else {
                return {
                  value: data,
                  label: `${data}-${Number(data) + 1}`
                }
              }
            })
            setSelectedYear({
              value: resp?.sessions[0],
              label: filteredArr[0]?.label
            })
            setYears(filteredArr)
            fetchTaxDetails(filteredArr[0]?.value)
          }
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <Card>
      <CardBody>
        <div className='d-flex align-items-center mb-4'>
          <h5 className='card-title flex-grow-1 mb-0'>Tax Report</h5>
        </div>
        <div className='live-preview'>
          <div>
            <div className='d-flex mt-5 mb-2'>
              <DateDropdown
                options={years}
                onHandleChange={e => {
                  setSelectedYear({ value: e?.value, label: e?.label })
                  fetchTaxDetails(e.value)
                }}
                value={selectedYear}
              />
              <div>
                {taxDetails?.length > 0 ? (
                  <CSVLink
                    data={taxDetails}
                    filename={`${selectedYear?.value}.csv`}
                    target='_blank'
                  >
                    <button
                      className='btn-icon btn-primary border-1 ms-4'
                      style={{ height: 50, width: 50 }}
                    >
                      <i className='ri-download-line' />
                    </button>
                  </CSVLink>
                ) : (
                  <button
                    className='btn-icon btn-primary  border-0 ms-4'
                    style={{ height: 50, width: 50 }}
                    onClick={() => toast.error('Records not available')}
                  >
                    <i className='ri-download-line' />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default TaxReport
