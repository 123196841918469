import React from 'react'
import { Switch, Route } from 'react-router-dom'

//Layouts
import NonAuthLayout from '../Layouts/NonAuthLayout'
import VerticalLayout from '../Layouts/index'
//routes
import { authProtectedRoutes, publicRoutes, routes, openPublicRoute } from './allRoutes'
import { AuthProtected, AccessRoute, FullPageRoute, OpenPublicRoutes } from './AuthProtected'

import OnePage from "../pages/Landing"
import BookPageNew from '../Components/Common/BookPageNew'

const Index = () => {
  const availablePublicRoutesPaths = publicRoutes.map(r => r.path)
  const availableAuthRoutesPath = authProtectedRoutes.map(r => r.path)
  const availableRoutes = routes.map(r => r.path)
  const availableOpenRoutes = openPublicRoute.map(r => r.path)
  return (
    <React.Fragment>
      <Switch>

        <Route path={availableRoutes}>
          <NonAuthLayout>
              <Switch>
                {routes.map((route, idx) => (
                  <Route
                    path={route.path}
                    component={route.component}
                    key={idx}
                    exact={true}
                  />
                ))}
              </Switch>
          </NonAuthLayout>
        </Route>

        <Route path="/" exact>
          <FullPageRoute>
            <NonAuthLayout>
              <OnePage />
            </NonAuthLayout>
          </FullPageRoute>
        </Route>

        <Route path="/book-new" exact>
          <NonAuthLayout>
            <BookPageNew />
          </NonAuthLayout>
        </Route>

        <Route path={availableOpenRoutes}>
          <NonAuthLayout>
            <OpenPublicRoutes>
              <Switch>
                {openPublicRoute.map((route, idx) => (
                  <Route
                    path={route.path}
                    component={route.component}
                    key={idx}
                    exact={true}
                  />
                ))}
              </Switch>
            </OpenPublicRoutes>
          </NonAuthLayout>
        </Route>

        <Route path={availablePublicRoutesPaths}>
          <NonAuthLayout>
            <FullPageRoute>
              <Switch>
                {publicRoutes.map((route, idx) => (
                  <Route
                    path={route.path}
                    component={route.component}
                    key={idx}
                    exact={true}
                  />
                ))}
              </Switch>
            </FullPageRoute>
          </NonAuthLayout>
        </Route>

        <Route path={availableAuthRoutesPath}>
          <AuthProtected>
            <VerticalLayout>
              <Switch>
                {authProtectedRoutes.map((route, idx) => (
                  <AccessRoute
                    path={route.path}
                    component={route.component}
                    key={idx}
                    exact={true}
                  />
                ))}
              </Switch>
            </VerticalLayout>
          </AuthProtected>
        </Route>
      </Switch>
    </React.Fragment>
  )
}

export default Index
