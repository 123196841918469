import { headerContent } from '.'
import { getCookie } from '../utils/constants'
import {
  GET_CONVERSATION,
  GET_MESSAGES,
  GET_NOTIFICATIONS,
  GET_ORG_WHERE_CLIENT_WORK,
  PATCH_READ_NOTIFIATION,
  PATCH_READ_ALL_NOTIFICATIONS,
  PATCH_CLEAR_ALL_NOTIFICATIONS,
  POST_SEND_MESSAGE,
  MARK_AS_READ_MESSAGES
} from './endpoints'
var token = localStorage.getItem('token')

export const headerContentWithAuthorization = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`
}

const customHeaders = token => {
  let copiedHeaderContentWithAuthorization = {
    ...headerContentWithAuthorization
  }
  copiedHeaderContentWithAuthorization['Authorization'] = `Bearer ${token}`
  return copiedHeaderContentWithAuthorization
}

export const chatServices = {
  // Get Organisation where client works
  getOrgWhereClientWork: token =>
    fetch(GET_ORG_WHERE_CLIENT_WORK.endpoint, {
      method: GET_ORG_WHERE_CLIENT_WORK.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get conversation client
  getConversationClients: (org_id, user_id, token) =>
    fetch(GET_CONVERSATION.endpoint(org_id, user_id), {
      method: GET_CONVERSATION.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // send message
  postSendMessage: (data, token) =>
    fetch(POST_SEND_MESSAGE.endpoint, {
      method: POST_SEND_MESSAGE.method,
      headers: customHeaders(token),
      body: data ? (typeof data === 'string' ? data : JSON.stringify(data)) : ''
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get messages
  getMessages: (conversation_id, page, token) =>
    fetch(GET_MESSAGES.endpoint(conversation_id, page), {
      method: GET_MESSAGES.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get notifications
  getAllNotifications: (token) =>
    fetch(GET_NOTIFICATIONS.endpoint, {
      method: GET_NOTIFICATIONS.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  //read notification
  patchReadNotification: (notification_id, data, token) =>
    fetch(PATCH_READ_NOTIFIATION.endpoint(notification_id), {
      method: PATCH_READ_NOTIFIATION.method,
      headers: customHeaders(token),
      body: data ? (typeof data === 'string' ? data : JSON.stringify(data)) : ''
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  //read all notifications
  patchReadAllNotifications: (token) =>
    fetch(PATCH_READ_ALL_NOTIFICATIONS.endpoint(), {
      method: PATCH_READ_ALL_NOTIFICATIONS.method,
      headers: customHeaders(token)
    }).then(resp => {
      return resp.json()
    })
      .catch(err => console.log(err)),

  //clear all notifications
  patchClearAllNotifications: (token) =>
    fetch(PATCH_CLEAR_ALL_NOTIFICATIONS.endpoint(), {
      method: PATCH_CLEAR_ALL_NOTIFICATIONS.method,
      headers: customHeaders(token)
    }).then(resp => {
      return resp.json()
    })
      .catch(err => console.log(err)),

  //mark as read messages
  markAsReadMessages: (conversationId, uuid, token) =>
    fetch(MARK_AS_READ_MESSAGES.endpoint(conversationId, uuid), {
      method: MARK_AS_READ_MESSAGES.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),
}
