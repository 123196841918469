import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle, Form } from 'reactstrap'

//import images
// import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

//import Components
// import LanguageDropdown from '../Components/Common/LanguageDropdown'
// import WebAppsDropdown from '../Components/Common/WebAppsDropdown'
// import MyCartDropdown from '../Components/Common/MyCartDropdown'
// import FullScreenDropdown from '../Components/Common/FullScreenDropdown'
import NotificationDropdown from '../Components/Common/NotificationDropdown'
import ProfileDropdown from '../Components/Common/ProfileDropdown'
import LightDark from '../Components/Common/LightDark'
import { tokenDecode } from '../utils/constants';
import io from 'socket.io-client'
import { chatServices } from '../services/chatServices';
import { toast } from 'react-toastify';
import moment from 'moment'
import { socket } from '../socket/socket';


const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
  const [search, setSearch] = useState(false)
  const [messages, setMessage] = useState([])
  const [clientUuid, setClientUuid] = useState('')
  // const socket = io.connect(process.env.REACT_APP_SOCKET_BASE_URL)

  const toogleSearch = () => {
    setSearch(!search)
  }

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth

    if (windowSize > 767)
      document.querySelector('.hamburger-icon').classList.toggle('open')

    //For collapse horizontal menu
    if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
      document.body.classList.contains('menu')
        ? document.body.classList.remove('menu')
        : document.body.classList.add('menu')
    }

    //For collapse vertical menu
    if (document.documentElement.getAttribute('data-layout') === 'vertical') {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove('vertical-sidebar-enable')
        document.documentElement.getAttribute('data-sidebar-size') === 'sm'
          ? document.documentElement.setAttribute('data-sidebar-size', '')
          : document.documentElement.setAttribute('data-sidebar-size', 'sm')
      } else if (windowSize > 1025) {
        document.body.classList.remove('vertical-sidebar-enable')
        document.documentElement.getAttribute('data-sidebar-size') === 'lg'
          ? document.documentElement.setAttribute('data-sidebar-size', 'sm')
          : document.documentElement.setAttribute('data-sidebar-size', 'lg')
      } else if (windowSize <= 767) {
        document.body.classList.add('vertical-sidebar-enable')
        document.documentElement.setAttribute('data-sidebar-size', 'lg')
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute('data-layout') === 'twocolumn') {
      document.body.classList.contains('twocolumn-panel')
        ? document.body.classList.remove('twocolumn-panel')
        : document.body.classList.add('twocolumn-panel')
    }
  }
  let chatId = new URLSearchParams(useLocation().search).get('id')
  
  const token = localStorage.getItem('token')
  
  let location = useLocation();
  
  useEffect(() => {
    if(location.pathname  !== '/chat'){
      socket.emit('addClient', {
        uuid: tokenDecode(token)?.uuid
      })
    }
  }, [location.pathname])
  
  useEffect(() => {
    if (token) {
      const clientIdFromtoken = tokenDecode(token)
      setClientUuid(clientIdFromtoken.uuid)
      socket.emit('addClient', {
        uuid: tokenDecode(token).uuid
      })
    }
  }, [token])

   useEffect(() => {
        // creating socket connection
        socket.connect()
        return () => {
            socket.disconnect();
        }
    }, []);

  const fetchGetOrgWhereClientWorks = token => {
    chatServices
      .getOrgWhereClientWork(token)
      .then(resp => {
        if (resp.success) {

          if (chatId) {
            const chatOrg = resp.organisations.find(
              item => item.uuid === chatId
            )
            if (chatOrg) {
              fetchConversation(chatOrg.uuid, tokenDecode(token).uuid, token)
            }
          } else {
            if(resp.organisations[0]?.uuid){
            fetchConversation(
              resp.organisations[0]?.uuid,
              tokenDecode(token).uuid,
              token
            )
            }
          }
        } else {
          toast.error('Something went wrong!')
        }
      })
      .catch(err => console.log(err))
  }

  const fetchConversation = (org_id, userId = clientUuid, token) => {
    chatServices
      .getConversationClients(org_id, userId, token)
      .then(resp => {
        if (resp.success) {
          if (resp?.conversation) {
            fetchGetMessages(resp?.conversation?.uuid, 1, token)
          }
        }
      })
      .catch(err => console.log(err))
  }

  const fetchGetMessages = (conversationId, page = 1, token) => {
    chatServices
      .getMessages(conversationId, page, token)
      .then(resp => {
        if (resp.success) {
          setMessage(resp?.messages)
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    const socketMessages = data => {
      setMessage(prevState => [...prevState, { message: data.message,read_recipient: 0, conversation_id: 1, createdAt: moment().format(), updatedAt: moment().format(), isSocketMessage: true }]);
    }

    socket.on('getMessage', socketMessages)

    return () => {
      socket.off('getMessage', socketMessages);
    };

  }, [socket, messages])


  useEffect(() => {
    fetchGetOrgWhereClientWorks(token)
  }, [])

  const clearMessages = () => {
    setMessage([]);
  }

  return (
    <React.Fragment>
      <header id='page-topbar' className={headerClass}>
        <div className='layout-width'>
          <div className='navbar-header'>
            <div className='d-flex'>
              <div className='navbar-brand-box horizontal-logo'>
                <Link to="/calendar" className="logo logo-dark">
                  {/* <span className="logo-sm">
                    <img src={logoSm} alt="" height="28" />
                  </span> */}
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="28" />
                  </span>
                </Link>

                <Link to="/calendar" className="logo logo-light">
                  {/* <span className="logo-sm">
                    <img src={logoSm} alt="" height="28" />
                  </span> */}
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="28" />
                  </span>
                </Link>
              </div>

              <button
                onClick={toogleMenuBtn}
                type='button'
                className='btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger'
                id='topnav-hamburger-icon'
              >
                <span className='hamburger-icon'>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>



            <div className='d-flex align-items-center'>
              {/* <Dropdown
                isOpen={search}
                toggle={toogleSearch}
                className='d-md-none topbar-head-dropdown header-item'
              >
                <DropdownToggle
                  type='button'
                  tag='button'
                  className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'
                >
                  <i className='bx bx-search fs-22'></i>
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-lg dropdown-menu-end p-0'>
                  <Form className='p-3'>
                    <div className='form-group m-0'>
                      <div className='input-group'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Search ...'
                          aria-label="Recipient's username"
                        />
                        <button className='btn btn-primary' type='submit'>
                          <i className='mdi mdi-magnify'></i>
                        </button>
                      </div>
                    </div>
                  </Form>
                </DropdownMenu>
              </Dropdown> */}

              {/* LanguageDropdown */}
              {/* <LanguageDropdown /> */}

              {/* WebAppsDropdown */}
              {/* <WebAppsDropdown /> */}

              {/* MyCartDropdwon */}
              {/* <MyCartDropdown /> */}

              {/* FullScreenDropdown */}
              {/* <FullScreenDropdown /> */}

              {/* Dark/Light Mode set */}
              {/* <LightDark
                layoutMode={layoutModeType}
                onChangeLayoutMode={onChangeLayoutMode}
              /> */}
              <div className='ms-1 header-item d-flex'>
                <Link to='/chat' onClick={()=>{
                  clearMessages();
                }} className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'>
                  <i className='ri-chat-3-line fs-22'></i>
                  {messages?.filter(item => (!item?.read_recipient) && (item?.sender?.uuid !== tokenDecode(token)?.uuid))?.length > 0 &&
                    <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                      {messages?.filter(item => (!item?.read_recipient) && (item?.sender?.uuid !== tokenDecode(token)?.uuid))?.length}<span className="visually-hidden">unread messages</span>
                    </span>}
                </Link>
              </div>

              {/* NotificationDropdown */}
              <NotificationDropdown />

              {/* ProfileDropdown */}
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header
