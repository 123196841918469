import { headerContent } from './index'
import {
  DELETE_BANK_ACCOUNT,
  GET_BANK_DETAILS,
  PATCH_SET_AS_DEFAULT_BANK,
  POST_ADD_BANK_DETAILS
} from './endpoints'
import { getCookie } from '../utils/constants'

var token = localStorage.getItem('token')

export const headerContentWithAuthorization = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`
}

const customHeaders = token => {
  let copiedHeaderContentWithAuthorization = {
    ...headerContentWithAuthorization
  }
  copiedHeaderContentWithAuthorization['Authorization'] = `Bearer ${token}`
  return copiedHeaderContentWithAuthorization
}

export const bankServices = {
  // Save user profile details
  postAddBankDetails: (data, token) =>
    fetch(POST_ADD_BANK_DETAILS.endpoint, {
      method: POST_ADD_BANK_DETAILS.method,
      headers: customHeaders(token),
      body: data ? (typeof data === 'string' ? data : JSON.stringify(data)) : ''
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get Bank details API
  getBankDetails: token =>
    fetch(GET_BANK_DETAILS.endpoint, {
      method: GET_BANK_DETAILS.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Set as Default Bank
  patchSetAsDefaultBank: (data, token) =>
    fetch(PATCH_SET_AS_DEFAULT_BANK.endpoint, {
      method: PATCH_SET_AS_DEFAULT_BANK.method,
      headers: customHeaders(token),
      body: data ? (typeof data === 'string' ? data : JSON.stringify(data)) : ''
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  deleteBankAccount: (id, token) =>
    fetch(DELETE_BANK_ACCOUNT.endpoint(id), {
      method: DELETE_BANK_ACCOUNT.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err))
}
