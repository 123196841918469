import jwt_decode from 'jwt-decode'

export const procedureTypeCodeClass = _type => {
  switch (_type.toLowerCase()) {
    case 'completed':
      return 'success'
    case 'withdrawn':
      return 'danger'
    case 'manual':
      return 'info'
    case 'published':
      return 'secondary'
    case 'market_place':
      return 'info'
    case 'rejected':
      return 'danger'
    case 'awaiting':
      return 'warning'
    case 'accepted':
      return 'success'
    case 'approved':
      return 'success'
    case 'awaiting_approval':
      return 'warning'

    case 'cancelled':
      return 'danger'
    case 'paid':
      return 'success'
    case 'expired':
      return 'danger'
    case 'manual':
      return '#590696'
    case 'draft':
      return '#9c41d1'
    case 'published':
      return '#3285a8'
    case 'booked':
      return '#035DB5'
    case 'applied':
      return '#F6B84B'
    case 'completed':
      return '#42A831'
    case 'worked':
      return '#42a832'
    case 'withdrawn':
      return '#d62d2d'
    default:
      return 'primary'
  }
}

export const sessionStatusColor = type => {
  const _ = type.toLowerCase()
  switch (_) {
    case 'manual':
      return '#590696'
    case 'draft':
      return '#9c41d1'
    case 'published':
      return '#3285a8'
    case 'booked':
      return '#035DB5'
    case 'applied':
      return '#ff8000'
    case 'completed':
      return '#42A831'
    case 'worked':
      return '#42a832'
    case 'withdrawn':
      return '#d62d2d'
    case 'unfilled':
      return '#d62d2d'
    default:
      return '#3285a8'
  }
}

export const badgeColor = type => {
  switch (type.toLowerCase()) {
    case 'booked':
      return 'primary'
    case 'worked':
      return 'success'
    case 'applied':
      return 'warning'
    case 'manual':
      return 'info'
    case 'awaiting':
      return 'warning'
    case 'accepted':
      return 'success'
    case 'market_place':
      return 'warning'
    case 'yes':
      return 'success'
    case 'withdrawn':
      return 'danger'
    case 'no':
      return 'danger'
    default:
      return 'primary'
  }
}

export const documentStatusColor = type => {
  switch (type.toLowerCase()) {
    case 'rejected':
      return 'danger'
    case 'approved':
      return 'success'
    case 'awaiting_approval':
      return 'primary'
    case 'expired':
      return 'warning'
    case 'signed':
      return 'success'
    case 'unsigned':
      return 'warning'
    case 'unpaid':
      return 'danger'
    case 'mandatory':
      return 'danger'
    case 'optional':
      return 'warning'
    default:
      return 'warning'
  }
}

export const calculatePensionablePay = (fee, expence) => {
  const total = fee + expence
  const contributionPay = (total * 90) / 100
  // let finalValue = total - contributionPay
  return contributionPay.toFixed(2)
}

export const SCHEDULE_TABS = ['All', 'Applied', 'Booked']

export const INVOICES_TABS = ['Invoice', 'Unpaid', 'Paid'] // , 'CANCEL'

export const BOOK_PAGE_TABS = ['All', 'Instant Book', 'Following']

export const PUBLIC_BOOK_PAGE_TABS = ['All']

export const ADD_MANUAL_SESSION_VALUES_ = {
  organisation: '',
  startTime: '',
  endTime: '',
  fee: ''
}

export const INVOICE_GENERATE_VALUES = {
  add_note: '',
  add_private_note: '',
  email: ''
}

export const INVOICE_INITIAL_STATE = {
  amount: 0,
  expenseType: 'Home visit'
}
// $card-bg

export const EDIT_INVOICE_INITIAL_STATE = {
  startTime: '',
  endTime: '',
  breakDuration: '',
  hourlyRate: ''
}

export const PROFILE_STATE = {
  name: '',
  regulatorType: '',
  regulatorNumber: '',
  smartCardNumber: '',
  gender: '',
  dob: '',
  bio: '',
  itSystem: [],
  qualification: [],
  language: [],
  address1: '',
  address2: ''
}

export const PROFILE_ADDRESS_STATE = {
  add_line_1: '',
  add_line_2: '',
  add_line_3: '',
  add_line_4: '',
  country: 'England',
  postcode: '',
  lat: '',
  long: ''
}

export const PROFILE_INITIAL_STATE = {
  userName: '',
  bio: '',
  dateOfBirth: '',
  gender: 'Male',
  regulatorNumber: '',
  contact: '',
  smartCardNumber: '',
  itSystem: [],
  language: [],
  qualification: []
}

export const PROFILE_PASSWORD_UPDATE = {
  old_password: '',
  new_password: '',
  confirm_new_password: ''
}

export const BANK_DETAILS_INFORMATION = {
  name_on_bank: '',
  bank_name: '',
  account_number: '',
  sortcode: ''
}

export const EMAIL_PREFERENCES = {
  session_updates: '',
  expiring_documents_reminder: '',
  messages: '',
  invoice_overdue: '',
  payment_received: '',
  session_availabilities: ''
}

export const convertTimeToMinute = (sTime, eTime, breakDuration) => {
  const startTime = new Date(sTime).valueOf()
  const endTime = new Date(eTime).valueOf()
  const durationTs = endTime - startTime
  const durationInSecondes = durationTs / 1000
  const durationInMinutes = durationInSecondes / 60
  const totalMinutes = durationInMinutes - breakDuration

  return totalMinutes || '0'
}

export const PROFILE_TABS = [
  'Profile Information',
  'Payment',
  'Pension',
  'Account Settings'
]

export const PROFILE_TABS_ICONS = [
  'ri-user-line',
  'ri-bank-line',
  'ri-file-ppt-2-line',
  'ri-settings-4-line'
]

export const REACT_SELECT_DROPDOWN_INFO = [
  { value: '0', label: 'London [Barking Dagenham Havering and Redbridge]' },
  { value: '1', label: 'London [City and Hackney]' },
  { value: '2', label: 'London [North Central London]' },
  { value: '3', label: 'London [North West London]' },
  { value: '4', label: 'London [South East London]' },
  { value: '5', label: 'London [South West London]' },
  { value: '6', label: 'London [Waltham Forest and East London]' }
]

export const PENSION_DATA_VALUE = {
  pension_region: '',
  tier_contribution_rate: '',
  PCSE_region: '',
  SD_number: '',
  added_years: '',
  additional_pension: '',
  MPAVCs: '',
  ERRBO: '',
  claim_pension: '',
  defaultPensionRegion: '',
  defaultPensionPCSE: '',
  defaultTireContribution: '',
  NI_Number: '',
  form_a_version: ''
}

export const PENSION_FORM_A_TABS = ['Unsigned', 'Signed']

export const PENSION_FORM_B_TABS = [
  'Ready',
  'Upcoming',
  'Archived',
  'Pension Log'
]

export const calculateEmployersSuperannuation = (fee, expence = 0, tierRate) => {
  const subTotal = fee + expence
  const pensionablePay = (subTotal * 90) / 100
  const employersSuperannuation = (pensionablePay * tierRate) / 100
  const totalPay = subTotal + employersSuperannuation
  return employersSuperannuation.toFixed(2)
}

export const calculateEmployersContribution = (fee, expence = 0) => {
  const total = fee + expence
  const contributionPay = ((total * 90) / 100) * 0.1438
  // let finalValue = total - contributionPay
  return contributionPay.toFixed(2)
}

export const tokenDecode = token => {
  if (token) {
    const decoded = jwt_decode(token)
    return decoded
  }
}

export const profileImgColor = type => {
  switch (type?.toLowerCase()) {
    case 'a':
      return 'profile-color-A'
    case 'b':
      return 'profile-color-B'
    case 'c':
      return 'profile-color-C'
    case 'd':
      return 'profile-color-D'
    case 'e':
      return 'profile-color-E'
    case 'f':
      return 'profile-color-F'
    case 'g':
      return 'profile-color-G'
    case 'h':
      return 'profile-color-H'
    case 'i':
      return 'profile-color-I'
    case 'j':
      return 'profile-color-J'
    case 'k':
      return 'profile-color-K'
    case 'l':
      return 'profile-color-L'
    case 'm':
      return 'profile-color-M'
    case 'n':
      return 'profile-color-N'
    case 'o':
      return 'profile-color-O'
    case 'p':
      return 'profile-color-P'
    case 'q':
      return 'profile-color-Q'
    case 'r':
      return 'profile-color-R'
    case 's':
      return 'profile-color-S'
    case 't':
      return 'profile-color-T'
    case 'u':
      return 'profile-color-U'
    case 'v':
      return 'profile-color-V'
    case 'w':
      return 'profile-color-W'
    case 'x':
      return 'profile-color-X'
    case 'y':
      return 'profile-color-Y'
    case 'z':
      return 'profile-color-Z'
    default:
      return 'profile-color-Z'
  }
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export function getCookie(cname) {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const TITLE_MENU = ['Dr', 'Mr', 'Miss', 'Mrs']
