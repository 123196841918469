import React, { useEffect, useState } from 'react'

//Import Icons
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from 'reactstrap'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import BootstrapTheme from '@fullcalendar/bootstrap'

import BreadCrumb from '../../../Components/Common/BreadCrumb'

//Simple bar
import listPlugin from '@fullcalendar/list'
import { scheduleServices } from '../../../services/scheduleServices'
import {
  ADD_MANUAL_SESSION_VALUES_,
  getCookie,
  tokenDecode,
  sessionStatusColor
} from '../../../utils/constants'
import moment from 'moment/moment'
import { toast } from 'react-toastify'
import { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import ClientSessionEditModal from '../../../Components/Common/ClientSessionEditModal'
import SetUnavailibilityModal from '../../../Components/Common/SetUnavailibilityModal'
import SessionDetailsModal from '../../../Components/Common/SessionDetailsModal'

import SimpleBar from "simplebar-react";
import SpinnerComponent from '../../../Components/Common/Spinner'
import SyncCalenderLinkModal from '../../../Components/Common/SyncCalenderLinkModal'

const Calender = () => {
  const [addManualSessionValues, setAddManualSessionValues] = useState(
    ADD_MANUAL_SESSION_VALUES_
  )
  const [modal, setModal] = useState(false)
  const [selectedDay, setSelectedDay] = useState(0)
  const [userSessionDetails, setUserSessionDetails] = useState([])
  const [userListDetails, setUserListDetails] = useState([])
  const [searchOrganisationText, setSearchOrganisationText] = useState(null)
  const [orgDetails, setOrgDetails] = useState([])
  const [jsEvent, setJSEvent] = useState()
  const [overlayShow, setOverlayShow] = useState(false)
  const [overlayData, setOverlayData] = useState()
  const [userSessions, setUserSessions] = useState([])
  const [unavailableModal, setUnavailableModal] = useState(false)
  const [blockedDates, setBlockedDates] = useState([])
  const [monthStartDate, setMonthStartDate] = useState()
  const [monthEndDate, setMonthEndDate] = useState()
  const [componentRender, setComponentRender] = useState()
  const [selectedUnavailableDates, setSelectedUnavailableDates] = useState([])
  const [selectedDate, setSelectedDate] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [sessionDetailsModal, setSessionDetailsModal] = useState(false)
  const [selectedSessionDetails, setSelectedSessionDetails] = useState([])
  const [userSessionData, setUserSessionData] = useState([])
  const [selectedSessionId, setSelectedSessionId] = useState('')
  const [bonusPercentage, setBonusPercentage] = useState(1)
  const [sessionDetails, setSessionDetails] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [syncCalenderModal, setSyncCalenderModal] = useState(false);
  const overleyRef = useRef()
  const history = useHistory()
  const token = localStorage.getItem('token')

  const [modal_animationRight, setmodal_animationRight] = useState(false)
  function tog_animationRight() {
    setmodal_animationRight(!modal_animationRight)
  }

  useEffect(() => {
    fetchOrganisation('', 1, 20, token)
    fetchUserSessions(monthStartDate, monthEndDate, token)
    fetchUnavailableDates(monthStartDate, monthEndDate, token)
  }, [])

  useEffect(() => {
    fetchUnavailableDates(monthStartDate, monthEndDate, token)
  }, [monthStartDate, monthEndDate, componentRender])

  useEffect(() => {
    let delayedApiCall
    if (searchOrganisationText !== undefined && searchOrganisationText !== '') {
      delayedApiCall = setTimeout(() => {
        fetchOrganisation(searchOrganisationText, 1, 20, token)
      }, 300)
    }
    return () => clearTimeout(delayedApiCall)
  }, [searchOrganisationText])

  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const fetchUnavailabilityCall = () => {
    scheduleServices
      .postSetUserUnavailibility(
        {
          dates: selectedUnavailableDates,
          isUnavailable: true
        },
        token
      )
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          setUnavailableModal(false)
          fetchUnavailableDates(monthStartDate, monthEndDate, token)
        } else {
          toast.error(resp.message || 'Something went wrong, Please try again.')
          setUnavailableModal(false)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchUnavailableDates = (monthStartDate, monthEndDate, token) => {
    if ((monthStartDate !== undefined) & (monthEndDate !== undefined)) {
      scheduleServices
        .getSetUserUnavailibilityDates(monthStartDate, monthEndDate, token)
        .then(resp => {
          if (resp.success) {
            let sessionDetails = []
            if (resp.userUnavailabilities.length === 0) {
              setBlockedDates([])
              setComponentRender(componentRender + 1)
              return
            }
            resp?.userUnavailabilities?.map(data => {
              const detailObj = {
                id: data.uuid,
                title: 'Blocked',
                start: moment(data.unavailable_date).format('YYYY-MM-DD'),
                display: 'background',
                backgroundColor: '#000000',
                disable: true
              }
              sessionDetails.push(detailObj)
              setBlockedDates(sessionDetails)
            })
          } else {
            toast.error('Something went wrong.')
          }
        })
        .catch(err => console.log(err))
    }
  }

  const handleDateClick = arg => {
    let blockDate = blockedDates.find(date => {
      return date.start === arg.dateStr
    })

    let openDate = userSessionDetails.find(date => {
      return date.start !== arg.dateStr
    })

    if (blockDate) {
      tog_animationRight()
      setModal(false)
    } else {
      toggle()
    }

    setAddManualSessionValues({
      organisation: '',
      startTime: '',
      endTime: '',
      fee: ''
    })
    const date = arg['date']
    setSelectedDay(moment(date).format('YYYY-MM-DD'))
    setSelectedDate(moment(date).format('YYYY-MM-DD'))
  }

  const createSessionHandler = event => {
    const data = {
      organisation_name: addManualSessionValues.organisation,
      start_time: `${selectedDay} ${addManualSessionValues.startTime}:00`,
      end_time: `${selectedDay} ${addManualSessionValues.endTime}:00`,
      fee: addManualSessionValues.fee
    }

    // Add manual API
    if (
      addManualSessionValues.organisation.length === 0 ||
      addManualSessionValues.startTime.length === 0 ||
      addManualSessionValues.endTime === 0 ||
      addManualSessionValues.fee.length === 0
    ) {
      toast.error('Please fill all fields.')
    } else {
      scheduleServices
        .postAddManualSession(data, token)
        .then(resp => {
          if (resp.success) {
            toast.success(resp.message)
            toggle()
            fetchUserSessions(monthStartDate, monthEndDate, token)
          } else {
            toast.error(
              resp.message || 'Something went wrong, Please try again.'
            )
          }
        })
        .catch(err => console.log(err))
    }
  }

  function tog_standard() {
    setUnavailableModal(!unavailableModal)
  }

  const fetchUserSessions = (monthStartDate, monthEndDate, token) => {
    setIsLoading(true)
    scheduleServices
      .getUserSessions(monthStartDate, monthEndDate, token)
      .then(res => {
        if (res.success) {
          setUserSessions(res?.data || [])
          let sessionDetails = []
          res?.data?.map(data => {
            const detailObj = {
              id: data?.uuid,
              title: data?.Session_statuses?.slice(-1)[0]?.status,
              start: moment(data?.session?.start_time).format('YYYY-MM-DD'),
              color: sessionStatusColor(
                data?.Session_statuses?.slice(-1)[0]?.status
              ),
              disable: false
            }
            sessionDetails.push(detailObj)
            setUserSessionDetails(sessionDetails)
            setBonusPercentage(res?.user_profile?.bonus_fee_percentage)
          })
          setUserListDetails(res?.data?.sort(function (a, b) {
            return new Date(a?.session?.start_time) - new Date(b?.session?.start_time)
          }))
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchOrganisation = (param, page, limit, token) => {
    setIsLoading(true)
    scheduleServices
      .getOrganisationsDetails(param, page, limit, token)
      .then(res => {
        if (res.success) {
          let arr = [...orgDetails]
          res.data?.map(_ =>
            arr.push({ value: _.uuid, label: _.organisation_name })
          )
          setOrgDetails(arr)
        }else{
          if(res?.status === 401){
            localStorage.clear();
            history.push('/login')
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const handleScroll = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      setPageCount(pageCount + 1)
      fetchOrganisation('', pageCount, 20, token)
    }
  }

  const handleEventMouseEnter = eventInfo => {
    const filteredData = userSessions?.filter(item => {
      return item?.uuid === eventInfo.event._def.publicId
    })
    setJSEvent(eventInfo.jsEvent.target)
    setOverlayData({
      name: filteredData[0]?.session?.organisation?.name,
      time: `${moment(filteredData[0]?.session?.start_time).format(
        'LT'
      )} To ${moment(filteredData[0]?.session?.end_time).format('LT')}`,
      hourly_rate: '',
      fee: filteredData[0]?.session?.fee
    })
    setOverlayShow(true)
  }

  const handleEventMouseLeave = () => {
    setOverlayShow(false)
    setOverlayData({
      name: 'Blocked day',
      time: 'NA',
      hourly_rate: 'NA',
      fee: 'NA'
    })
  }

  const handleAvailableClick = () => {
    scheduleServices
      .postSetUserUnavailibility(
        {
          dates: [selectedDate.toString()],
          isUnavailable: false
        },
        token
      )
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          tog_animationRight()
          fetchUnavailableDates(monthStartDate, monthEndDate, token)
        } else {
          toast.error(resp.message || 'Something went wrong, Please try again.')
          tog_animationRight()
        }
      })
      .catch(err => console.log(err))
  }

  const handleEventClick = e => {
    // history.push(`/session-details/${e.event._def.publicId}/manual`)
    scheduleServices
      .getUserSessionById(e.event._def.publicId, token)
      .then(resp => {
        if (resp.success) {
          setUserSessionData(resp?.data)
          setSelectedSessionDetails(resp?.data?.session)
          setSessionDetails(resp?.data)
          setSessionDetailsModal(true)
          setSelectedSessionId(e.event._def.publicId)
        }
      })
      .catch(err => console.log(err))
  }

  const handleSyncCalendar = () => {
    setSyncCalenderModal(true)
  };

  document.title = 'Calendar | airGP'
  return (
    <React.Fragment>
      <div className='page-content'>
        {isLoading && <SpinnerComponent /> }
        <Container fluid>
          <div className='d-none d-xl-block'><BreadCrumb className title='Calendar' pageTitle='Schedule' /></div>


          <Row>
            <Col xl={3} className='d-none d-xl-block'>
              <div>
                <h5 className="mb-1">Upcoming Sessions</h5>
                <p className="text-muted"> </p>
                <SimpleBar
                  className="p-3 mb-3"
                  style={{ height: "600px" }}
                >
                  <div id="upcoming-event-list">
                    {userListDetails.map((item, index) => {
                      return (
                        ((item?.Session_statuses[0]?.status === 'Booked') && (moment(item?.session?.start_time).diff(moment()) > 0) ?
                          <Card className="mb-3 shadow-none border border-1 border-dark border-bottom border-dashed" key={`Card${index}`} >
                            <CardBody>
                              <div className="d-flex mb-3">
                                <div className="flex-grow-1">
                                  <i
                                    className={"ri-checkbox-blank-circle-fill me-2 text-primary"}
                                  ></i>
                                  <span className="fs-12">
                                    {moment(item?.session?.start_time).format(
                                      "ddd, L"
                                    )}
                                  </span>
                                </div>
                                <div className="flex-shrink-0">
                                  <small className="badge badge-soft-primary ms-auto">
                                    {moment(item?.session?.start_time).format("HH:mm") ||
                                      "00:00"}{" "}
                                    - {moment(item?.session?.end_time).format("HH:mm") || "NA"}
                                  </small>
                                </div>
                              </div>
                              <p className="">{item?.session?.organisation?.name}</p>
                            </CardBody>
                          </Card> :
                          <React.Fragment key={index}></React.Fragment>)
                      )
                    })}
                  </div>
                </SimpleBar>
              </div>
            </Col>
            <Col xl={9}  className='d-none d-xl-block'>
              <Card className='card-h-100'>
                <CardBody>
                  <FullCalendar
                    plugins={[
                      BootstrapTheme,
                      dayGridPlugin,
                      interactionPlugin
                    ]}
                    customButtons={{
                      myCustomButton: {
                        text: 'List View',
                        click: function () {
                          history.push('/session-list')
                        }
                      },
                      setUnavailableBtn: {
                        text: 'Set unavailability',
                        click: () => setUnavailableModal(true)
                      },
                      syncCalendarBtn:{
                        text: 'Sync Calendar',
                        click: () => handleSyncCalendar()
                      }
                    }}
                    timeZone='GB'
                    // aspectRatio= '2'
                    height='auto'
                    firstDay={1}
                    selectAllow={function (e) {
                      if (
                        e.end.getTime() / 1000 - e.start.getTime() / 1000 <=
                        86400
                      ) {
                        return true
                      }
                    }}
                    initialView='dayGridMonth'
                    slotDuration={'00:15:00'}
                    handleWindowResize={true}
                    datesSet={arg => {
                      const start = moment(arg.start).format('YYYY-MM-DD')
                      const end = moment(arg.end).format('YYYY-MM-DD')
                      setMonthStartDate(start)
                      setMonthEndDate(end)
                    }}
                    themeSystem='bootstrap'
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'myCustomButton,setUnavailableBtn,syncCalendarBtn'
                    }}
                    eventSources={[userSessionDetails, blockedDates]}
                    editable={false}
                    droppable={false}
                    selectable={false}
                    dateClick={handleDateClick}
                    eventClick={handleEventClick}
                    eventMouseEnter={handleEventMouseEnter}
                    eventMouseLeave={handleEventMouseLeave}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

{/* ***mobile view*** */}
        <div className='mx-n4 py-3 fc-mobile d-xl-none'>
          <FullCalendar
                    plugins={[
                      BootstrapTheme,
                      dayGridPlugin,
                      interactionPlugin
                    ]}
                    customButtons={{
                      myCustomButton: {
                        text: 'List View',
                        click: function () {
                          history.push('/session-list')
                        }
                      },
                      setUnavailableBtn: {
                        text: 'Set unavailability',
                        click: () => setUnavailableModal(true)
                      },
                      syncCalendarBtn: {
                        text: 'Sync Calendar',
                        click: () => handleSyncCalendar()
                      }
                    }}
                    timeZone='GB'
                    // aspectRatio= '5'
                    // display='auto'
                    height='auto'
                    firstDay={1}
                    selectAllow={function (e) {
                      if (
                        e.end.getTime() / 1000 - e.start.getTime() / 1000 <=
                        86400
                      ) {
                        return true
                      }
                    }}
                    initialView='dayGridMonth'
                    slotDuration={'00:15:00'}
                    handleWindowResize={true}
                    datesSet={arg => {
                      const start = moment(arg.start).format('YYYY-MM-DD')
                      const end = moment(arg.end).format('YYYY-MM-DD')
                      setMonthStartDate(start)
                      setMonthEndDate(end)
                    }}
                    themeSystem='bootstrap'
                    titleFormat={{ year: 'numeric', month: 'short'} }

                    footerToolbar={{
                      // left: 'myCustomButton',
                      right: 'setUnavailableBtn',
                      left: 'syncCalendarBtn'
                    }}
                    eventSources={[userSessionDetails, blockedDates]}
                    editable={false}
                    droppable={false}
                    selectable={false}
                    dateClick={handleDateClick}
                    eventClick={handleEventClick}
                    eventMouseEnter={handleEventMouseEnter}
                    eventMouseLeave={handleEventMouseLeave}
                  />
              </div>







          <SetUnavailibilityModal
            open={unavailableModal}
            tog_standard={tog_standard}
            onClick={fetchUnavailabilityCall}
            selectedDate={e => setSelectedUnavailableDates(e)}
          />

          <SyncCalenderLinkModal
            open={syncCalenderModal}
            tog_standard={() => setSyncCalenderModal(false)}
            link={`${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/cal/${tokenDecode(token)?.uuid}`}
          />

          <ClientSessionEditModal
            open={false}
            onSelectInputChange={e => {
              setSearchOrganisationText(e)
            }}
            options={orgDetails}
            handleScroll={handleScroll}
            onSelectChange={e => {
              let copyiedObj = { ...addManualSessionValues }
              copyiedObj.organisation = e?.label
              setAddManualSessionValues(copyiedObj)
            }}
            onStartTimeChange={e => {
              let copyiedObj = { ...addManualSessionValues }
              copyiedObj.startTime = moment(e[0]).format('HH:mm')
              setAddManualSessionValues(copyiedObj)
            }}
            onEndTimeChange={e => {
              let copyiedObj = { ...addManualSessionValues }
              copyiedObj.endTime = moment(e[0]).format('HH:mm')
              setAddManualSessionValues(copyiedObj)
            }}
            onFeeChange={e => {
              let copyiedObj = { ...addManualSessionValues }
              copyiedObj.fee = e.target.value
              setAddManualSessionValues(copyiedObj)
            }}
            toggle={toggle}
            onClickCreateBtn={createSessionHandler}
            btnName={'Create'}
            headers={'Create manual session'}
          />

          <Modal
            id='fadeInRightModal'
            isOpen={modal_animationRight}
            toggle={() => {
              tog_animationRight()
            }}
            modalClassName=''
            centered
          >
            <ModalHeader
              className='p-3 bg-airgp modal-title"'
              id='fadeInRightModalLabel'
              toggle={() => {
                tog_animationRight()
              }}
            >
            <div className="modal-title text-white h5" id="myModalLabel">
              Set to available
            </div>
            </ModalHeader>
            <ModalBody>
              <h5 className='fs-16'>
                Mark this day as available?
              </h5>
            </ModalBody>
            <div className='modal-footer'>

              <Button color='primary' onClick={handleAvailableClick}>
                Available
              </Button>
              <Button
                color='light'
                onClick={() => {
                  tog_animationRight()
                }}
              >
                Close
              </Button>
            </div>
          </Modal>

          {/* <div ref={overleyRef}>
            <Overlay
              show={overlayShow}
              placement='bottom'
              container={overleyRef}
              target={jsEvent}
              containerPadding={20}
            >
              <Popover id='popover-contained'>
                <Popover.Body className='popover-body'>
                  <h6 className='overlay-body'>{overlayData?.name || 'na'}</h6>
                  <p className='overlay-body m-0'>
                    Time: {overlayData?.time || 'na'}
                  </p>
                  <hr className='overlay-body' />
                  <p className='overlay-body'>
                    Fee: {overlayData?.fee || 'na'}
                  </p>
                </Popover.Body>
              </Popover>
            </Overlay>
          </div> */}
          <SessionDetailsModal
            open={sessionDetailsModal}
            handleClose={() => setSessionDetailsModal(false)}
            sessionDetails={selectedSessionDetails}
            toggle={() => setSessionDetailsModal(!sessionDetailsModal)}
            userSessionData={userSessionData}
            sessionId={selectedSessionId}
            bonusPercantage={bonusPercentage}
            fetchUserSessions={fetchUserSessions}
            monthStartDate={monthStartDate}
            monthEndDate={monthEndDate}
            session={sessionDetails}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Calender
