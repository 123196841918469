import PropTypes from 'prop-types'
import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const ConfirmationModal = ({
  show,
  onDeleteClick,
  onCloseClick,
  deleteMsg,
  btnName
}) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} id='event-modal' centered>
          <ModalHeader
          className="p-3 bg-airgp modal-title"
          toggle={onCloseClick}
          >
          <div className="modal-title text-white h5" id="myModalLabel">
          Confirm
          </div>
          </ModalHeader>
      <ModalBody className='needs-validation view-event'>
        <div className='mt-2 text-center'>
          <div className='fs-16'>
            {/* <h4>Are you sure ?</h4> */}
            <p className='text-muted mx-4 mb-0'>
              {deleteMsg || 'Are you sure you want to remove this record ?'}
            </p>
          </div>
        </div>
        <div className='d-flex gap-2 justify-content-center mt-4 mb-2'>
          <button
            type='button'
            className='btn w-sm btn-primary '
            id='delete-record'
            onClick={onDeleteClick}
          >
            {btnName || 'Confirm'}
          </button>
          <button
            type='button'
            className='btn w-sm btn-light'
            data-bs-dismiss='modal'
            onClick={onCloseClick}
          >
            Close
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default ConfirmationModal