import moment from 'moment'
import React from 'react'
import 'moment/locale/en-gb';

//import Scss
import './assets/scss/themes.scss'

//imoprt Route
import Route from './Routes'

function App () {
  moment.locale('en-gb')
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  )
}

export default App
