import { getCookie } from '../utils/constants'
import {
  GET_CLINICAL_DOCUMENTS_TYPES,
  GET_EMAIL_PREFERENCES,
  GET_LANGUAGES,
  GET_PROFILE_PHOTO,
  GET_QUALIFICATIONS,
  GET_TAX_DETAILS,
  GET_USER_PROFILE_DETAILS,
  PATCH_EMAIL_PREFERENCES,
  PATCH_PROFILE_PHOTO_UPLOAD,
  PATCH_SAVE_USER_PROFILE_DETAILS,
  POST_DELETE_ACCOUNT,
  POST_UPLOAD_CLINICAL_DOCUMENTS,
  DELETE_CLINICAL_DOCUMENTS,
  DOWNLOAD_DOCUMENT
} from './endpoints'
import { headerContent } from './index'

var token = localStorage.getItem('token')

export const headerContentWithAuthorization = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`
}

const customHeaders = token => {
  let copiedHeaderContentWithAuthorization = {
    ...headerContentWithAuthorization
  }
  copiedHeaderContentWithAuthorization['Authorization'] = `Bearer ${token}`
  return copiedHeaderContentWithAuthorization
}

export const accountServices = {
  // Get Languages API
  getLanguages: token =>
    fetch(GET_LANGUAGES.endpoint, {
      method: GET_LANGUAGES.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get Languages API
  getQualifications: token =>
    fetch(GET_QUALIFICATIONS.endpoint, {
      method: GET_QUALIFICATIONS.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Save user profile details
  patchSaveUserProfileDetails: (data, token) =>
    fetch(PATCH_SAVE_USER_PROFILE_DETAILS.endpoint, {
      method: PATCH_SAVE_USER_PROFILE_DETAILS.method,
      headers: customHeaders(token),
      body: data ? (typeof data === 'string' ? data : JSON.stringify(data)) : ''
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get user profile details
  getUserProfileDetails: token =>
    fetch(GET_USER_PROFILE_DETAILS.endpoint, {
      method: GET_USER_PROFILE_DETAILS.method,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Patch upload profile photo
  patchUploadProfilePhoto: (data, token,category) =>
    fetch(PATCH_PROFILE_PHOTO_UPLOAD.endpoint(category), {
      method: PATCH_PROFILE_PHOTO_UPLOAD.method,
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: data
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // GET profile photo
  getProfilePhoto: token =>
    fetch(GET_PROFILE_PHOTO.endpoint, {
      method: GET_PROFILE_PHOTO.method,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // GET Documents types
  getClinicalDocumentTypes: token =>
    fetch(GET_CLINICAL_DOCUMENTS_TYPES.endpoint, {
      method: GET_CLINICAL_DOCUMENTS_TYPES.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Post upload Documents types
  postUploadDocument: (data, docId, documentCategory, token) =>
    fetch(POST_UPLOAD_CLINICAL_DOCUMENTS.endpoint(docId, documentCategory), {
      method: POST_UPLOAD_CLINICAL_DOCUMENTS.method,
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: data
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  deleteDocument: (docId, token) =>
    fetch(DELETE_CLINICAL_DOCUMENTS.endpoint(docId), {
      method: DELETE_CLINICAL_DOCUMENTS.method,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  downloadDocument: (pdf_uuid, status, token) =>
    fetch(DOWNLOAD_DOCUMENT.endpoint(pdf_uuid, status), {
      method: DOWNLOAD_DOCUMENT.method,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => {
        return resp
      })
      .catch(err => console.log(err)),

  getTaxReportDetails: (year, token) =>
    fetch(GET_TAX_DETAILS.endpoint(year), {
      method: GET_TAX_DETAILS.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  getEmailPreferences: token =>
    fetch(GET_EMAIL_PREFERENCES.endpoint, {
      method: GET_EMAIL_PREFERENCES.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  patchEmailPreferences: (data, token) =>
    fetch(PATCH_EMAIL_PREFERENCES.endpoint, {
      method: PATCH_EMAIL_PREFERENCES.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  postDeleteAccount: (data, token) =>
    fetch(POST_DELETE_ACCOUNT.endpoint(data), {
      method: POST_DELETE_ACCOUNT.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err))
}
