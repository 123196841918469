import { headerContent } from '.'
import { getCookie } from '../utils/constants'
import {
  CANCEL_APPLIED_SESSION,
  DELETE_SESSION_CLIENT,
  DELETE_USER_SESSION,
  GET_BOOK_SESSIONS,
  GET_DOWNLOAD_ORGANISATION_DOCUMENTS,
  GET_FEATURES,
  GET_IT_SYSTEMS,
  GET_ORGANISATIONS_DETAILS,
  GET_ORGANISATIONS_PUBLIC,
  GET_PM_SESSIONS_BY_ID,
  GET_PUBLIC_BOOK_SESSIONS,
  GET_SEARCH_PREFERENCES,
  GET_SESSION_LIST,
  GET_SESSION_TYPES,
  GET_USER_SESSIONS,
  GET_USER_SESSION_BY_ID,
  GET_USER_UNAVAILABILIY_DATES,
  PATCH_ADD_FAVOURITE_ORGANISATION,
  PATCH_BOOK_SEARCH_PREFERENCES,
  POST_ADD_MANUAL_SESSION,
  POST_APPLY_SESSION,
  SET_USER_UNAVAILABILIY,
  UPDATE_MANUAL_SESSION
} from './endpoints'

var token = localStorage.getItem('token')

export const headerContentWithAuthorization = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`
}

export const customHeaders = token => {
  let copiedHeaderContentWithAuthorization = {
    ...headerContentWithAuthorization
  }
  copiedHeaderContentWithAuthorization['Authorization'] = `Bearer ${token}`
  return copiedHeaderContentWithAuthorization
}

export const scheduleServices = {
  // Get Organisation details API
  getOrganisationsDetails: (param, page, limit, token) =>
    fetch(GET_ORGANISATIONS_DETAILS.endpoint(param, page, limit), {
      headers: customHeaders(token)
    }).then(resp => {
      if (resp.status !== GET_ORGANISATIONS_DETAILS.successStatusCode) {
        if (resp.status) {
          return resp
        }
        throw new Error('Error in forgot API')
      } else {
        return resp.json()
      }
    }),
  //Add manual session API
  postAddManualSession: (data, token) =>
    fetch(POST_ADD_MANUAL_SESSION.endpoint, {
      method: POST_ADD_MANUAL_SESSION.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    }).then(resp => {
      if (resp.status !== POST_ADD_MANUAL_SESSION.successStatusCode) {
        return resp.json()
      } else {
        return resp.json()
      }
    }),

  //GET All Users session details
  getUserSessions: (monthStartDate, monthEndDate, token) =>
    fetch(GET_USER_SESSIONS.endpoint(monthStartDate, monthEndDate), {
      method: GET_USER_SESSIONS.method,
      headers: customHeaders(token)
    }).then(resp => {
      if (resp.status !== GET_USER_SESSIONS.successStatusCode) {
        return resp.json()
      } else {
        return resp.json()
      }
    }),

  // Get user session by Id
  getUserSessionById: (data, token) =>
    fetch(GET_USER_SESSION_BY_ID.endpoint(data), {
      method: GET_USER_SESSION_BY_ID.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  //Delete manual session APi
  deleteUserSession: (data, token) =>
    fetch(DELETE_USER_SESSION.endpoint(data), {
      method: DELETE_USER_SESSION.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.status
      })
      .catch(err => console.log(err)),

  //Update Manual session Api
  updateManualSession: (id, data, token) =>
    fetch(UPDATE_MANUAL_SESSION.endpoint(id), {
      method: UPDATE_MANUAL_SESSION.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    }).then(resp => {
      if (resp.status !== UPDATE_MANUAL_SESSION.successStatusCode) {
        return resp.json()
      } else {
        return resp.json()
      }
    }),

  getSetUserUnavailibilityDates: (startDate, endDate, token) =>
    fetch(GET_USER_UNAVAILABILIY_DATES.endpoint(startDate, endDate), {
      method: GET_USER_UNAVAILABILIY_DATES.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  //set User unavalibility
  postSetUserUnavailibility: (data, token) =>
    fetch(SET_USER_UNAVAILABILIY.endpoint, {
      method: SET_USER_UNAVAILABILIY.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  //GEt session List
  getSessionList: (param, limit, page, token) =>
    fetch(GET_SESSION_LIST.endpoint(param, limit, page), {
      method: GET_SESSION_LIST.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  getSessionTypes: token =>
    fetch(GET_SESSION_TYPES.endpoint, {
      method: GET_SESSION_TYPES.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get Features API
  getFeatures: token =>
    fetch(GET_FEATURES.endpoint, {
      method: GET_FEATURES.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get IT systems
  getItSystems: () =>
    fetch(GET_IT_SYSTEMS.endpoint, {
      method: GET_IT_SYSTEMS.method,
      headers: headerContent
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  getBookSessions: (
    it_system_id,
    features_id,
    session_id,
    zipcode,
    distance,
    limit,
    page,
    token,
    unavailable,
    instantBooking,
    org_uuid,
    favoriteOrganisation
  ) =>
    fetch(
      GET_BOOK_SESSIONS.endpoint(
        it_system_id,
        features_id,
        session_id,
        zipcode,
        distance,
        limit,
        page,
        unavailable,
        instantBooking,
        org_uuid,
        favoriteOrganisation
      ),
      {
        method: GET_BOOK_SESSIONS.method,
        headers: customHeaders(token)
      }
    )
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  getPublicBookSession: org_uuid =>
    fetch(GET_PUBLIC_BOOK_SESSIONS.endpoint(org_uuid), {
      method: GET_PUBLIC_BOOK_SESSIONS.method,
      headers: headerContent
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),
  // Get PM session by id
  getPmSessionsById: (id, token) =>
    fetch(GET_PM_SESSIONS_BY_ID.endpoint(id), {
      method: GET_PM_SESSIONS_BY_ID.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // POST Session Apply
  postApplySession: (data, token) =>
    fetch(POST_APPLY_SESSION.endpoint, {
      method: POST_APPLY_SESSION.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Cancel Session
  cancelAppliedSession: (id, token) =>
    fetch(CANCEL_APPLIED_SESSION.endpoint(id), {
      method: CANCEL_APPLIED_SESSION.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  deleteSessionClient: (data, token) =>
    fetch(DELETE_SESSION_CLIENT.endpoint, {
      method: DELETE_SESSION_CLIENT.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  patchBookSearchPrefenrences: (data, token) =>
    fetch(PATCH_BOOK_SEARCH_PREFERENCES.endpoint, {
      method: PATCH_BOOK_SEARCH_PREFERENCES.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get Book search preferences
  getBookSearchPreferences: token =>
    fetch(GET_SEARCH_PREFERENCES.endpoint, {
      method: GET_SEARCH_PREFERENCES.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  getOrganisationsPublically: (page, limit, prefix) =>
    fetch(GET_ORGANISATIONS_PUBLIC.endpoint(page, limit, prefix), {
      method: GET_ORGANISATIONS_PUBLIC.method,
      headers: headerContent
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  patchAddFavouriteOrganisation: (org_id, active, token) =>
    fetch(PATCH_ADD_FAVOURITE_ORGANISATION.endpoint(org_id, active), {
      method: PATCH_ADD_FAVOURITE_ORGANISATION.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  downloadOrganisationDocument: (documentId, token) =>
    fetch(GET_DOWNLOAD_ORGANISATION_DOCUMENTS.endpoint(documentId), {
      method: GET_DOWNLOAD_ORGANISATION_DOCUMENTS.method,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => {
        return resp
      })
      .catch(err => console.log(err)),
}
