import React, { useState } from 'react'
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledDropdown
} from 'reactstrap'
import { Link } from 'react-router-dom'
import {
  ADD_MANUAL_SESSION_VALUES_,
  badgeColor,
  convertTimeToMinute,
  getCookie,
  procedureTypeCodeClass,
  SCHEDULE_TABS,
  tokenDecode
} from '../../../utils/constants'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import classnames from 'classnames'
import { scheduleServices } from '../../../services/scheduleServices'
import { useEffect } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import ClientSessionEditModal from '../../../Components/Common/ClientSessionEditModal'
import { toast } from 'react-toastify'
import ConfirmationModal from '../../../Components/Common/ConfirmationModal'
import PaginationV2 from '../../../Components/Common/PaginationV2'
import io from 'socket.io-client'
import SessionListViewInfoModal from '../../../Components/Common/SessionListViewInfoModal'
import { socket } from '../../../socket/socket'
import SpinnerComponent from '../../../Components/Common/Spinner'

import { accountServices } from '../../../services/accountServices'

// const socket = io.connect(process.env.REACT_APP_SOCKET_BASE_URL)

const SessionList = () => {
  const [topBorderjustifyTab, settopBorderjustifyTab] = useState(0)
  const [searchOrganisationText, setSearchOrganisationText] = useState(null)
  const [addManualSessionValues, setAddManualSessionValues] = useState(
    ADD_MANUAL_SESSION_VALUES_
  )
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [selectedSessionId, setSelectedSessionId] = useState()
  const [page, setPage] = useState(1)
  const [appliedModal, setShowAppliedModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [sessionDetails, setSessionDetails] = useState()
  const [showWithdrawn, setShowWithdrawnModal] = useState(false)
  const [orgDetails, setOrgDetails] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [componentReload, setComponentReload] = useState(0)
  const [sessionList, setSessionList] = useState([])
  const [modal, setModal] = useState(false)
  const [selectedSession, setSelectedSession] = useState({})
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [bonusPercentage, setBonusPercentage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const token = localStorage.getItem('token')

  const [user, setUser] = useState();

  useEffect(() => {
    if (topBorderjustifyTab === 0) {
      fetchSessionList('', 20, page, token)
    } else if (topBorderjustifyTab === 1) {
      fetchSessionList('Applied', 20, page, token)
    } else if (topBorderjustifyTab === 2) {
      fetchSessionList('Booked', 20, page, token)
    } else if (topBorderjustifyTab === 3) {
      fetchSessionList('Manual', 20, page, token)
    }
  }, [topBorderjustifyTab, page])

  useEffect(() => {
    fetchOrganisation('', pageCount, 20, token)
  }, [])

  useEffect(() => {
    if (token)
      socket.emit('addClient', {
        uuid: tokenDecode(token).uuid
      })
  }, [token])

  useEffect(()=>{
    fetchUserProfileData(token);
  }, []);

  const fetchSessionList = (param, limit, page, token) => {
    setIsLoading(true)
    scheduleServices
      .getSessionList(param, limit, page, token)
      .then(resp => {
        if (resp.success) {
          setSessionList(resp.data || [])
          setBonusPercentage(resp.user_profile.bonus_fee_percentage)
          setTotalPageCount(resp?.totalPages === 0 ? 1 : resp?.totalPages)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchOrganisation = (param, page, limit, token) => {
    setIsLoading(true)
    scheduleServices
      .getOrganisationsDetails(param, page, limit, token)
      .then(res => {
        if (res.success) {
          let arr = [...orgDetails]
          res.data?.map(_ =>
            arr.push({ value: _.uuid, label: _.organisation_name })
          )
          setOrgDetails(arr)
        }else{
          if(res?.status === 401){
            localStorage.clear();
            history.push('/login')
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const handleScroll = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      setPageCount(pageCount + 1)
      fetchOrganisation('', pageCount, 20, token)
    }
  }

  const editManualSession = () => {
    scheduleServices
      .updateManualSession(
        selectedSessionId,
        {
          organisation_name: addManualSessionValues?.organisation,
          session_fee: addManualSessionValues?.fee,
          start_time: `${moment(sessionDetails?.session?.start_time).format(
            'YYYY-MM-DD'
          )} ${addManualSessionValues?.startTime}:00`,
          end_time: `${moment(sessionDetails?.session?.end_time).format(
            'YYYY-MM-DD'
          )} ${addManualSessionValues?.endTime}:00`
        },
        token
      )
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          toggle()
          setComponentReload(componentReload + 1)
        } else {
          toast.error(resp.message || 'Something went wrong, Please try again.')
        }
      })
  }

  const handleManualEditClick = (session, index) => {
    toggle()
    let copyiedObj = { ...addManualSessionValues }
    copyiedObj.organisation = session?.session?.organisation?.name
    copyiedObj.startTime = moment(session?.session?.start_time).format('HH:mm')
    copyiedObj.endTime = moment(session?.session?.end_time).format('HH:mm')
    copyiedObj.fee = session?.session?.fee
    setAddManualSessionValues(copyiedObj)
    setSelectedSessionId(session?.session?.uuid)
    setSessionDetails(session)
  }

  const handleDeleteEvent = () => {
    scheduleServices
      .deleteUserSession(selectedSessionId, token)
      .then(resp => {
        if (resp === 204) {
          toast.success('Session deleted successfully.')
          // history.push('/')
          if (topBorderjustifyTab === 0) {
            fetchSessionList('', 20, page, token)
          } else if (topBorderjustifyTab === 1) {
            fetchSessionList('Applied', 20, page, token)
          } else if (topBorderjustifyTab === 2) {
            fetchSessionList('Booked', 20, page, token)
          } else if (topBorderjustifyTab === 3) {
            fetchSessionList('Manual', 20, page, token)
          }
          setDeleteModal(false)
          setComponentReload(componentReload + 1)
        } else {
          toast.error('session does not exist.')
        }
      })
      .catch(err => console.log(err))
  }

  const fetchCancelApi = () => {
    scheduleServices
      .cancelAppliedSession(selectedSessionId, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          setShowAppliedModal(false)
          // history.push('/')
          if (topBorderjustifyTab === 0) {
            fetchSessionList('', 20, page, token)
          } else if (topBorderjustifyTab === 1) {
            fetchSessionList('Applied', 20, page, token)
          } else if (topBorderjustifyTab === 2) {
            fetchSessionList('Booked', 20, page, token)
          } else if (topBorderjustifyTab === 3) {
            fetchSessionList('Manual', 20, page, token)
          }
        } else {
          toast.error(resp.message)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchUserProfileData = token => {
    accountServices
      .getUserProfileDetails(token)
      .then(resp => {
        if (resp.data && resp?.data?.user) {
          // setProfileImg(resp?.data?.profile_photo_link)
          setUser(resp?.data?.user)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchWithdrawnSessionClient = () => {
    
    socket.emit('withdraw-session', {
      pm_id: selectedSession?.user_id,
      withdrawn_by_pm: false,
      withdrawn_by_client: true,
      content: 'withdrawn',
      sender: {
        firstName: tokenDecode(token).firstName,
        lastName: tokenDecode(token).lastName,
        title: tokenDecode(token).title
      },
      session: {
        start_time: selectedSession.start_time,
        end_time: selectedSession.end_time
      },
      orgId: selectedSession.organisation ? selectedSession.organisation.uuid : null,
    })

    scheduleServices
      .deleteSessionClient({ sessionId: selectedSessionId }, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          setShowWithdrawnModal(false)
          if (topBorderjustifyTab === 0) {
            fetchSessionList('', 20, page, token)
          } else if (topBorderjustifyTab === 1) {
            fetchSessionList('Applied', 20, page, token)
          } else if (topBorderjustifyTab === 2) {
            fetchSessionList('Booked', 20, page, token)
          } else if (topBorderjustifyTab === 3) {
            fetchSessionList('Manual', 20, page, token)
          }
        } else {
          toast.error(resp.message)
          setShowWithdrawnModal(false)
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <React.Fragment>
      {isLoading && <SpinnerComponent />}
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Session List'
            pageTitle='Schedule'
            to={'/calendar'}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader className='mb-0 pb-0'>
                  <Nav tabs className='nav-tabs'>
                    {SCHEDULE_TABS.map((tab, index) => {
                      return (
                        <NavItem key={index}>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: topBorderjustifyTab === index
                            })}
                            onClick={() => {
                              settopBorderjustifyTab(index)
                              setPage(1)
                            }}
                          >
                            {tab}
                          </NavLink>
                        </NavItem>
                      )
                    })}
                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent
                    activeTab={topBorderjustifyTab}
                    className='text-muted'
                  >
                    <TabPane
                      tabId={topBorderjustifyTab}
                      id='nav-border-top-home'
                    >
                      {/* Desktop View */}
                      <div className='live-preview  d-none d-xl-block'>
                        <div className='table-responsive table-card'>
                          <Table className='align-middle  table-hover  table-nowrap mb-0'>
                            <thead className='text-muted'>
                              <tr>
                                <th></th>
                                <th scope='col'>ID</th>
                                <th scope='col'>Organisation</th>
                                <th scope='col'>Date & Time</th>
                                <th scope='col'>Fee</th>
                                <th scope='col'>Status</th>
                                <th scope='col'>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {sessionList?.length !== 0  ? (
                                   sessionList.map((item, index) => {
                                  
                                  return (
                                    <tr key={item?.uuid}>
                                      <td></td>
                                      <td>
                                        {/* <Link to='#' id='tooltipUrl'>
                                            #{item?.uuid?.slice(0, 6) || 'NA'}
                                          </Link> */}
                                        <Link
                                          to='#'
                                          onClick={() =>
                                          // history.push(
                                          //   `/session-details/${item?.uuid}/manual`
                                          // )
                                          {
                                            setSelectedSession(item)
                                            setShowInfoModal(true)
                                          }
                                          }
                                        >
                                          #
                                          {item?.session?.uuid.substring(
                                            0,
                                            5
                                          ) || 'NA'}
                                        </Link>
                                      </td>
                                      <td className='text-truncate' style={{maxWidth: 220}}>

                                        {item?.session?.organisation?.name || 'NA'}
                                        <p className='text-muted small mb-0'>
                                        {item?.session?.organisation?.postcode || 'NA'}
                                        {/* {' - '} */}
                                        {/* {item?.session?.organisation?.organisation_code || 'NA'} */}
                                        {/* {console.log(item?.session?.organisation)} */}
                                        </p>
                                      </td>
                                      <td>
                                              {`${moment(
                                                item?.session?.start_time
                                              ).format(
                                                'ddd, Do  MMMM YYYY'
                                              )}` || 'NA'}
                                            <p className='text-muted mb-0 small'>

                                                {`${moment(
                                                  item?.session?.start_time
                                                ).format('LT')}` || 'NA'}{' '}
                                                -
                                                {`${moment(
                                                  item?.session?.end_time
                                                ).format('LT')}` || 'NA'}{' '}
                                                ({' '}{item?.session?.unpaid_break_duration || ' No break'}{' '}
                                                {item?.session?.unpaid_break_duration !== 0 ? "mins break" : ""}{' '})
                                            </p>
                                      </td>
                                      <td>
                                              £
                                              {item?.session?.status ===
                                                'withdrawn'
                                                ? (
                                                  ((item?.session
                                                    ?.hourly_rate /
                                                    60) *
                                                    convertTimeToMinute(
                                                      item?.session
                                                        ?.start_time,
                                                      item?.session?.end_time,
                                                      item?.session
                                                        ?.unpaid_break_duration
                                                    ) *
                                                    item?.session
                                                      ?.withdrawal_percentage) /
                                                  100
                                                )?.toFixed(0)
                                                : (
                                                  (item?.session
                                                    ?.hourly_rate /
                                                    60) *
                                                  convertTimeToMinute(
                                                    item?.session
                                                      ?.start_time,
                                                    item?.session?.end_time,
                                                    item?.session
                                                      ?.unpaid_break_duration
                                                  ) +
                                                  Number(
                                                    item?.session
                                                      ?.client_bonus_amount
                                                  )
                                                )?.toFixed(0)}

                                            <p className='text-muted small mb-0'>
                                              <span
                                                className={`fw-medium ${item?.session?.status ===
                                                  'withdrawn' &&
                                                  'text-decoration-line-through'
                                                  } `}
                                              >
                                                £
                                                {(
                                                  (item?.session?.hourly_rate /
                                                    60) *
                                                  convertTimeToMinute(
                                                    item?.session?.start_time,
                                                    item?.session?.end_time,
                                                    item?.session
                                                      ?.unpaid_break_duration
                                                  )
                                                ).toFixed() || 'NA'}{' '}
                                                (£
                                                {item?.session?.hourly_rate ||
                                                  'NA'}{' '}
                                                /hour)
                                                {Number(
                                                  item?.session
                                                  ?.client_bonus_amount
                                                  )?.toFixed() > 0 && (
                                                  <>
                                                    + £{Number(item?.session?.client_bonus_amount)?.toFixed(2)} 
                                                {' '}bonus
                                                  </>
                                                )}
                                                {item?.session?.pensionable ===
                                                  1 ? (
                                                  <i title='Pensionable' className="ri-file-ppt-line ms-1"></i>
                                                ) : (
                                                  ''
                                                )}
                                              </span>
                                            </p>

                                      </td>

                                      <td>
                                        <Badge
                                          color={badgeColor(
                                            item?.Session_statuses?.slice(-1)[0]
                                              ?.status
                                          )}
                                          className='ms-auto'
                                        >
                                          {item?.Session_statuses?.slice(-1)[0]
                                            ?.status || 'NA'}
                                        </Badge>
                                      </td>
                                      <td>
                                        {item?.Session_statuses?.slice(-1)[0]
                                          ?.status === 'Manual' && (
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                href='#'
                                                className='btn-soft-primary btn-sm dropdown'
                                                tag='button'
                                              >
                                                <i className='ri-more-fill align-middle'></i>
                                              </DropdownToggle>
                                              <DropdownMenu className='dropdown-menu-end'>
                                                <DropdownItem
                                                  onClick={() =>
                                                    handleManualEditClick(
                                                      item,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <i className='ri-pencil-fill align-bottom me-2 text-muted'></i>{' '}
                                                  Edit
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    setDeleteModal(true)
                                                    setSelectedSessionId(
                                                      item.session.uuid
                                                    )
                                                  }}
                                                >
                                                  <i className='ri-delete-bin-6-line align-bottom me-2 text-muted'></i>{' '}
                                                  Delete
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                  // history.push(
                                                  //   `/session-details/${item?.uuid}/manual`
                                                  // )
                                                  {
                                                    setSelectedSession(item)
                                                    setShowInfoModal(true)
                                                  }
                                                  }
                                                >
                                                  <i className='ri-eye-fill align-bottom me-2 text-muted'></i>{' '}
                                                  View
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          )}

                                        {item?.Session_statuses?.slice(-1)[0]
                                          ?.status === 'Applied' && (
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                href='#'
                                                className='btn-soft-primary btn-sm dropdown'
                                                tag='button'
                                              >
                                                <i className='ri-more-fill align-middle'></i>
                                              </DropdownToggle>
                                              <DropdownMenu className='dropdown-menu-end'>
                                                <DropdownItem
                                                  onClick={() => {
                                                    setShowAppliedModal(true)
                                                    setSelectedSessionId(
                                                      item.uuid
                                                    )
                                                  }}
                                                >
                                                  <i className='ri-delete-bin-6-line align-bottom me-2 text-muted'></i>{' '}
                                                  Cancel
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                  // history.push(
                                                  //   `/session-details/${item?.uuid}/manual`
                                                  // )
                                                  {
                                                    setSelectedSession(item)
                                                    setShowInfoModal(true)
                                                  }
                                                  }
                                                >
                                                  <i className='ri-eye-fill align-bottom me-2 text-muted'></i>{' '}
                                                  View
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          )}

                                        {item?.Session_statuses?.slice(-1)[0]
                                          ?.status === 'Booked' && (
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                href='#'
                                                className='btn-soft-primary btn-sm dropdown'
                                                tag='button'
                                              >
                                                <i className='ri-more-fill align-middle'></i>
                                              </DropdownToggle>
                                              <DropdownMenu className='dropdown-menu-end'>
                                                <DropdownItem
                                                  onClick={() => {
                                                    setShowWithdrawnModal(true)
                                                    setSelectedSessionId(
                                                      item?.session?.uuid
                                                    )
                                                    setSelectedSession(
                                                      item.session
                                                    )
                                                  }}
                                                >
                                                  <i className='ri-delete-bin-6-line align-bottom me-2 text-muted'></i>{' '}
                                                  Withdraw
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                  // history.push(
                                                  //   `/session-details/${item?.uuid}/manual`
                                                  // )
                                                  {
                                                    setSelectedSession(item)
                                                    setShowInfoModal(true)
                                                  }
                                                  }
                                                >
                                                  <i className='ri-eye-fill align-bottom me-2 text-muted'></i>{' '}
                                                  View
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          )}
                                        {item?.Session_statuses?.slice(-1)[0]
                                          ?.status === 'Worked' && (
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                href='#'
                                                className='btn-soft-primary btn-sm dropdown'
                                                tag='button'
                                              >
                                                <i className='ri-more-fill align-middle'></i>
                                              </DropdownToggle>
                                              <DropdownMenu className='dropdown-menu-end'>
                                                <DropdownItem
                                                  onClick={() =>
                                                  // history.push(
                                                  //   `/session-details/${item?.uuid}/manual`
                                                  // )
                                                  {
                                                    setSelectedSession(item)
                                                    setShowInfoModal(true)
                                                  }
                                                  }
                                                >
                                                  <i className='ri-eye-fill align-bottom me-2 text-muted'></i>{' '}
                                                  View
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          )}

                                        {item?.Session_statuses?.slice(-1)[0]
                                          ?.status === 'withdrawn' && (
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                href='#'
                                                className='btn-soft-primary btn-sm dropdown'
                                                tag='button'
                                              >
                                                <i className='ri-more-fill align-middle'></i>
                                              </DropdownToggle>
                                              <DropdownMenu className='dropdown-menu-end'>
                                                <DropdownItem
                                                  onClick={() => {
                                                    setSelectedSession(item)
                                                    setShowInfoModal(true)
                                                  }}
                                                >
                                                  <i className='ri-eye-fill align-bottom me-2 text-muted'></i>{' '}
                                                  View
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          )}
                                      </td>
                                    </tr>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td colSpan='13' className='text-center text-muted'>
                                    No data found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      {/* Mobile View */}
                      <div className='live-preview  d-xl-none'>

                      {sessionList?.length !== 0  ? (
                                   sessionList.filter(item => item?.Session_statuses?.slice(-1)[0]?.status !== 'Worked' && item?.Session_statuses?.slice(-1)[0]?.status !== 'withdrawn')?.map((item, index) => {
                                  
                                  return (

                                  <Card className='d-xl-none rounded-1' key={item?.uuid}>
                                    {/* <CardHeader className='bg-soft-light pb-0'>
                                    </CardHeader> */}
                                    <CardBody className='text-dark'>


                                    <Row className='pb-2'>
                                      <Col xs={8} className='fw-semibold text-dark'>
                                      {item?.session?.organisation?.name || 'NA'}
                                      </Col>
                                      <Col xs={4} className='text-end'>

                                      <span className={`badge rounded-pill badge-soft-${badgeColor(
                                            item?.Session_statuses?.slice(-1)[0]
                                              ?.status
                                          )}`}>  {item?.Session_statuses?.slice(-1)[0]
                                            ?.status || 'NA'}</span>
                                      </Col>
                                    </Row>

                                        <Row>
                                          <Col>
                                          {`${moment(
                                                item?.session?.start_time
                                              ).format(
                                                'dddd'
                                              )}` || 'NA'}
                                          </Col>
                                          <Col className='text-end fw-semibold text-primary fs-20 lh-1'>
                                          £
                                              {item?.session?.status ===
                                                'withdrawn'
                                                ? (
                                                  ((item?.session
                                                    ?.hourly_rate /
                                                    60) *
                                                    convertTimeToMinute(
                                                      item?.session
                                                        ?.start_time,
                                                      item?.session?.end_time,
                                                      item?.session
                                                        ?.unpaid_break_duration
                                                    ) *
                                                    item?.session
                                                      ?.withdrawal_percentage) /
                                                  100
                                                )?.toFixed(0)
                                                : (
                                                  (item?.session
                                                    ?.hourly_rate /
                                                    60) *
                                                  convertTimeToMinute(
                                                    item?.session
                                                      ?.start_time,
                                                    item?.session?.end_time,
                                                    item?.session
                                                      ?.unpaid_break_duration
                                                  ) +
                                                  Number(
                                                    item?.session
                                                      ?.client_bonus_amount
                                                  )
                                                )?.toFixed(0)}
                                          </Col>
                                        </Row>


                                        <Row>
                                          <Col xs={9}>
                                          {`${moment(
                                                item?.session?.start_time
                                              ).format(
                                                'Do MMMM YYYY'
                                              )}` || 'NA'}
                                          </Col>
                                          <Col  xs={3} className={`text-muted text-end ${item?.session?.status ===
                                                  'withdrawn' &&
                                                  'text-decoration-line-through'
                                                  } `}>

                                          £
                                            {(
                                                  (item?.session?.hourly_rate /
                                                    60) *
                                                  convertTimeToMinute(
                                                    item?.session?.start_time,
                                                    item?.session?.end_time,
                                                    item?.session
                                                      ?.unpaid_break_duration
                                                  )
                                                ).toFixed() || 'NA'}{' '}
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col  xs={7}>

                                          {`${moment(
                                                  item?.session?.start_time
                                                ).format('LT')}` || 'NA'}{' '}
                                                -{' '}
                                                {`${moment(
                                                  item?.session?.end_time
                                                ).format('LT')}` || 'NA'}{' '}
                                          </Col>
                                          <Col  xs={5} className={`text-end text-muted  ${item?.session?.status ===
                                                  'withdrawn' &&
                                                  'text-decoration-line-through'
                                                  } `}>
                                          £
                                                {item?.session?.hourly_rate ||
                                                  'NA'}
                                                /hour
                                          </Col>
                                        </Row>



                                        <Row>
                                          <Col>
                                          {item?.session?.unpaid_break_duration || ' No break'}{' '}
                                                {item?.session?.unpaid_break_duration !== 0 ? "mins break" : ""}
                                          </Col>
                                          <Col className={`text-end text-muted  ${item?.session?.status ===
                                                  'withdrawn' &&
                                                  'text-decoration-line-through'
                                                  } `}>
                                                {Number(
                                                  item?.session
                                                  ?.client_bonus_amount
                                                  )?.toFixed(2) > 0 && (
                                                  <>
                                                   £{Number(item?.session?.client_bonus_amount)?.toFixed(2)} 
                                                {' '}Bonus
                                                  </>
                                                )}
                                          </Col>
                                        </Row>


                                        <div className='d-flex flex-row justify-content-end small gap-1 my-1'>

                                        <Link
                                          to='#'
                                          className='fw-medium'
                                          onClick={() =>
                                          {
                                            setSelectedSession(item)
                                            setShowInfoModal(true)
                                          }
                                          }
                                        >
                                          <span className='badge rounded-pill badge-soft-danger'>                                         #
                                          {item?.session?.uuid.substring(0,6) || 'NA'}</span>
                                        </Link>

                                          {item?.session?.pensionable ===
                                                  1 ? (
                                                  <div> <span className='badge rounded-pill badge-soft-success'>Pensionable</span></div>
                                                ) : (
                                                  ''
                                                )}
                                                
                                        </div>
                                    

                                    </CardBody>
                                    <CardFooter>
                                    <div className='hstack gap-2 pt-1 float-end'>

                                    {item?.Session_statuses?.slice(-1)[0]
                                          ?.status === 'Manual' && (
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                href='#'
                                                className='btn-soft-primary btn-sm dropdown'
                                                tag='button'
                                              >
                                                <i className='ri-more-fill align-middle'></i>
                                              </DropdownToggle>
                                              <DropdownMenu className='dropdown-menu-end'>
                                                <DropdownItem
                                                  onClick={() =>
                                                    handleManualEditClick(
                                                      item,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <i className='ri-pencil-fill align-bottom me-2 text-muted'></i>{' '}
                                                  Edit
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() => {
                                                    setDeleteModal(true)
                                                    setSelectedSessionId(
                                                      item.session.uuid
                                                    )
                                                  }}
                                                >
                                                  <i className='ri-delete-bin-6-line align-bottom me-2 text-muted'></i>{' '}
                                                  Delete
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                  // history.push(
                                                  //   `/session-details/${item?.uuid}/manual`
                                                  // )
                                                  {
                                                    setSelectedSession(item)
                                                    setShowInfoModal(true)
                                                  }
                                                  }
                                                >
                                                  <i className='ri-eye-fill align-bottom me-2 text-muted'></i>{' '}
                                                  View
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          )}

                                        {item?.Session_statuses?.slice(-1)[0]
                                          ?.status === 'Applied' && (
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                href='#'
                                                className='btn-soft-primary btn-sm dropdown'
                                                tag='button'
                                              >
                                                <i className='ri-more-fill align-middle'></i>
                                              </DropdownToggle>
                                              <DropdownMenu className='dropdown-menu-end'>
                                                <DropdownItem
                                                  onClick={() => {
                                                    setShowAppliedModal(true)
                                                    setSelectedSessionId(
                                                      item.uuid
                                                    )
                                                  }}
                                                >
                                                  <i className='ri-delete-bin-6-line align-bottom me-2 text-muted'></i>{' '}
                                                  Cancel
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                  // history.push(
                                                  //   `/session-details/${item?.uuid}/manual`
                                                  // )
                                                  {
                                                    setSelectedSession(item)
                                                    setShowInfoModal(true)
                                                  }
                                                  }
                                                >
                                                  <i className='ri-eye-fill align-bottom me-2 text-muted'></i>{' '}
                                                  View
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          )}

                                        {item?.Session_statuses?.slice(-1)[0]
                                          ?.status === 'Booked' && (
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                href='#'
                                                className='btn-soft-primary btn-sm dropdown'
                                                tag='button'
                                              >
                                                <i className='ri-more-fill align-middle'></i>
                                              </DropdownToggle>
                                              <DropdownMenu className='dropdown-menu-end'>
                                                <DropdownItem
                                                  onClick={() => {
                                                    setShowWithdrawnModal(true)
                                                    setSelectedSessionId(
                                                      item?.session?.uuid
                                                    )
                                                    setSelectedSession(
                                                      item.session
                                                    )
                                                  }}
                                                >
                                                  <i className='ri-delete-bin-6-line align-bottom me-2 text-muted'></i>{' '}
                                                  Withdraw
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                  // history.push(
                                                  //   `/session-details/${item?.uuid}/manual`
                                                  // )
                                                  {
                                                    setSelectedSession(item)
                                                    setShowInfoModal(true)
                                                  }
                                                  }
                                                >
                                                  <i className='ri-eye-fill align-bottom me-2 text-muted'></i>{' '}
                                                  View
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          )}
                                        {item?.Session_statuses?.slice(-1)[0]
                                          ?.status === 'Worked' && (
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                href='#'
                                                className='btn-soft-primary btn-sm dropdown'
                                                tag='button'
                                              >
                                                <i className='ri-more-fill align-middle'></i>
                                              </DropdownToggle>
                                              <DropdownMenu className='dropdown-menu-end'>
                                                <DropdownItem
                                                  onClick={() =>
                                                  // history.push(
                                                  //   `/session-details/${item?.uuid}/manual`
                                                  // )
                                                  {
                                                    setSelectedSession(item)
                                                    setShowInfoModal(true)
                                                  }
                                                  }
                                                >
                                                  <i className='ri-eye-fill align-bottom me-2 text-muted'></i>{' '}
                                                  View
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          )}

                                        {item?.Session_statuses?.slice(-1)[0]
                                          ?.status === 'withdrawn' && (
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                href='#'
                                                className='btn-soft-primary btn-sm dropdown'
                                                tag='button'
                                              >
                                                <i className='ri-more-fill align-middle'></i>
                                              </DropdownToggle>
                                              <DropdownMenu className='dropdown-menu-end'>
                                                <DropdownItem
                                                  onClick={() => {
                                                    setSelectedSession(item)
                                                    setShowInfoModal(true)
                                                  }}
                                                >
                                                  <i className='ri-eye-fill align-bottom me-2 text-muted'></i>{' '}
                                                  View
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          )}
                                        </div>
                                    </CardFooter>
                                  </Card>






                                    // <tr key={item?.uuid}>
                                    //   <td></td>
                                    //   <td>
                                    //     {/* <Link to='#' id='tooltipUrl'>
                                    //         #{item?.uuid?.slice(0, 6) || 'NA'}
                                    //       </Link> */}
                                    //     <Link
                                    //       to='#'
                                    //       className='fw-medium'
                                    //       onClick={() =>
                                    //       // history.push(
                                    //       //   `/session-details/${item?.uuid}/manual`
                                    //       // )
                                    //       {
                                    //         setSelectedSession(item)
                                    //         setShowInfoModal(true)
                                    //       }
                                    //       }
                                    //     >
                                    //       #
                                    //       {item?.session?.uuid.substring(
                                    //         0,
                                    //         5
                                    //       ) || 'NA'}
                                    //     </Link>
                                    //   </td>
                                    //   <td>
                                    //     <div className='d-flex align-items-center'>
                                    //       <div className='flex-grow-1'>
                                    //         <h5 className='fs-14 mb-1'>
                                    //           {item?.session?.organisation
                                    //             ?.name || 'NA'}
                                    //         </h5>
                                    //       </div>
                                    //     </div>
                                    //   </td>
                                    //   <td>
                                    //     <div className='d-flex align-items-center'>
                                    //       <div className='flex-grow-1'>
                                    //         <h5 className='fs-14 mb-1'>
                                    //           {`${moment(
                                    //             item?.session?.start_time
                                    //           ).format(
                                    //             'dddd, MMMM Do YYYY'
                                    //           )}` || 'NA'}
                                    //         </h5>
                                    //         <p className='text-muted mb-0'>
                                    //           <span className='fw-medium'>
                                    //             {`${moment(
                                    //               item?.session?.start_time
                                    //             ).format('LT')}` || 'NA'}{' '}
                                    //             -
                                    //             {`${moment(
                                    //               item?.session?.end_time
                                    //             ).format('LT')}` || 'NA'}{' '}
                                    //             ({' '}{item?.session?.unpaid_break_duration || ' No break'}{' '}
                                    //             {item?.session?.unpaid_break_duration !== 0 ? "mins break" : ""}{' '})
                                    //           </span>
                                    //         </p>
                                    //       </div>
                                    //     </div>
                                    //   </td>
                                    //   <td>
                                    //     <div className='d-flex align-items-center'>
                                    //       <div className='flex-grow-1'>
                                    //         <h5 className='mb-1 text-primary fw-semibold '>
                                    //           £
                                    //           {item?.session?.status ===
                                    //             'withdrawn'
                                    //             ? (
                                    //               ((item?.session
                                    //                 ?.hourly_rate /
                                    //                 60) *
                                    //                 convertTimeToMinute(
                                    //                   item?.session
                                    //                     ?.start_time,
                                    //                   item?.session?.end_time,
                                    //                   item?.session
                                    //                     ?.unpaid_break_duration
                                    //                 ) *
                                    //                 item?.session
                                    //                   ?.withdrawal_percentage) /
                                    //               100
                                    //             )?.toFixed(0)
                                    //             : (
                                    //               (item?.session
                                    //                 ?.hourly_rate /
                                    //                 60) *
                                    //               convertTimeToMinute(
                                    //                 item?.session
                                    //                   ?.start_time,
                                    //                 item?.session?.end_time,
                                    //                 item?.session
                                    //                   ?.unpaid_break_duration
                                    //               ) +
                                    //               Number(
                                    //                 item?.session
                                    //                   ?.client_bonus_amount
                                    //               )
                                    //             )?.toFixed(0)}
                                    //         </h5>
                                    //         <p className='text-muted mb-0'>
                                    //           <span
                                    //             className={`fw-medium ${item?.session?.status ===
                                    //               'withdrawn' &&
                                    //               'text-decoration-line-through'
                                    //               } `}
                                    //           >
                                    //             £
                                    //             {(
                                    //               (item?.session?.hourly_rate /
                                    //                 60) *
                                    //               convertTimeToMinute(
                                    //                 item?.session?.start_time,
                                    //                 item?.session?.end_time,
                                    //                 item?.session
                                    //                   ?.unpaid_break_duration
                                    //               )
                                    //             ).toFixed() || 'NA'}{' '}
                                    //             (£
                                    //             {item?.session?.hourly_rate ||
                                    //               'NA'}{' '}
                                    //             /hour) + £
                                    //             {Number(
                                    //               item?.session
                                    //                 ?.client_bonus_amount
                                    //             )?.toFixed()}{' '}
                                    //             Bonus
                                    //             {item?.session?.pensionable ===
                                    //               1 ? (
                                    //               <i
                                    //                 title='Pensionable'
                                    //                 className='ms-1 ri-file-ppt-line'
                                    //               ></i>
                                    //             ) : (
                                    //               ''
                                    //             )}
                                    //           </span>
                                    //         </p>
                                    //       </div>
                                    //     </div>
                                    //   </td>

                                    //   <td>
                                    //   <span className={`badge rounded-pill badge-soft-${badgeColor(
                                    //         item?.Session_statuses?.slice(-1)[0]
                                    //           ?.status
                                    //       )}`}>  {item?.Session_statuses?.slice(-1)[0]
                                    //         ?.status || 'NA'}</span>
                                    //     {/* <Badge
                                    //       color={badgeColor(
                                    //         item?.Session_statuses?.slice(-1)[0]
                                    //           ?.status
                                    //       )}
                                    //       className='ms-auto'
                                    //     >
                                    //       {item?.Session_statuses?.slice(-1)[0]
                                    //         ?.status || 'NA'}
                                    //     </Badge> */}
                                    //   </td>
                                    //   <td>
                                    //     {item?.Session_statuses?.slice(-1)[0]
                                    //       ?.status === 'Manual' && (
                                    //         <UncontrolledDropdown>
                                    //           <DropdownToggle
                                    //             href='#'
                                    //             className='btn-soft-secondary btn-sm dropdown'
                                    //             tag='button'
                                    //           >
                                    //             <i className='ri-more-fill align-middle'></i>
                                    //           </DropdownToggle>
                                    //           <DropdownMenu className='dropdown-menu-end'>
                                    //             <DropdownItem
                                    //               onClick={() =>
                                    //                 handleManualEditClick(
                                    //                   item,
                                    //                   index
                                    //                 )
                                    //               }
                                    //             >
                                    //               <i className='ri-pencil-fill align-bottom me-2 text-muted'></i>{' '}
                                    //               Edit
                                    //             </DropdownItem>
                                    //             <DropdownItem
                                    //               onClick={() => {
                                    //                 setDeleteModal(true)
                                    //                 setSelectedSessionId(
                                    //                   item.session.uuid
                                    //                 )
                                    //               }}
                                    //             >
                                    //               <i className='ri-delete-bin-6-line align-bottom me-2 text-muted'></i>{' '}
                                    //               Delete
                                    //             </DropdownItem>
                                    //             <DropdownItem
                                    //               onClick={() =>
                                    //               // history.push(
                                    //               //   `/session-details/${item?.uuid}/manual`
                                    //               // )
                                    //               {
                                    //                 setSelectedSession(item)
                                    //                 setShowInfoModal(true)
                                    //               }
                                    //               }
                                    //             >
                                    //               <i className='ri-eye-fill align-bottom me-2 text-muted'></i>{' '}
                                    //               View
                                    //             </DropdownItem>
                                    //           </DropdownMenu>
                                    //         </UncontrolledDropdown>
                                    //       )}

                                    //     {item?.Session_statuses?.slice(-1)[0]
                                    //       ?.status === 'Applied' && (
                                    //         <UncontrolledDropdown>
                                    //           <DropdownToggle
                                    //             href='#'
                                    //             className='btn-soft-secondary btn-sm dropdown'
                                    //             tag='button'
                                    //           >
                                    //             <i className='ri-more-fill align-middle'></i>
                                    //           </DropdownToggle>
                                    //           <DropdownMenu className='dropdown-menu-end'>
                                    //             <DropdownItem
                                    //               onClick={() => {
                                    //                 setShowAppliedModal(true)
                                    //                 setSelectedSessionId(
                                    //                   item.uuid
                                    //                 )
                                    //               }}
                                    //             >
                                    //               <i className='ri-delete-bin-6-line align-bottom me-2 text-muted'></i>{' '}
                                    //               Cancel
                                    //             </DropdownItem>
                                    //             <DropdownItem
                                    //               onClick={() =>
                                    //               // history.push(
                                    //               //   `/session-details/${item?.uuid}/manual`
                                    //               // )
                                    //               {
                                    //                 setSelectedSession(item)
                                    //                 setShowInfoModal(true)
                                    //               }
                                    //               }
                                    //             >
                                    //               <i className='ri-eye-fill align-bottom me-2 text-muted'></i>{' '}
                                    //               View
                                    //             </DropdownItem>
                                    //           </DropdownMenu>
                                    //         </UncontrolledDropdown>
                                    //       )}

                                    //     {item?.Session_statuses?.slice(-1)[0]
                                    //       ?.status === 'Booked' && (
                                    //         <UncontrolledDropdown>
                                    //           <DropdownToggle
                                    //             href='#'
                                    //             className='btn-soft-secondary btn-sm dropdown'
                                    //             tag='button'
                                    //           >
                                    //             <i className='ri-more-fill align-middle'></i>
                                    //           </DropdownToggle>
                                    //           <DropdownMenu className='dropdown-menu-end'>
                                    //             <DropdownItem
                                    //               onClick={() => {
                                    //                 setShowWithdrawnModal(true)
                                    //                 setSelectedSessionId(
                                    //                   item?.session?.uuid
                                    //                 )
                                    //                 setSelectedSession(
                                    //                   item.session
                                    //                 )
                                    //               }}
                                    //             >
                                    //               <i className='ri-delete-bin-6-line align-bottom me-2 text-muted'></i>{' '}
                                    //               Withdraw
                                    //             </DropdownItem>
                                    //             <DropdownItem
                                    //               onClick={() =>
                                    //               // history.push(
                                    //               //   `/session-details/${item?.uuid}/manual`
                                    //               // )
                                    //               {
                                    //                 setSelectedSession(item)
                                    //                 setShowInfoModal(true)
                                    //               }
                                    //               }
                                    //             >
                                    //               <i className='ri-eye-fill align-bottom me-2 text-muted'></i>{' '}
                                    //               View
                                    //             </DropdownItem>
                                    //           </DropdownMenu>
                                    //         </UncontrolledDropdown>
                                    //       )}
                                    //     {item?.Session_statuses?.slice(-1)[0]
                                    //       ?.status === 'Worked' && (
                                    //         <UncontrolledDropdown>
                                    //           <DropdownToggle
                                    //             href='#'
                                    //             className='btn-soft-secondary btn-sm dropdown'
                                    //             tag='button'
                                    //           >
                                    //             <i className='ri-more-fill align-middle'></i>
                                    //           </DropdownToggle>
                                    //           <DropdownMenu className='dropdown-menu-end'>
                                    //             <DropdownItem
                                    //               onClick={() =>
                                    //               // history.push(
                                    //               //   `/session-details/${item?.uuid}/manual`
                                    //               // )
                                    //               {
                                    //                 setSelectedSession(item)
                                    //                 setShowInfoModal(true)
                                    //               }
                                    //               }
                                    //             >
                                    //               <i className='ri-eye-fill align-bottom me-2 text-muted'></i>{' '}
                                    //               View
                                    //             </DropdownItem>
                                    //           </DropdownMenu>
                                    //         </UncontrolledDropdown>
                                    //       )}

                                    //     {item?.Session_statuses?.slice(-1)[0]
                                    //       ?.status === 'withdrawn' && (
                                    //         <UncontrolledDropdown>
                                    //           <DropdownToggle
                                    //             href='#'
                                    //             className='btn-soft-secondary btn-sm dropdown'
                                    //             tag='button'
                                    //           >
                                    //             <i className='ri-more-fill align-middle'></i>
                                    //           </DropdownToggle>
                                    //           <DropdownMenu className='dropdown-menu-end'>
                                    //             <DropdownItem
                                    //               onClick={() => {
                                    //                 setSelectedSession(item)
                                    //                 setShowInfoModal(true)
                                    //               }}
                                    //             >
                                    //               <i className='ri-eye-fill align-bottom me-2 text-muted'></i>{' '}
                                    //               View
                                    //             </DropdownItem>
                                    //           </DropdownMenu>
                                    //         </UncontrolledDropdown>
                                    //       )}
                                    //   </td>
                                    // </tr>
                                  )
                                })
                              ) : (
                                <div  className='d-xl-none text-center mt-4'>
                                  <p>No result found</p>
                                  </div>
                              )}
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
                <PaginationV2
                  totalPageCount={totalPageCount === 0 ? 1 : totalPageCount}
                  previousDisabled={page === 1}
                  nextDisabled={totalPageCount === page ? true : false}
                  previousPageCall={() => setPage(page - 1)}
                  currentPage={page}
                  nextPageCalls={() => setPage(page + 1)}
                  onPageInputChange={({ target: { value } }) => {
                    var regex = new RegExp(`^[1-${totalPageCount}]$`)
                    if (value <= totalPageCount && value !== 0) {
                      setPage(value)
                    }
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Container>

        <ClientSessionEditModal
          open={modal}
          onSelectInputChange={e => {
            setSearchOrganisationText(e)
          }}
          options={orgDetails}
          handleScroll={handleScroll}
          onSelectChange={e => {
            let copyiedObj = { ...addManualSessionValues }
            copyiedObj.organisation = e?.label
            setAddManualSessionValues(copyiedObj)
          }}
          onStartTimeChange={e => {
            let copyiedObj = { ...addManualSessionValues }
            copyiedObj.startTime = moment(e[0]).format('HH:mm')
            setAddManualSessionValues(copyiedObj)
          }}
          onEndTimeChange={e => {
            let copyiedObj = { ...addManualSessionValues }
            copyiedObj.endTime = moment(e[0]).format('HH:mm')
            setAddManualSessionValues(copyiedObj)
          }}
          onFeeChange={e => {
            let copyiedObj = { ...addManualSessionValues }
            copyiedObj.fee = e.target.value
            setAddManualSessionValues(copyiedObj)
          }}
          toggle={toggle}
          onClickCreateBtn={editManualSession}
          btnName={'Edit'}
          headers={'Edit manual session'}
          startTime={addManualSessionValues?.startTime}
          endTime={addManualSessionValues?.endTime}
          fee={addManualSessionValues?.fee}
          organisationValue={addManualSessionValues?.organisation}
        />

        <SessionListViewInfoModal
          open={showInfoModal}
          sessionDetails={selectedSession}
          bonusPercentage={bonusPercentage}
          handleClose={() => setShowInfoModal(!showInfoModal)}
          showWithDrawnModal={() => {
            setShowWithdrawnModal(true)
            setSelectedSessionId(selectedSession?.session?.uuid)
            document.body.style.overflow = 'visible'
          }}
          cancelSelecteSession={() => {
            setShowAppliedModal(true)
            setSelectedSessionId(selectedSession.uuid)
            document.body.style.overflow = 'visible'
          }}
        />

        <ConfirmationModal
          show={deleteModal}
          onDeleteClick={handleDeleteEvent}
          onCloseClick={() => setDeleteModal(false)}
          deleteMsg='Are you sure you want to delete this session ?'
        />

        <ConfirmationModal
          show={appliedModal}
          onDeleteClick={fetchCancelApi}
          onCloseClick={() => setShowAppliedModal(false)}
          deleteMsg='Are you sure you want to cancel this session ?'
          btnName='Yes'
        />

        <ConfirmationModal
          show={showWithdrawn}
          onDeleteClick={fetchWithdrawnSessionClient}
          onCloseClick={() => setShowWithdrawnModal(false)}
          deleteMsg='Are you sure you want to withdraw this session ?'
          btnName='Yes'
        />
      </div>
    </React.Fragment>
  )
}

export default SessionList
