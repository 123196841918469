import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import ParticlesAuth from '../ParticlesAuth'

import { authServices } from '../../../services/authServices'
import { useEffect } from 'react'
import Spinner2 from '../../../Components/Common/Spinner2'

//logo
import logoLight from "../../../assets/images/logo-light.png";


const EmailVerificationSuccess = () => {
  document.title = 'Verification Successful |  airGP'
  const [isLoading, setIsLoading] = useState(true)
  const [emailVerification, setEmailVerification] = useState(false)

  let id = new URLSearchParams(document.location.search).get('verification_id')

  useEffect(() => {
    if (id) fetchEmailVerification(id)
  }, [id])

  const fetchEmailVerification = id => {
    authServices
      .postEmailVerification(id)
      .then(resp => {
        if (resp.success) {
          setIsLoading(false)
          setEmailVerification(true)
        } else {
          setIsLoading(false)
          setEmailVerification(false)
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <Spinner2 />
      ) : (
        <>
          {emailVerification ? (
            <div className='auth-page-wrapper'>
              <ParticlesAuth>
                <div className='auth-page-content'>
                  <Container>
                    <Row>
                      <Col lg={12}>
                        <div className='text-center mt-sm-5 mb-4 text-white'>
                          <div>
                            <Link
                              to='/dashboard'
                              className='d-inline-block auth-logo'
                            >
                              <img src={logoLight} alt="" height="40" />
                            </Link>
                          </div>
                          <p className='mt-3 fs-15 fw-medium'>
                            GP Dashboard
                          </p>
                        </div>
                      </Col>
                    </Row>

                    <Row className='justify-content-center'>
                      <Col md={8} lg={6} xl={5}>
                        <Card className='mt-4'>
                          <CardBody className='p-4 text-center'>
                            <div className='avatar-lg mx-auto mt-2'>
                              <div className='avatar-title bg-light text-success display-3 rounded-circle'>
                                <i className='ri-checkbox-circle-fill'></i>
                              </div>
                            </div>
                            <div className='mt-4 pt-2'>
                              <h4>Account Verified</h4>
                              {/* <p className='text-muted mx-4'>
                                Verification successful
                              </p> */}
                              <div className='mt-4'>
                                <Link
                                  to='/login'
                                  className='btn btn-primary w-100'
                                >
                                  Proceed To Login
                                </Link>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </ParticlesAuth>
            </div>
          ) : (
            <div className='auth-page-wrapper'>
              <ParticlesAuth>
                <div className='auth-page-content'>
                  <Container>
                    <Row>
                      <Col lg={12}>
                        <div className='text-center mt-sm-5 mb-4 text-white'>
                          <div>
                            <Link
                              to='/dashboard'
                              className='d-inline-block auth-logo'
                            >
                              <span className='airgp-logo'>
                                Air<span className='logo-color'>GP</span>
                              </span>
                            </Link>
                          </div>
                          <p className='mt-3 fs-15 fw-medium'>
                            GP Dashboard
                          </p>
                        </div>
                      </Col>
                    </Row>

                    <Row className='justify-content-center'>
                      <Col md={8} lg={6} xl={5}>
                        <Card className='mt-4'>
                          <CardBody className='p-4 text-center'>
                            <div className='avatar-lg mx-auto mt-2'>
                              <div className='avatar-title bg-light text-warning display-3 rounded-circle'>
                                <i className='ri-spam-2-fill'></i>
                              </div>
                            </div>
                            <div className='mt-4 pt-2'>
                              <h4>Failed Account Verification!</h4>
                              <p className='text-muted mx-4'>
                                Kindly give it another try later for
                                verification.
                              </p>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </ParticlesAuth>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  )
}

export default EmailVerificationSuccess
