import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import {
  convertTimeToMinute,
  documentStatusColor,
  getCookie,
  PENSION_FORM_A_TABS
} from '../../utils/constants'
import classnames from 'classnames'
import { pensionServices } from '../../services/pensionServices'
import PaginationV2 from '../../Components/Common/PaginationV2'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { toast } from 'react-toastify'
import SignaturePadModal from '../../Components/Common/SignaturePadModal'
import { accountServices } from '../../services/accountServices'
import fetchPDF from '../../common/fetchPDF'
import SpinnerComponent from '../../Components/Common/Spinner'
import ConfirmationModal from '../../Components/Common/ConfirmationModal'
import { useHistory } from 'react-router-dom'


let LIMIT = 20
const FormA = () => {
  const [topBorderjustifyTab, settopBorderjustifyTab] = useState(0)
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [formAdocs, setFormADocs] = useState([])
  const [page, setPage] = useState(1)
  const [showDegitalSignModal, setShowDigitalSignModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showMarkAsSignModal, setShowMarkAsSignedModal] = useState(false)
  const [markAsSignId, setMarkAsSignId] = useState()

  const token = localStorage.getItem('token')

  useEffect(() => {
    if (topBorderjustifyTab === 0) fetchFormAPdf(page, LIMIT, '', token)
    if (topBorderjustifyTab === 1) fetchFormAPdf(page, LIMIT, 'SIGNED', token)
  }, [topBorderjustifyTab, page])

  const history = useHistory();

  const fetchFormAPdf = (page, limit, status, token) => {
    setFormADocs([])
    setIsLoading(true)
    pensionServices
      .getPensionFormsAandB(page, limit, status, token)
      .then(resp => {
        if (resp.success) {
          setFormADocs(resp.data)
          setTotalPageCount(resp?.totalPages === 0 ? 1 : resp?.totalPages)
        }else{
          if(resp?.message === "Unauthorised"){
            localStorage.clear();
            history.push('/login');
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchPatchAddSignature = img => {
    const file = DataURIToBlob(img)
    const formData = new FormData()
    formData.append('avatar', file, 'signature.png')

    function DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(',')
      const byteString =
        splitDataURI[0].indexOf('base64') >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1])
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

      const ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

      return new Blob([ia], { type: mimeString })
    }

    setIsLoading(true)
    pensionServices
      .patchAddSignature(formData, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          setShowDigitalSignModal(false)
        } else {
          setShowDigitalSignModal(false)
          toast.error(resp.message)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchPostAddManualSignOnformA = (formId, token) => {
    pensionServices
      .postAddSignatureManually(formId, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          fetchFormAPdf(page, LIMIT, '', token)
          setShowMarkAsSignedModal(false)
        } else {
          toast.error(resp.message)
        }
      })
      .catch(err => console.log(err))
  }

  const handleDownloadDocument = (pdf_uuid, status, originalFileName) => {
    setIsLoading(true)
    accountServices.downloadDocument(pdf_uuid, status, token)
      .then(resp => fetchPDF(resp, originalFileName))
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  return (
    <React.Fragment>
      {isLoading && <SpinnerComponent />}
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Pension list - Form A' pageTitle='Pension' />
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardHeader className='mb-0 pb-0'>


                      <Nav tabs className='nav-tabs'>
                        {PENSION_FORM_A_TABS.map((tab, index) => {
                          return (
                            <NavItem key={index}>
                              <NavLink
                                style={{ cursor: 'pointer' }}
                                className={classnames({
                                  active: topBorderjustifyTab === index
                                })}
                                onClick={() => {
                                  settopBorderjustifyTab(index)
                                  setPage(1)
                                }}
                              >
                                {tab}
                              </NavLink>
                            </NavItem>
                          )
                        })}
                      </Nav>
                    </CardHeader>
                    <CardBody>
                      <TabContent
                        activeTab={topBorderjustifyTab}
                        className='text-muted mb-4'
                      >
                        <TabPane
                          tabId={topBorderjustifyTab}
                          id='nav-border-top-home'
                        >
                          {/* ***desktop view*** */}
                          <div className='live-preview   d-none d-xl-block'>
                            <div className='table-responsive table-card'>
                              <Table className='align-middle table-hover  table-nowrap mb-0'>
                                <thead className='text-muted'>
                                  <tr>
                                    <th></th>
                                    <th scope='col'>ID</th>
                                    <th scope='col'>Organisation</th>
                                    <th scope='col'>Date & Time</th>
                                    <th scope='col'>Fee</th>
                                    <th scope='col'>Status</th>
                                    <th scope='col'>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {formAdocs?.length !== 0 ? (
                                    formAdocs?.map((item, idx) => {

                                      let expenceTotalAmt = 0

                                      for (let i = 0; i < item?.session?.expenses?.length; i++) {
                                        expenceTotalAmt += +item.session.expenses[i].amount
                                      }

                                      return (
                                        <tr key={item?.uuid}>
                                          <td></td>
                                          <td>
                                            {/* <Link
                                              to='#'
                                            > */}
                                              #
                                              {item?.uuid.substring(
                                                0,
                                                5
                                              ) || 'NA'}
                                            {/* </Link> */}
                                          </td>
                                          <td className='text-truncate' style={{maxWidth: 220}}>
                                                  {item?.session?.organisation
                                                    ?.name || 'NA'}                                            
                                              <p className='text-muted small mb-0'>
                                                    {item?.session?.organisation
                                                      ?.postcode || 'NA'}
                                                      </p>
                                          </td>
                                          <td>
                                                  {`${moment(
                                                    item?.session?.start_time
                                                  ).format(
                                                    'ddd, Do MMMM YYYY'
                                                  )}` || 'NA'}
                                                <p className='text-muted small mb-0'>
                                                  <span className='fw-medium'>
                                                    {`${moment(
                                                      item?.session?.start_time
                                                    ).format(
                                                      'LT'
                                                    )}` || 'NA'}{' '}
                                                    -
                                                    {`${moment(
                                                      item?.session?.end_time
                                                    ).format(
                                                      'LT'
                                                    )}` || 'NA'}{' '}
                                                    ({' '}{item?.session?.unpaid_break_duration || ' No break'}{' '}
                                                    {item?.session?.unpaid_break_duration !== 0 ? "mins break" : ""}{' '})
                                                  </span>
                                                </p>
                                          </td>
                                          <td>
                                                  £
                                                  {(
                                                    (item?.session?.hourly_rate /
                                                      60) *
                                                    convertTimeToMinute(
                                                      item?.session?.start_time,
                                                      item?.session?.end_time,
                                                      item?.session?.unpaid_break_duration
                                                    ) +
                                                    Number(
                                                      item?.session?.client_bonus_amount
                                                    ) + Number(expenceTotalAmt)
                                                  )?.toFixed(2)}
                                                <p className='text-muted small mb-0'>
                                                  <span className='fw-medium'>
                                                    £
                                                    {(
                                                      (item?.session?.hourly_rate /
                                                        60) *
                                                      convertTimeToMinute(
                                                        item?.session?.start_time,
                                                        item?.session?.end_time,
                                                        item?.session?.unpaid_break_duration
                                                      )
                                                    ).toFixed() ||
                                                      'NA'}{' '}
                                                    (£
                                                    {item?.session?.hourly_rate ||
                                                      'NA'}{' '}
                                                    /hour) 
                                                    {Number(item?.session?.client_bonus_amount)?.toFixed() > 0 && (
                                                    <> + £
                                                    {Number(
                                                      item?.session?.client_bonus_amount
                                                    )?.toFixed(2)}{' '}
                                                    bonus</>
                                                     )}
                                                    { expenceTotalAmt !== 0 && <> + £{expenceTotalAmt} Expenses</>}
                                                  </span>
                                                  {item?.session?.pensionable ===
                                                    1 ? (
                                                    <i title='Pensionable' className="ri-file-ppt-line ms-1"></i>
                                                  ) : (
                                                    ''
                                                  )}
                                                </p>
                                          </td>

                                          <td>
                                            <span className={`badge rounded-pill badge-soft-${documentStatusColor(
                                              item?.status || 'NA'
                                            )}`}>{item?.status || 'NA'}</span>
                                          </td>
                                          <td>
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                href='#'
                                                className='btn-soft-primary btn-sm dropdown'
                                                tag='button'
                                              >
                                                <i className='ri-more-fill align-middle'></i>
                                              </DropdownToggle>
                                              <DropdownMenu className='dropdown-menu-end'>
                                                {item?.status ===
                                                  'UNSIGNED' && (
                                                    <DropdownItem
                                                      onClick={() => {
                                                        setShowMarkAsSignedModal(true)
                                                        setMarkAsSignId(item.uuid)
                                                      }}
                                                    >
                                                      <i className='ri-clipboard-fill align-bottom me-2 text-muted'></i>{' '}
                                                      Mark as signed
                                                    </DropdownItem>
                                                  )}
                                                <DropdownItem
                                                  onClick={() =>
                                                    handleDownloadDocument(item.uuid, "pensionforma", item?.form_a_file_path?.split('/')[2])
                                                  }
                                                >
                                                  <i className='ri-download-line align-bottom me-2 text-muted'></i>{' '}
                                                  Download Form_A
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </td>
                                        </tr>
                                      )
                                    })
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan='11'
                                        className='text-center text-muted'
                                      >
                                        No data found
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>

                          {/* ***mobile view*** */}
                          <div className='live-preview d-xl-none'>

                            {formAdocs?.length !== 0 ? (
                              formAdocs?.map((item, idx) => {



                                let expenceTotalAmt = 0

                                for (let i = 0; i < item?.session?.expenses?.length; i++) {
                                  expenceTotalAmt += +item.session.expenses[i].amount
                                }
                                return (
                                  <Card className='d-xl-none rounded-1' key={item?.uuid}>
                                    <CardBody className='text-dark'>


                                      <Row className='pb-2'>
                                        <Col xs={8} className='fw-semibold text-dark'>
                                          {item?.session?.organisation?.name || 'NA'}
                                        </Col>
                                        <Col xs={4} className='text-end'>

                                          <span className={`badge rounded-pill badge-soft-${documentStatusColor(
                                            item?.status || 'NA'
                                          )}`}>{item?.status || 'NA'}</span>
                                        </Col>
                                      </Row>



                                      <Row>
                                        <Col>
                                          {`${moment(
                                            item?.session?.start_time
                                          ).format(
                                            'dddd'
                                          )}` || 'NA'}
                                        </Col>
                                        <Col className='text-end fw-semibold text-primary fs-20 lh-1'>
                                        £
                                                  {(
                                                    (item?.session?.hourly_rate /
                                                      60) *
                                                    convertTimeToMinute(
                                                      item?.session?.start_time,
                                                      item?.session?.end_time,
                                                      item?.session?.unpaid_break_duration
                                                    ) +
                                                    Number(
                                                      item?.session?.client_bonus_amount
                                                    ) + Number(expenceTotalAmt)
                                                  )?.toFixed(2)}

                                        </Col>
                                      </Row>




                                      <Row>
                                        <Col xs={9}>
                                          {`${moment(
                                            item?.session?.start_time
                                          ).format(
                                            'Do MMMM YYYY'
                                          )}` || 'NA'}
                                        </Col>
                                        <Col xs={3} className='text-muted text-end '>
                                          £
                                          {(
                                            (item?.session?.hourly_rate /
                                              60) *
                                            convertTimeToMinute(
                                              item?.session?.start_time,
                                              item?.session?.end_time,
                                              item?.session?.unpaid_break_duration
                                            )
                                          ).toFixed() ||
                                            'NA'}
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col xs={8}>

                                          {`${moment(
                                            item?.session?.start_time
                                          ).format('LT')}` || 'NA'}{' '}
                                          -
                                          {`${moment(
                                            item?.session?.end_time
                                          ).format('LT')}` || 'NA'}{' '}
                                        </Col>
                                        <Col xs={4} className='text-end text-muted'>
                                          £
                                          {item?.session?.hourly_rate ||
                                            'NA'}
                                          /h
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col>
                                          {item?.session?.unpaid_break_duration || ' No break'}{' '}
                                          {item?.session?.unpaid_break_duration !== 0 ? "mins break" : ""}
                                        </Col>
                                        <Col className='text-end text-muted'>
                                        {Number(item?.session?.client_bonus_amount)?.toFixed() > 0 && (
                                                    <> £
                                                    {Number(
                                                      item?.session?.client_bonus_amount
                                                    )?.toFixed(2)}{' '}
                                                    bonus</>
                                                     )}
                                          
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col>
                                        </Col>
                                        <Col className='text-end text-muted'>
                                        { expenceTotalAmt !== 0 && <> + £{expenceTotalAmt} Expenses</>}
                                        </Col>
                                      </Row>




                                      <div className='d-flex flex-row justify-content-end small gap-1 my-1'>


                                        <div><span className='badge rounded-pill badge-soft-danger'>
                                          #{item?.session?.uuid.substring(0, 5) || 'NA'}</span></div>

                                        {item?.session?.pensionable ===
                                          1 ? (
                                          <div> <span className='badge rounded-pill badge-soft-success'>Pensionable</span></div>
                                        ) : (
                                          ''
                                        )}

                                      </div>

                                    </CardBody>

                                    <CardFooter>
                                      <div className='hstack gap-2 pt-1 float-end'>
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            href='#'
                                            className='btn-soft-primary btn-sm dropdown'
                                            tag='button'
                                          >
                                            <i className='ri-more-fill align-middle'></i>
                                          </DropdownToggle>
                                          <DropdownMenu className='dropdown-menu-end'>
                                            {item?.status ===
                                              'UNSIGNED' && (
                                                <DropdownItem
                                                  onClick={() =>
                                                    fetchPostAddManualSignOnformA(
                                                      item.uuid,
                                                      token
                                                    )
                                                  }
                                                >
                                                  <i className='ri-clipboard-fill align-bottom me-2 text-muted'></i>{' '}
                                                  Mark as signed
                                                </DropdownItem>
                                              )}

                                            <DropdownItem
                                              onClick={() =>
                                                handleDownloadDocument(item.uuid, "pensionforma", item?.form_a_file_path?.split('/')[2])
                                              }
                                            >
                                              <i className='ri-download-line align-bottom me-2 text-muted'></i>{' '}
                                              Download Form_A
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </div>
                                    </CardFooter>
                                  </Card>
                                )
                              })
                            ) : (
                              <div className='d-xl-none text-center mt-4'>
                                <p>No data found</p>
                              </div>
                            )}
                          </div>

                        </TabPane>
                      </TabContent>

                      <PaginationV2
                        totalPageCount={
                          totalPageCount === 0 ? 1 : totalPageCount
                        }
                        previousDisabled={page === 1}
                        nextDisabled={totalPageCount === page ? true : false}
                        previousPageCall={() => setPage(page - 1)}
                        currentPage={page}
                        nextPageCalls={() => setPage(page + 1)}
                        onPageInputChange={({ target: { value } }) => {
                          var regex = new RegExp(`^[1-${totalPageCount}]$`)
                          if (value <= totalPageCount && value !== 0) {
                            setPage(value)
                          }
                        }}
                      />

                      <SignaturePadModal
                        handleClose={() => setShowDigitalSignModal(false)}
                        tog_standard={() => {
                          setShowDigitalSignModal(!showDegitalSignModal)
                        }}
                        open={showDegitalSignModal}
                        handleSaveChanges={fetchPatchAddSignature}
                      />

                      <ConfirmationModal
                        show={showMarkAsSignModal}
                        onDeleteClick={() => {
                          fetchPostAddManualSignOnformA(
                            markAsSignId,
                            token
                          )
                        }}
                        onCloseClick={() => setShowMarkAsSignedModal(false)}
                        deleteMsg="Are you sure you want to mark as sign on this session?"
                        btnName="Confirm"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormA
