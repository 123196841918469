import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// import BreadCrumb from "../../Components/Common/BreadCrumb";
// import FeatherIcon from "feather-icons-react";

import Navbar from '../Landing/navbar'
import Footer from '../Landing/footer'

const PrivacyPolicy = () => {
  document.title = "airGP | Privacy Policy";
  return (
    <React.Fragment>
      <Navbar />
      <div className="page-content">
        <Container>
          <Row className="justify-content-center  mt-3">
            <Col lg={12}>
              <Card className="rounded-top">
              <div className="position-relative bg-airgp rounded-top">
                  <CardBody className="pt-4 mt-2">
                    <div className="text-center">
                      <h3 className="text-white">Privacy Policy</h3>
                      <p className="mb-0 text-light">
                        Last update: 16 Sept, 2022
                      </p>
                    </div>
                  </CardBody>
                </div>
                <CardBody className="p-4">
                  <div className="text-muted">
                      <h5>Privacy Policy for airGP</h5>
                      <p>Thank you for choosing airGP! At airGP, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our website, airgp.com, and the web tracking software Pagesense (collectively referred to as "the Service"). By accessing or using the Service, you agree to the practices described in this Privacy Policy.</p>
                  
                      <p>This Privacy Policy explains how airGP ("we," "us," or "our") collects, uses, and safeguards your personal information when you visit our website and use our services. Please read this Privacy Policy carefully to understand how we handle your data.</p>

                      <h5>Information We Collect</h5>

                      <p>When you visit airGP, we may collect the following types of information:</p>

                      <ul>
                          <li><strong>Personal Information:</strong> We may collect personal information such as your name, email address, and contact details when you provide them voluntarily, for example, when you subscribe to our newsletter or contact us through our website.</li>
                          <li><strong>Usage Information:</strong> We use web tracking software called Pagesense to collect non-personal information about your interactions with our website, including but not limited to pages visited, browser type, IP address, and device information. This information helps us improve our website and services.</li>
                          <li><strong>Cookies:</strong> We use cookies and similar tracking technologies to enhance your browsing experience and provide personalized content. You can manage your cookie preferences through your browser settings.</li>
                      </ul>

                      <h5>How We Use Your Information</h5>

                      <p>We may use the information we collect for the following purposes:</p>

                      <ul>
                          <li>To provide and maintain our services.</li>
                          <li>To send you updates, newsletters, and promotional materials.</li>
                          <li>To improve and optimize our website and user experience.</li>
                          <li>To analyze trends, track usage, and gather demographic information.</li>
                          <li>To comply with legal obligations and protect our rights.</li>
                      </ul>

                      <h5>Sharing Your Information</h5>

                      <p>We may share your personal information with third-party service providers that assist us in operating our website and providing our services. We do not sell, trade, or rent your personal information to third parties for marketing purposes.</p>

                      <h5>Your Choices</h5>

                      <p>You have the right to:</p>

                      <ul>
                          <li>Access, correct, or delete your personal information.</li>
                          <li>Opt-out of receiving marketing communications from us.</li>
                          <li>Disable cookies through your browser settings.</li>
                      </ul>

                      <h5>Security</h5>

                      <p>We take reasonable measures to protect your personal information from unauthorized access and disclosure. However, no method of transmission over the internet or electronic storage is entirely secure.</p>

                      <h5>Changes to this Privacy Policy</h5>

                      <p>We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will post any updates on this page.</p>

                      <h5>Contact Us</h5>

                      <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:contact@airgp.co.uk">contact@airgp.co.uk</a></p>


                  
                  
                  
                  
                  
                  
                  
                  
                  
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default PrivacyPolicy;
