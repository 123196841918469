import React, { useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useEffect } from 'react'

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip
} from 'reactstrap'

import parse from 'html-react-parser'

import BreadCrumb from '../../Components/Common/BreadCrumb'
import { scheduleServices } from '../../services/scheduleServices'
import {
  ADD_MANUAL_SESSION_VALUES_,
  convertTimeToMinute,
  getCookie,
  tokenDecode
} from '../../utils/constants'
import ClientSessionEditModal from './ClientSessionEditModal'
import ConfirmationModal from './ConfirmationModal'
import Loader from './Loader'

import { toast } from 'react-toastify'
import moment from 'moment'
import io from 'socket.io-client'
import { socket } from '../../socket/socket'
import { accountServices } from '../../services/accountServices'


// const socket = io.connect(process.env.REACT_APP_SOCKET_BASE_URL)

function SessionDetailsPageNew(props) {
  document.title = 'Session Details | airGP'
  const [sessionDetails, setSessionDetails] = useState()
  const [addManualSessionValues, setAddManualSessionValues] = useState(
    ADD_MANUAL_SESSION_VALUES_
  )
  const [modal, setModal] = useState(false)
  const [searchOrganisationText, setSearchOrganisationText] = useState(null)
  const [orgDetails, setOrgDetails] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [componentReload, setComponentReload] = useState(0)
  const [deleteModal, setDeleteModal] = useState(false)
  const [appliedModal, setShowAppliedModal] = useState(false)
  const [showWithdrawn, setShowWithdrawnModal] = useState(false)
  const [showApplyModal, setShowApplyModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [userSessionData, setUserSessionData] = useState()
  const [user, setUser] = useState();


  const history = useHistory()
  let { id, name } = useParams()
  const token = localStorage.getItem('token')

  useEffect(() => {
    window.onpopstate = function () {
      history.go(1);
    }
  }, [])

  useEffect(() => {
    if (name === 'manual') fetchUserByIdSessions(id, token)
    fetchOrganisation('', 1, 20, token)
  }, [])

  useEffect(() => {
    if (name === 'manual') fetchUserByIdSessions(id, token)
    fetchOrganisation('', 1, 20, token)
  }, [componentReload])

  useEffect(() => {
    if (name === 'PM') fetchPMSessionByID(id, token)
  }, [])

  useEffect(()=>{
    fetchUserProfileData(token);
  }, []);

  const fetchUserByIdSessions = (id, token) => {
    scheduleServices.getUserSessionById(id, token).then(resp => {
      if (resp.success) {
        setUserSessionData(resp?.data)
        setSessionDetails(resp?.data?.session)
        let copyiedObj = { ...addManualSessionValues }
        copyiedObj.organisation = resp?.data?.session?.organisation?.name
        copyiedObj.startTime = moment(resp?.data?.session?.start_time).format(
          'HH:mm'
        )
        copyiedObj.endTime = moment(resp?.data?.session?.end_time).format(
          'HH:mm'
        )
        copyiedObj.fee = resp?.data?.session?.fee
        copyiedObj.organisation = resp?.data?.session?.organisation?.name
        setAddManualSessionValues(copyiedObj)
      }
    })
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const handleScroll = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      setPageCount(pageCount + 1)
      fetchOrganisation('', pageCount, 20, token)
    }
  }

  const fetchOrganisation = (param, page, limit, token) => {
    scheduleServices
      .getOrganisationsDetails(param, page, limit, token)
      .then(res => {
        if (res.success) {
          let arr = [...orgDetails]
          res.data?.map(_ =>
            arr.push({ value: _.uuid, label: _.organisation_name })
          )
          setOrgDetails(arr)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchPMSessionByID = (id, token) => {
    scheduleServices
      .getPmSessionsById(id, token)
      .then(resp => {
        if (resp.success) {
          setSessionDetails(resp?.session)
        }
      })
      .catch(err => console.log(err))
  }

  const editManualSession = () => {
    scheduleServices
      .updateManualSession(
        sessionDetails.uuid,
        {
          organisation_name: addManualSessionValues.organisation,
          session_fee: addManualSessionValues.fee,
          start_time: `${moment(sessionDetails.start_time).format(
            'YYYY-MM-DD'
          )} ${addManualSessionValues.startTime}:00`,
          end_time: `${moment(sessionDetails.end_time).format('YYYY-MM-DD')} ${addManualSessionValues.endTime
            }:00`
        },
        token
      )
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          toggle()
          setComponentReload(componentReload + 1)
        } else {
          toast.error(resp.message || 'Something went wrong, Please try again.')
        }
      })
  }

  const handleDeleteEvent = () => {
    setIsLoading(true)
    scheduleServices
      .deleteUserSession(id, token)
      .then(resp => {
        if (resp === 204) {
          toast.success('Session deleted')
          history.push('/')
        } else {
          toast.error('session does not exist.')
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchCancelApi = () => {
    scheduleServices
      .cancelAppliedSession(id, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          history.push('/')
        } else {
          toast.error(resp.message)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchUserProfileData = token => {
    accountServices
      .getUserProfileDetails(token)
      .then(resp => {
        if (resp.data && resp.data.user) {
          setUser(resp?.data?.user)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchWithdrawnSessionClient = () => {
    socket.emit('withdraw-session', {
      pm_id: userSessionData?.session?.user_id,
      withdrawn_by_pm: false,
      withdrawn_by_client: true,
      content: 'withdrawn',
      sender: {
        firstName: tokenDecode(token).firstName,
        lastName: tokenDecode(token).lastName,
        title: tokenDecode(token).title
      },
      session: {
        start_time: userSessionData?.session?.start_time,
        end_time: userSessionData?.session?.end_time
      },
      orgId: userSessionData?.session?.organisation ? userSessionData?.session?.organisation?.uuid : null,
    })

    scheduleServices
      .deleteSessionClient({ sessionId: sessionDetails.uuid }, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          setShowWithdrawnModal(false)
          history.push('/')
        } else {
          setShowWithdrawnModal(false)
          toast.error(resp.message)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchApplyBtnHandler = () => {
    scheduleServices
      .postApplySession(
        {
          session_uuid: sessionDetails.uuid
        },
        token
      )
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          setShowApplyModal(false)
        } else {
          setShowApplyModal(false)
          toast.error(resp.message)
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <div className='page-content'>
      <Container fluid>
        {/* <BreadCrumb
          title='Session Details'
          pageTitle='Schedule'
          to='/'
        /> */}
        {name === 'manual' && (
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className='gx-lg-5'>
                    <Col xl={12}>
                      <div className='mt-xl-0 mt-5 p-5'>
                        <div className='d-flex'>
                          <div className='flex-grow-1'>
                            <h4>
                              {sessionDetails?.organisation?.name || 'NA'}{' '}
                              {userSessionData?.Session_statuses?.slice(-1)[0]
                                ?.status === 'Applied' && (
                                  <button
                                    type='button'
                                    className='btn btn-warning btn-sm m-3'
                                    onClick={() =>
                                      history.push(
                                        `/chat?id=${sessionDetails?.organisation?.uuid}`
                                      )
                                    }
                                  >
                                    <i className='ri-chat-1-fill align-bottom me-1'></i>
                                    Chat
                                  </button>
                                )}
                              {userSessionData?.Session_statuses?.slice(-1)[0]
                                ?.status === 'Booked' && (
                                  <button
                                    type='button'
                                    className='btn btn-warning btn-sm m-3'
                                    onClick={() =>
                                      history.push(
                                        `/chat?id=${sessionDetails?.organisation?.uuid}`
                                      )
                                    }
                                  >
                                    <i className='ri-chat-1-fill align-bottom me-1'></i>
                                    Chat
                                  </button>
                                )}
                              {userSessionData?.Session_statuses?.slice(-1)[0]
                                ?.status === 'Worked' && (
                                  <button
                                    type='button'
                                    className='btn btn-warning btn-sm m-3'
                                    onClick={() =>
                                      history.push(
                                        `/chat?id=${sessionDetails?.organisation?.uuid}`
                                      )
                                    }
                                  >
                                    <i className='ri-chat-1-fill align-bottom me-1'></i>
                                    Chat
                                  </button>
                                )}
                            </h4>
                            <div className='hstack gap-3 flex-wrap'>
                              <div className='text-muted'>
                                Date :{' '}
                                <span className='text-body fw-medium'>
                                  {`${moment(sessionDetails?.start_time).format(
                                    'll'
                                  )}` || 'NA'}
                                </span>
                              </div>
                              <div className='vr'></div>
                              <div className='text-muted'>
                                Time :{' '}
                                <span className='text-body fw-medium'>
                                  {`${moment(sessionDetails?.start_time).format(
                                    'LT'
                                  )}` || '00:00'}{' '}
                                  to{' '}
                                  {`${moment(sessionDetails?.end_time).format(
                                    'LT'
                                  )}` || 'NA'}
                                </span>
                              </div>
                              <div className='vr'></div>
                              <div className='text-muted'>
                                Break Duration :{' '}
                                <span className='text-body fw-medium'>
                                  {`${sessionDetails?.unpaid_break_duration}` ||
                                    '0'}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className='flex-shrink-0'></div>
                        </div>
                        <Row className='mt-4'>
                          {sessionDetails?.fee !== null && (
                            <Col lg={3} sm={6}>
                              <div className='p-2 border border-dashed rounded'>
                                <div className='d-flex align-items-center'>
                                  <div className='avatar-sm me-2'>
                                    <div className='avatar-title rounded bg-transparent text-success fs-24'>
                                      <i className='ri-money-pound-circle-fill'></i>
                                    </div>
                                  </div>
                                  <div className='flex-grow-1'>
                                    <p className='text-muted mb-1'>Fees :</p>
                                    <h5 className='mb-0'>
                                      {sessionDetails?.fee || '0'}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          )}

                          <Col lg={3} sm={6}>
                            <div className='p-2 border border-dashed rounded'>
                              <div className='d-flex align-items-center'>
                                <div className='avatar-sm me-2'>
                                  <div className='avatar-title rounded bg-transparent text-success fs-24'>
                                    <i className='ri-money-pound-circle-fill'></i>
                                  </div>
                                </div>
                                <div className='flex-grow-1'>
                                  <p className='text-muted mb-1'>
                                    Hourly Rate :
                                  </p>
                                  <h5 className='mb-0'>
                                    {sessionDetails?.hourly_rate || '0'}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={3} sm={6}>
                            <div className='p-2 border border-dashed rounded'>
                              <div className='d-flex align-items-center'>
                                <div className='avatar-sm me-2'>
                                  <div className='avatar-title rounded bg-transparent text-success fs-24'>
                                    <i className='ri-money-pound-circle-fill'></i>
                                  </div>
                                </div>
                                <div className='flex-grow-1'>
                                  <p className='text-muted mb-1'>Bonus :</p>
                                  <h5 className='mb-0'>
                                    {+sessionDetails?.client_bonus_amount ||
                                      '0'}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={3} sm={6}>
                            <div className='p-2 border border-dashed rounded'>
                              <div className='d-flex align-items-center'>
                                <div className='avatar-sm me-2'>
                                  <div className='avatar-title rounded bg-transparent text-success fs-24'>
                                    <i className='ri-money-pound-circle-fill'></i>
                                  </div>
                                </div>
                                <div className='flex-grow-1'>
                                  <p className='text-muted mb-1'>Total :</p>
                                  <h5 className='mb-0'>
                                    {sessionDetails?.session_source === 'manual'
                                      ? `${sessionDetails?.fee}`
                                      : (
                                        (sessionDetails?.hourly_rate / 60) *
                                        convertTimeToMinute(
                                          sessionDetails?.start_time,
                                          sessionDetails?.end_time,
                                          sessionDetails?.unpaid_break_duration
                                        ) +
                                        +sessionDetails?.client_bonus_amount
                                      ).toFixed() || 'NA'}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {sessionDetails?.session_source ===
                            'market_place' && (
                              <div className='mt-4 text-muted'>
                                <h5 className='fs-14'>Description :</h5>
                                <p>
                                  {sessionDetails?.session_description ? parse(sessionDetails?.session_description) : 'NA'}
                                </p>
                              </div>
                            )}
                        </Row>

                        <Row>
                          {sessionDetails?.organisation?.type === 'db' && (
                            <Col sm={4}>
                              <div className='mt-3'>
                                <h5 className='fs-14'>Address :</h5>
                                <ul className='list-unstyled product-desc-list'>
                                  <li className='py-1'>
                                    {sessionDetails?.organisation
                                      ?.address_line_1 || 'NA'}
                                  </li>
                                  <li className='py-1'>
                                    {sessionDetails?.organisation
                                      ?.address_line_2 || 'NA'}
                                  </li>
                                  <li className='py-1'>
                                    {sessionDetails?.organisation
                                      ?.address_line_3 || 'NA'}
                                  </li>
                                  <li className='py-1'>
                                    {sessionDetails?.organisation?.postcode ||
                                      'NA'}
                                  </li>
                                </ul>
                              </div>
                            </Col>
                          )}
                          {sessionDetails?.features && (
                            <Col sm={4}>
                              <div className='mt-3'>
                                <h5 className='fs-14'>Features :</h5>
                                <ul className='list-unstyled'>
                                  {sessionDetails?.features?.map((_, index) => {
                                    return (
                                      <li className='py-1' key={index}>
                                        <i className='mdi mdi-circle-medium me-1 text-muted align-middle'></i>{' '}
                                        {_?.name}
                                      </li>
                                    )
                                  })}
                                </ul>
                              </div>
                            </Col>
                          )}
                          {sessionDetails?.features && (
                            <Col sm={4}>
                              <div className='mt-3'>
                                <h5 className='fs-14'>It system :</h5>
                                <ul className='list-unstyled'>
                                  {sessionDetails?.users[0]?.gp_soc?.map(
                                    (_, index) => {
                                      return (
                                        <li className='py-1' key={index}>
                                          <i className='mdi mdi-circle-medium me-1 text-muted align-middle'></i>{' '}
                                          {_?.name}
                                        </li>
                                      )
                                    }
                                  )}
                                </ul>
                              </div>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col lg={3}>
                            {userSessionData?.Session_statuses?.slice(-1)[0]
                              ?.status === 'Manual' && (
                                <div className='margin-top'>
                                  <Link
                                    to='#'
                                    className='btn btn-primary margin-right'
                                    onClick={() => toggle()}
                                    id='tooltipBottom'
                                  >
                                    <i className='ri-download-2-line align-bottom '></i>{' '}
                                    Edit
                                  </Link>
                                  <Link
                                    to='#'
                                    className='btn btn-danger mr-2'
                                    id='tooltipDel'
                                    onClick={() => setDeleteModal(true)}
                                  >
                                    <i className='ri-delete-bin-6-line align-bottom me-1'></i>{' '}
                                    Delete
                                  </Link>

                                  <UncontrolledTooltip
                                    placement='bottom'
                                    target='tooltipBottom'
                                  >
                                    Edit Current Session
                                  </UncontrolledTooltip>

                                  <UncontrolledTooltip
                                    placement='bottom'
                                    target='tooltipDel'
                                  >
                                    Delete Current Session
                                  </UncontrolledTooltip>
                                </div>
                              )}

                            {userSessionData?.Session_statuses?.slice(-1)[0]
                              ?.status === 'Applied' && (
                                <div className='margin-top'>
                                  <Link
                                    to='#'
                                    className='btn btn-danger'
                                    id='tooltipDel'
                                    onClick={() => setShowAppliedModal(true)}
                                  >
                                    <i className='ri-delete-bin-6-line align-bottom me-1'></i>{' '}
                                    Cancel
                                  </Link>
                                  <UncontrolledTooltip
                                    placement='bottom'
                                    target='tooltipDel'
                                  >
                                    Cancel Current Session
                                  </UncontrolledTooltip>
                                </div>
                              )}

                            {userSessionData?.Session_statuses?.slice(-1)[0]
                              ?.status === 'Booked' && (
                                <div className='margin-top'>
                                  <Link
                                    to='#'
                                    className='btn btn-danger'
                                    id='tooltipDel'
                                    onClick={() => setShowWithdrawnModal(true)}
                                  >
                                    <i className='ri-delete-bin-6-line align-bottom me-1'></i>{' '}
                                    Withdrawn
                                  </Link>
                                  <UncontrolledTooltip
                                    placement='bottom'
                                    target='tooltipDel'
                                  >
                                    Withdrawn Current Session
                                  </UncontrolledTooltip>
                                </div>
                              )}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {name === 'PM' && (
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className='gx-lg-5'>
                    <Col xl={12}>
                      <div className='mt-xl-0 mt-5 p-5'>
                        <div className='d-flex'>
                          <div className='flex-grow-1'>
                            <h4>
                              {sessionDetails?.organisation?.name || 'NA'}
                            </h4>
                            <div className='hstack gap-3 flex-wrap'>
                              <div className='text-muted'>
                                Date :{' '}
                                <span className='text-body fw-medium'>
                                  {`${moment(sessionDetails?.start_time).format(
                                    'll'
                                  )}` || 'NA'}
                                </span>
                              </div>
                              <div className='vr'></div>
                              <div className='text-muted'>
                                Time :{' '}
                                <span className='text-body fw-medium'>
                                  {`${moment(sessionDetails?.start_time).format(
                                    'LT'
                                  )}` || 'NA'}{' '}
                                  to{' '}
                                  {`${moment(sessionDetails?.end_time).format(
                                    'LT'
                                  )}` || 'NA'}
                                </span>
                              </div>
                              <div className='vr'></div>
                              <div className='text-muted'>
                                Break Duration :{' '}
                                <span className='text-body fw-medium'>
                                  {`${sessionDetails?.unpaid_break_duration}` ||
                                    'NA'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Row className='mt-4'>
                          <Col lg={3} sm={6}>
                            <div className='p-2 border border-dashed rounded'>
                              <div className='d-flex align-items-center'>
                                <div className='avatar-sm me-2'>
                                  <div className='avatar-title rounded bg-transparent text-success fs-24'>
                                    <i className='ri-money-pound-circle-fill'></i>
                                  </div>
                                </div>
                                <div className='flex-grow-1'>
                                  <p className='text-muted mb-1'>Fees :</p>
                                  <h5 className='mb-0'>
                                    {sessionDetails?.fee || '0'}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col lg={3} sm={6}>
                            <div className='p-2 border border-dashed rounded'>
                              <div className='d-flex align-items-center'>
                                <div className='avatar-sm me-2'>
                                  <div className='avatar-title rounded bg-transparent text-success fs-24'>
                                    <i className='ri-money-pound-circle-fill'></i>
                                  </div>
                                </div>
                                <div className='flex-grow-1'>
                                  <p className='text-muted mb-1'>
                                    Hourly Rate :
                                  </p>
                                  <h5 className='mb-0'>
                                    {sessionDetails?.hourly_rate || '0'}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={3} sm={6}>
                            <div className='p-2 border border-dashed rounded'>
                              <div className='d-flex align-items-center'>
                                <div className='avatar-sm me-2'>
                                  <div className='avatar-title rounded bg-transparent text-success fs-24'>
                                    <i className='ri-money-pound-circle-fill'></i>
                                  </div>
                                </div>
                                <div className='flex-grow-1'>
                                  <p className='text-muted mb-1'>Total :</p>
                                  <h5 className='mb-0'>
                                    {(
                                      (sessionDetails?.hourly_rate / 60) *
                                      convertTimeToMinute(
                                        sessionDetails?.start_time,
                                        sessionDetails?.end_time,
                                        sessionDetails?.unpaid_break_duration
                                      )
                                    ).toFixed() || '0'}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <div className='mt-4 text-muted'>
                          <h5 className='fs-14'>Description :</h5>
                          <p>{sessionDetails?.session_description ? parse(sessionDetails?.session_description) : 'NA'}</p>
                        </div>

                        <Row>
                          {sessionDetails?.organisation?.type === 'db' && (
                            <Col sm={4}>
                              <div className='mt-3'>
                                <h5 className='fs-14'>Address :</h5>
                                <ul className='list-unstyled product-desc-list'>
                                  <li className='py-1'>
                                    {sessionDetails?.organisation
                                      ?.address_line_1 || 'NA'}
                                  </li>
                                  <li className='py-1'>
                                    {sessionDetails?.organisation
                                      ?.address_line_2 || 'NA'}
                                  </li>
                                  <li className='py-1'>
                                    {sessionDetails?.organisation
                                      ?.address_line_3 || 'NA'}
                                  </li>
                                  <li className='py-1'>
                                    {sessionDetails?.organisation?.postcode ||
                                      'NA'}
                                  </li>
                                </ul>
                              </div>
                            </Col>
                          )}
                          {sessionDetails?.features?.length !== 0 ? (
                            <Col sm={4}>
                              <div className='mt-3'>
                                <h5 className='fs-14'>Features :</h5>
                                <ul className='list-unstyled'>
                                  {sessionDetails?.features?.map((_, index) => {
                                    return (
                                      <li className='py-1' key={index}>
                                        <i className='mdi mdi-circle-medium me-1 text-muted align-middle'></i>{' '}
                                        {_?.name}
                                      </li>
                                    )
                                  })}
                                </ul>
                              </div>
                            </Col>
                          ) : (
                            ''
                          )}
                        </Row>
                        <div>
                          <Link
                            to='/book-new'
                            className='btn btn-danger ml-2'
                            id='tooltipDel'
                          // onClick={() => setShowApplyModal(true)}
                          >
                            {/* <i className='ri-delete-bin-6-line align-bottom me-1'></i>{' '} */}
                            Apply
                          </Link>
                          <UncontrolledTooltip
                            placement='bottom'
                            target='tooltipDel'
                          >
                            Cancel Current Session
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        <ClientSessionEditModal
          open={modal}
          onSelectInputChange={e => {
            setSearchOrganisationText(e)
          }}
          options={orgDetails}
          handleScroll={handleScroll}
          onSelectChange={e => {
            let copyiedObj = { ...addManualSessionValues }
            copyiedObj.organisation = e?.label
            setAddManualSessionValues(copyiedObj)
          }}
          onStartTimeChange={e => {
            let copyiedObj = { ...addManualSessionValues }
            copyiedObj.startTime = moment(e[0]).format('HH:mm')
            setAddManualSessionValues(copyiedObj)
          }}
          onEndTimeChange={e => {
            let copyiedObj = { ...addManualSessionValues }
            copyiedObj.endTime = moment(e[0]).format('HH:mm')
            setAddManualSessionValues(copyiedObj)
          }}
          onFeeChange={e => {
            let copyiedObj = { ...addManualSessionValues }
            copyiedObj.fee = e.target.value
            setAddManualSessionValues(copyiedObj)
          }}
          toggle={toggle}
          onClickCreateBtn={editManualSession}
          btnName={'Edit'}
          headers={'Edit manual session'}
          startTime={addManualSessionValues?.startTime}
          endTime={addManualSessionValues?.endTime}
          fee={addManualSessionValues?.fee}
          organisationValue={addManualSessionValues?.organisation}
        />

        <ConfirmationModal
          show={deleteModal}
          onDeleteClick={handleDeleteEvent}
          onCloseClick={() => setDeleteModal(false)}
          deleteMsg='Are you sure you want to delete this session ?'
        />

        <ConfirmationModal
          show={appliedModal}
          onDeleteClick={fetchCancelApi}
          onCloseClick={() => setShowAppliedModal(false)}
          deleteMsg='Are you sure you want to cancel this session ?'
          btnName='Cancel'
        />

        <ConfirmationModal
          show={showWithdrawn}
          onDeleteClick={fetchWithdrawnSessionClient}
          onCloseClick={() => setShowWithdrawnModal(false)}
          deleteMsg='Are you sure you want to withdrawn this session ?'
          btnName='Withdraw'
        />

        <ConfirmationModal
          show={showApplyModal}
          onDeleteClick={fetchApplyBtnHandler}
          onCloseClick={() => setShowApplyModal(false)}
          deleteMsg='Are you sure you want to Apply this session ?'
          btnName='Apply'
        />
      </Container>
    </div>
  )
}

export default SessionDetailsPageNew

