import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Signature from 'signature_pad'
import { Button } from 'reactstrap'

export default function SignaturePad ({ handleSave, onCloseClick }) {
  const [signaturePad, setSignaturePad] = useState()
  let signatureRedoArray = []

  const readyPad = () => {
    let wrapper = document.getElementById('signature-pad')
    let canvas = wrapper?.querySelector('canvas')
    canvas.getContext('2d').scale(1, 1)
    let tempSignaturePad = new Signature(canvas, {
      backgroundColor: 'rgb(255, 255, 255)'
    })
    setSignaturePad(tempSignaturePad)
  }

  const handleUndo = () => {
    let signatureRemovedData = []
    let signatureData = signaturePad.toData()
    let signatureRedoData = _.cloneDeep(signatureData) //original data

    if (signatureData.length > 0) {
      signatureData.pop() // remove the last dot or line
      signaturePad.fromData(signatureData)
      signatureRemovedData = signatureRedoData[signatureRedoData.length - 1]
      signatureRedoArray.push(signatureRemovedData)
    }
  }

  const handleRedo = () => {
    if (signatureRedoArray.length !== 0) {
      let values = signaturePad.toData()
      let lastValue = signatureRedoArray[signatureRedoArray.length - 1]
      values.push(lastValue)
      signaturePad.fromData(values)
      signatureRedoArray.pop(lastValue) //remove the redo item from array
    }
  }

  const handleClear = () => {
    signaturePad.clear()
  }

  useEffect(() => {
    readyPad()
  }, [])

  return (
    <div className='App'>
      <div id='signature-pad'>
        <canvas className='signature-canvas'></canvas>
        <div className='sig-btns'>
          <button
            type='button'
            className='btn btn-danger btn-icon mb-2'
            onClick={handleClear}
          >
            <i className='ri-delete-bin-5-line'></i>
          </button>
          <button
            type='button'
            className='btn btn-primary btn-icon t mb-2'
            onClick={handleRedo}
          >
            <i className='ri-arrow-go-forward-line'></i>
          </button>
          <button
            type='button'
            className='btn btn-light btn-icon mb-2'
            onClick={handleUndo}
          >
            <i className='ri-arrow-go-back-line'></i>
          </button>
        </div>
      </div>
      <div className='modal-footer mt-3 justify-content-center'>

        <Button
          color='primary'
          onClick={() => handleSave(signaturePad?.toDataURL())}
        >
          Save changes
        </Button>
        <Button color='light' onClick={onCloseClick}>
          Close
        </Button>
      </div>
    </div>
  )
}
