import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Col, Row, Input, Label, Accordion, AccordionItem, Collapse } from 'reactstrap';
import SignaturePadModal from '../../../Components/Common/SignaturePadModal';
import { pensionServices } from '../../../services/pensionServices';
import classnames from 'classnames';

const SavePensionDetails = ({
    pensionRegion,
    pensionValue,
    setPensionValue,
    setIsLoading,
    pensionPreferences,
    onclickSaveSign
}) => {
    const token = localStorage.getItem('token');
    const [pensionTierContribution, setPensiontierContribution] = useState([]);
    const [pensionPCSE, setPensionPCSE] = useState([]);
    const [showDegitalSignModal, setShowDigitalSignModal] = useState(false);
    const [formAVersions, setFormAVersions] = useState([]);

    useEffect(() => {
        fetchPensionPCSERegionList(token);
        fetchPensionTierContribution(token);
        fetchFormAVersions(token);
    }, []);

    const fetchPensionTierContribution = (token) => {
        pensionServices
            .getPensionTierContribution(token)
            .then((resp) => {
                if (resp.success) {
                    const pensionTireCon = resp?.tier_contribution_rates?.map((_) => {
                        return {
                            value: _.uuid,
                            label: `${_.type}%  -  ${_.tier_rate_name}`
                        };
                    });
                    setPensiontierContribution(pensionTireCon);
                }
            })
            .catch((err) => console.log(err));
    };

    const pensionValueChange = ({ target: { value, name } }) => {
        const copiedPensionData = { ...pensionValue };
        copiedPensionData[name] = value;
        setPensionValue(copiedPensionData);
    };

    const fetchFormAVersions = (token) => {
        pensionServices
            .getFormAVersions(token)
            .then((resp) => {
                if (resp.success) {
                    const versions = resp.form_a_version.map((item) => {
                        return { value: item, label: item };
                    });
                    setFormAVersions(versions);
                }
            })
            .catch((err) => console.log(err));
    };

    const fetchPensionPCSERegionList = (token) => {
        pensionServices
            .getPensionPCSERegionsList(token)
            .then((resp) => {
                if (resp.success) {
                    const pensionPCSERegionList = resp?.pcse_regions?.map((_) => {
                        return {
                            value: _.uuid,
                            label: _.region
                        };
                    });
                    setPensionPCSE(pensionPCSERegionList);
                }
            })
            .catch((err) => console.log(err));
    };
    const handleSaveInformationclick = () => {
        if (
            pensionValue.claim_pension.length !== 0 &&
            pensionValue.pension_region.length !== 0 &&
            pensionValue.tier_contribution_rate.length !== 0 &&
            pensionValue.PCSE_region.length !== 0 &&
            pensionValue.SD_number.length <= 8 &&
            /^[A-Za-z]{2}\d{6}[A-Za-z]{1}$/.test(pensionValue.NI_Number)
        ) {
            const data = {
                claim_pension: pensionValue.claim_pension,
                pension_region_uuid: pensionValue.pension_region,
                tier_contribution_rate_uuid: pensionValue.tier_contribution_rate,
                pcse_region_uuid: pensionValue.PCSE_region,
                SD_number: pensionValue.SD_number,
                NI_number: pensionValue.NI_Number,

                added_years: +pensionValue.added_years,
                additional_pension: +pensionValue.additional_pension,
                MPAVCs: +pensionValue.MPAVCs,
                ERRBO: +pensionValue.ERRBO,
                form_a_version: pensionValue.form_a_version
            };
            pensionServices
                .putSavePensionInformation(data, token)
                .then((resp) => {
                    if (resp.success) {
                        toast.success(resp.message);
                    } else {
                        toast.error(resp.message || resp.error[0].message);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            if (pensionValue.SD_number.length > 8) {
                toast.error('SD Number should be a maximum of 8 digits.');
            } else if (!/^[A-Za-z]{2}\d{6}[A-Za-z]{1}$/.test(pensionValue.NI_Number)) {
                toast.error(
                    'NI Number should be in the format of two prefix letters, six digits, and one suffix letter. e.g. AB123456X'
                );
            } else {
                toast.error('Please add your data.');
            }
        }
    };
    // const handleSaveInformationclick = () => {
    //   if (
    //     pensionValue.claim_pension.length !== 0 &&
    //     pensionValue.pension_region.length !== 0 &&
    //     pensionValue.tier_contribution_rate.length !== 0 &&
    //     pensionValue.PCSE_region.length !== 0 &&
    //     pensionValue.SD_number.length !== 0
    //   ) {
    //     const data = {
    //       claim_pension: pensionValue.claim_pension,
    //       pension_region_uuid: pensionValue.pension_region,
    //       tier_contribution_rate_uuid: pensionValue.tier_contribution_rate,
    //       pcse_region_uuid: pensionValue.PCSE_region,
    //       SD_number: pensionValue.SD_number,
    //       NI_number: pensionValue.NI_Number,

    //       added_years: +pensionValue.added_years,
    //       additional_pension: +pensionValue.additional_pension,
    //       MPAVCs: +pensionValue.MPAVCs,
    //       ERRBO: +pensionValue.ERRBO,
    //       form_a_version: pensionValue.form_a_version
    //       // ...(pensionValue.added_years && {
    //       //   added_years: pensionValue.added_years
    //       // }),
    //       // ...(pensionValue.additional_pension && {
    //       //   additional_pension: pensionValue.additional_pension
    //       // }),
    //       // ...(pensionValue.MPAVCs && { MPAVCs: pensionValue.MPAVCs }),
    //       // ...(pensionValue.ERRBO && { ERRBO: pensionValue.ERRBO })
    //     }
    //     pensionServices
    //       .putSavePensionInformation(data, token)
    //       .then(resp => {
    //         if (resp.success) {
    //           toast.success(resp.message)
    //         } else {
    //           toast.error(resp.message || resp.error[0].message)
    //         }
    //       })
    //       .catch(err => console.log(err))
    //   } else {
    //     toast.error('Please Add your data.')
    //   }
    // }

    const fetchPatchAddSignature = (img) => {
        const file = DataURIToBlob(img);
        const formData = new FormData();
        formData.append('avatar', file, 'signature.png');

        function DataURIToBlob(dataURI) {
            const splitDataURI = dataURI.split(',');
            const byteString =
                splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
            const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

            const ia = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

            return new Blob([ia], { type: mimeString });
        }

        setIsLoading(true);
        pensionServices
            .patchAddSignature(formData, token, 'Signature')
            .then((resp) => {
                if (resp.success) {
                    toast.success(resp.message);
                    setShowDigitalSignModal(false);
                    onclickSaveSign();
                } else {
                    setShowDigitalSignModal(false);
                    toast.error(resp.message);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const [col1, setcol1] = useState(false);
    const [col2, setcol2] = useState(false);

    useEffect(() => {
        setcol1(pensionValue.claim_pension === 1 ? true : false);
    }, [pensionValue]);

    const t_col1 = () => {
        setcol1(!Boolean(pensionValue.claim_pension));
    };

    const t_col2 = () => {
        setcol2(!col2);
    };

    return (
        <>
            <Accordion id="default-accordion-example" className="accordion-fill-info" open="0" toggle={() => t_col1()}>
                <AccordionItem>
                    <div className="px-3 py-3 accordion-header bg-soft-info rounded-top" id="headingOne">
                        <div className={classnames('form-check form-switch form-switch-md')}>
                            <Input
                                id="claim-pension-check"
                                type="checkbox"
                                role="switch"
                                checked={pensionValue.claim_pension === 1 ? true : false}
                                onChange={(e) => {
                                    const copiedPension = { ...pensionValue };
                                    copiedPension.claim_pension = e.target.checked ? 1 : 0;
                                    setPensionValue(copiedPension);
                                    t_col1(e.target.checked);
                                }}
                                className={classnames('form-check-input', { collapsed: !col1 })}
                            />
                            <label className="form-check-label" htmlFor="claim-pension-check">
                                <span>{'  '} Claim Pension</span>
                            </label>
                        </div>
                    </div>
                    <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne">
                        <div className="accordion-body">
                            <div className="mb-3">
                                <Label htmlFor='pension-region' className="form-label">
                                    Pension Region <span className="red-color">*</span>
                                </Label>
                                <Select
                                    inputId='pension-region'
                                    isClearable
                                    isMulti={false}
                                    options={pensionRegion || []}
                                    isSearchable={false}
                                    maxMenuHeight={210}
                                    onChange={(e) => {
                                        const copiedPension = { ...pensionValue };
                                        copiedPension.pension_region = e.value;
                                        copiedPension.defaultPensionRegion = e.label;
                                        setPensionValue(copiedPension);
                                    }}
                                    value={{ label: pensionValue.defaultPensionRegion }}
                                />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="tier-contribution-rate" className="form-label">
                                    Tier Contribution Rate <span className="red-color">*</span>
                                </Label>
                                <Select
                                    isClearable
                                    isMulti={false}
                                    isSearchable={false}
                                    inputId='tier-contribution-rate'
                                    options={pensionTierContribution || []}
                                    onChange={(e) => {
                                        const copiedPension = { ...pensionValue };
                                        copiedPension.tier_contribution_rate = e.value;
                                        copiedPension.defaultTireContribution = e.label;

                                        setPensionValue(copiedPension);
                                    }}
                                    value={{
                                        label: pensionValue.defaultTireContribution
                                    }}
                                />
                            </div>{' '}
                            <div className="mb-3">
                                <Label htmlFor="pcse-regions" className="form-label">
                                    PCSE Region <span className="red-color">*</span>
                                </Label>
                                <Select
                                    isClearable
                                    isMulti={false}
                                    inputId='pcse-regions'
                                    isSearchable={true}
                                    options={pensionPCSE || []}
                                    onChange={(e) => {
                                        const copiedPension = { ...pensionValue };
                                        copiedPension.PCSE_region = e.value;
                                        copiedPension.defaultPensionPCSE = e.label;

                                        setPensionValue(copiedPension);
                                    }}
                                    value={{
                                        label: pensionValue.defaultPensionPCSE
                                    }}
                                />
                            </div>
                            <div className="mb-3 pb-2">
                                <Label htmlFor="SD_number" className="form-label">
                                    SD Number <span className="red-color">*</span>
                                </Label>
                                <Input
                                    name="SD_number"
                                    type="text"
                                    className="form-control"
                                    onChange={pensionValueChange}
                                    value={pensionValue.SD_number || ''}
                                    id="SD_number"
                                    placeholder="Please enter your SD number"
                                />
                            </div>
                            <div className="mb-3 pb-2">
                                <Label htmlFor="NI_Number" className="form-label">
                                    NI Number <span className="red-color">*</span>
                                </Label>
                                <Input
                                    name="NI_Number"
                                    type="text"
                                    onChange={pensionValueChange}
                                    value={pensionValue.NI_Number || ''}
                                    autoComplete="false"
                                    className="form-control"
                                    id="NI_Number"
                                    placeholder="Please enter your  NI Number e.g QQ123456C"
                                />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="form-a-version" className="form-label">
                                    Form_A version <span className="red-color">*</span>
                                </Label>
                                <Select
                                    isClearable
                                    isMulti={false}
                                    inputId='form-a-version'
                                    options={formAVersions || []}
                                    isSearchable={false}
                                    maxMenuHeight={210}
                                    onChange={(e) => {
                                        const copiedPension = { ...pensionValue };
                                        copiedPension.form_a_version = e.value;
                                        setPensionValue(copiedPension);
                                    }}
                                    value={{ label: pensionValue.form_a_version }}
                                />
                            </div>
                        </div>
                    </Collapse>
                </AccordionItem>
                <AccordionItem>
                    <h2 className="accordion-header" id="pension-opt-setting-heading">
                        <button
                            className={classnames('accordion-button bg-soft-dark', {
                                collapsed: !col2
                            })}
                            type="button"
                            onClick={t_col2}
                            style={{ cursor: 'pointer' }}
                        >
                            Advanced optional settings
                        </button>
                    </h2>
                    <Collapse isOpen={col2} className="accordion-collapse" id="collapseOne">
                        <div className="accordion-body">
                            <div className="mb-3 pb-2">
                                <Label htmlFor="added_years" className="form-label">
                                    Added Years %
                                </Label>
                                <Input
                                    name="added_years"
                                    type="number"
                                    max={100}
                                    min={0}
                                    onChange={pensionValueChange}
                                    value={pensionValue.added_years || ''}
                                    className="form-control"
                                    id="added_years"
                                    placeholder="Please enter Added Years"
                                />
                            </div>
                            <div className="mb-3 pb-2">
                                <Label htmlFor="additional_pension" className="form-label">
                                    Additional Pension
                                </Label>
                                <Input
                                    name="additional_pension"
                                    type="number"
                                    max={100000}
                                    min={0}
                                    onChange={pensionValueChange}
                                    value={pensionValue.additional_pension || ''}
                                    className="form-control"
                                    id="additional_pension"
                                    placeholder="Please enter your Additional Pension"
                                />
                            </div>
                            <div className="mb-3 pb-2">
                                <Label htmlFor="MPAVCs" className="form-label">
                                    MPAVCs %
                                </Label>
                                <Input
                                    name="MPAVCs"
                                    type="number"
                                    min={0}
                                    max={100}
                                    onChange={pensionValueChange}
                                    value={pensionValue.MPAVCs || ''}
                                    className="form-control"
                                    id="MPAVCs"
                                    placeholder="Please enter your MPAVCs"
                                />
                            </div>
                            <div className="mb-3 pb-2">
                                <Label htmlFor="ERRBO" className="form-label">
                                    ERRBO %
                                </Label>
                                <Input
                                    name="ERRBO"
                                    type="number"
                                    onChange={pensionValueChange}
                                    value={pensionValue.ERRBO || ''}
                                    max={100}
                                    min={0}
                                    autoComplete="false"
                                    className="form-control"
                                    id="ERRBO"
                                    placeholder="Please enter your ERRBO"
                                />
                            </div>
                        </div>
                    </Collapse>
                </AccordionItem>
            </Accordion>

            <Col>
            <Row>
                
                <div className="d-flex flex-wrap gap-2 mt-4 mb-3 ">
                    <button type="button" className="btn btn-primary" onClick={handleSaveInformationclick}>
                        Save Information
                    </button>
                    <button
                        type="button"
                        className="btn btn-success"
                        data-bs-toggle="button"
                        onClick={() => setShowDigitalSignModal(true)}
                    >
                        Add Signature
                    </button>
                    {pensionPreferences?.user?.signature ? (
                        <p className=" fs-16 green-color">
                            {' '}
                            <i className="ri-check-line text-success"></i> Signature added
                        </p>
                    ) : (
                        <p className="m-0 red-color  mb-1">
                            <i className="ri-error-warning-line me-1"></i>
                            {/* <i className='mdi mdi-arrow-left-thin fs-16 red-color'></i> */}
                            Please add your signature
                        </p>
                    )}
                </div>
                </Row>
            </Col>
            <Row>
                <Col>
            <SignaturePadModal
                handleClose={() => setShowDigitalSignModal(false)}
                tog_standard={() => {
                    setShowDigitalSignModal(!showDegitalSignModal);
                }}
                open={showDegitalSignModal}
                handleSaveChanges={fetchPatchAddSignature}
            />
            </Col>
            </Row>
        </>
    );
};

export default SavePensionDetails;
