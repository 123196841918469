import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import {
  getCookie,
  PENSION_FORM_B_TABS
} from '../../utils/constants'
import classnames from 'classnames'
import PaginationV2 from '../../Components/Common/PaginationV2'

import moment from 'moment'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { pensionServices } from '../../services/pensionServices'
import { Link } from 'react-router-dom'
import SpinnerComponent from '../../Components/Common/Spinner'
import ReadyPensionFromBlist from './ReadyPensionFromBlist'
import UpcomingFormBList from './UpcomingFormBList'
import ArchieveFormBList from './ArchieveFormBList'
import { accountServices } from '../../services/accountServices'
import fetchPDF from '../../common/fetchPDF'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

let LIMIT = 20

const FormB = () => {
  const [topBorderjustifyTab, settopBorderjustifyTab] = useState(0)
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [page, setPage] = useState(1)
  const [coll1, setcoll1] = useState(-1)
  const [formBArray, setFormBArray] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [archivedFormB, setArchivedFormB] = useState([])
  const [pensionLogs, setPensionLogs] = useState([])

  const token = localStorage.getItem('token')

  useEffect(() => {
    if (topBorderjustifyTab === 0) fetchGetFormB('ready', token)
    if (topBorderjustifyTab === 1) fetchGetFormB('upcomming', token)
    if (topBorderjustifyTab === 2) getArchivedFormB(token)
    if (topBorderjustifyTab === 3) getPensionLogs(token)
  }, [topBorderjustifyTab])

  const history = useHistory();

  const fetchGetFormB = (status, token) => {
    setIsLoading(true)
    pensionServices
      .getFormB(status, token)
      .then(resp => {
        if (resp.success) {
          let set = new Set()
          let finalArray = []

          resp.sessions.forEach(item2 => {
            let arrayData = resp?.sessions?.flatMap(item => {
              const obj = {
                ...item,
                checked: item.week_gap < 11 ? true : false
              }
              let date = new Date(obj.paid_date).getMonth()
              let date2 = new Date(item2.paid_date).getMonth()
              if (!set.has(obj.id)) {
                if (date == date2) {
                  set.add(obj.id)
                  return obj
                }
              }
            })
            let data = arrayData?.filter(function (element) {
              return element !== undefined
            })
            if (data?.length !== 0) {
              finalArray?.push(data)
            }
          })
          setFormBArray(finalArray?.reverse())
        }else{
          if(resp?.message === "Unauthorised"){
            localStorage.clear();
            history.push()
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const getArchivedFormB = token => {
    setIsLoading(true)
    pensionServices
      .getArchivedFormB(token)
      .then(resp => {
        if (resp.success) {
          setArchivedFormB(resp.data)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const getPensionLogs = token => {
    setIsLoading(true)
    pensionServices
      .getPensionLogs(token)
      .then(resp => {
        if (resp.success) {
          setPensionLogs(resp.data)
        }
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getFormBData = (path, status) => {
    setIsLoading(true)
    const file = path.split('/').slice(-1)[0]
    pensionServices
      .getFormBData(file, token)
      .then(resp => {
        if (resp.success) {
          handleDownloadDocument(resp.data, status, file)
        }
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const handleDownloadDocument = (pdf_uuid, status, originalFileName) => {
    setIsLoading(true)
    accountServices.downloadDocument(pdf_uuid, status, token)
      .then(resp => fetchPDF(resp, originalFileName))
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  document.title = 'Form B | airGP'
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {isLoading && <SpinnerComponent />}
          <BreadCrumb title='Pension list - Form B' pageTitle='Pension' />
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className='mb-0 pb-0'>

                  <Nav tabs className='nav-tabs'>
                    {PENSION_FORM_B_TABS.map((tab, index) => {
                      return (
                        <NavItem key={index}>
                          <NavLink
                            to='#'
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: topBorderjustifyTab === index
                            })}
                            onClick={() => {
                              settopBorderjustifyTab(index)
                              setPage(1)
                            }}
                          >
                            {tab}
                          </NavLink>
                        </NavItem>
                      )
                    })}
                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent
                    activeTab={topBorderjustifyTab}
                    className='text-muted'
                  >
                    <TabPane tabId={0} id='nav-border-top-home'>
                      <ReadyPensionFromBlist
                        formBArray={formBArray}
                        coll1={coll1}
                        setcoll1={setcoll1}
                        fetchGetFormB={fetchGetFormB}
                        setFormBArray={setFormBArray}
                        setIsLoading={setIsLoading}
                      />
                    </TabPane>
                    <TabPane tabId={1} id='nav-border-top-home'>
                      <UpcomingFormBList
                        formBArray={formBArray}
                        coll1={coll1}
                        setcoll1={setcoll1}
                        setFormBArray={setFormBArray}
                        fetchGetFormB={fetchGetFormB}
                      />
                    </TabPane>
                    <TabPane tabId={2} id='nav-border-top-home'>
                      <ArchieveFormBList
                        archivedFormB={archivedFormB}
                        onFormArchive={() => getArchivedFormB(token)}
                      />
                    </TabPane>
                    <TabPane tabId={3} id='nav-border-top-home'>
                                <div className='live-preview'>
                                  <div className='table-responsive table-card'>
                                    <Table className='table-hover align-middle table-sm table-nowrap mt-3 fs-11'>
                                      <thead className='table-light fs-10'>
                                        <tr>
                                          <th scope='col'>ID</th>
                                          <th scope='col'>Pension Month</th>
                                          <th scope='col'>
                                            Pensionable Pay
                                          </th>
                                          <th scope='col'>Tier Rate</th>
                                          <th scope='col'>
                                            Tier Rate Amount
                                          </th>
                                          <th scope='col'>Added Year</th>
                                          <th scope='col'>
                                            Added Year Amount
                                          </th>
                                          <th scope='col'>MPAVC</th>
                                          <th scope='col'>MPAVC Amount</th>
                                          <th scope='col'>Added Pension</th>
                                          <th scope='col'>ERRBO</th>
                                          <th scope='col'>ERRBO Amount</th>
                                          <th scope='col'>
                                            Employee Contribution
                                          </th>
                                          <th scope='col'>
                                            Employer Contribution
                                          </th>
                                          <th scope='col'>
                                            Contribution Date
                                          </th>
                                          <th scope='col'>Download</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {pensionLogs.length !== 0 ? (
                                          pensionLogs.map((data, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  {/* <Link
                                                    to='#'
                                                    className='fw-medium'
                                                  > */}
                                                    #
                                                    {data?.uuid?.slice(
                                                      0,
                                                      5
                                                    )}
                                                  {/* </Link> */}
                                                </td>
                                                <td>
                                                  {moment(
                                                    data?.pension_month
                                                  ).format('MMMM YYYY') ||
                                                    'NA'}
                                                </td>
                                                <td>
                                                  {Number(data?.pen_pay).toFixed(2) || '-'}
                                                </td>
                                                <td>
                                                  {Number(data?.tier_rate_percent).toFixed(2) ||
                                                    '-'}
                                                </td>
                                                <td>
                                                  {Number(data?.tiered_rate_amount).toFixed(2) ||
                                                    '-'}
                                                </td>
                                                <td>
                                                  {Number(data?.added_year_percent).toFixed(2) ||
                                                    '-'}
                                                </td>
                                                <td>
                                                  {Number(data?.added_years_amount).toFixed(2) ||
                                                    '-'}
                                                </td>
                                                <td>
                                                  {Number(data?.MPAVC_percent).toFixed(2) ||
                                                    '-'}
                                                </td>
                                                <td>
                                                  {Number(data?.MPAVC_amount).toFixed(2) ||
                                                    '-'}
                                                </td>
                                                <td>
                                                  {Number(data?.added_pension).toFixed(2) ||
                                                    '-'}
                                                </td>
                                                <td>
                                                  {Number(data?.ERRBO_percent).toFixed(2) ||
                                                    '-'}
                                                </td>
                                                <td>
                                                  {Number(data?.ERRBO).toFixed(2) || '-'}
                                                </td>
                                                <td>
                                                  {Number(data?.Employee_contribution).toFixed(2) ||
                                                    '-'}
                                                </td>
                                                <td>
                                                  {Number(data?.Employer_contribution).toFixed(2) ||
                                                    '-'}
                                                </td>

                                                <td className='text-success text-capitalize'>
                                                  {moment(
                                                    data?.contribution_date
                                                  ).format('LL') ||
                                                    'NA'}
                                                </td>

                                                <td>
                                                  <button
                                                    type='button'
                                                    className='btn btn-success btn-sm'
                                                    onClick={() =>
                                                      getFormBData(data?.form, "formb")
                                                    }
                                                  >
                                                    Download Form B
                                                  </button>
                                                </td>
                                              </tr>
                                            )
                                          })
                                        ) : (
                                          <tr>
                                            <td colSpan="16" className='text-center text-muted'>No data found</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                    </TabPane>
                  </TabContent>

                  <PaginationV2
                    totalPageCount={
                      totalPageCount === 0 ? 1 : totalPageCount
                    }
                    previousDisabled={page === 1}
                    nextDisabled={totalPageCount === page ? true : false}
                    previousPageCall={() => setPage(page - 1)}
                    currentPage={page}
                    nextPageCalls={() => setPage(page + 1)}
                    onPageInputChange={({ target: { value } }) => {
                      var regex = new RegExp(`^[1-${totalPageCount}]$`)
                      if (value <= totalPageCount && value !== 0) {
                        setPage(value)
                      }
                    }}
                  />
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormB
