import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap'
import { pensionServices } from '../../services/pensionServices'
import { getCookie } from '../../utils/constants'
import { accountServices } from '../../services/accountServices'
import fetchPDF from '../../common/fetchPDF'
import SpinnerComponent from '../../Components/Common/Spinner'

const ReadyPensionFromBlist = ({
  formBArray,
  coll1,
  setcoll1,
  fetchGetFormB,
  setFormBArray,
  setIsLoading
}) => {
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)

  const handleDownloadFormBClick = index => {
    const copiedArray = [...formBArray]
    const filterdData = copiedArray[index]
      .filter(_ => _.checked === true)
      .map(item => item.pension_form_a_uuid)

    if (filterdData.length !== 0) {
      if (filterdData.length < 10) {
        fetchGenerateFormB(
          {
            form_a_ids: filterdData,
            form_b_generated_for: moment(
              copiedArray[index][0]?.paid_date
            ).format('MMMM YYYY')
          },
          token
        )
      } else {
        toast.error("You can't select sessions more that 9 at a time!")
      }
    } else {
      toast.error('Please select form_a please!')
    }
  }

  const fetchGenerateFormB = (data, token) => {
    setIsLoading(true)
    pensionServices
      .postGeneratePensionFormB(data, token)
      .then(resp => {
        if (resp.success) {
          handleDownloadDocument(resp.uuid, "formb", resp?.data?.split('/')[2])
          fetchGetFormB('ready', token)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const handleDownloadDocument = (pdf_uuid, status,originalFileName) => {
    setLoading(true)
    accountServices.downloadDocument(pdf_uuid, status, token)
      .then(resp => fetchPDF(resp, originalFileName))
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }

  return (
    <>
      {loading && <SpinnerComponent />}
      <Row>
        <Col xl={12}>
          <div className='live-preview'>
            <div className='table-responsive table-card mb-2'>
              {formBArray.length !== 0 ? (
                formBArray.map((item, idx) => {
                  const year =
                    new Date(item[0]?.paid_date).getMonth() === 12
                      ? new Date(item[0]?.paid_date).getFullYear() + 1
                      : new Date(item[0]?.paid_date).getFullYear()

                  const month =
                    new Date(item[0]?.paid_date).getMonth() === 11
                      ? 1
                      : new Date(item[0]?.paid_date).getMonth() + 2

                  const finalDate = new Date(`${year}-${month}-01`)

                  const curDate = new Date()

                  return (
                    <React.Fragment key={idx}>
                      <Table className='align-middle table-nowrap mb-0 min-height1 mt-3'>
                        <thead className='mt-3'>
                          <tr>
                            <th></th>
                            <th></th>
                            <th
                              scope='col'
                              style={{
                                width: '25%'
                              }}
                            >
                              {moment(item[0]?.paid_date).format('MMMM YYYY')} -
                              Form B
                            </th>
                            <th
                              scope='col'
                              style={{
                                width: '25%'
                              }}
                            >
                              Paid Date :{' '}
                              {moment(item[0]?.paid_date).format('ll')} -{' '}
                              {moment(item.slice(-1)[0]?.paid_date).format('ll')}
                            </th>
                            <th></th>
                            <th
                              scope='col'
                              // style={{ width: '25%' }}
                              className='d-flex justify-content-around border-bottom-0'
                            >
                              <button
                                type='button'
                                className='btn btn-info waves-effect btn-sm waves-light'
                                onClick={() => handleDownloadFormBClick(idx, item[0].uuid)}
                              >
                                Download Form B & Archive
                              </button>
                              {/* <button
                                    type='button'
                                    className='btn btn-success waves-effect btn-sm waves-light ml-2'
                                  >
                                    Send & Archive Form_B
                                  </button> */}
                            </th>
                          </tr>
                        </thead>

                        <tbody className=''>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>
                              <button
                                type='button'
                                className='btn btn-success  waves-effect waves-light mt-2'
                                onClick={() => {
                                  coll1 === idx ? setcoll1(-1) : setcoll1(idx)
                                }}
                                style={{
                                  cursor: 'pointer'
                                }}
                              >
                                <i className='ri-arrow-down-s-fill'></i> {item.length}{' '}
                                - Forms
                              </button>
                            </td>
                            <td
                              style={{
                                color: 'red'
                              }}
                            >
                              {finalDate > curDate ? (
                                <span className='text-success'>
                                  Please send before (7th of{' '}
                                  {new Date(finalDate).toLocaleString('dafault', {
                                    month: 'short'
                                  })}
                                  )
                                </span>
                              ) : (
                                <span className='text-danger'>
                                  Past a deadline (7th of{' '}
                                  {new Date(finalDate).toLocaleString('dafault', {
                                    month: 'short'
                                  })}
                                  )
                                </span>
                              )}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </Table>
                      <CardBody key={idx} className='mt-0 p-0'>
                        <div className='live-preview'>
                          <Collapse
                            isOpen={coll1 === idx && true}
                            id='collapseExample'
                            className='mt-0'
                          >
                            <div className='mb-0 mt-0'>
                              <CardBody>
                                <div id='table-product-list-all' className=''>
                                  <div className='live-preview'>
                                    <div className='table-responsive table-card'>
                                      <Table className='table-hover align-middle table-nowrap mb-0 min-height1 mt-3'>
                                        <thead className='table-light'>
                                          <tr>
                                            <th
                                              scope='col'
                                              style={{
                                                width: '42px'
                                              }}
                                            ></th>
                                            <th></th>
                                            <th scope='col'>ID</th>
                                            <th scope='col'>Organisation</th>
                                            <th scope='col'>Session Date</th>
                                            <th scope='col'>Paid Date</th>
                                            <th scope='col'>Week Gap</th>
                                            <th scope='col'>Status</th>
                                            <th scope='col'>Form A Signature</th>
                                            <th scope='col'>Download</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {item.map((data, index) => {
                                            return (
                                              <tr key={index}>
                                                <td></td>
                                                <th scope='row'>
                                                  <div className='form-check'>
                                                    <Input
                                                      className='form-check-input'
                                                      type='checkbox'
                                                      defaultValue=''
                                                      id='responsivetableCheck02'
                                                      checked={
                                                        data.checked || false
                                                      }
                                                      onChange={e => {
                                                        let copiedArray = [
                                                          ...formBArray
                                                        ]
                                                        copiedArray[idx][
                                                          index
                                                        ].checked =
                                                          e.target.checked
                                                        setFormBArray(copiedArray)
                                                      }}
                                                    />
                                                    <Label
                                                      className='form-check-label'
                                                      htmlFor='responsivetableCheck02'
                                                    ></Label>
                                                  </div>
                                                </th>
                                                <td>
                                                  <Link
                                                    to='#'
                                                    className='fw-medium'
                                                  >
                                                    #{data?.uuid?.slice(0, 5)}
                                                  </Link>
                                                </td>
                                                <td className='text-truncate' style={{maxWidth: 220}}>
                                                  {data?.organisation_name ||
                                                    'NA'}
                                                </td>
                                                <td>
                                                  {moment(
                                                    data?.start_time
                                                  ).format('ll')}{' '}
                                                  (
                                                  {moment(
                                                    data?.start_time
                                                  ).format('LT')}
                                                  -{' '}
                                                  {moment(data?.end_time).format(
                                                    'LT'
                                                  )}
                                                  )
                                                </td>
                                                <td>
                                                  {moment(data?.paid_date).format(
                                                    'll'
                                                  )}
                                                </td>
                                                <td>{data?.week_gap} Week</td>
                                                <td>
                                                  {data?.week_gap > 10 ? (
                                                    <span className='text-danger'>
                                                      Non Generable
                                                    </span>
                                                  ) : (
                                                    <span className='text-success'>
                                                      Generable
                                                    </span>
                                                  )}
                                                </td>
                                                <td className='text-success'>
                                                  <i className='ri-checkbox-circle-line fs-17 align-middle'></i>{' '}
                                                  {data?.pension_form_status ||
                                                    'NA'}
                                                </td>
                                                <td>
                                                  <button
                                                    type='button'
                                                    className='btn btn-warning waves-effect btn-sm waves-light'
                                                    onClick={() =>
                                                      handleDownloadDocument(data?.pension_form_a_uuid, "pensionforma", data?.form_a_file_path?.split('/')[2])
                                                    }
                                                  >
                                                    Download Form A
                                                  </button>
                                                </td>
                                              </tr>
                                            )
                                          })}
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </div>
                          </Collapse>
                        </div>
                      </CardBody>
                    </React.Fragment>
                  )
                })
              ) : (
                <Table className='align-middle table-nowrap mb-0 mt-3 text-center border'>
                  <tbody>
                    <tr>
                      <td className='text-center text-muted'>No data found</td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ReadyPensionFromBlist