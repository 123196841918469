import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Badge,Card, CardHeader, CardBody,CardFooter, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown } from 'reactstrap'
import ConfirmationModal from '../../../Components/Common/ConfirmationModal'
import SuccessConfirmationModal from '../../../Components/Common/SuccessConfirmationModal'
import { bankServices } from '../../../services/bankServices'
import { getCookie } from '../../../utils/constants'

const AddedAccountList = ({ 
  bankDetailsList, 
  fetchBankDetials,
  }) => {
  const [showSetAsDefaultModal, setShowSetAsDefaultModal] = useState(false)
  const [showDeleteBankModal, setShowDeleteBankModal] = useState(false)
  const [bankId, setBankId] = useState('')

  const token = localStorage.getItem('token')
  
  const fetchSetAsDefaultBank = () => {
    const data = {
      uuid: bankId,
      default_bank: true
    }

    bankServices
      .patchSetAsDefaultBank(data, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          setShowSetAsDefaultModal(false)
          fetchBankDetials(token)
        } else {
          toast.error(resp.message)
          setShowSetAsDefaultModal(false)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchDeleteBankAccount = () => {
    bankServices
      .deleteBankAccount(bankId, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          setShowDeleteBankModal(false)
          fetchBankDetials(token)
        } else {
          toast.error(resp.message)
          setShowDeleteBankModal(false)
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <>
      <Row>
      <Col>
      {/* Desktop View */}
      <div className='live-preview  d-none d-xl-block'>
      <div className='table-responsive table-card mt-4'>
        <Table className='align-middle table-hover table-sm table-nowrap'>
          <thead className='table-light text-muted'>
            <tr>
              <th scope='col'>Bank name</th>
              <th scope='col'>Name on account</th>
              <th scope='col'>Account number</th>
              <th scope='col'>Sortcode</th>
              <th scope='col'>Default account</th>
              <th scope='col'>Action</th>
            </tr>
          </thead>
          <tbody>
            {bankDetailsList.map((bank, index) => {
              return (
                <tr key={index}>
                  <td>{bank.bank_name}</td>
                  <td>{bank.name_on_bank}</td>
                  <td>{bank.account_number}</td>
                  <td>{bank.sortcode}</td>
                  <td>
                    {bank.default_bank === 1 && (
                      <span className='badge rounded-pill badge-soft-success'><i className="ri-check-line me-1"></i>selected</span>
                      // <Badge
                      //   color='success'
                      //   pill
                      //   id='tooltipApply'
                      // >
                      //   Selected
                      // </Badge>
                    )}
                  </td>
                  <td>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        href='#'
                        className='btn-soft-primary btn-sm dropdown'
                        tag='button'
                      >
                        <i className='ri-more-fill align-middle'></i>
                      </DropdownToggle>
                      <DropdownMenu className='dropdown-menu-end'>
                        <DropdownItem
                          onClick={() => {
                            setShowSetAsDefaultModal(true)
                            setBankId(bank?.uuid)
                          }}
                        >
                          <i className='ri-pencil-fill align-bottom me-2 text-muted'></i>{' '}
                          Set As Default
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setShowDeleteBankModal(true)
                            setBankId(bank?.uuid)
                          }}
                        >
                          <i className='ri-delete-bin-6-line align-bottom me-2 text-muted'></i>{' '}
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        </div>
        </div>
        {/* Mobile View */}
        <div className='live-preview  d-xl-none'>



            {bankDetailsList.map((bank, index) => {
              return (
                <Card className='d-xl-none rounded-1 mt-4' key={index}>
                        <CardHeader className='pb-2 bg-gradient bg-light '>
                          <Row>
                              <Col xs={9} className=' fw-semibold'>
                              {bank.bank_name}
                              </Col>
                              <Col xs={3} className='text-end mt-n1'>
                              {bank.default_bank === 1 && (
                                  <Badge
                                    color='success'
                                    pill
                                  >
                                    Selected
                                  </Badge>
                                )}
                              </Col></Row>
                          </CardHeader>
                          <CardBody className=''>

                            <Row>
                              <Col xs={3}>
                              Name:
                              </Col>
                              <Col xs={9} className='text-end'>
                              {bank.name_on_bank}
                              </Col>
                            </Row>
                            <Row>
                              <Col  xs={6}>
                              Account number:
                              </Col>
                              <Col   xs={6} className='text-end'>
                              {bank.account_number}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={5}>
                              Sort code:
                              </Col>
                              <Col  xs={7} className='text-end'>
                              {bank.sortcode}
                              </Col>
                            </Row>
                          </CardBody>
                          <CardFooter>
                    <UncontrolledDropdown className='float-end'>
                      <DropdownToggle
                        href='#'
                        className='btn-soft-primary btn-sm dropdown'
                        tag='button'
                      >
                        <i className='ri-more-fill align-middle'></i>
                      </DropdownToggle>
                      <DropdownMenu className='dropdown-menu-end'>
                        <DropdownItem
                          onClick={() => {
                            setShowSetAsDefaultModal(true)
                            setBankId(bank?.uuid)
                          }}
                        >
                          <i className='ri-pencil-fill align-bottom me-2 text-muted'></i>{' '}
                          Set As Default
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setShowDeleteBankModal(true)
                            setBankId(bank?.uuid)
                          }}
                        >
                          <i className='ri-delete-bin-6-line align-bottom me-2 text-muted'></i>{' '}
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    </CardFooter>
                  </Card>
              )
            })}



          </div>
        </Col>
      </Row>
      <SuccessConfirmationModal
        show={showSetAsDefaultModal}
        onDeleteClick={fetchSetAsDefaultBank}
        onCloseClick={() => setShowSetAsDefaultModal(false)}
        deleteMsg='Set this account as default payment?'
      />
      <ConfirmationModal
        show={showDeleteBankModal}
        onDeleteClick={fetchDeleteBankAccount}
        onCloseClick={() => setShowDeleteBankModal(false)}
        deleteMsg='Delete this bank account detail?'
      />
    </>
  )
}

export default AddedAccountList