import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Col, Form, Input, Label, Row } from 'reactstrap'
import { authServices } from '../../../services/authServices'
import { getCookie, PROFILE_PASSWORD_UPDATE } from '../../../utils/constants'

const ChangePassword = ({ setActiveTab }) => {
  const token = localStorage.getItem('token')
  const [profilePasswordValues, setProfilePasswordValues] = useState(PROFILE_PASSWORD_UPDATE)

  const fetchUpdatePassword = token => {
    const data = {
      old_password: profilePasswordValues.old_password,
      new_password: profilePasswordValues.new_password,
      confirm_new_password: profilePasswordValues.confirm_new_password
    }

    authServices
      .patchUpdatePassword(data, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          setActiveTab(0)
          const copiedPassword = {
            old_password: '',
            new_password: '',
            confirm_new_password: ''
          }
          setProfilePasswordValues(copiedPassword)
        } else {
          if(resp?.error) {
            toast.error(resp.error[0]?.message.replace(/["_]/g, " "))
          } else {
            toast.error(resp.message)
          }
        }
      })
      .catch(err => console.log(err))
  }

  const passwordValueChange = ({ target: { value, name } }) => {
    const copiedPasswordValue = { ...profilePasswordValues }
    copiedPasswordValue[name] = value
    setProfilePasswordValues(copiedPasswordValue)
  }

  return (
    <Form>
      <Row className='g-2'>
        <Col xl={12}>
          <div>
            <Label
              htmlFor='oldpasswordInput'
              className='form-label'
            >
              Old password <span className="red-color">*</span>
            </Label>
            <Input
              type='password'
              name='old_password'
              value={profilePasswordValues.old_password || ''}
              onChange={passwordValueChange}
              className='form-control'
              id='oldpasswordInput'
              placeholder='Enter current password'
            />
          </div>
        </Col>

        <Col xl={12}>
          <div>
            <Label
              htmlFor='newpasswordInput'
              className='form-label'
            >
              New password <span className="red-color">*</span>
            </Label>
            <Input
              type='password'
              name='new_password'
              value={profilePasswordValues.new_password || ''}
              onChange={passwordValueChange}
              className='form-control'
              id='newpasswordInput'
              placeholder='Enter new password'
            />
          </div>
        </Col>

        <Col lg={12}>
          <div>
            <Label
              htmlFor='confirmpasswordInput'
              className='form-label'
            >
              Confirm password <span className="red-color">*</span>
            </Label>
            <Input
              type='password'
              name='confirm_new_password'
              value={
                profilePasswordValues.confirm_new_password ||
                ''
              }
              onChange={passwordValueChange}
              className='form-control'
              id='confirmpasswordInput'
              placeholder='Confirm password'
            />
          </div>
        </Col>

        {/* <Col lg={12}>
          <div className='mb-3'>
            <Link
              to='#'
              className='link-primary text-decoration-underline'
            >
              Forgot Password ?
            </Link>
          </div>
        </Col> */}

        <Col lg={2}>
          <div className='pt-3'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={() => fetchUpdatePassword(token)}
            >
              Change Password
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default ChangePassword