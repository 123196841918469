import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import SignaturePad from './SignaturePad'

const SignaturePadModal = ({
  handleClose,
  tog_standard,
  open,
  handleSaveChanges
}) => {
  return (
    <Modal
      id='myModal'
      isOpen={open}
      toggle={() => {
        tog_standard()
      }}
    >
      <ModalHeader
        className='p-3 bg-airgp modal-title'
        id='myModalLabel'
        toggle={() => {
          tog_standard()
        }}
      >
        <div className="modal-title text-white h5">
        eSignature
        </div>
      </ModalHeader>
      <ModalBody>
        <SignaturePad
          handleSave={handleSaveChanges}
          onCloseClick={handleClose}
        />
      </ModalBody>
    </Modal>
  )
}

export default SignaturePadModal
