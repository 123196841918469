import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Progress,
  Row,
  Table
} from 'reactstrap'

import { useHistory } from 'react-router-dom'


const ProfileOverview = ({
  profileInfo,
  progressCount,
  documentVerified,
  toggleTab,
  paymentInformation,
  profileAvailable,
  GMCStatus,
  performerVerified,
  userProfileInformationComplete
}) => {
  const history = useHistory()

  return (
    <Row>
      <Col xxl={4}>
        <Card>
          <CardBody>
            <h5 className='card-title mb-5'>Profile Completion</h5>
            <Progress
              value={progressCount}
              // color='warning'
              color={progressCount >99 ? ('success'):('warning')}
              className='progress-xl animated-progress'
            ><div className="label">{progressCount.toFixed(0)}% completed</div> </Progress>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h5 className='card-title mb-3'>Profile Information</h5>
            <div className='table-responsive'>
              <Table className='table-borderless mb-0'>
                <tbody>
                  <tr>
                    <th className='ps-0' scope='row'>
                      {profileInfo?.regulatorType || 'GMC'} :
                    </th>
                    <td className='text-muted'>
                      {profileInfo?.regulatorNumber || 'NA'}
                    </td>
                  </tr>
                  <tr>
                    <th className='ps-0' scope='row'>
                      Smartcard :
                    </th>
                    <td className='text-muted'>
                      {profileInfo?.smartCardNumber || 'NA'}
                    </td>
                  </tr>
                  <tr>
                    <th className='ps-0' scope='row'>
                      Sex :
                    </th>
                    <td className='text-muted'>{profileInfo?.gender}</td>
                  </tr>
                  <tr>
                    <th className='ps-0' scope='row'>
                      Date of birth :
                    </th>
                    <td className='text-muted'>{profileInfo?.dob}</td>
                  </tr>
                  <tr>
                    <th className='ps-0' scope='row'>
                      IT Systems :
                    </th>
                    <td className='text-muted'>
                      {profileInfo?.itSystem?.length > 0
                        ? profileInfo?.itSystem?.map((_, index) => {
                          return (
                            <p className='mb-0' key={index}>
                              {_}
                            </p>
                          )
                        })
                        : 'NA'}
                    </td>
                  </tr>
                  <tr>
                    <th className='ps-0' scope='row'>
                      Languages :
                    </th>
                    <td className='text-muted'>
                      {profileInfo?.language?.length > 0
                        ? profileInfo?.language?.map((_, index) => {
                          return (
                            <p className='mb-0' key={index}>
                              {_}
                            </p>
                          )
                        })
                        : 'NA'}
                    </td>
                  </tr>
                  <tr>
                    <th className='ps-0' scope='row'>
                      Qualifications:
                    </th>
                    <td className='text-muted'>
                      {profileInfo?.qualification?.length > 0
                        ? profileInfo?.qualification?.map((_, index) => {
                          return (
                            <p className='mb-0' key={index}>
                              {_}
                            </p>
                          )
                        })
                        : 'NA'}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xxl={8}>
        <Card>
          <CardBody>
            <h5 className='card-title mb-3'>Account Status</h5>

            <div className='d-flex flex-column'>
              <Row>
              <Col sm={12} md={6}>
                <div className='p-2 text-center  d-grid '>
                  {userProfileInformationComplete ? (
                    <Button color='success' className='btn btn-label '
                      onClick={((e)=>{
                        history.push('/profile-settings')
                      })}>
                      {' '}
                      <i className='ri-check-line label-icon align-middle fs-22 me-2'></i>{' '}
                      PROFILE INFORMATION{' '}
                    </Button>
                  ) : (
                    <Button color='warning' className='btn btn-label'  
                      onClick={((e)=>{
                        history.push('/profile-settings')
                      })}>
                      {' '}
                      <i className='ri-error-warning-line label-icon align-middle fs-22 me-2'></i>{' '}
                      PROFILE INFORMATION INCOMPLETE{' '}
                    </Button>
                  )}
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div className='p-2 text-center  d-grid '>
                  {GMCStatus ? (
                    <Button color='success' className='btn btn-label ' disabled>
                      {' '}
                      <i className='ri-check-line label-icon align-middle fs-22 me-2'></i>{' '}
                      GMC STATUS{' '}
                    </Button>
                  ) : (
                    <Button color='warning' className='btn btn-label' disabled>
                      {' '}
                      <i className='ri-error-warning-line label-icon align-middle fs-22 me-2'></i>{' '}
                      GMC STATUS AWAITING CHECKS{' '}
                    </Button>
                  )}
                </div>
              </Col>
              </Row>
              <Row>
              <Col sm={12} md={6}>
                <div className='p-2 text-center  d-grid '>
                  {performerVerified ? (
                    <Button color='success' className='btn btn-label ' disabled>
                      {' '}
                      <i className='ri-check-line label-icon align-middle fs-22 me-2'></i>{' '}
                      PERFORMER LIST{' '}
                    </Button>
                  ) : (
                    <Button color='warning' className='btn btn-label' disabled>
                      {' '}
                      <i className='ri-error-warning-line label-icon align-middle fs-22 me-2'></i>{' '}
                      PERFORMER LIST AWAITING CHECKS{' '}
                    </Button>
                  )}
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div className='p-2 text-center  d-grid '>
                  {documentVerified ? (
                    <Button color='success' className='btn btn-label '
                      onClick={(e)=>{
                        toggleTab('4')
                      }}
                     >
                      {' '}
                      <i className='ri-check-line label-icon align-middle fs-22 me-2'></i>{' '}
                      DOCUMENTS{' '}
                    </Button>
                  ) : (
                    <Button color='warning' className='btn btn-label' 
                      onClick={(e)=>{
                        toggleTab('4')
                      }}
                    >
                      {' '}
                      <i className='ri-error-warning-line label-icon align-middle fs-22 me-2'></i>{' '}
                      DOCUMENTS NOT VERIFIED{' '}
                    </Button>
                  )}
                </div>
              </Col>
              </Row>
              <Row>
              <Col sm={12} md={6}>
                <div className='p-2 text-center d-grid '>
                  {paymentInformation ? (
                    <Button color='success' className='btn btn-label '
                      onClick={((e)=>{
                        history.push('/profile-settings?tab=1')
                      })}>
                      {' '}
                      <i className='ri-check-line label-icon align-middle fs-22 me-2'></i>{' '}
                      BANK DETAIL{' '}
                    </Button>
                  ) : (
                    <Button color='warning' className='btn btn-label' 
                      onClick={((e)=>{
                        history.push('/profile-settings?tab=1')
                      })}
                    >
                      {' '}
                      <i className='ri-error-warning-line label-icon align-middle fs-22 me-2'></i>{' '}
                      BANK DETAIL {' '}
                    </Button>
                  )}
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div className='p-2 text-center d-grid '>
                  {profileAvailable ? (
                    <Button color='success' className='btn btn-label '
                      onClick={((e)=>{
                        history.push('/profile-settings')
                      })}
                    >
                      {' '}
                      <i className='ri-check-line label-icon align-middle fs-22 me-2'></i>{' '}
                      PROFILE PICTURE{' '}
                    </Button>
                  ) : (
                    <Button color='warning' className='btn btn-label' 
                      onClick={((e)=>{
                        history.push('/profile-settings')
                      })}
                    >
                      {' '}
                      <i className='ri-error-warning-line label-icon align-middle fs-22 me-2'></i>{' '}
                      UPLOAD PROFILE PICTURE{' '}
                    </Button>
                  )}
                </div>
              </Col>
              </Row>

              {/* 
              <Col xxl={4}>
                  {documentVerified ? <Button color="success" className="btn-lg btn-label"> <i className="ri-check-line label-icon align-middle fs-22 me-2"></i> Documents verified </Button>
                   : <Button color="danger"  className="btn-lg btn-label" > <i className="ri-error-warning-line label-icon align-middle fs-22 me-2"></i> Documents not verified </Button>

                  }
              </Col>
              <Col xxl={4}>
                  {paymentInformation ? <Button color="success" className="btn-lg btn-label"> <i className="ri-check-line label-icon align-middle fs-22 me-2"></i> Bank detail </Button>
                   : <Button color="danger" className="btn-lg btn-label" outline> <i className="ri-error-warning-line label-icon align-middle fs-22 me-2"></i> Bank detail </Button>
                  }
              </Col>
              <Col xxl={4}>
                  {profileAvailable ? <Button color="success" className="btn-lg btn-label"> <i className="ri-check-line label-icon align-middle fs-22 me-2"></i> Profile picture </Button>
                   : <Button color="danger" className="btn-lg btn-label" outline> <i className="ri-error-warning-line label-icon align-middle fs-22 me-2"></i> Profile picture </Button>
                  } */}
              {/* </Col>
              <Col xxl={4}>
                <Card
                  className={`border ${
                    paymentInformation
                      ? 'card-success'
                      : 'card-danger'
                  }`}
                >
                  <CardHeader>
                    <h6
                      className={`card-title mb-0 ${
                        paymentInformation
                          ? 'green-color'
                          : 'red-color'
                      }`}
                    >
                      PAYMENT INFORMATION{''}
                    </h6>
                  </CardHeader>
                </Card>
              </Col>
              <Col xxl={4}>
                <Card
                  className={`border ${
                    profileAvailable
                      ? 'card-success'
                      : 'card-danger'
                  }`}
                >
                  <CardHeader>
                    <h6
                      className={`card-title mb-0 ${
                        profileAvailable
                          ? 'green-color'
                          : 'red-color'
                      }`}
                    >
                      PROFILE PICTURE
                    </h6>
                  </CardHeader>
                </Card> */}
              {/* </Col> */}
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h5 className='card-title mb-3'>About</h5>
            <p>{profileInfo?.bio}</p>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ProfileOverview
