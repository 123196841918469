import React, { useEffect, useState } from 'react'

import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  Form,
  Input,
  Label,
  Table
} from 'reactstrap'

import { Link, useHistory, useParams } from 'react-router-dom'
import Flatpickr from 'react-flatpickr'
import { saveAs } from 'file-saver'

import BreadCrumb from '../../Components/Common/BreadCrumb'
import { invoiceServices } from '../../services/invoiceServices'
import moment from 'moment'
import { toast } from 'react-toastify'
import {
  convertTimeToMinute,
  getCookie,
  INVOICE_GENERATE_VALUES,
  INVOICE_INITIAL_STATE
} from '../../utils/constants'

const InvoiceCreate = () => {
  const [completedSession, setCompletedSessions] = useState()
  const [invoiceValues, setInvoiceValues] = useState(INVOICE_GENERATE_VALUES)
  const [emailMeCopy, setEmailMeCopy] = useState(false)
  const [expenseData, setExpenseData] = useState([])
  const [expenseTypes, setExpenseTypes] = useState([])
  const history = useHistory()
  let { id } = useParams()

  useEffect(() => {
    setExpenseData([INVOICE_INITIAL_STATE])
  }, [])

  const token = localStorage.getItem('token')
  useEffect(() => {
    fetchWorkedSessionAPI('', 20, 1, token)
    fetchExpenseTypes(token)
  }, [])

  const fetchWorkedSessionAPI = (orgId, limit, page, token) => {
    invoiceServices
      .getWorkedSessions(orgId, limit, page, token)
      .then(resp => {
        if (resp.success) {
          const filteredData = resp?.data?.filter(_ => {
            return _.uuid === id
          })
          setCompletedSessions(filteredData)
        }
      })
      .catch(err => console.log(err))
  }

  const handleInvoiceValueChange = e => {
    const { value, name } = e.target
    const copyiedInvoiceValues = { ...invoiceValues }
    copyiedInvoiceValues[name] = value
    setInvoiceValues(copyiedInvoiceValues)
  }

  const handlePlusClick = () => {
    let cED = [...expenseData]
    if (cED.length < 4) {
      cED.push({
        amount: '',
        expenseType: ''
      })
      setExpenseData(cED)
    }
  }

  const handleMinusClick = index => {
    if (expenseData.length === 1) {
      return
    } else {
      let copiedExpenseData = [...expenseData]
      copiedExpenseData.splice(index, 1)
      setExpenseData(copiedExpenseData)
    }
  }

  const handleExpenceTableChange = ({ target: { value, name } }, idx) => {
    let copiedExpenseData = [...expenseData]
    copiedExpenseData[idx][name] = value
    setExpenseData(copiedExpenseData)
  }

  const fetchExpenseTypes = token => {
    invoiceServices
      .getExpenseTypes(token)
      .then(resp => {
        if (resp.success) {
          setExpenseTypes(resp.data)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchGeneratePdfAPI = () => {
    const durationInMinutes = convertTimeToMinute(
      completedSession[0]?.start_time,
      completedSession[0]?.end_time,
      completedSession[0]?.unpaid_break_duration,
    );

    const data = {
      org_id: completedSession[0]?.organisation?.uuid,
      session_id: completedSession[0]?.uuid,
      total_amount: (completedSession[0]?.hourly_rate / 60) * durationInMinutes,
      email_to: invoiceValues.email,
      private_note: invoiceValues.add_private_note,
      public_note: invoiceValues.add_note,
      email_me_copy: emailMeCopy,
      expence: expenseData,
    }
    if (
      invoiceValues.add_private_note.length !== 0 &&
      invoiceValues.add_note.length !== 0
    ) {
      invoiceServices
        .postGenerateInvoice(data, token)
        .then(resp => {
          if (resp.success) {
            toast.success(resp.message)
            setTimeout(() => {
              invoiceServices
                .getInvoicePDF(token)
                .then(resp => {
                  const pdfBlob = new Blob([resp], { type: 'application/pdf' })
                  saveAs(pdfBlob, 'Invoice.pdf')
                })
                .catch(err => console.log(err))
            }, 500)
            history.push('/invoices')
          } else {
            toast.error(resp.message)
          }
        })
        .catch(err => console.log(err))
    } else {
      toast.error('Please fill all fields')
    }
  }

  document.title = 'Generate Invoice | airGP'

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          title='Create Invoice'
          pageTitle='Invoice List'
          to='/invoices'
        />
        <Row className='justify-content-center'>
          <Col xxl={12}>
            <Card>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  return false
                }}
                className='needs-validation'
                id='invoice_form'
              >
                <CardBody className='p-4'>
                  <div className='table-responsive'>
                    <Table className='invoice-table table-borderless table-nowrap mb-0 min-height1FV'>
                      <thead className='align-middle'>
                        <tr className='table-active'>
                          <th scope='col' style={{ width: '50px' }}>
                            #
                          </th>
                          <th scope='col'>Add Your Expenses</th>
                          <th
                            scope='col'
                            className='text-start'
                            style={{ width: '150px' }}
                          >
                            Amount
                          </th>
                          <th style={{ width: '150px' }}>Action</th>
                          <th
                            scope='col'
                            className='text-end'
                            style={{ width: '105px' }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody id='newlink'>
                        {expenseData &&
                          expenseData?.map((_, index) => (
                            <tr id='1' className='product' key={index}>
                              <th scope='row' className='product-id'>
                                {index + 1}
                              </th>
                              <td className='text-start'>
                                <div className='mb-2'>
                                  <select
                                    className='form-select bg-light border-0 product-line-price'
                                    aria-label='Default select example'
                                    onChange={event =>
                                      handleExpenceTableChange(event, index)
                                    }
                                    name='expenseType'
                                    value={_?.expenseType}
                                  >
                                    <option>Select expence type</option>
                                    {expenseTypes?.map((_, index) => {
                                      return (
                                        <option key={index}>{_?.type}</option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </td>

                              <td className='text-end'>
                                <div>
                                  <Input
                                    type='text'
                                    className='form-control bg-light border-0 product-line-price'
                                    id='productPrice-1'
                                    placeholder='£0.00'
                                    name='amount'
                                    onChange={event =>
                                      handleExpenceTableChange(event, index)
                                    }
                                    value={_?.amount}
                                  />
                                </div>
                              </td>
                              <td className='product-removal'>
                                <Link
                                  to='#'
                                  className='btn btn-success'
                                  onClick={() => handleMinusClick(index)}
                                >
                                  Delete
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <Link
                      to='#'
                      className='btn btn-soft-primary fw-medium'
                      id='add-item'
                      onClick={handlePlusClick}
                    >
                      <i className='ri-add-fill me-1 align-bottom'></i> Add Item
                    </Link>
                  </div>
                </CardBody>
                <CardBody className='p-4'>
                  <Row className='g-3'>
                    <Col lg={4} sm={6}>
                      <Label className='text-muted text-uppercase fw-semibold'>
                        Start Time
                      </Label>
                      <Flatpickr
                        name='startTime'
                        className='form-control bg-light border-0'
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: 'H:i',
                          time_24hr: true
                        }}
                        placeholder='Start time'
                        disabled
                        value={
                          completedSession
                            ? moment(completedSession[0]?.start_time).format(
                                'LT'
                              )
                            : '00:00'
                        }
                      />
                    </Col>
                    <Col lg={4} sm={6}>
                      <Label className='text-muted text-uppercase fw-semibold'>
                        End Time
                      </Label>
                      <Flatpickr
                        name='startTime'
                        className='form-control bg-light border-0'
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: 'H:i',
                          time_24hr: true
                        }}
                        placeholder='Start time'
                        disabled
                        value={
                          completedSession
                            ? moment(completedSession[0]?.end_time).format('LT')
                            : '00:00'
                        }
                      />
                    </Col>

                    <Col lg={4} sm={6}>
                      <div>
                        <Label
                          for='totalamountInput'
                          className='text-muted text-uppercase fw-semibold'
                        >
                          Fee
                        </Label>
                        <Input
                          type='text'
                          className='form-control bg-light border-0'
                          id='totalamountInput'
                          placeholder='Fee'
                          readOnly
                          value={
                            completedSession
                              ? completedSession[0]?.fee
                              : '00:00'
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardBody className='p-4 border-top border-top-dashed'>
                  <Row className='d-flex justify-content-between'>
                    <Col lg={6} sm={6}>
                      <div className='mb-2'>
                        <Label
                          for='billingName'
                          className='text-muted text-uppercase fw-semibold'
                        >
                          Add Note
                        </Label>
                        <Input
                          type='text'
                          className='form-control bg-light border-0'
                          id='billingName'
                          name='add_note'
                          onChange={handleInvoiceValueChange}
                          placeholder='This note will reflect on Invoice'
                        />
                      </div>
                    </Col>
                    <Col lg={6} sm={6}>
                      <div className='mb-2'>
                        <Label
                          for='billingName'
                          className='text-muted text-uppercase fw-semibold'
                        >
                          Private note
                        </Label>
                        <Input
                          type='text'
                          className='form-control bg-light border-0'
                          data-plugin='cleave-phone'
                          id='billingPhoneno'
                          name='add_private_note'
                          onChange={handleInvoiceValueChange}
                          placeholder='This will send personaly'
                        />
                      </div>
                    </Col>
                  </Row>
                  <Col lg={12} sm={6}>
                    <div className='mb-2 mt-3'>
                      <Label
                        for='billingName'
                        className='text-muted text-uppercase fw-semibold'
                      >
                        Email To
                      </Label>
                      <Input
                        type='text'
                        className='form-control bg-light border-0'
                        data-plugin='cleave-phone'
                        id='billingPhoneno'
                        name='email'
                        onChange={handleInvoiceValueChange}
                        placeholder='Enter email'
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className='form-check mt-4'>
                      <Input
                        type='checkbox'
                        className='form-check-input'
                        id='same'
                        name='same'
                        onChange={e => setEmailMeCopy(e.target.checked)}
                      />
                      <Label className='form-check-label' for='same'>
                        Email me a copy!
                      </Label>
                    </div>
                  </Col>
                  <div className='hstack gap-2 justify-content-end d-print-none mt-4'>
                    <Link
                      to='#'
                      className='btn btn-danger'
                      onClick={fetchGeneratePdfAPI}
                    >
                      <i className='ri-printer-line align-bottom me-1'></i>{' '}
                      Generate
                    </Link>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default InvoiceCreate
