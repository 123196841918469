import React from 'react';
import { useState, useEffect } from 'react';
import { Col, Button, Modal, ModalBody, Container, Row } from 'reactstrap'



const CookieNotice = ({ getCookieAcceptance }) => {
  const [accepted, setAccepted] = useState(getCookieAcceptance);

  const handleAccept = () => {
    setAccepted(true);
    localStorage.setItem('cookie-acceptance', true);
  };

 


  if (!accepted) {
    return (
        <Row className="align-items-center p-2 mt-2">
            <Col sm={9}>
            <p className='small'>We use cookies to give you the best and most relevant experience. By clicking on accept, you give your consent to the use of cookies as per our privacy policy.</p>
            </Col>
            <Col sm={3}>
            <button className='btn btn-primary float-end' onClick={handleAccept}>Accept</button>

            </Col>

        </Row>


    );
  } else {
    return null;
  }
};

export default CookieNotice;