import React, { useState, useEffect } from 'react'
import { ButtonGroup, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Collapse, Container, NavbarToggler, NavLink, NavItem } from 'reactstrap'
import Scrollspy from 'react-scrollspy'
import { Link } from 'react-router-dom'

// Import Images
import logodark from '../../assets/images/logo-dark.png'
import logolight from '../../assets/images/logo-light.png'

const Navbar = () => {
  const [isOpenMenu, setisOpenMenu] = useState(false)
  const [navClass, setnavClass] = useState('')

  const toggle = () => setisOpenMenu(!isOpenMenu)

  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true)
  })

  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop
    if (scrollup > 50) {
      setnavClass('is-sticky')
    } else {
      setnavClass('')
    }
  }

  return (
    <React.Fragment>
      <nav
        className={
          'navbar navbar-expand-lg navbar-landing fixed-top ' + navClass
        }
        id='navbar'
      >
        <Container>
          <Link className="navbar-brand" to="/">
            <img src={logodark} className="card-logo card-logo-dark" alt="logo dark" height="28" />
            <img src={logolight} className="card-logo card-logo-light" alt="logo light" height="28" />
          </Link>

          <NavbarToggler
            className='navbar-toggler py-0 fs-20 text-body'
            onClick={toggle}
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <i className='ri-menu-line'></i>
          </NavbarToggler>

          <Collapse
            isOpen={isOpenMenu}
            className='navbar-collapse'
            id='navbarSupportedContent'
          >
            <div className='navbar-nav mx-auto mt-2 mt-lg-0'>
              {/* <li className='nav-item '>
                <NavLink href='/#GPs'>GPs</NavLink>
              </li>
              <li className='nav-item'>
                <NavLink href='/#organisations'>Organisations</NavLink>
              </li> */}
            </div>
            <div className=''>
              <ButtonGroup>
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-link fw-medium text-decoration-none text-dark">
                    Sign in <i className="ri ri-arrow-down-s-line"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdownmenu-primary">
                    <Link className="dropdown-item" to="/login">GP</Link>
                    <DropdownItem divider />
                    <a className="dropdown-item" href="https://org.airgp.co.uk/login" >Organisation</a>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ButtonGroup>
              <ButtonGroup>
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-primary">
                    Sign up <i className="ri ri-arrow-down-s-line"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdownmenu-primary">
                    <Link className="dropdown-item" to="/register">GP</Link>
                    <DropdownItem divider />
                    <a className="dropdown-item" href="https://org.airgp.co.uk/register" >Organisation</a>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ButtonGroup>
            </div>
          </Collapse>
        </Container>
      </nav>
    </React.Fragment>
  )
}

export default Navbar
