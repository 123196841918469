import moment from 'moment'
import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Card, CardBody, CardHeader, Button, TabContent, Table, TabPane, CardFooter } from 'reactstrap'
import AdvertisingSessionModal from '../../Components/Common/AdvertisingSessionModal'
import { convertTimeToMinute, tokenDecode } from '../../utils/constants'
import LikeButton from '../../Components/Common/LikeButton'
import ConfirmationModal from '../../Components/Common/ConfirmationModal'
import { scheduleServices } from '../../services/scheduleServices'
import { toast } from 'react-toastify'
import { io } from 'socket.io-client'
import { socket } from '../../socket/socket'

// const socket = io.connect(process.env.REACT_APP_SOCKET_BASE_URL)

const BookingList = ({
  pmsessionsData,
  setShowApplyModal,
  setShowWithdrawnModal,
  setSelectedSessionId,
  setSelectedId,
  bonusPercantage,
  handleApplyClick,
  handleLikeClick,
  advertisingSessions = false
}) => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false)
  const [selectedSession, setSelectedSession] = useState()
  const token = localStorage.getItem('token')


  return (
    <TabContent>
      <TabPane>


      <div>
      {pmsessionsData?.length !== 0 ? (
                  pmsessionsData?.map((item, idx) => {

                    let isApplied; 
                    item?.Session_clients?.length === 0 && item?.status.toLowerCase() === "booked" ?
                      
                      isApplied = ['bookedByOtherPm']
                    :
                    isApplied = item?.Session_clients?.flatMap((record)=>{
                        const sessionStatuses = record.Session_statuses || [];
                        return sessionStatuses?.map((innerRecord) => innerRecord.status);
                      })
                  
                    return !(isApplied?.includes('Rejected')) && !(isApplied?.includes('bookedByOtherPm')) ? (
                      <React.Fragment key={idx}>
                        {item?.days ? (
                          
                          <div className='d-xl-none'>
                            {/* <Card  className='small mt-1'>
                            <CardBody className='text-center py-1'> */}
                            <div className='text-center py-2'>
                              <span className='card-text small'>
                              {moment(item?.timestamp).format('dddd, Do MMMM YYYY')} 
                              </span>
                              </div>
                            {/* </CardBody>
                            </Card> */}
                          </div>
                        ) : (
                          
                          <Card className='d-xl-none rounded-1' key={item?.uuid}>
                            <CardHeader className='pb-2 bg-gradient bg-white'>
                            <Row>
                              <Col xs={10} className=' fw-semibold'>
                              {item?.organisation?.name || 'NA'}{' '}
                              </Col>
                              <Col xs={2} className='text-end'>
                              <LikeButton
                                        session={item}
                                        handleSuccess={handleLikeClick}
                              />
                            </Col></Row>
                            </CardHeader>
                            <CardBody className=''>

                            {/* <Row>
                              <Col xs={10}>
                                <h5>{item?.organisation?.name || 'NA'}{' '}</h5>
                              </Col>
                              <Col xs={2} className='text-end'>
                              <LikeButton
                                      session={item}
                                      handleSuccess={handleLikeClick}
                                    />
                              </Col>
                            </Row> */}

                            <Row>
                              <Col>
                              {`${moment(item?.start_time).format(
                                      'dddd'
                                    )}` || 'NA'}
                              </Col>
                              <Col className='text-end'>
                              {!advertisingSessions ?
                                    <div className='fw-semibold text-primary fs-20 lh-1 '>£
                                    {(
                                      (item?.hourly_rate / 60) *
                                      convertTimeToMinute(
                                        item?.start_time,
                                        item?.end_time,
                                        item?.unpaid_break_duration
                                      ) +
                                      (item?.hourly_rate / 60) *
                                      convertTimeToMinute(
                                        item?.start_time,
                                        item?.end_time,
                                        item?.unpaid_break_duration
                                      ) *
                                      +item?.organisation
                                        ?.service_fee_percentage *
                                      bonusPercantage?.bonus_fee_percentage
                                    ).toFixed(0)}
                                    </div>
                                    :
                                    <div className='fw-semibold text-primary fs-20 lh-1 '>
                                    £
                                    {(
                                      (item?.hourly_rate / 60) *
                                      convertTimeToMinute(
                                        item?.start_time,
                                        item?.end_time,
                                        item?.unpaid_break_duration
                                      )
                                    )?.toFixed(0)}
                                    </div>
                                      }
                                                                      
                              </Col>
                            </Row>




                            <Row>
                              <Col xs={9}>
                              {`${moment(item?.start_time).format(
                                      'Do MMMM YYYY'
                                    )}` || 'NA'}
                              </Col>
                              <Col xs={3} className='text-end  text-muted'>
                              {!advertisingSessions ? <>
                              £
                                        {(
                                          (item?.hourly_rate / 60) *
                                          convertTimeToMinute(
                                            item?.start_time,
                                            item?.end_time,
                                            item?.unpaid_break_duration
                                          )
                                        ).toFixed(0) || 'NA'}{' '}
                                         </>: <></>}
                              </Col>
                              </Row>


                            <Row>
                              <Col xs={7}>
                              {`${moment(item?.start_time).format(
                                      'HH:mm'
                                    )}` || 'NA'}{' '}
                                    -
                                    {`${moment(item?.end_time).format(
                                      'HH:mm'
                                    )}` || 'NA'}{' '}
                              </Col>
                              <Col xs={5} className='text-end  text-muted'>
                                £{item?.hourly_rate || 'NA'}/h
                              </Col>
                            </Row>



                            <Row>
                              <Col xs={8}>
                              {item?.unpaid_break_duration || ' no break'}
                                    {item?.unpaid_break_duration !== 0 ? " mins break" : ""}
                              </Col>
                              <Col xs={4} className='text-end text-muted'>
                              {bonusPercantage?.bonus_fee_percentage > 0 && (
                              !advertisingSessions ? <>
                              £
                                        {bonusPercantage?.bonus_fee_percentage
                                          ? (
                                            (item?.hourly_rate / 60) *
                                            convertTimeToMinute(
                                              item?.start_time,
                                              item?.end_time,
                                              item?.unpaid_break_duration
                                            ) *
                                            +item?.organisation
                                              ?.service_fee_percentage *
                                            bonusPercantage?.bonus_fee_percentage
                                          ).toFixed(0)
                                          : '0'}{' '}bonus
                             </>: <></>)}
                              </Col>
                            </Row>

                            <div className='d-flex flex-row justify-content-end small gap-1 my-1'>


                              <div>
                                <span className='badge rounded-pill badge-soft-info'>{item?.length !== 0 && item?.organisation
                                        ?.organisation_profiles[0]?.gp_soc
                                        ?.name}</span>
                                </div>
                                {(item?.pensionable === 1 ?<div> <span className='badge rounded-pill badge-soft-success'>Pensionable</span></div> : '')}
                            </div>


                            <div className='d-flex flex-row justify-content-end small gap-1 my-1'>
                            {!advertisingSessions ? <>
                              <div>
                              <span className='badge rounded-pill badge-soft-secondary'><i className="ri-map-pin-line"></i>{" "}{item?.miles?.toFixed(0)} Miles</span>
                              </div>
                              </>:<></>}
                              <div className='text-truncate'>
                                  <span className='badge rounded-pill badge-soft-primary'>{item?.session_type?.type || "NA"}</span>
                              </div>
                              <div>
                                <Link
                                  to='#'
                                  onClick={() => {
                                    setOpenDetailsModal(true)
                                    setSelectedSession(item)
                                  }}
                                  id='tooltipUrl'
                                  className='small'
                                >
                                <span className='badge rounded-pill badge-soft-danger'>#{item?.uuid?.slice(0, 5) || 'NA'}</span>
                                  </Link>
                              </div>
                            </div>






                            </CardBody>
                            <CardFooter className=''>
                            <div className='hstack gap-2 pt-1 float-end'>
                              <Button
                                onClick={() => {
                                  setOpenDetailsModal(true)
                                  setSelectedSession(item)
                                }}
                                size='sm'
                                className='btn-soft-info'
                                // size='sm'

                              >
                                Details
                              </Button>

                              {
                             isApplied && isApplied.length > 0 ? (
                              isApplied.map((status, index) => (
                                // <span className='badge badge-gradient-primary' key={index}>{status}</span>
                                <Button
                                key={index}
                                color={status === 'Booked' ? 'success' : 'warning'}
                                onClick={() => {
                                  setOpenDetailsModal(true)
                                  setSelectedSession(item)
                                }}
                                className={status === 'Booked' ? 'btn-success' : 'btn-warning'}
                                size='sm'
                                

                              disabled> {status} </Button>
                              ))
                              ) : (
                              <Button
                                color='primary'
                                size='sm'
                                onClick={() => {
                                    setShowApplyModal(true)
                                    setSelectedId(item)
                                    handleApplyClick()
                                }}
                                
                              >
                              Apply
                              </Button>
                              )
                            }
                            </div>
                            </CardFooter>
                          </Card>
                        )}
                      </React.Fragment>
                    ) : null
                  })
                ) : (
                  <div  className='d-xl-none text-center mt-4'>
                      No Result Found <br></br><p className='text-muted small'>Try expanding your location or filters</p>
                  </div>
                )}
        </div>






        <div className='live-preview d-none d-xl-block'>
          <div className='table-responsive table-card'>
            <Table className='mb-0 align-middle table-hover table-nowrap'>
              <thead className='text-muted'>
                <tr>
                  <th scope='col'>ID</th>
                  <th scope='col'>Organisation</th>
                  <th scope='col'>Date & Time</th>
                  <th scope='col'>Fee</th>
                  <th scope='col'>Action</th>
                  {/* <th scope='col'></th> */}
                </tr>
              </thead>
              <tbody>
                {pmsessionsData?.length !== 0 ? (
                  pmsessionsData?.map((item, idx) => {

                    let isApplied; 
                    item?.Session_clients?.length === 0 && item?.status.toLowerCase() === "booked" ?
                      
                      isApplied = ['bookedByOtherPm']
                    :
                    isApplied = item?.Session_clients?.flatMap((record)=>{
                        const sessionStatuses = record.Session_statuses || [];
                        return sessionStatuses?.map((innerRecord) => innerRecord.status);
                      })
                  
                    return !(isApplied?.includes('Rejected')) && !(isApplied?.includes('bookedByOtherPm')) ? (
                      <React.Fragment key={idx}>
                        {item?.days ? (
                          <tr className='bg-light '>

                            <td colSpan={6} className='py-0 fs-12'>
                              {moment(item?.timestamp).format('dddd, Do MMMM YYYY')}
                            </td>


                          </tr>
                        ) : (
                          <tr key={item?.uuid}>
                            <td>
                              <Link
                                to='#'
                                onClick={() => {
                                  setOpenDetailsModal(true)
                                  setSelectedSession(item)
                                }}
                                id='tooltipUrl'
                                className='small'
                              >
                                #{item?.uuid?.slice(0, 5) || 'NA'}
                              </Link>
                            </td>
                            <td>
                              <div className='d-flex align-items-center '>
                                  <div className='mb-0 d-inline-block text-truncate flex-shrink-1'  style={{maxWidth: 220}}>
                                    {item?.organisation?.name || 'NA'}{' '}
                                  </div>
                                  <div className='flex-grow-0'>
                                  <LikeButton
                                      session={item}
                                      handleSuccess={handleLikeClick}
                                    />
                                  </div>
                                  </div>
                                  <div className='text-muted small mb-0'>
                                    <div>
                                    {!advertisingSessions ?
                                    <>
                                      <i className='ri-map-pin-line fs-12'></i>&nbsp;
                                      {item?.miles?.toFixed(0)} Miles -{' '}
                                      </>
                                     : <></>}
                                      {item?.organisation
                                        ?.organisation_profiles[0]?.gp_soc
                                        ?.name || ''}

                                    </div>
                                  </div>


                            </td>
                            <td>

                                    {`${moment(item?.start_time).format(
                                      'Do MMMM YYYY'
                                    )}` || 'NA'}

                                  <div className='text-muted small mb-0'>

                                    {`${moment(item?.start_time).format(
                                      'HH:mm'
                                    )}` || 'NA'}{' '}
                                    -
                                    {`${moment(item?.end_time).format(
                                      'HH:mm'
                                    )}` || 'NA'}{' '}
                                    ({' '}{item?.unpaid_break_duration || ' No break'}{' '}
                                    {item?.unpaid_break_duration !== 0 ? "mins break" : ""}{' '})
                                  </div>
                            </td>
                            {!advertisingSessions ?
                              <td>
                                    <div className='mb-0 text-primary fw-semibold h5 '>
                                      £
                                      {(
                                        (item?.hourly_rate / 60) *
                                        convertTimeToMinute(
                                          item?.start_time,
                                          item?.end_time,
                                          item?.unpaid_break_duration
                                        ) +
                                        (item?.hourly_rate / 60) *
                                        convertTimeToMinute(
                                          item?.start_time,
                                          item?.end_time,
                                          item?.unpaid_break_duration
                                        ) *
                                        +item?.organisation
                                          ?.service_fee_percentage *
                                        bonusPercantage?.bonus_fee_percentage
                                      ).toFixed(0)}

                                    </div>
                                    <div className='text-muted small mb-0'>
                                      <div>
                                        £
                                        {(
                                          (item?.hourly_rate / 60) *
                                          convertTimeToMinute(
                                            item?.start_time,
                                            item?.end_time,
                                            item?.unpaid_break_duration
                                          )
                                        ).toFixed(0) || 'NA'}{' '}
                                        (£{item?.hourly_rate || 'NA'}/h)
                                        {bonusPercantage?.bonus_fee_percentage > 0 && (
                                          <>
                                            {((item?.hourly_rate / 60) *
                                              convertTimeToMinute(
                                                item?.start_time,
                                                item?.end_time,
                                                item?.unpaid_break_duration
                                              ) *
                                              +item?.organisation?.service_fee_percentage *
                                              bonusPercantage?.bonus_fee_percentage
                                            ).toFixed(0) > 0 && (
                                              <>
                                                + £
                                        {bonusPercantage?.bonus_fee_percentage
                                          ? (
                                            (item?.hourly_rate / 60) *
                                            convertTimeToMinute(
                                              item?.start_time,
                                              item?.end_time,
                                              item?.unpaid_break_duration
                                            ) *
                                            +item?.organisation
                                              ?.service_fee_percentage *
                                            bonusPercantage?.bonus_fee_percentage
                                          ).toFixed(0)
                                          : '0'}{' '}
                                        bonus
                                              </>
                                            )}
                                          </>
                                        )}
                                        {item?.pensionable === 1 ? (
                                        <i title='Pensionable' className="ri-file-ppt-line ms-1"></i>
                                        ) : (
                                          ''
                                        )}
                                        {/* {item?.pensionable === 1 ? (
                                          <i
                                            title='Pensionable'
                                            className='ms-1 ri-file-ppt-line'
                                          ></i>
                                        ) : (
                                          ''
                                        )} */}
                                      </div>
                                    </div>
                              </td>
                              :
                              <td>
                                    <div className='mb-0 text-primary fw-semibold h5 '>
                                      £
                                      {(
                                        (item?.hourly_rate / 60) *
                                        convertTimeToMinute(
                                          item?.start_time,
                                          item?.end_time,
                                          item?.unpaid_break_duration
                                        )
                                      )?.toFixed(0)}
                                    </div>
                                    <p className='text-muted small mb-0'>

                                        £
                                        {(
                                          (item?.hourly_rate / 60) *
                                          convertTimeToMinute(
                                            item?.start_time,
                                            item?.end_time,
                                            item?.unpaid_break_duration
                                          )
                                        ).toFixed(0) || 'NA'}{' '}
                                        ({' '}£
                                        {item?.hourly_rate || 'NA'}
                                        /hour {' '})
                                        {item?.pensionable === 1 ? (
                                        <span
                                        title='pensionable'
                                        className='ms-2 fs-10 badge rounded-pill badge-soft-success'
                                        ><i className="ri-check-line me-1"></i>Pension</span>
                                        ) : (
                                          ''
                                        )}
                                    </p>
                              </td>
                            }
                            <td>
                              <Button
                                // color='warning'
                                onClick={() => {
                                  setOpenDetailsModal(true)
                                  setSelectedId(item)
                                  setSelectedSession(item)
                                }}
                                className='btn-soft-info me-2'
                                size='sm'

                              >
                                Details
                              </Button>
                            {/* </td>
                            <td> */}
                            {
                             isApplied && isApplied.length > 0 ? (
                              isApplied.map((status, index) => (
                                // <span className='badge badge-gradient-primary' key={index}>{status}</span>
                                <Button
                                key={index}
                                color={status === 'Booked' ? 'success' : 'warning'}
                                onClick={() => {
                                  setOpenDetailsModal(true)
                                  setSelectedSession(item)
                                }}
                                className={status === 'Booked' ? 'btn-success' : 'btn-warning'}
                                size='sm'

                              disabled> {status} </Button>
                              ))
                              ) : (
                              <Button
                                color='primary'
                                onClick={() => {
                                    setShowApplyModal(true)
                                    setSelectedId(item)
                                    handleApplyClick()
                                }}
                                size='sm'
                              >
                              Apply
                              </Button>
                              )
                            }
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ) : null
                  })
                ) : (
                  <tr>
                    <td colSpan='13' className='text-center'>
                      No Result Found <br></br><p className='text-muted small'>Try expanding your location or filters</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>

        <AdvertisingSessionModal
          open={openDetailsModal}
          toggle={() => setOpenDetailsModal(!openDetailsModal)}
          sessionDetails={selectedSession}
          bonusPercantage={bonusPercantage}
          handleClose={() => setOpenDetailsModal(!openDetailsModal)}
          handleApplyClick={() => {
            setOpenDetailsModal(false)
            setShowApplyModal(true)
            setSelectedId(selectedSession)
            handleApplyClick()
            document.body.style.overflow = 'visible'

          }}
          advertisingSessions={advertisingSessions}
          showWithDrawnModal={() => {
            setOpenDetailsModal(false)
            setShowWithdrawnModal(true)
            setSelectedSessionId(selectedSession?.uuid)
            document.body.style.overflow = 'visible'
          }}
        />

      </TabPane>
    </TabContent>
  )
}

export default BookingList
