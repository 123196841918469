import Select from 'react-select'


const DateDropdown = ({ options, onHandleChange, value }) => {

  const inputBoxStyles = {
    control: base => ({
      ...base,
      height: 50,
      fontSize: 15
    })
  }

  return (
    <div className='flex-grow-1'>
      <Select
        value={value}
        isSearchable={false}
        onChange={onHandleChange}
        options={options}
        name='choices-single-default'
        id='idStatus'
        styles={inputBoxStyles}
        classNamePrefix="react-select"
      />
    </div>
  )
}

export default DateDropdown
