import React, { useEffect, useState } from 'react';
import {
    Badge,
    Col,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import classnames from 'classnames';

//import images
import avatar3 from '../../assets/images/users/user-dummy-img.jpg';

//SimpleBar
import SimpleBar from 'simplebar-react';
import io from 'socket.io-client';
import { getCookie, tokenDecode } from '../../utils/constants';
import { chatServices } from '../../services/chatServices';
import moment from 'moment';
import { socket } from '../../socket/socket';

//const socket = io.connect(process.env.REACT_APP_SOCKET_BASE_URL)

const NotificationDropdown = () => {
    const token = localStorage.getItem('token');
    //Dropdown Toggle
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const [notificationMessages, setNotificationMessages] = useState([]);
    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    //Tab
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        if (token) {
            fetchAllNotifications(token);
        }
    }, []);

    useEffect(() => {
        if (token)
            socket.emit('addClient', {
                uuid: tokenDecode(token).uuid
            });
    }, [token]);

    useEffect(() => {
        const socketNotificationMessages = (data) => {
            let copiedNotificationMessages = [...notificationMessages];
            copiedNotificationMessages.unshift({
                ...data,
                createdAt: moment().format(),
                updatedAt: moment().format(),
                isSocketNotification: true
            });
            setNotificationMessages(copiedNotificationMessages);
        };

        socket.on('sessionBooked', socketNotificationMessages);

        socket.on('sessionWithdrawn', socketNotificationMessages);

        socket.on('paidForSession', socketNotificationMessages);
    }, [socket, notificationMessages]);

    const fetchAllNotifications = (token) => {
        chatServices
            .getAllNotifications(token)
            .then((resp) => {
                if (resp?.notificationData) {
                    setNotificationMessages(resp.notificationData);
                }
            })
            .catch((err) => console.log(err));
    };

    const sendReadNotifications = (notification) => {
        if (!notification?.is_read && !notification?.isSocketNotification) {
            chatServices
                .patchReadNotification(
                    notification?.id,
                    {
                        status: 'read'
                    },
                    token
                )
                .then((resp) => {
                    if (resp?.notification) {
                        fetchAllNotifications(token);
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    const markAllNotificationsAsRead = (token) => {
        chatServices
            .patchReadAllNotifications(token)
            .then((resp) => {
                if (resp.success) {
                    fetchAllNotifications(token);
                } else {
                    toast.error(resp.message);
                }
            })
            .catch((err) => console.log(err));
    };

    const clearAllNotificationsAsRead = (token) => {
        chatServices
            .patchClearAllNotifications(token)
            .then((resp) => {
                if (resp.success) {
                    fetchAllNotifications(token);
                } else {
                    toast.error(resp.message);
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <React.Fragment>
            <Dropdown
                isOpen={isNotificationDropdown}
                toggle={toggleNotificationDropdown}
                className="topbar-head-dropdown ms-1 header-item"
            >
                <DropdownToggle
                    type="button"
                    tag="button"
                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                    id="dropdown-basic"
                >
                    <i className="ri-notification-4-line fs-22"></i>
                    {notificationMessages?.filter((item) => !item?.notification?.is_read)?.length > 0 && (
                        <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                            {notificationMessages?.filter((item) => !item?.notification?.is_read)?.length}
                            <span className="visually-hidden">unread messages</span>
                        </span>
                    )}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end pt-0">
                    <div className="dropdown-head bg-primary rounded-top-airgp">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                </Col>

                                <div className="col-auto dropdown-tabs">
                                    {notificationMessages?.length > 0 && (
                                        <button
                                            type="button"
                                            className="btn btn-sm  btn-light text-dark  me-2"
                                            onClick={() => clearAllNotificationsAsRead(token)}
                                        >
                                            Clear All
                                        </button>
                                    )}
                                    {/* <span className="badge badge-soft-light fs-13"> */}
                                        {/* <Badge color='danger'>
                                        {notificationMessages?.filter((item) => !item?.notification?.is_read)?.length}{' '}
                                        New
                                        </Badge> */}
                                    {/* </span> */}
                                </div>
                            </Row>
                        </div>
                    </div>

                    {notificationMessages ? (
                        <SimpleBar style={{ maxHeight: '300px' }} className="pe-2">
                            {notificationMessages.map((notification, index) => (
                                <div
                                    key={`notification-${index}`}
                                    className={`text-reset notification-item d-block dropdown-item ${
                                        !notification?.notification?.is_read && 'unread-notification-item'
                                    }`}
                                >
                                    <div
                                        className="d-flex"
                                        onClick={() => sendReadNotifications(notification?.notification)}
                                    >
                                        {/* <img src={avatar3}
                      className="me-3 rounded-circle avatar-xs" alt="user-pic" /> */}
                                        <div className="flex-1">
                                            {notification?.notification?.content === 'session paid' ? (
                                                <div>
                                                    <div className="fw-bold text-dark">
                                                        {notification?.notification?.session?.organisation?.name}
                                                    </div>
                                                    <div className="text-muted">
                                                        {`Session worked on ${moment(
                                                            notification?.notification?.session?.start_time
                                                        ).format('LL')}
                                                        `}
                                                        has been paid
                                                        {/* to ${moment(
                                                            notification?.notification?.session?.end_time
                                                        ).format('LT')} */}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    {notification?.notification ? (
                                                        <div>
                                                            <div className="fw-bold text-dark">
                                                                {
                                                                    notification?.notification?.session?.organisation
                                                                        ?.name
                                                                }
                                                            </div>
                                                            <div>
                                                                Session on{' '}
                                                                {moment(
                                                                    notification?.notification?.session?.start_time
                                                                ).format('L')}
                                                                -{' '}
                                                                {moment(
                                                                    notification?.notification?.session?.start_time
                                                                ).format('LT')}{' '}
                                                                to{' '}
                                                                {moment(
                                                                    notification?.notification?.session?.end_time
                                                                ).format('LT')}{' '}
                                                                has been {notification?.notification?.content}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <div className="fw-bold text-dark">
                                                                {notification?.session?.organisation?.name}
                                                            </div>
                                                            <div>
                                                                Session on{' '}
                                                                {moment(notification?.session?.start_time).format('L')}-{' '}
                                                                {moment(notification?.session?.start_time).format('LT')}
                                                                to{' '}
                                                                {moment(notification?.session?.end_time).format('LT')}
                                                                has been {notification?.content}
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                            )}

                                            <p className="mb-0 fs-11 fw-medium text-muted">
                                                {/* <span>
                                                    <i className="ri-time-line"></i>{' '}
                                                    {notification?.notification?.createdAt
                                                        ? `${moment(notification?.notification?.createdAt).fromNow()} `
                                                        : 'NA'}
                                                </span> */}
                                                {notification?.notification ? (
                                                    <span>
                                                    <i className="ri-time-line"></i>{' '}
                                                    {notification?.notification?.createdAt
                                                        ? `${moment(notification?.notification?.createdAt).fromNow()} `
                                                        : 'NA'}
                                                </span>
                                                ) : (<span>
                                                    <i className="ri-time-line"></i>{' '}
                                                    {notification?.createdAt
                                                        ? `${moment(notification?.createdAt).fromNow()} `
                                                        : 'NA'}
                                                </span>)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="my-3 text-center">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-soft-success"
                                    onClick={() => markAllNotificationsAsRead(token)}
                                >
                                    Mark All as Read
                                </button>
                            </div>
                        </SimpleBar>
                    ) : (
                        <div className="m-2 text-muted text-center">
                            {' '}
                            <i className="ri-notification-line"></i> You are up to date
                        </div>
                    )}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default NotificationDropdown;
