import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
    Badge,
    Button,
    Form,
    NavItem,
    Nav,
    NavLink,
    TabPane,
    TabContent,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    Table,
    UncontrolledTooltip,
    ModalFooter,
} from "reactstrap";
// Import Scroll Bar - SimpleBar
import parse from "html-react-parser"
import SimpleBar from "simplebar-react";
import classnames from "classnames";

import { calculateEmployersContribution, convertTimeToMinute } from "../../utils/constants";
import { scheduleServices } from "../../services/scheduleServices";
import fetchPDF from '../../common/fetchPDF';

const SessionListViewInfoModal = ({
    open,
    handleClose,
    toggle,
    bonusPercentage,
    sessionDetails,
    showWithDrawnModal,
    cancelSelecteSession,
}) => {

    const token = localStorage.getItem("token")

    const closeBtn = (
        <button
            className="btn-close  text-white"
            onClick={handleClose}
            type="button"
        ></button>
    );
    // Justify Tabs
    const [justifyTab, setjustifyTab] = useState("1");
    const justifyToggle = (tab) => {
        if (justifyTab !== tab) {
            setjustifyTab(tab);
        }
    };

    const fetchDownloadOrgDocument = (documentId, fileName, token) => {
        scheduleServices.downloadOrganisationDocument(documentId, token).then(resp => {
            if (resp.ok) {
                fetchPDF(resp, fileName)
            }
        }).catch(err => console.log(err))
    }
   
    
    return (
        <Modal isOpen={open} id="event-modal">
            <ModalHeader
                toggle={() => {
                    justifyToggle("1")
                    toggle()
                }}
                className="p-3 bg-airgp modal-title"
                close={closeBtn}
            >
                <div className="modal-title text-white h5" id="myModalLabel">
                    Session Details
                </div>
            </ModalHeader>
            <ModalBody>
                <Form
                    className={"needs-validation view-event"}
                    name="event-form"
                    id="form-event"
                    onSubmit={(e) => e.preventDefault()}
                >


                    <Row>
                        <Col xs={9}>
                            <h5 className='fw-semibold'>{sessionDetails?.session?.organisation?.name || "NA"}</h5>
                        </Col>
                        <Col xs={3} className='text-end h3 text-primary fw-semibold'>
                            £
                            {(
                                (sessionDetails?.session?.hourly_rate / 60) *
                                convertTimeToMinute(
                                    sessionDetails?.session?.start_time,
                                    sessionDetails?.session?.end_time,
                                    sessionDetails?.session?.unpaid_break_duration
                                ) +
                                (sessionDetails?.session?.hourly_rate / 60) *
                                convertTimeToMinute(
                                    sessionDetails?.session?.start_time,
                                    sessionDetails?.session?.end_time,
                                    sessionDetails?.session?.unpaid_break_duration
                                ) *
                                +sessionDetails?.session?.organisation?.service_fee_percentage *
                                bonusPercentage
                                // +(sessionDetails?.session?.pensionable===1?Number(calculateEmployersContribution((sessionDetails?.session?.hourly_rate / 60) * convertTimeToMinute(sessionDetails?.session?.start_time,
                                //   sessionDetails?.session?.end_time,
                                //   sessionDetails?.session?.unpaid_break_duration
                                // ))):Number(0))
                            ).toFixed(0)}
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={9}>
                            {moment(sessionDetails?.session?.start_time).format(
                                "dddd, Do MMMM YYYY"
                            )}
                        </Col>
                        <Col xs={3} className='text-end text-muted'>
                            £
                            {(
                                (sessionDetails?.session?.hourly_rate / 60) *
                                convertTimeToMinute(
                                    sessionDetails?.session?.start_time,
                                    sessionDetails?.session?.end_time,
                                    sessionDetails?.session?.unpaid_break_duration
                                )
                            ).toFixed() || "NA"}
                        </Col>
                    </Row>



                    <Row>
                        <Col xs={7}>
                            {moment(sessionDetails?.session?.start_time).format("HH:mm") ||
                                "00:00"}{" "}
                            to{" "}
                            {moment(sessionDetails?.session?.end_time).format("HH:mm") ||
                                "NA"}
                        </Col>
                        <Col xs={5} className='text-end text-muted'>
                            £{sessionDetails?.session?.hourly_rate || "NA"}/hour
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={7}>
                            {sessionDetails?.session?.unpaid_break_duration || 'no break'}
                            {sessionDetails?.session?.unpaid_break_duration !== 0 ? " mins break" : ""}{''}
                        </Col>
                        <Col xs={5} className='text-end text-muted'>
                            {bonusPercentage && (
                                <>
                                {((sessionDetails?.session?.hourly_rate / 60) *
                                    convertTimeToMinute(
                                    sessionDetails?.session?.start_time,
                                    sessionDetails?.session?.end_time,
                                    sessionDetails?.session?.unpaid_break_duration
                                    ) *
                                    +sessionDetails?.session?.organisation?.service_fee_percentage *
                                    bonusPercentage
                                ).toFixed(0) > 0 && (
                                    <>
                                    £ {(
                                    (sessionDetails?.session?.hourly_rate / 60) *
                                    convertTimeToMinute(
                                        sessionDetails?.session?.start_time,
                                        sessionDetails?.session?.end_time,
                                        sessionDetails?.session?.unpaid_break_duration
                                    ) *
                                    +sessionDetails?.session?.organisation
                                        ?.service_fee_percentage *
                                    bonusPercentage
                                ).toFixed(0)
                                }{" "}
                            bonus
                                    </>
                                )}
                                </>
                            )}
                        </Col>
                    </Row>





                    <div className='d-flex flex-row justify-content-end small gap-1 my-1'>
                        <div>
                            <span className='badge rounded-pill badge-soft-info'>{sessionDetails?.session?.organisation?.organisation_profiles && sessionDetails?.session?.organisation?.organisation_profiles[0]?.gp_soc?.name}</span>
                        </div>
                        {(sessionDetails?.session?.pensionable === 1 ? <div> <span className='badge rounded-pill badge-soft-success'>Pensionable</span></div> : '')}
                    </div>
                    <div className='d-flex flex-row justify-content-end small gap-1 my-1'>

                        <div className='text-truncate'>
                            <span className='badge rounded-pill badge-soft-primary'>{sessionDetails?.session?.session_type?.type || "NA"}</span>
                        </div>
                        <div>
                            <span className='badge rounded-pill badge-soft-danger'>#{sessionDetails?.session?.uuid?.slice(0, 5) || 'NA'}</span>
                        </div>
                    </div>






                    {/* 







                    <div className="d-flex fs-10 float-end align-items-center">
                        <Badge color='primary'>{sessionDetails?.session?.organisation?.organisation_profiles && sessionDetails?.session?.organisation?.organisation_profiles[0]?.gp_soc?.name}</Badge>
                    </div>

                    <div className="mt-1 fw-semibold h5">
                        {sessionDetails?.session?.organisation?.name || "NA"}
                    </div>

                    <div className="hstack d-flex align-items-start small gap-2 mb-3">
                        <div className="">
                            <p className="mb-0 text-muted">
                                {moment(sessionDetails?.session?.start_time).format(
                                    "dddd, Do MMMM YYYY"
                                )}
                            </p>
                            <p className="mb-0 text-muted">
                                {moment(sessionDetails?.session?.start_time).format("HH:mm") ||
                                    "00:00"}{" "}
                                to{" "}
                                {moment(sessionDetails?.session?.end_time).format("HH:mm") ||
                                    "NA"}
                            </p>
                            <p className="mb-0 text-muted">
                                {sessionDetails?.session?.unpaid_break_duration || ""} Mins
                                Break
                            </p>
                        </div>
                        <div className="text-primary ms-auto">
                            <div className="d-flex flex-column align-items-end text-primary  ms-auto">
                                <div className="text-primary h3 fw-semibold">
                                    £
                                    {(
                                        (sessionDetails?.session?.hourly_rate / 60) *
                                        convertTimeToMinute(
                                            sessionDetails?.session?.start_time,
                                            sessionDetails?.session?.end_time,
                                            sessionDetails?.session?.unpaid_break_duration
                                        ) +
                                        (sessionDetails?.session?.hourly_rate / 60) *
                                        convertTimeToMinute(
                                            sessionDetails?.session?.start_time,
                                            sessionDetails?.session?.end_time,
                                            sessionDetails?.session?.unpaid_break_duration
                                        ) *
                                        +sessionDetails?.session?.organisation?.service_fee_percentage *
                                        bonusPercentage
                                        // +(sessionDetails?.session?.pensionable===1?Number(calculateEmployersContribution((sessionDetails?.session?.hourly_rate / 60) * convertTimeToMinute(sessionDetails?.session?.start_time,
                                        //   sessionDetails?.session?.end_time,
                                        //   sessionDetails?.session?.unpaid_break_duration
                                        // ))):Number(0))
                                    ).toFixed(0)}
                                </div>
                                <div className="text-muted p mb-0 float-end">
                                    £
                                    {(
                                        (sessionDetails?.session?.hourly_rate / 60) *
                                        convertTimeToMinute(
                                            sessionDetails?.session?.start_time,
                                            sessionDetails?.session?.end_time,
                                            sessionDetails?.session?.unpaid_break_duration
                                        )
                                    ).toFixed() || "NA"}{" "}
                                    (£{sessionDetails?.session?.hourly_rate || "NA"} /hour) + £
                                    {bonusPercentage
                                        ? (
                                            (sessionDetails?.session?.hourly_rate / 60) *
                                            convertTimeToMinute(
                                                sessionDetails?.session?.start_time,
                                                sessionDetails?.session?.end_time,
                                                sessionDetails?.session?.unpaid_break_duration
                                            ) *
                                            +sessionDetails?.session?.organisation
                                                ?.service_fee_percentage *
                                            bonusPercentage
                                        ).toFixed(0)
                                        : "0"}{" "}
                                    Bonus */}
                    {/* {sessionDetails?.pensionable === 1 ? (
                      <i
                        title="Pensionable"
                        className="ms-1 ri-file-ppt-line"
                      ></i>
                    ) : (
                      ""
                    )} */}
                    {/* {(sessionDetails?.session?.pensionable === 1 ?
                                        // ` + £${Number(calculateEmployersContribution((sessionDetails?.session?.hourly_rate / 60) * convertTimeToMinute(sessionDetails?.session?.start_time,
                                        //   sessionDetails?.session?.end_time,
                                        //   sessionDetails?.session?.unpaid_break_duration
                                        // )))}
                                        ` + Pension` : '')}
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <Nav
                        tabs
                        className="nav nav-tabs nav-tabs-custom  nav-primary nav-justified mb-3 mx-n3"
                    >
                        <NavItem className='text-truncate'>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({ active: justifyTab === "1" })}
                                onClick={() => {
                                    justifyToggle("1");
                                }}
                            >
                                At Glance
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({ active: justifyTab === "2" })}
                                onClick={() => {
                                    justifyToggle("2");
                                }}
                            >
                                Description
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({ active: justifyTab === "3" })}
                                onClick={() => {
                                    justifyToggle("3");
                                }}
                            >
                                Address
                            </NavLink>
                        </NavItem>
                        {(sessionDetails?.Session_statuses?.some(item => item.status === "Booked")) && <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({ active: justifyTab === "4" })}
                                onClick={() => {
                                    justifyToggle("4");
                                }}
                            >
                                Files
                            </NavLink>
                        </NavItem>}
                    </Nav>

                    <TabContent activeTab={justifyTab} className="text-muted">
                        <TabPane tabId="1">
                            {/* <span className="badge rounded-pill badge-outline-primary float-end">{sessionDetails?.session?.session_type?.type || 'NA'}</span> */}
                            {/* <Badge color='primary' className="float-end">
                                {sessionDetails?.session?.session_type?.type || "NA"}
                            </Badge> */}

                            <div>
                                {sessionDetails?.session?.Session_features?.map((_, index) => (
                                    <div className="d-flex  align-items-center" key={index}>
                                        <div className="flex-shrink-0">
                                            <i className=" ri-check-fill fs-18 pe-1 text-success"></i>
                                        </div>
                                        <div className="flex-grow">{_?.Feature?.name || "NA"}</div>
                                    </div>
                                ))}
                            </div>
                        </TabPane>

                        <TabPane tabId="2">
                            <div>
                                <SimpleBar style={{ maxHeight: "250px" }}>
                                    {sessionDetails?.session?.session_description ? parse(sessionDetails?.session?.session_description) : "NA"}
                                </SimpleBar>
                            </div>
                        </TabPane>

                        <TabPane tabId="3">
                            <p>
                                <span>{sessionDetails?.session?.organisation?.address_line_1}<br /></span>
                                <span>{sessionDetails?.session?.organisation?.address_line_2}<br /></span>
                                {sessionDetails?.session?.organisation?.address_line_3 &&
                                    <span>
                                        {sessionDetails?.session?.organisation?.address_line_3}<br />
                                    </span>}
                                {sessionDetails?.session?.organisation?.address_line_4 &&
                                    <span>
                                        {sessionDetails?.session?.organisation?.address_line_4}<br />
                                    </span>}
                                <span>
                                    {sessionDetails?.session?.organisation?.postcode}<br />
                                </span>
                            </p>

                            <div className="d-grid gap-2">
                                <Link to={{ pathname: `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${sessionDetails?.session?.organisation?.name}, ${sessionDetails?.session?.organisation?.postcode}`)}` }} target='_blank' className='btn btn-soft-primary btn-sm'>
                                    {" "}
                                    Open in Google Maps{" "}
                                </Link>
                            </div>
                        </TabPane>

                        <TabPane tabId="4">
                            <div className='table-responsive small m-n3'>
                                <Table className='table-hover align-content-middle table-nowrap table-sm mb-0'>
                                    <thead className='table-light text-muted'>
                                        <tr>
                                            <th scope='col'>File Name</th>
                                            <th scope='col'>Uploaded Date</th>
                                            {/* <th scope='col'>Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(sessionDetails?.session?.organisation?.Organisation_documents || []).map((_, index) => (
                                            <tr key={index}>
                                                <td><span className='d-inline-block text-truncate' style={{ maxWidth: '350px' }}>
                                                    <Link
                                                        to='#'
                                                        onClick={() => fetchDownloadOrgDocument(_?.uuid, _?.original_file_name, token)}
                                                    >
                                                        <i className="ri-file-3-fill"></i>&nbsp;
                                                        {_?.original_file_name}
                                                    </Link>
                                                </span></td>
                                                <td>{moment(_?.createdAt).format('ll')}</td>
                                            </tr>
                                        ))}
                                        {sessionDetails?.session?.organisation?.Organisation_documents?.length == 0 && <tr colSpan="2">
                                            <td>Documents not found</td>
                                            <td></td>
                                        </tr>}
                                    </tbody>
                                </Table>
                            </div>
                        </TabPane>
                    </TabContent>
                </Form>
            </ModalBody>
            <ModalFooter className="float-end">
                {sessionDetails?.Session_statuses?.slice(-1)[0]?.status ===
                    "Manual" && (
                        <div>
                            <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={() => {
                                    // toggleModal()
                                    handleClose();
                                }}
                                id="tooltipBottom"
                            >
                                <i className="ri-download-2-line align-bottom "></i> Edit
                            </Link>
                            <Link
                                to="#"
                                className="btn btn-danger mr-2"
                                id="tooltipDel"
                                onClick={() => {
                                    // setDeleteModal(true)
                                    handleClose();
                                }}
                            >
                                <i className="ri-delete-bin-6-line align-bottom me-1"></i> Delete
                            </Link>

                        </div>
                    )}

                {sessionDetails?.Session_statuses?.slice(-1)[0]?.status ===
                    "Applied" && (
                        <div>
                            <Link
                                to="#"
                                className="btn btn-danger"
                                id="tooltipDel"
                                onClick={() => {
                                    cancelSelecteSession();
                                    handleClose();
                                }}
                            >
                                <i className="ri-delete-bin-6-line align-bottom me-1"></i> Cancel
                            </Link>
                        </div>
                    )}

                {sessionDetails?.Session_statuses?.slice(-1)[0]?.status ===
                    "Booked" && (
                        <div>
                            <Link
                                to="#"
                                className="btn btn-danger"
                                id="tooltipDel"
                                onClick={() => {
                                    showWithDrawnModal();
                                    handleClose();
                                    // document.body.style.overflow = 'visible'
                                }}
                            >

                                Withdraw
                            </Link>
                        </div>
                    )}
                <Button color="light" onClick={() => {
                    justifyToggle("1")
                    handleClose()
                }}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default SessionListViewInfoModal;
