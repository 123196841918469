import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Link } from 'react-router-dom'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'

import { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Images
import imgpattern from "../../assets/images/landing/doodle2.svg";
import gpdemo from "../../assets/images/demos/gpdemo.webp";
import pmdemo from "../../assets/images/demos/pmdemo.webp";


const Home = () => {
  return (
    <React.Fragment>
      <section className='section pb-0  hero-section' id='hero'>
        <div className='bg-overlay bg-overlay-pattern'></div>
        <Container>
          <Row className='justify-content-center'>
            <Col lg={8} sm={10}>
              <div className='text-center mt-lg-5 pt-4'>
              <h1 className="display-4 fw-semibold mb-3 lh-sm text-muted">NHS GP Locum Jobs Platform
              <p className="text-primary">  airGP </p></h1>
              {/* <p className="lead text-muted lh-base fs-20">The locum GP platform</p> */}
              <p className="lead lh-base text-muted">Exclusively for NHS GPs & Surgeries</p>


                <div className='d-flex gap-2 justify-content-center mt-4 mb-5'>
                  <a href='/login' className='btn btn-primary'>
                    Sign in for GPs{' '}
                    <i className='ri-arrow-right-line align-middle ms-1'></i>
                  </a>
                  <a href='https://org.airgp.co.uk/login' className='btn btn-success'>
                    Sign in for Organisations{' '}
                    <i className='ri-arrow-right-line align-middle ms-1'></i>
                  </a>
                </div>


                <div className='mt-4 mt-sm-5 pt-sm-5 mb-sm-n5 demo-carousel'>
                                <div className="demo-img-patten-top d-none d-sm-block">
                                    <img src={imgpattern} className="d-block img-fluid" alt="..." />
                                </div>
                                <div className="demo-img-patten-bottom d-none d-sm-block">
                                    <img src={imgpattern} className="d-block img-fluid" alt="..." />
                                </div>
                                <Swiper
                                    spaceBetween={30}
                                    effect={"fade"}
                                    loop={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    autoplay={{ delay: 2000, disableOnInteraction: false }}
                                    modules={[EffectFade, Autoplay]}
                                    className="mySwiper shadow-lg rounded" >

                                    <SwiperSlide className="carousel-inner  p-2 bg-white ">
                                        <img src={gpdemo} className="d-block w-100" alt="..." />
                                    </SwiperSlide>
                                    <SwiperSlide className="carousel-inner  p-2 bg-white ">
                                        <img src={pmdemo} className="d-block w-100" alt="..." />
                                    </SwiperSlide>
                                </Swiper>
                            </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="position-absolute start-0 end-0 bottom-0 hero-shape-svg">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                        // xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 1440 120">
                        <g mask="url(&quot;#SvgjsMask1003&quot;)" fill="none">
                            <path d="M 0,118 C 288,98.6 1152,40.4 1440,21L1440 140L0 140z">
                            </path>
                        </g>
                    </svg>
                </div>
      </section>
    </React.Fragment>
  )
}

export default Home
