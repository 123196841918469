import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Accordion,
  AccordionItem,
  Collapse
} from 'reactstrap'
import classnames from 'classnames'
import Autocomplete from 'react-google-autocomplete'
import { FilePond, File } from 'react-filepond'

//import images
import avatar1 from '../../../assets/images/users/avatar-1.jpg'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import {
  BANK_DETAILS_INFORMATION,
  EMAIL_PREFERENCES,
  getCookie,
  PENSION_DATA_VALUE,
  PROFILE_ADDRESS_STATE,
  PROFILE_INITIAL_STATE,
  PROFILE_TABS,
  PROFILE_TABS_ICONS,
  REACT_SELECT_DROPDOWN_INFO
} from '../../../utils/constants'
import profile from '../../../assets/images/profile.png'

import Select from 'react-select'
import { accountServices } from '../../../services/accountServices'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { scheduleServices } from '../../../services/scheduleServices'
import moment from 'moment'
import SpinnerComponent from '../../../Components/Common/Spinner'
import { bankServices } from '../../../services/bankServices'
import SuccessConfirmationModal from '../../../Components/Common/SuccessConfirmationModal'
import { pensionServices } from '../../../services/pensionServices'
import AddedAccountList from './AddedAccountList'
import ChangePassword from './ChangePassword'
import SavePensionDetails from './SavePensionDetails'
import ImageUploader from '../../../Components/Common/ImageUpload'
import { tokenDecode } from '../../../utils/constants'

const ProfileSettingPage = () => {
  const [pensionValue, setPensionValue] = useState(PENSION_DATA_VALUE)
  const urlParams = new URLSearchParams(window.location.search);
  const [activeTab, setActiveTab] = useState(Number(urlParams.get('tab')) || 0)
  const [profileAddress, setProfileAddress] = useState(PROFILE_ADDRESS_STATE)
  const [profileInfo, setProfileInfo] = useState(PROFILE_INITIAL_STATE)
  const [bankDetails, setBankDetails] = useState(BANK_DETAILS_INFORMATION)
  const [bankDetailsList, setBankDetailsList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showAddBankModal, setShowAddBankModal] = useState(false)
  const [languages, setLanguages] = useState([])
  const [qualifications, setQualifications] = useState([])
  const [itSystems, setItSystems] = useState([])
  const [profileUrl, setProfileUrl] = useState()
  const [progressCount, setProgressCount] = useState(0)
  const [pensionRegion, setPensionRegion] = useState([])
  const [employmentType, setEmploymentType] = useState('Self Employed')
  const [pensionPreferences, setPensionPreferences] = useState()
  const [emailPreferences, setEmailPreferences] = useState(EMAIL_PREFERENCES)
  const [password, setPassword] = useState()

  // const [limitedCompanyName, setLimitedCompanyName] = useState('')

  const [col1, setcol1] = useState(false)
  const t_col1 = () => {
    setcol1(!col1)
  }

  const token = localStorage.getItem('token')
  const user = tokenDecode(token);

  useEffect(() => {
    fetchLanguages(token)
    fetchQualifications(token)
    fetchUserProfileDetails(token)
    fetchItsystem()
    fetchProfilePhoto()
    fetchBankDetials(token)
    fetchPensionRegionList(token)
    fetchGetPensionPreferense(token)
    fetchEmailPreferences(token)
  }, [])

  const tabChange = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const fetchLanguages = token => {
    accountServices
      .getLanguages(token)
      .then(resp => {
        if (resp.success) {
          let language = [...languages]
          resp?.data?.map(_ => {
            language.push({ value: _.uuid, label: _.language })
          })
          setLanguages(language)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchQualifications = token => {
    accountServices
      .getQualifications(token)
      .then(resp => {
        if (resp.success) {
          let qualification = [...qualifications]
          resp?.data?.map(_ => {
            qualification.push({ value: _.uuid, label: _.qualification })
          })
          setQualifications(qualification)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchItsystem = () => {
    scheduleServices
      .getItSystems()
      .then(resp => {
        if (resp.success) {
          let itSystem = [...itSystems]
          resp?.it_systems?.map(_ => {
            itSystem.push({ value: _.uuid, label: _.name })
          })
          setItSystems(itSystem)
        }
      })
      .catch(err => console.log(err))
  }

  const handlePlaceSelect = place => {
    let copyedProfileInfo = { ...profileAddress }

    copyedProfileInfo.add_line_1 = place?.address_components[1]?.long_name
    copyedProfileInfo.add_line_2 = place?.address_components[2]?.long_name
    copyedProfileInfo.add_line_3 = place?.address_components[3]?.long_name
    copyedProfileInfo.add_line_4 = place?.address_components[4]?.long_name
    copyedProfileInfo.postcode = place?.address_components[0]?.long_name
    copyedProfileInfo.country = place?.address_components[5]?.long_name
    copyedProfileInfo.lat = place.geometry.location.lat()
    copyedProfileInfo.long = place.geometry.location.lng()

    setProfileAddress(copyedProfileInfo)
  }

  const profileAddressChange = event => {
    let copyedProfileInfo = { ...profileAddress }

    const name = event.target.name
    const value = event.target.value

    copyedProfileInfo[name] = value

    setProfileAddress(copyedProfileInfo)
  }

  const profileInfoChange = ({ target: { value, name } }) => {
    let copyedProfileInfo = { ...profileInfo }
    copyedProfileInfo[name] = value
    setProfileInfo(copyedProfileInfo)
  }

  const handleProfileDitailsSubmit = event => {
    const itSystem = []
    const qualification = []
    const language = []

    profileInfo.itSystem.map(_ => {
      itSystem.push(_.label)
    })

    profileInfo.qualification.map(_ => {
      qualification.push(_.label)
    })

    profileInfo.language.map(_ => {
      language.push(_.label)
    })

    event.preventDefault()
    const data = {
      dob: profileInfo.dateOfBirth,
      gender: profileInfo.gender,
      address_line_1: profileAddress.add_line_1,
      address_line_2: profileAddress.add_line_2,
      ...(profileAddress.add_line_3?.length !== 0 && {
        address_line_3: profileAddress.add_line_3
      }),
      ...(profileAddress.add_line_4?.length !== 0 && {
        address_line_4: profileAddress.add_line_4
      }),
      // address_line_5: profileAddress.country,
      postcode: profileAddress.postcode,
      country: 'England',
      contact_number: profileInfo.contact,
      language: language,
      smartcard_number: profileInfo.smartCardNumber,
      qualification: qualification,
      regulator_number: profileInfo?.regulatorNumber,
      gp_soc: itSystem,
      bio: profileInfo.bio,
      // latitude: profileAddress.lat || '',
      // longitude: profileAddress.long || '',
      employment_type:
        employmentType === 'Self Employed'
          ? 'self_employed'
          : 'limited_company' || 'self_employed'
    }

    setIsLoading(true)
    accountServices
      .patchSaveUserProfileDetails(data, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp?.message)
        } else {
          toast.error(resp?.message || resp.error[0].message)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchUserProfileDetails = token => {
    setIsLoading(true)
    accountServices
      .getUserProfileDetails(token)
      .then(resp => {
        countProgressNumber(
          resp.documentsVerified,
          resp.bankDetails,
          resp.profilePhoto
        )
        let copyedProfileInfo = { ...profileInfo }
        let copyedProfileAdd = { ...profileAddress }
        copyedProfileInfo.language = []
        copyedProfileInfo.qualification = []
        copyedProfileInfo.itSystem = []

        copyedProfileInfo.bio = resp?.data?.bio
        copyedProfileInfo.dateOfBirth = moment(resp?.data?.dob).format(
          'YYYY-MM-DD'
        )
        copyedProfileInfo.gender = resp?.data?.gender ? resp?.data?.gender : profileInfo.gender
        copyedProfileInfo.userName = `${resp?.data?.user?.title} ${resp?.data?.user?.firstname} ${resp?.data?.user?.lastname}`
        copyedProfileAdd.postcode = resp?.data?.postcode
        copyedProfileAdd.add_line_1 = resp?.data?.address_line_1
        copyedProfileAdd.add_line_2 = resp?.data?.address_line_2
        copyedProfileAdd.add_line_3 = resp?.data?.address_line_3
        copyedProfileAdd.add_line_4 = resp?.data?.address_line_4
        copyedProfileAdd.country = resp?.data?.address_line_5
        copyedProfileAdd.lat = resp?.data?.latitude
        copyedProfileAdd.long = resp?.data?.longitude

        copyedProfileInfo.regulatorType = resp?.data?.regulator_type
        copyedProfileInfo.regulatorStatus = resp?.data?.regulator_status
        copyedProfileInfo.regulatorNumber = resp?.data?.regulator_number
        copyedProfileInfo.smartCardNumber = resp?.data?.smartcard_number
        copyedProfileInfo.contact = resp?.data?.contact_number

        setEmploymentType(
          resp?.data?.employment_type === 'self_employed'
            ? 'Self Employed'
            : 'Limited Company' ?? 'Self Employed'
        )

        resp?.data?.user?.languages?.map(_ => {
          copyedProfileInfo.language.push({
            value: _.uuid,
            label: _.language
          })
        })

        resp?.data?.user?.qualification?.map(_ => {
          copyedProfileInfo.qualification.push({
            value: _.uuid,
            label: _.qualification
          })
        })

        resp?.data?.user?.gp_soc?.map(_ => {
          copyedProfileInfo.itSystem.push({
            value: _.uuid,
            label: _.name
          })
        })

        setProfileAddress(copyedProfileAdd)
        setProfileInfo(copyedProfileInfo)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const countProgressNumber = (
    documentVerified,
    paymentVerified,
    profileVerified
  ) => {
    const argsArray = [documentVerified, paymentVerified, profileVerified]
    argsArray.map(_ => {
      if (_) {
        setProgressCount(progressCount + 34)
      }
      return progressCount
    })
  }

  const fetchProfilePhoto = () => {
    setIsLoading(true)
    accountServices
      .getProfilePhoto(token)
      .then(resp => {
        if (resp.success) {
          accountServices.downloadDocument(resp?.data?.uuid,"profilephoto",token)
          .then(resp=> resp.blob())
          .then(resp=>URL.createObjectURL(resp))
          .then(resp=>setProfileUrl(resp))
          .catch(err => console.log(err))
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const bankDetailsOnChange = ({ target: { value, name } }) => {
    const copiedBankDetails = { ...bankDetails }
    if (name === 'sortcode') {
      var sortCodeNumber = value.split('-').join('')
      sortCodeNumber = sortCodeNumber
        ?.match(new RegExp('.{1,2}', 'g'))
        .join('-')
      copiedBankDetails[name] = sortCodeNumber
    } else {
      copiedBankDetails[name] = value
    }
    setBankDetails(copiedBankDetails)
  }

  const fetchAddBankDetails = event => {
    event.preventDefault()
    if (
      bankDetails.name_on_bank.length !== 0 &&
      bankDetails.bank_name.length !== 0 &&
      bankDetails.account_number.length !== 0 &&
      bankDetails.sortcode.length !== 0
    ) {
      const data = {
        name_on_bank: bankDetails.name_on_bank,
        bank_name: bankDetails.bank_name,
        account_number: bankDetails.account_number,
        sortcode: bankDetails.sortcode
      }
      bankServices
        .postAddBankDetails(data, token)
        .then(resp => {
          if (resp.success) {
            toast.success(resp.message)
            setShowAddBankModal(false)
            const copiedBankDetailsObj = {
              name_on_bank: '',
              bank_name: '',
              account_number: '',
              sortcode: ''
            }
            setBankDetails(copiedBankDetailsObj)
            fetchBankDetials(token)
          } else {
            toast.error(resp.message)
            setShowAddBankModal(false)
          }
        })
        .catch(err => console.log(err))
    } else {
      toast.error('Please fill all fields')
    }
  }

  const fetchBankDetials = token => {
    setIsLoading(true)
    bankServices
      .getBankDetails(token)
      .then(resp => {
        if (resp.success) {
          setBankDetailsList(resp.data || [])
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const handleAddBankDetailsClick = event => {
    event.preventDefault()
    if (
      bankDetails.name_on_bank.length !== 0 &&
      bankDetails.bank_name.length !== 0 &&
      bankDetails.account_number.length !== 0 &&
      bankDetails.sortcode.length !== 0
    ) {
      setShowAddBankModal(true)
    } else {
      toast.error('Please fill all fields')
    }
  }

  const fetchPensionRegionList = token => {
    setIsLoading(true)
    pensionServices
      .getPensionRegionsList(token)
      .then(resp => {
        if (resp.success) {
          const pensionRegionList = resp?.pension_regions?.map(_ => {
            return {
              value: _.uuid,
              label: _.region
            }
          })
          setPensionRegion(pensionRegionList)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchGetPensionPreferense = () => {
    setIsLoading(true)
    pensionServices
      .getPensionPreferences(token)
      .then(resp => {
        if (resp.success && resp.pension_preference) {
          const copiedPenionValue = { ...pensionValue }
          copiedPenionValue.ERRBO = resp.pension_preference.ERRBO

          copiedPenionValue.MPAVCs = resp.pension_preference.MPAVCs

          copiedPenionValue.SD_number = resp.pension_preference.SD_number

          copiedPenionValue.added_years = resp.pension_preference.added_years

          copiedPenionValue.additional_pension =
            resp.pension_preference.additional_pension

          copiedPenionValue.claim_pension =
            resp.pension_preference.claim_pension

          copiedPenionValue.pension_region =
            resp.pension_preference.pension_region.uuid

          copiedPenionValue.PCSE_region =
            resp.pension_preference.pcse_region.uuid

          copiedPenionValue.tier_contribution_rate =
            resp.pension_preference.tier_contribution_rate.uuid

          copiedPenionValue.defaultPensionRegion =
            resp.pension_preference.pension_region.region

          copiedPenionValue.defaultPensionPCSE =
            resp.pension_preference.pcse_region.region

          copiedPenionValue.defaultTireContribution =
            `${resp.pension_preference.tier_contribution_rate.type} | ${resp.pension_preference.tier_contribution_rate.tier_rate_name}`

          copiedPenionValue.claim_pension =
            resp.pension_preference.claim_pension

          copiedPenionValue.NI_Number = resp.pension_preference.ni_number

          copiedPenionValue.form_a_version = resp.pension_preference.form_a_version
          setPensionPreferences(resp.pension_preference)
          setPensionValue(copiedPenionValue)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchEmailPreferences = token => {
    setIsLoading(true)
    accountServices
      .getEmailPreferences(token)
      .then(resp => {
        if (resp.status) {
          const copiedEmailPreferences = { ...emailPreferences }
          copiedEmailPreferences.session_updates =
            resp?.emailPreferences?.session_updates
          copiedEmailPreferences.session_availabilities =
            resp?.emailPreferences?.session_availabilities
          copiedEmailPreferences.payment_received =
            resp?.emailPreferences?.payment_received
          copiedEmailPreferences.messages = resp?.emailPreferences?.messages
          copiedEmailPreferences.invoice_overdue =
            resp?.emailPreferences?.invoice_overdue
          copiedEmailPreferences.expiring_documents_reminder =
            resp?.emailPreferences?.expiring_documents_reminder

          setEmailPreferences(copiedEmailPreferences)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const handleEmailPreferencesChange = ({ target: { checked, name } }) => {
    const copiedEmailPreference = { ...emailPreferences }
    copiedEmailPreference[name] = checked
    setEmailPreferences(copiedEmailPreference)

    accountServices
      .patchEmailPreferences(copiedEmailPreference, token)
      .then(resp => {
        if (resp.success) {
          // toast.success(resp.message)
        } else {
          toast.error(resp.message)
        }
      })
      .catch(err => console.log(err))
  }

  const handleDeleteMyAccountClick = () => {
    accountServices
      .postDeleteAccount(password, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          t_col1()
        } else {
          toast.error(resp.message)
        }
      })
      .catch(err => console.log(err))
  }

  document.title = 'Profile Setting | airGP'
  return (
    <React.Fragment>
      <div className='page-content'>
        {isLoading && <SpinnerComponent />}
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card className='mt-n4 mx-n2 rounded-bottom border-top-0 rounded-0'>
                <div className='bg-airgp rounded-bottom border-top-0'>
                  <CardBody className='pb-0 px-4'>
                    <Row className='mb-3'>
                      <div className='col'>
                        <Row className='align-items-center g-3'>
                          <div className='col-md-auto '>
                            <ImageUploader></ImageUploader>
                          </div>
                          <div className='col'>
                            <div>
                              <h2 className='mb-1 text-capitalize text-light fw-semibold'>
                                {profileInfo.userName}
                              </h2>
                            </div>
                          </div>
                          <div className='col-lg-auto order-last order-lg-0 col-12 text-end'>
                          <Link
                                  to='/profile-info'
                                  className='btn btn-light btn-label'
                                >
                                  <i className='ri-user-line label-icon align-middle fs-16 me-2'></i>{' '}
                                  View Profile
                          </Link>
                          </div>
                        </Row>
                      </div>
                    </Row>
                    <Nav className='nav-tabs' role='tablist'>
                      {PROFILE_TABS.map((_, index) => (
                          (index !== 2 || (index === 2 && user['user_type_id'] === 3)) && (
                            <NavItem key={index} className='cursor'>
                              <NavLink
                                className={classnames({
                                  active: activeTab === index
                                })}
                                onClick={() => {
                                  tabChange(index)
                                }}
                              >
                                <i
                                  className={`${PROFILE_TABS_ICONS[index]} fs-20 d-inline-block d-md-none`}
                                ></i>{' '}
                                <span className='d-none d-md-inline-block fs-14'>
                                  {_}
                                </span>
                              </NavLink>
                            </NavItem>
                          )
                      ))}
                    </Nav>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
          <Col>
            <Card>
              <CardBody>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={0}>
                    <Form>
                      <Row>
                        <Col lg={3} className='mb-2'>
                          <Label
                            htmlFor='date-of-birth'
                            className='form-label mt-2 '
                          >
                            Date of Birth <span className='red-color'>*</span>
                          </Label>
                        </Col>
                        <Col lg={9} className='mb-2'>
                          <Input
                            name='dateOfBirth'
                            onChange={profileInfoChange}
                            value={profileInfo?.dateOfBirth || ""}
                            type='date'
                            className='form-control'
                            id='date-of-birth'
                          />
                        </Col>
                        <Col lg={3} className='mb-2'>
                          <Label htmlFor='gender-input' className='form-label'>
                            Gender <span className='red-color'>*</span>
                          </Label>
                        </Col>
                        <Col lg={9} className='mb-2'>
                          <select
                            id='gender-input'
                            className='form-select mb-3'
                            name='gender'
                            onChange={profileInfoChange}
                            value={profileInfo?.gender}
                          >
                            <option>Select your gender </option>
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</option>
                          </select>
                        </Col>
                        <Col lg={3} className='mb-2'>
                          <Label htmlFor='Smartcard' className='form-label'>
                            Smartcard number{' '}
                            <span className='red-color'>*</span>
                          </Label>
                        </Col>
                        <Col lg={9} className='mb-2'>
                          <div className='input-group'>
                            <Input
                              name='smartCardNumber'
                              onChange={profileInfoChange}
                              value={profileInfo.smartCardNumber || ''}
                              type='text'
                              className='form-control'
                              id='Smartcard'
                              placeholder='Enter your smartcard number'
                              pattern='[0-9]*'
                            />
                          </div>
                        </Col>
                        <Col lg={3} className='mb-2'>
                          <Label
                            htmlFor='PhoneNumber'
                            className='form-label'
                          >
                            Phone number <span className='red-color'>*</span>
                          </Label>
                        </Col>
                        <Col lg={9} className='mb-2'>
                          <div className='input-group'>
                            <Input
                              name='contact'
                              onChange={profileInfoChange}
                              value={profileInfo?.contact || ""}
                              type='text'
                              className='form-control'
                              id='PhoneNumber'
                              placeholder='Enter your contact number'
                              pattern='[0-9]*'
                            />
                          </div>
                        </Col>
                        {/* <Col lg={3} className='mb-2'>
                          <Label
                            htmlFor='RegulatorNumber'
                            className='form-label'
                          >
                            Regulator Number <span className='red-color'>*</span>
                          </Label>
                        </Col>
                        <Col lg={9} className='mb-2'>
                          <div className='input-group'>
                            <Input
                              name='regulatorNumber'
                              onChange={profileInfoChange}
                              value={profileInfo?.regulatorNumber || ""}
                              type='text'
                              className='form-control'
                              id='RegulatorNumber'
                              placeholder='Enter your regulator number'
                              pattern='[0-9]*'
                              disabled={profileInfo?.regulatorNumber}
                            />
                          </div>
                        </Col> */}
                        <Col lg={3} className='mb-2'>
                          <Label htmlFor='Address1' className='form-label'>
                            Address<span className='red-color'>*</span>
                          </Label>
                        </Col>
                        <Col lg={9} className='mb-2'>
                          <Input
                            type='text'
                            name='add_line_1'
                            onChange={profileAddressChange}
                            value={profileAddress?.add_line_1 || ""}
                            className='form-control'
                            id='Address1'
                            placeholder='Address line 1'
                            autoComplete="address-line1"
                          />
                        </Col>
                        <Col lg={3} className='mb-2'></Col>
                        <Col lg={9} className='mb-2'>
                          <Input
                            type='text'
                            name='add_line_2'
                            onChange={profileAddressChange}
                            value={profileAddress?.add_line_2 || ""}
                            className='form-control'
                            id='Address2'
                            placeholder='Address line 2 - (optional)'
                            autoComplete="address-line2"
                          />
                        </Col>
                        <Col lg={3} className='mb-2'></Col>
                        <Col lg={9} className='mb-2'>
                          <Input
                            type='text'
                            name='add_line_3'
                            onChange={profileAddressChange}
                            value={profileAddress?.add_line_3 || ""}
                            className='form-control'
                            id='Address3'
                            placeholder='Town or city'
                            autoComplete="address-level2"
                          />
                        </Col>
                        <Col lg={3} className='mb-2'></Col>
                        <Col lg={9} className='mb-2'>
                          <Input
                            type='text'
                            name='add_line_4'
                            onChange={profileAddressChange}
                            value={profileAddress?.add_line_4 || ""}
                            className='form-control'
                            id='Address4'
                            placeholder='County (optional)'
                            // autoComplete='addressCounty'
                          />
                        </Col>
                        <Col lg={3} className='mb-2'>
                          <Label
                            htmlFor='postcode-input'
                            className='form-label color-red'
                            autoComplete="postal-code"
                          >
                            Postcode <span className='red-color'>*</span>
                          </Label>
                        </Col>
                        <Col lg={9} className='mb-2'>
                          {/* <Autocomplete
                            id='postcode-input'
                            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                            className='form-control'
                            onPlaceSelected={place => {
                              handlePlaceSelect(place)
                            }}
                            inputvalue={['address']}
                            componentrestrictions={{ country: 'GB' }}
                            name='postcode'
                            libraries={['places']}
                            placeholder='Enter postcode *'
                            value={profileAddress?.postcode || ''}
                            onChange={profileAddressChange}
                            options={{ types: ['postal_code'] }}
                            required
                          /> */}
                          <Input
                            type='text'
                            name='postcode'
                            onChange={profileAddressChange}
                            value={profileAddress?.postcode || ""}
                            className='form-control'
                            id='postcode-input'
                            placeholder='Postcode'
                          />
                        </Col>
                        <Col lg={3} className='mb-2'>
                          <Label
                            htmlFor='self-employed'
                            className='form-label'
                          >
                            Employment Type
                            <span className='red-color'> *</span>
                          </Label>
                        </Col>
                        <Col lg={9} className='mb-2'>
                          <div className='mb-2'>
                            <Input
                              id='self-employed'
                              name='emploment-type'
                              type='radio'
                              value='Self Employed'
                              checked={employmentType === 'Self Employed'}
                              onChange={e => {
                                if (e.target.checked) {
                                  setEmploymentType(e.target.value)
                                }
                              }}
                              className='form-check-input'
                            />
                            <Label className='ms-2 me-3' role='button' for='self-employed'>Self Employed</Label>
                            <Input
                              id='Limited_Company'
                              name='emploment-type'
                              type='radio'
                              value='Limited Company'
                              checked={employmentType === 'Limited Company'}
                              onChange={e => {
                                if (e.target.checked) {
                                  setEmploymentType(e.target.value)
                                }
                              }}
                              className='form-check-input'
                            />
                            <Label className='ms-2' role='button' for='Limited_Company'>Limited Company</Label>
                          </div>
                        </Col>
                        {/* {employmentType === "Limited Company" && <Col lg={12}>
                          <div className='mb-3'>
                            <Input
                              name='smartCardNumber'
                              onChange={e => {
                                setLimitedCompanyName(e.target.value)
                              }}
                              value={limitedCompanyName || ''}
                              type='text'
                              className='form-control'
                              id='Smartcard'
                              placeholder='Enter your Company Name'
                            />
                          </div>
                        </Col>} */}
                        <Col lg={3} className='mb-2'>
                          <Label htmlFor='it-system' className='form-label'>
                            IT System <span className='red-color'>*</span>
                          </Label>
                        </Col>
                        <Col lg={9} className='mb-2'>
                          <Select
                            name='itSystem'
                            inputId='it-system'
                            value={profileInfo.itSystem || {}}
                            isMulti={true}
                            isClearable={true}
                            onChange={e => {
                              let copyiedProfileObj = { ...profileInfo }
                              copyiedProfileObj.itSystem = e
                              setProfileInfo(copyiedProfileObj)
                            }}
                            options={itSystems}
                          />
                        </Col>
                        <Col lg={3} className='mb-2'>
                          <Label
                            htmlFor='Languages'
                            className='form-label'
                          >
                            Languages
                          </Label>
                        </Col>
                        <Col lg={9} className='mb-2'>
                          <Select
                            name='language'
                            inputId='Languages'
                            value={profileInfo.language || {}}
                            isMulti={true}
                            isClearable={true}
                            onChange={e => {
                              let copyiedProfileObj = { ...profileInfo }
                              copyiedProfileObj.language = e
                              setProfileInfo(copyiedProfileObj)
                            }}
                            options={languages}
                          />
                        </Col>
                        <Col lg={3} className='mb-2'>
                          <Label
                            htmlFor='Qualifications'
                            className='form-label'
                          >
                            Qualifications <span className='red-color'>*</span>
                          </Label>
                        </Col>
                        <Col lg={9} className='mb-2'>
                          <Select
                            name='qualification'
                            inputId='Qualifications'
                            value={profileInfo.qualification || {}}
                            isMulti={true}
                            isClearable={true}
                            onChange={e => {
                              let copyiedProfileObj = { ...profileInfo }
                              copyiedProfileObj.qualification = e
                              setProfileInfo(copyiedProfileObj)
                            }}
                            options={qualifications}
                          />
                        </Col>
                        <Col lg={3} className='mb-2'>
                          <Label
                            htmlFor='exampleFormControlTextarea'
                            className='form-label'
                          >
                            About
                          </Label>
                        </Col>
                        <Col lg={9} className='mb-2'>
                          <textarea
                            name='bio'
                            onChange={profileInfoChange}
                            value={profileInfo?.bio || ""}
                            className='form-control'
                            id='about-text-box'
                            placeholder='Brief description about yourself, for example: years of experience, special interest, or something that makes you stands out!'
                            rows='7'
                          ></textarea>
                        </Col>
                        <Col lg={6} className='mb-2'>
                          <div className='hstack gap-2 justify-content-start mt-5'>
                            <button
                              type='button'
                              className='btn btn-primary'
                              onClick={handleProfileDitailsSubmit}
                            >
                              Update Profile
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </TabPane>
                  <TabPane tabId={1}>
                    <div id='newlink'>
                      <div id='1'>
                        <Row>
                          <Col lg={3} className='mb-2'>
                            <Label htmlFor='Name' className='form-label'>
                              Name or Limited Company name{' '}
                              <span className='red-color'>*</span>
                            </Label>
                          </Col>
                          <Col lg={9} className='mb-2'>
                            <Input
                              type='text'
                              name='name_on_bank'
                              onChange={bankDetailsOnChange}
                              value={bankDetails.name_on_bank || ''}
                              className='form-control'
                              id='Name'
                              autoComplete='name'
                              placeholder='Enter your name on bank acount'
                            />
                          </Col>
                          <Col lg={3} className='mb-2'>
                            <Label htmlFor='bankName' className='form-label'>
                              Bank name <span className='red-color'>*</span>
                            </Label>
                          </Col>
                          <Col lg={9} className='mb-2'>
                            <Input
                              type='text'
                              name='bank_name'
                              onChange={bankDetailsOnChange}
                              value={bankDetails.bank_name || ''}
                              className='form-control'
                              id='bankName'
                              placeholder='Enter your bank name'
                              autoComplete='off'
                            />
                          </Col>
                          <Col lg={3} className='mb-2'>
                            <Label htmlFor='accountNo' className='form-label'>
                              Bank account number{' '}
                              <span className='red-color'>*</span>
                            </Label>
                          </Col>
                          <Col lg={9} className='mb-2'>
                            <Input
                              type='text'
                              name='account_number'
                              onChange={bankDetailsOnChange}
                              value={bankDetails.account_number || ''}
                              className='form-control'
                              id='accountNo'
                              placeholder='Enter your bank account number'
                              maxLength={8}
                              autoComplete='off'
                            />
                          </Col>
                          <Col lg={3}>
                            <Label htmlFor='Sortcode' className='form-label'>
                              Sort code number <span className='red-color'>*</span>
                            </Label>
                          </Col>
                          <Col lg={9} className='mb-2'>
                            <Input
                              type='text'
                              name='sortcode'
                              onChange={bankDetailsOnChange}
                              value={bankDetails.sortcode || ''}
                              className='form-control'
                              id='Sortcode'
                              placeholder='Enter your bank account sortcode'
                              maxLength={8}
                              autoComplete='off'
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <Row>
                    <Col lg={12}>
                      <div>
                        <button
                          className='btn btn-primary'
                          onClick={handleAddBankDetailsClick}
                        >
                          Add
                        </button>
                      </div>
                    </Col>
                    </Row>
                    <Row><Col>
                    {bankDetailsList.length !== 0 && (
                      <AddedAccountList
                        bankDetailsList={bankDetailsList}
                        fetchBankDetials={fetchBankDetials}
                      />
                    )}
                    </Col></Row>
                  </TabPane>
                    <TabPane tabId={2}>
                      <SavePensionDetails
                        pensionRegion={pensionRegion}
                        pensionValue={pensionValue}
                        setPensionValue={setPensionValue}
                        setIsLoading={setIsLoading}
                        pensionPreferences={pensionPreferences}
                        onclickSaveSign={() => fetchGetPensionPreferense()}
                     />
                    </TabPane>
                  <TabPane tabId={3}>
                    <ChangePassword setActiveTab={setActiveTab} />
                    <hr />
                    <div className='mb-3 mt-3'>
                      <h5 className=' mb-3'>Email settings:</h5>
                      <ul className='list-unstyled mb-0'>
                        <li className='d-flex'>
                          <div className='flex-grow-1'>
                            <Label
                              htmlFor='directMessage'
                              className='form-check-label fs-14'
                            >
                              Session updates
                            </Label>
                            <p className='text-muted'>
                              Receive an email when session is accepted by an
                              organisation
                            </p>
                          </div>
                          <div className='flex-shrink-0'>
                            <div className='form-check form-switch'>
                              <Input
                                className='form-check-input'
                                role='switch'
                                type='checkbox'
                                id='directMessage'
                                name='session_updates'
                                checked={
                                  emailPreferences?.session_updates || false
                                }
                                onChange={handleEmailPreferencesChange}
                              />
                            </div>
                          </div>
                        </li>
                        <li className='d-flex'>
                          <div className='flex-grow-1'>
                            <Label
                              htmlFor='documentExpiration'
                              className='form-check-label fs-14'
                            >
                              Expiring documents reminder
                            </Label>
                            <p className='text-muted'>
                              Receive an email when a document is due to expire
                              soon
                            </p>
                          </div>
                          <div className='flex-shrink-0'>
                            <div className='form-check form-switch'>
                              <Input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                id='documentExpiration'
                                name='expiring_documents_reminder'
                                checked={
                                  emailPreferences?.expiring_documents_reminder ||
                                  false
                                }
                                onChange={handleEmailPreferencesChange}
                              />
                            </div>
                          </div>
                        </li>
                        <li className='d-flex'>
                          <div className='flex-grow-1'>
                            <Label
                              htmlFor='messageOrganisation'
                              className='form-check-label fs-14'
                            >
                              Messages
                            </Label>
                            <p className='text-muted'>
                              Receive an email when an organisation send you a
                              message
                            </p>
                          </div>
                          <div className='flex-shrink-0'>
                            <div className='form-check form-switch'>
                              <Input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                id='messageOrganisation'
                                name='messages'
                                checked={emailPreferences?.messages || false}
                                onChange={handleEmailPreferencesChange}
                              />
                            </div>
                          </div>
                        </li>
                        <li className='d-flex'>
                          <div className='flex-grow-1'>
                            <Label
                              htmlFor='invoiceReminder'
                              className='form-check-label fs-14'
                            >
                              Invoice
                            </Label>
                            <p className='text-muted'>
                              Receive an email when an invoice is overdue
                            </p>
                          </div>
                          <div className='flex-shrink-0'>
                            <div className='form-check form-switch'>
                              <Input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                id='invoiceReminder'
                                name='invoice_overdue'
                                checked={
                                  emailPreferences?.invoice_overdue || false
                                }
                                onChange={handleEmailPreferencesChange}
                              />
                            </div>
                          </div>
                        </li>
                        <li className='d-flex'>
                          <div className='flex-grow-1'>
                            <Label
                              htmlFor='invoicePaid'
                              className='form-check-label fs-14'
                            >
                              Payment
                            </Label>
                            <p className='text-muted'>
                              Receive an email when an invoice is paid
                            </p>
                          </div>
                          <div className='flex-shrink-0'>
                            <div className='form-check form-switch'>
                              <Input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                id='invoicePaid'
                                name='payment_received'
                                checked={
                                  emailPreferences?.payment_received || false
                                }
                                onChange={handleEmailPreferencesChange}
                              />
                            </div>
                          </div>
                        </li>
                        <li className='d-flex'>
                          <div className='flex-grow-1'>
                            <Label
                              htmlFor='suitableSessions'
                              className='form-check-label fs-14'
                            >
                              Session availabilities
                            </Label>
                            <p className='text-muted'>
                              Receive an email summary of suitable sessions
                            </p>
                          </div>
                          <div className='flex-shrink-0'>
                            <div className='form-check form-switch'>
                              <Input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                id='suitableSessions'
                                name='session_availabilities'
                                checked={
                                  emailPreferences?.session_availabilities ||
                                  false
                                }
                                onChange={handleEmailPreferencesChange}
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <hr />
                    <div>
                      <Accordion
                        className='accordion-fill-danger'
                        id='delete-account-accordion'
                        open='0'
                        toggle={() => t_col1()}
                      >
                        <AccordionItem>
                          <h2 className='accordion-header'>
                            <button
                              className={classnames(
                                'accordion-button btn-soft-danger',
                                { collapsed: !col1 }
                              )}
                              type='button'
                              onClick={t_col1}
                              style={{ cursor: 'pointer' }}
                            >
                              Delete Account:
                            </button>
                          </h2>
                          <Collapse
                            isOpen={col1}
                            className='accordion-collapse'
                          >
                            <div className='accordion-body'>
                              <p className='text-muted'>
                                Enter your password to request a deletion of
                                your account
                              </p>
                              <div>
                                <Input
                                  type='password'
                                  className='form-control'
                                  id='passwordInput'
                                  placeholder='Enter your password'
                                  style={{ maxWidth: '265px' }}
                                  value={password || ""}
                                  onChange={e => setPassword(e.target.value)}
                                />
                              </div>
                              <div
                                className='hstack gap-2 mt-3'
                                onClick={handleDeleteMyAccountClick}
                              >
                                <Link to='#' className='btn btn-soft-danger'>
                                  Delete My Acccount
                                </Link>
                              </div>
                            </div>
                          </Collapse>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          <SuccessConfirmationModal
            show={showAddBankModal}
            onDeleteClick={fetchAddBankDetails}
            onCloseClick={() => setShowAddBankModal(false)}
            deleteMsg='Save bank account ?'
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProfileSettingPage
