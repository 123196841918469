import React from 'react'

const Spinner2 = () => {
  return (
    <div className='loader-container'>
      <div className='loader'></div>
    </div>
  )
}

export default Spinner2

// .loader-container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100vh;
//   }

//   .loader {
//     border: 5px solid #f3f3f3;
//     border-top: 5px solid #3498db;
//     border-radius: 50%;
//     width: 50px;
//     height: 50px;
//     animation: spin 1s linear infinite;
//   }

//   @keyframes spin {
//     0% { transform: rotate(0deg); }
//     100% { transform: rotate(360deg); }
//   }
