import {
  GET_AMENDED_SESSIONS,
  GET_EXPENSE_TYPES,
  GET_INVOICE_PDF,
  GET_INVOICE_PDF_LINK,
  GET_INVOICE_WITH_STATUS,
  GET_TAX_YEARS,
  GET_WORKED_SESSIONS,
  PATCH_APPROVE_AMENDED_SESSIONS,
  POST_AMEND_SESSION,
  POST_GENERATE_INVOICE,
  POST_REJECT_AMENDED_SESSION,
  POST_UPDATE_INOVICE_STATUS
} from './endpoints'

import { headerContent } from '.'
import { getCookie } from '../utils/constants'
var token = localStorage.getItem('token')

export const headerContentWithAuthorization = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`
}

const customHeaders = token => {
  let copiedHeaderContentWithAuthorization = {
    ...headerContentWithAuthorization
  }
  copiedHeaderContentWithAuthorization['Authorization'] = `Bearer ${token}`
  return copiedHeaderContentWithAuthorization
}

export const invoiceServices = {
  // Get Organisation details API
  getWorkedSessions: (orgId, limit, page, token) =>
    fetch(GET_WORKED_SESSIONS.endpoint(orgId, limit, page), {
      method: GET_WORKED_SESSIONS.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Generate invoice api
  postGenerateInvoice: (data, token,category) =>
    fetch(POST_GENERATE_INVOICE.endpoint(category), {
      method: POST_GENERATE_INVOICE.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get Invoice API
  getInvoicePDF: token =>
    fetch(GET_INVOICE_PDF.endpoint, {
      method: GET_INVOICE_PDF.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.blob()
      })
      .catch(err => console.log(err)),

  // Get Invoice from status unpaid/paid
  getInvoicesFromStatus: (status, orgId, limit, page, token) =>
    fetch(GET_INVOICE_WITH_STATUS.endpoint(status, orgId, limit, page), {
      method: GET_INVOICE_WITH_STATUS.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Post update invoice status to unpaid/paid
  postUpdateInvoiceStatus: (id, status, data, token) =>
    fetch(POST_UPDATE_INOVICE_STATUS.endpoint(id, status), {
      method: POST_UPDATE_INOVICE_STATUS.method,
      headers: customHeaders(token),
      body: data ? (typeof data === 'string' ? data : JSON.stringify(data)) : ''
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Post Amend session
  postAmendSession: (data, token) =>
    fetch(POST_AMEND_SESSION.endpoint, {
      method: POST_AMEND_SESSION.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get Amended session
  getAmendedSessions: (id, token) =>
    fetch(GET_AMENDED_SESSIONS.endpoint(id), {
      method: GET_AMENDED_SESSIONS.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Approve Amended session
  patchApproveAmendedSession: (data, token) =>
    fetch(PATCH_APPROVE_AMENDED_SESSIONS.endpoint, {
      method: PATCH_APPROVE_AMENDED_SESSIONS.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Reject Amended session
  postRejectAmendedSession: (data, token) =>
    fetch(POST_REJECT_AMENDED_SESSION.endpoint, {
      method: POST_REJECT_AMENDED_SESSION.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Download session
  getDownloadPdfLink: (id, token) =>
    fetch(GET_INVOICE_PDF_LINK.endpoint(id), {
      method: GET_INVOICE_PDF_LINK.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Expense types session
  getExpenseTypes: token =>
    fetch(GET_EXPENSE_TYPES.endpoint, {
      method: GET_EXPENSE_TYPES.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Expense types session
  getTaxYear: token =>
    fetch(GET_TAX_YEARS.endpoint, {
      method: GET_TAX_YEARS.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err))
}
