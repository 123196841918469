import React, { useEffect, useState, useCallback, useRef } from 'react'
import {Container,Button,Input,DropdownToggle,DropdownMenu,Dropdown,Row,Col,Card,CardBody,TabContent,TabPane} from 'reactstrap'
import { Link, useLocation } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import { isEmpty, map } from 'lodash'
import avatar2 from '../../assets/images/users/avatar-1.jpg'
import userDummayImage from '../../assets/images/users/user-dummy-img.jpg'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { profileImgColor, tokenDecode } from '../../utils/constants'
import { chatServices } from '../../services/chatServices'
import { toast } from 'react-toastify'
import moment from 'moment'
import Loader from '../../Components/Common/Loader'
import { socket } from '../../socket/socket'
import SpinnerComponent from '../../Components/Common/Spinner'

const Chat = () => {
  const userChatShow = useRef()
  const [customActiveTab, setcustomActiveTab] = useState('1')
  const [Chat_Box_Username, setChat_Box_Username] = useState('Lisa Parker')
  const [clientUuid, setClientUuid] = useState('')
  const [Chat_Box_Image, setChat_Box_Image] = useState(avatar2)
  const [currentRoomId, setCurrentRoomId] = useState(1)
  const [messageBox, setMessageBox] = useState(null)
  const [curMessage, setcurMessage] = useState('')
  const [search_Menu, setsearch_Menu] = useState(false)
  const [reply, setreply] = useState('')
  const [messages, setMessage] = useState([])
  const [activeOrg, setActiveOrg] = useState([])
  const [page, setPage] = useState(1)
  const [clientWorkedOrg , setClientWorkedOrg] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const token = localStorage.getItem('token')
  const conversation_id = localStorage.getItem('conversation_id')
  let chatId = new URLSearchParams(useLocation().search).get('id')
  const chatRef = useRef(null)
  const inputRef = useRef(null)
  const [activePMOrg, setActivePMOrg] = useState();

  useEffect(() => {
    if (token) {
      const clientIdFromtoken = tokenDecode(token)
      setClientUuid(clientIdFromtoken.uuid)
    }
  }, [token])

  useEffect(() => {
    if (token)
      socket.emit('addClient', {
        uuid: tokenDecode(token).uuid
      })
    socket.emit('checkOnline',true)
  }, [token])
  const scrollToBottom = useCallback(() => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000
    }
  }, [messageBox])

  useEffect(() => {
    socket.on('getMessage', data => {
      // if (messages.length !== 0) {
      if (messages.length !== 0) {

        moveUserUpWhenNewMsg(data)
        const messageObj = {
          uuid: '9fb949aa-cf45-4e70-aa9b-d6105ece7167',
          message: data.message,
          read_recipient: 0,
          conversation_id: 1,
          sender: data.sender,
          createdAt: moment().format(),
          updatedAt: moment().format()
        }
        const copiedMessageArr = [...messages]
        if(clientWorkedOrg[currentRoomId]?.uuid === data?.sender?.uuid){
        copiedMessageArr.push(messageObj)
      }
      setMessage(copiedMessageArr)
      } else {
        moveUserUpWhenNewMsg(data)
        const messageObj = {
          uuid: '9fb949aa-cf45-4e70-aa9b-d6105ece7167',
          message: data.message,
          read_recipient: 0,
          conversation_id: 1,
          sender: data.sender,
          createdAt: moment().format(),
          updatedAt: moment().format()
        }
        if(clientWorkedOrg[currentRoomId]?.uuid === data?.sender?.uuid){

          messages.push(messageObj)
        }
        setMessage(messages)
      }
    })
  }, [socket, messages])

  // const moveUserUpWhenNewMsg = (newMsg) => {
  //   let newMessaageUserIndex 
  //    clientWorkedOrg.map((item,index) => {
  //   if(newMsg?.sender?.uuid === item?.uuid){
  //      newMessaageUserIndex = index
      
  //   }})


  //  const removedEle =  clientWorkedOrg.splice(newMessaageUserIndex, newMessaageUserIndex + 1)[0]
  //  clientWorkedOrg.unshift(removedEle)
    
  //   setClientWorkedOrg(clientWorkedOrg)
  // }
  
  useEffect(() => {
    setClientWorkedOrg(prevOrgs => {
      const sortedOrgs = prevOrgs.slice().sort((a, b) => {
        const lastMessageTimeA = a.lastMessageTime || 0;
        const lastMessageTimeB = b.lastMessageTime || 0;
        return moment(lastMessageTimeB).diff(moment(lastMessageTimeA));
      });
      return sortedOrgs;
    });
  }, [messages]);
  
  useEffect(() => {
    socket.on('getActiveOrgs', data => {
      setActiveOrg(data)
    })
  }, [socket])

  useEffect(() => {
    fetchGetOrgWhereClientWorks(token)
    // markAsRead(localStorage.getItem('conversation_id'),tokenDecode(token)?.uuid,token);
  }, [])



  useEffect(() => {
    if (!isEmpty(messages)) scrollToBottom()
  }, [messages, scrollToBottom])

  const fetchGetOrgWhereClientWorks = token => {
    setIsLoading(true)
    chatServices
      .getOrgWhereClientWork(token)
      .then(resp => {
        if (resp.success) {
          setClientWorkedOrg(resp.organisations)

          if (chatId) {
            const chatOrg = resp.organisations.find(
              item => item.uuid === chatId
            )
            const idx = resp.organisations.findIndex(
              item => item.uuid === chatId
            )
            
            if (chatOrg) {
              setActivePMOrg(chatOrg);
              fetchConversation(chatOrg.uuid, tokenDecode(token).uuid, token)
              userChatOpen(chatOrg?.uuid, chatOrg?.name, '', idx, undefined)
            }
          } else {
            setActivePMOrg(resp?.organisations[0])
            fetchConversation(
              resp.organisations[0]?.uuid,
              tokenDecode(token).uuid,
              token
            )
            userChatOpen(
              resp.organisations[0]?.uuid,
              resp.organisations[0]?.name,
              '',
              0,
              undefined
            )
          }
        } else {
          toast.error('Something went wrong!')
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchConversation = (org_id, userId = clientUuid, token) => {
    chatServices
      .getConversationClients(org_id, userId, token)
      .then(resp => {
        if (resp?.success) {
          if (resp?.conversation) {
            localStorage.setItem('conversation_id', resp?.conversation?.uuid)
            fetchGetMessages(resp?.conversation?.uuid, page, token)
          } else {
            localStorage.removeItem('conversation_id')
          }
        }
      })
      .catch(err => console.log(err))
  }

  const fetchGetMessages = (conversationId, page = 1, token) => {
    chatServices
      .getMessages(conversationId, page, token)
      .then(resp => {
        if (resp.success) {
          setMessage(resp?.messages)
        }
      })
      .catch(err => console.log(err))
  }

  const markAsRead = (conversationId, uuid, token) => {
    chatServices
      .markAsReadMessages(conversationId, uuid, token)
      .then(resp => {})
      .catch(err => console.log(err))
  }

  const fetchSendMessages = (
    message,
    conversation_uuid,
  ) => {
    const data = {
      message,
      conversation_uuid
    }
    if (message) {
      chatServices
        .postSendMessage(data, token)
        .then(resp => {
          if (resp.success) {
            setcurMessage('')
          } else {
            setcurMessage('')
            toast.error(
              'You dont have authority to chat with this organisation!'
            )
          }
        })
        .catch(err => console.log(err))
    }
  }

  //Use For Chat Box
  const userChatOpen = (id, name, status, roomId, image) => {
    setChat_Box_Username(name)
    setCurrentRoomId(roomId)
    setChat_Box_Image(image)
    if (window.innerWidth < 991) {
      userChatShow.current.classList.add("user-chat-show");
    }
  }

  const backToUserChat = () => {
    userChatShow.current.classList.remove("user-chat-show");
  }

  const onKeyPress = e => {
    const { key } = e
    if (key === 'Enter') {
      sendMessage(e.target.value)
      fetchSendMessages(e.target.value, conversation_id, clientUuid, token)
    }
  }

  //serach recent user
  const searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue
    input = document.getElementById('search-user')
    filter = input.value.toUpperCase()
    var userList = document.getElementsByClassName('users-list')
    Array.prototype.forEach.call(userList, function (el) {
      li = el.getElementsByTagName('li')
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName('a')[0]
        txtValue = a.textContent || a.innerText
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = ''
        } else {
          li[i].style.display = 'none'
        }
      }
    })
  }

  //Search Message
  const searchMessages = () => {
    var searchInput, searchFilter, searchUL, searchLI, a, i, txtValue
    searchInput = document.getElementById('searchMessage')
    searchFilter = searchInput.value.toUpperCase()
    searchUL = document.getElementById('users-conversation')
    searchLI = searchUL.getElementsByTagName('li')
    Array.prototype.forEach.call(searchLI, function (search) {
      a = search.getElementsByTagName('p')[0]
        ? search.getElementsByTagName('p')[0]
        : ''
      txtValue =
        a.textContent || a.innerText ? a.textContent || a.innerText : ''
      if (txtValue.toUpperCase().indexOf(searchFilter) > -1) {
        search.style.display = ''
      } else {
        search.style.display = 'none'
      }
    })
  }

  const sendMessage = message => {
    if (message) {
      const messageObj = {
        uuid: '9fb949aa-cf45-4e70-aa9b-d6105ece7167',
        message: message,
        read_recipient: 0,
        conversation_id: 1,
        sender: {
          uuid: tokenDecode(token).uuid,
          firstName: tokenDecode(token).firstName
        },
        createdAt: moment().format(),
        updatedAt: moment().format()
      }

      const copiedMessageArr = [...messages]
      copiedMessageArr.push(messageObj)

      moveUserUpWhenNewMsgSend(messageObj);
      setMessage(copiedMessageArr)

      socket.emit(
        'sendMessageToOrg',
        {
          uuid: clientWorkedOrg[currentRoomId].uuid,
          message: message,
          sender: {
            uuid: tokenDecode(token).uuid,
            firstName: tokenDecode(token).firstName,
            lastName: tokenDecode(token).lastName
          }
        },
        resp => {
          // console.log('Resp', resp)
        }
      )
    } else {
      toast.error('Please enter message!')
    }
  }

  useEffect(() => {
    inputRef.current?.focus()
  }, [search_Menu])

  useEffect(() => {
    chatRef.current?.focus()
  }, [curMessage])

  const handleClientClick = () => {
    socket.on('getActiveOrgs', data => {
      setActiveOrg(data)
    })
  }

  function removeAndAddToBeginning(arr, index) {
      if (index >= 0 && index < arr.length) {
          const removedElement = arr.splice(index, 1)[0];
          arr.unshift(removedElement);
          return arr
      }
      return arr
  }

  const moveUserUpWhenNewMsg = (newMsg) => {
    let newMessaageUserIndex 
    clientWorkedOrg?.map((item,index) => {
      if(newMsg?.sender?.uuid === item?.uuid){
        newMessaageUserIndex = index
      }})

      const clientWorkedOrgCopy = [...clientWorkedOrg]
      const sortedClientWorkedOrgCopy = removeAndAddToBeginning(clientWorkedOrgCopy, newMessaageUserIndex);
      sortedClientWorkedOrgCopy.map((item,index) => {
        if(activePMOrg?.uuid === item?.uuid){
          setCurrentRoomId(index);
        }})

      const newClietWorkedOrg = sortedClientWorkedOrgCopy?.map((item, index)=>{
        if(newMsg?.sender?.uuid === item?.uuid){
          return { ...item, practiceManagerUnreadMessages: item?.practiceManagerUnreadMessages+1 }
        }else{
          return item
        }
      })

      setClientWorkedOrg(newClietWorkedOrg)

  }

  const moveUserUpWhenNewMsgSend = (newMsg) => {
      const clientWorkedOrgCopy = [...clientWorkedOrg]
      const sortedClientWorkedOrgCopy = removeAndAddToBeginning(clientWorkedOrgCopy, currentRoomId);
      setClientWorkedOrg(sortedClientWorkedOrgCopy)
      userChatOpen(
        sortedClientWorkedOrgCopy[0]?.uuid,
        sortedClientWorkedOrgCopy[0]?.name,
        sortedClientWorkedOrgCopy[0]?.status,
        0,
        sortedClientWorkedOrgCopy[0]?.image
      )
      handleClientClick(sortedClientWorkedOrgCopy[0]?.uuid)
      
  }

  const makeCountOfUnreadMessagesZero = (chat)=>{
    const newClietWorkedOrg = clientWorkedOrg?.map((item,index) => {
        if(chat?.uuid === item?.uuid){
          return {...item, practiceManagerUnreadMessages:0 }
          
        }else{
          return item
        }
      })
    
    setClientWorkedOrg(newClietWorkedOrg);
  }

  document.title = 'Chat | airGP'

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {isLoading && <SpinnerComponent />}
          <div className='chat-wrapper d-lg-flex gap-1 mt-n4 p-0  bg-light border-light border border-1'>
            <div className='chat-leftsidebar'>
              <div className='px-4 pt-4 mb-3'>
                <div className='d-flex align-items-start'>
                  <div className='flex-grow-1'>
                    <h5 className='mb-4'>Chats</h5>
                  </div>
                </div>
                <div className='search-box'>
                  <input
                    onKeyUp={searchUsers}
                    id='search-user'
                    type='text'
                    className='form-control  border-light'
                    placeholder='Search here...'
                  />
                  <i className='ri-search-line search-icon'></i>
                </div>
              </div>

              <TabContent activeTab={customActiveTab} className='text-muted'>
                <TabPane tabId='1' id='chats'>
                  <SimpleBar
                    className='chat-room-list pt-3'
                    style={{ margin: '-16px 0px 0px' }}
                  >
                    <div className='d-flex align-items-center px-4 mb-2'>
                      <div className='flex-grow-1'>
                        <h4 className='mb-0 fs-11 text-muted text-uppercase'>
                          Direct Messages
                        </h4>
                      </div>
                    </div>

                    <div className='chat-message-list'>
                      <ul
                        className='list-unstyled chat-list chat-user-list users-list'
                        id='userList'
                      >
                        {clientWorkedOrg?.map((chat, idx) => (
                          <li
                            key={chat?.uuid + idx}
                            className={currentRoomId === idx ? 'active' : ''}
                            onClick={() => {
                              fetchConversation(chat?.uuid, clientUuid, token)
                              handleClientClick(chat?.uuid)
                              markAsRead(localStorage.getItem('conversation_id'),tokenDecode(token)?.uuid,token);
                              makeCountOfUnreadMessagesZero(chat)
                              setActivePMOrg(chat)
                          
                            }}
                          >
                            <Link
                              to='#'
                              onClick={e => {
                                userChatOpen(
                                  chat?.uuid,
                                  chat?.name,
                                  chat?.status,
                                  idx,
                                  chat?.image
                                )
                              }}
                            >
                              <div className='d-flex align-items-center'>
                                <div className='flex-shrink-0 chat-user-img online align-self-center me-2 ms-0'>
                                  <div className='avatar-xxs'>
                                    {chat?.image ? (
                                      <img
                                        src={chat?.image}
                                        className='rounded-circle img-fluid userprofile'
                                        alt=''
                                      />
                                    ) : (
                                      <div
                                        className={
                                          'avatar-title rounded-circle ' +
                                          `${profileImgColor(
                                            chat?.name?.charAt(0)
                                          )}` +
                                          ' userprofile'
                                        }
                                      >
                                        {chat?.name?.charAt(0)}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className='flex-grow-1 overflow-hidden'>
                                  <p className='text-truncate mb-0'>
                                    {chat?.name}
                                  </p>
                                </div>
                                {
                                  chat?.practiceManagerUnreadMessages > 0 ? 
                                  <div>
                                    {chat?.practiceManagerUnreadMessages}
                                  </div>
                                  : null
                                }
                                
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </SimpleBar>
                </TabPane>
              </TabContent>
            </div>
            {clientWorkedOrg.length !== 0 && (
              <div className='user-chat w-100 overflow-hidden' ref={userChatShow}>
                <div className='chat-content'>
                  <div className='w-100 overflow-hidden position-relative'>
                    <div className='position-relative'>
                      <div className='p-3 user-chat-topbar'>
                        <Row className='align-items-center'>
                          <Col sm={4} xs={8}>
                            <div className='d-flex align-items-center'>
                              <div className='flex-shrink-0 d-block d-lg-none me-3'>
                                <Link
                                  to='#'
                                  className='user-chat-remove fs-18 p-1'
                                  onClick={backToUserChat}
                                >
                                  <i className='ri-arrow-left-s-line align-bottom'></i>
                                </Link>
                              </div>
                              <div className='flex-grow-1 overflow-hidden'>
                                <div className='d-flex align-items-center'>
                                  <div className='flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0'>
                                    {Chat_Box_Image === undefined ? (
                                      <img
                                        src={userDummayImage}
                                        className='rounded-circle avatar-xs'
                                        alt=''
                                      />
                                    ) : (
                                      <img
                                        src={Chat_Box_Image}
                                        className='rounded-circle avatar-xs'
                                        alt=''
                                      />
                                    )}

                                    {activeOrg.length !== 0 &&
                                      activeOrg.some(
                                        org =>
                                          org.uuid ===
                                          clientWorkedOrg[currentRoomId].uuid
                                      ) && (
                                        <span className='user-status'></span>
                                      )}
                                  </div>
                                  <div className='flex-grow-1 overflow-hidden'>
                                    <h5 className='text-truncate mb-0 fs-16'>
                                      <a
                                        className='text-reset username'
                                        data-bs-toggle='offcanvas'
                                        // href='#userProfileCanvasExample'
                                        aria-controls='userProfileCanvasExample'
                                      >
                                        {Chat_Box_Username}
                                      </a>
                                    </h5>
                                    <p className='text-truncate text-muted fs-14 mb-0 userStatus'>
                                      {activeOrg.length !== 0 &&
                                        activeOrg.some(
                                          org =>
                                            org.uuid ===
                                            clientWorkedOrg[currentRoomId].uuid
                                        ) ? (
                                        <small>Online</small>
                                      ) : (
                                        <small>Offline</small>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col sm={8} xs={4}>
                            <ul className='list-inline user-chat-nav text-end mb-0'>
                              <li className='list-inline-item m-0'>
                                <Dropdown
                                  isOpen={search_Menu}
                                  toggle={() => setsearch_Menu(!search_Menu)}
                                >
                                  <DropdownToggle
                                    className='btn btn-ghost-secondary btn-icon'
                                    tag='button'
                                  >
                                  <i className='ri-search-line'></i>
                                    {/* <FeatherIcon
                                      icon='search'
                                      className='icon-sm'
                                    /> */}
                                  </DropdownToggle>
                                  <DropdownMenu className='p-0 dropdown-menu-end dropdown-menu-lg'>
                                    <div className='p-2'>
                                      <div className='search-box'>
                                        <Input
                                          innerRef={inputRef}
                                          onKeyUp={searchMessages}
                                          type='text'
                                          className='form-control bg-light border-light'
                                          placeholder='Search here...'
                                          id='searchMessage'
                                        />
                                        <i className='ri-search-2-line search-icon'></i>
                                      </div>
                                    </div>
                                  </DropdownMenu>
                                </Dropdown>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </div>

                      <div className='position-relative' id='users-chat'>
                        <PerfectScrollbar
                          className='chat-conversation p-3 p-lg-4'
                          id='chat-conversation'
                          containerRef={ref => setMessageBox(ref)}
                        >
                          <div id='elmLoader'></div>
                          <ul
                            className='list-unstyled chat-conversation-list'
                            id='users-conversation'
                          >
                            {messages !== undefined || null ? (
                              map(messages, (message, key) => (
                                <li
                                  className={
                                    message?.sender?.uuid ===
                                      tokenDecode(token)?.uuid
                                      ? ' chat-list right'
                                      : 'chat-list  left'
                                  }
                                  key={key}
                                >
                                  <div className='conversation-list'>
                                    <div className='chat-avatar'>
                                      {message?.sender?.User_profile
                                        ?.profile_photo_link ? (
                                        <img src={userDummayImage} alt='' />
                                      ) : (
                                        <img src={userDummayImage} alt='' />
                                      )}
                                    </div>

                                    <div className='user-chat-content'>
                                      <div className='ctext-wrap'>
                                        <div className='ctext-wrap-content'>
                                          <p className='mb-0 ctext-content'>
                                            {message.message}
                                          </p>
                                        </div>
                                      </div>
                                      <div className='conversation-name'>
                                        <small className='text-muted time'>
                                          {`${message?.sender?.firstName
                                            ?.length !== 0
                                            ? message?.sender?.firstName
                                            : ''
                                            } `}
                                          {' '}<span className="badge badge-soft-light text-muted"><i>{moment(message.createdAt).format(
                                            'LT - ll'
                                          )}</i></span>
                                        </small>{' '}
                                        <span className='text-success check-message-icon'>
                                          <i className='ri-check-double-line align-bottom'></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))
                            ) : (
                              <Loader />
                            )}
                          </ul>
                        </PerfectScrollbar>
                        {/* {emojiPicker && (
                          <div className='pickerEmoji' ref={emojiRef}>
                            <Picker 
                              disableSearchBar={true}
                              onEmojiClick={onEmojiClick}
                            />
                          </div>
                        )} */}
                      </div>

                      <div className='chat-input-section p-3 p-lg-4'>
                        <form
                          id='chatinput-form'
                          onSubmit={e => e.preventDefault()}
                        >
                          <Row className='g-0 align-items-center'>
                            <div className='col-auto'>
                              <div className='chat-input-links me-2'>
                              </div>
                            </div>

                            <div className='col'>
                              <div className='chat-input-feedback'>
                                Please Enter a Message
                              </div>
                              <input
                                type='text'
                                ref={chatRef}
                                value={curMessage}
                                onKeyPress={onKeyPress}
                                onChange={e => setcurMessage(e.target.value)}
                                className='form-control chat-input bg-light border-light'
                                id='chat-input'
                                placeholder='Type your message...'
                                autoComplete='off'
                              />
                            </div>
                            <div className='col-auto'>
                              <div className='chat-input-links ms-2'>
                                <div className='links-list-item'>
                                  <Button
                                    type='button'
                                    color='success'
                                    onClick={() => {
                                      sendMessage(curMessage)
                                      fetchSendMessages(
                                        curMessage,
                                        conversation_id,
                                        clientUuid,
                                        token
                                      )
                                    }}
                                    className='chat-send'
                                  >
                                    <i className='ri-send-plane-2-fill align-bottom'></i>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </form>
                      </div>

                      <div className={reply ? 'replyCard show' : 'replyCard'}>
                        <Card className='mb-0'>
                          <CardBody className='py-3'>
                            <div className='replymessage-block mb-0 d-flex align-items-start'>
                              <div className='flex-grow-1'>
                                <h5 className='conversation-name'>
                                  {reply && reply.sender}
                                </h5>
                                <p className='mb-0'>{reply && reply.message}</p>
                              </div>
                              <div className='flex-shrink-0'>
                                <button
                                  type='button'
                                  id='close_toggle'
                                  className='btn btn-sm btn-link mt-n2 me-n3 fs-18'
                                  onClick={() => setreply('')}
                                >
                                  <i className='ri-align-center'></i>
                                </button>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Chat
