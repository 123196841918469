import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'

// Import Images
import logodark from '../../assets/images/logo-dark.png'
import CookieNotice from '../../Components/Common/CookieNotice';
const getCookieAcceptanceFromLocalStorage = () => {
  return localStorage.getItem('cookie-acceptance') === 'true';
};
const Footer = () => {
  return (
    <React.Fragment>
      <footer className='custom-footer bg-light py-3 position-relative'>
      <Container >
          <Row>
            <Col sm={4}>{new Date().getFullYear()} © airGP</Col>
            <Col sm={8}>
              <div className='text-sm-end'>
       
                <a href="/terms" className='text-muted'> 
                Terms & Conditions ᐧ
                </a>                     
                <a href="/privacy" className='text-muted'> 
                &nbsp;Privacy Policy ᐧ
                </a>                   
                {/* <a href="/faq" className='text-muted'> 
                &nbsp;FAQ 
                </a>                     */}
                <a href="/support" className='text-muted'> 
                &nbsp;Support
                </a>            
              </div>
            </Col>
          </Row>

          <CookieNotice getCookieAcceptance={getCookieAcceptanceFromLocalStorage} />

        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
