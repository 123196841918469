import React from 'react'

import Navbar from './navbar'
import Home from './home'
import Client from './client';
import Features from './features';
import Faq from './faq';
import Footer from './footer'

const Index = () => {
  document.title = 'airGP | NHS GP Locum Jobs Platform'
  return (
    <React.Fragment>
      <div className='layout-wrapper landing'>
        <Navbar />
        <Home />
        {/* <Client /> */}
        
        <Faq />
        <Features />
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default Index
