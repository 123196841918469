import React from 'react'

import {
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Col
} from 'reactstrap'

import CreatableSelect from 'react-select/creatable'
import Flatpickr from 'react-flatpickr'

const ClientSessionEditModal = ({
  open,
  toggle,
  options,
  onFeeChange,
  onStartTimeChange,
  onSelectInputChange,
  onSelectChange,
  onEndTimeChange,
  handleScroll,
  startTime,
  endTime,
  fee,
  onClickCreateBtn,
  headers,
  btnName,
  organisationValue
}) => {
  return (
    <Modal isOpen={open} id='event-modal' centered toggle={toggle}>
      <ModalHeader
        toggle={toggle}
        tag='h5'
        className='p-3 bg-soft-info modal-title'
      >
        {`${headers}` || 'Create Manual Session'}
      </ModalHeader>
      <ModalBody>
        <Form
          className={'needs-validation view-event'}
          name='event-form'
          id='form-event'
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <div className='mb-3'>
            <Label
              htmlFor='choices-multiple-groups'
              className='form-label mb-0'
            >
              Select Organisations
            </Label>
            <CreatableSelect
              isClearable
              isMulti={false}
              onInputChange={onSelectInputChange}
              options={options || []}
              maxMenuHeight={210}
              closeMenuOnSelect={true}
              onMenuScrollToBottom={handleScroll}
              onChange={onSelectChange}
              defaultInputValue={organisationValue || ''}
            />
          </div>
          <div className='d-flex justify-content-between'>
            <div className='mb-3 add-session-modal'>
              <Label className='form-label mb-0'>Start Time</Label>
              <Flatpickr
                name='startTime'
                className='form-control w-100'
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: 'H:i',
                  time_24hr: true
                }}
                onChange={onStartTimeChange}
                value={startTime || '00:00'}
              />
            </div>
            <div className='mb-3'>
              <Label className='form-label mb-0'>End Time</Label>
              <Flatpickr
                name='endTime'
                className='form-control w-100'
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: 'H:i',
                  time_24hr: true
                }}
                onChange={onEndTimeChange}
                value={endTime || '00:00'}
              />
            </div>
          </div>
          <div className='mb-3'>
            <Label htmlFor='event-location'>Fee</Label>
            <Col lg={12}>
              <div className='input-group'>
                <span className='input-group-text' id='basic-addon1'>
                  £
                </span>
                <Input
                  type='number'
                  className={'form-control d-block'}
                  name='fee'
                  id='event-location'
                  placeholder='Enter fee in pounds (£)'
                  onChange={onFeeChange}
                  value={fee}
                />
              </div>
            </Col>
            <div></div>
          </div>

          <div className='hstack gap-2 justify-content-end'>
            <button
              className='btn btn-success'
              id='btn-save-event'
              onClick={onClickCreateBtn}
            >
              {`${btnName}` || 'Create'}
            </button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ClientSessionEditModal
