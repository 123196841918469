const METHODS = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  del: 'DELETE',
  patch: 'PATCH'
}

const generateAbsPath = relativePath =>
  `${process.env.REACT_APP_LOCAL_BASE_URL?.replace(
    /\/$/,
    ''
  )}/${relativePath.replace(/^\//, '')}`

const AUTH_SERVICE_BASE_URL = generateAbsPath('/api/v1/auth')
const CLIENT_DATA_BASE_URL = generateAbsPath('/api/v1')

export const POST_LOGIN_CRED = {
  endpoint: `${AUTH_SERVICE_BASE_URL}/login`,
  method: METHODS.post,
  successStatusCode: 200
}

export const POST_REGISTER_CRED = {
  endpoint: `${AUTH_SERVICE_BASE_URL}/register`,
  method: METHODS.post,
  successStatusCode: 201
}

export const POST_FORGOT_PASSWORD = {
  endpoint: `${AUTH_SERVICE_BASE_URL}/forgot-password`,
  method: METHODS.post,
  successStatusCode: 200
}

export const POST_RESET_PASSWORD = {
  endpoint: (id, token) =>
    `${AUTH_SERVICE_BASE_URL}/reset-password/${id}/${token}`,
  method: METHODS.post,
  successStatusCode: 200
}

export const GET_USER_TYPES = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/user-types`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_ORGANISATIONS_DETAILS = {
  endpoint: (param, page, limit) =>
    `${CLIENT_DATA_BASE_URL}/organisations${param
      ? `/search?prefix=${param}&page=${page}&limit=${limit}`
      : `?page=${page}&limit=${limit}`
    }`,
  method: METHODS.get,
  successStatusCode: 200
}

export const POST_ADD_MANUAL_SESSION = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/manual-sessions`,
  method: METHODS.post,
  successStatusCode: 201
}

export const GET_USER_SESSIONS = {
  endpoint: (sDate, eDate) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/sessions`,
  method: METHODS.get,
  successStatusCode: 200
}
// ?start_date=${sDate}&end_date=${eDate}

export const DELETE_USER_SESSION = {
  endpoint: id =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/manual-sessions/${id}`,
  method: METHODS.del,
  successStatusCode: 204
}

export const GET_USER_SESSION_BY_ID = {
  endpoint: id =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/manual-sessions/${id}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const UPDATE_MANUAL_SESSION = {
  endpoint: id =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/manual-sessions/${id}`,
  method: METHODS.patch,
  successStatusCode: 200
}

export const GET_USER_UNAVAILABILIY_DATES = {
  endpoint: (startDate, endDate) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/users/unavailabilities?end_date=${endDate}&start_date=${startDate}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const SET_USER_UNAVAILABILIY = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/users/unavailabilities`,
  method: METHODS.post,
  successStatusCode: 201
}

export const GET_SESSION_LIST = {
  endpoint: (param, limit, page) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/manual-sessions/list${param ? `?status=${param}` : ''
    }${param ? `&limit=${limit ? limit : 20}` : `?limit=${limit ? limit : 20}`
    }&page=${page ? page : 1}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_SESSION_TYPES = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/sessions/types`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_FEATURES = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/features`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_IT_SYSTEMS = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/it-systems`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_BOOK_SESSIONS = {
  endpoint: (
    it_system_id,
    features_id,
    session_id,
    zipcode,
    distance,
    limit,
    page,
    unavailable,
    instantBooking,
    org_uuid,
    favoriteOrganisation
  ) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/sessions?book=true&${it_system_id.length !== 0
      ? `it_systems=${it_system_id ? it_system_id : ''}&`
      : ''
    }practice_features=${features_id ? features_id : ''}&session_types=${session_id ? session_id : ''
    }&zipcode=${zipcode ? zipcode : ''}&country=${'England'}&distance=${distance ? distance : ''
    }&limit=${limit ? limit : 20}&page=${page ? page : 1}&unavailable=${unavailable ? unavailable : false
    }${instantBooking
      ? `&instantBooking=${instantBooking ? instantBooking : false}`
      : ''
    }${org_uuid ? `&org_uuid=${org_uuid}` : ''}${favoriteOrganisation
      ? `&favoriteOrganisation=${favoriteOrganisation}`
      : ''
    }`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_PUBLIC_BOOK_SESSIONS = {
  endpoint: org_uuid => {
    return `${process.env.REACT_APP_LOCAL_BASE_URL
      }/api/v1/public/sessions?book=true&it_systems=&practice_features=&session_types=&zipcode=SW1A%200AA&country=England&distance=10000Miles&limit=20&page=1&org_uuid=${org_uuid ? org_uuid : ''
      }`
  },
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_PM_SESSIONS_BY_ID = {
  endpoint: id =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/sessions/${id}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const POST_APPLY_SESSION = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/sessions/apply`,
  method: METHODS.post,
  successStatusCode: 201
}

export const CANCEL_APPLIED_SESSION = {
  endpoint: id =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/manual-sessions/${id}/cancel`,
  method: METHODS.del
}

export const DELETE_SESSION_CLIENT = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/sessions/cancel`,
  method: METHODS.del
}

export const GET_WORKED_SESSIONS = {
  endpoint: (orgId, limit, page) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/sessions/worked${orgId.length > 0
      ? `?org_id=${orgId}`
      : `?limit=${limit ? limit : 20}&page=${page ? page : 1}`
    }`,
  method: METHODS.get
}

export const GET_INVOICE_PDF = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/invoices/generate`,
  method: METHODS.get
}

export const POST_GENERATE_INVOICE = {
  endpoint: (category) => `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/invoices/generate?category=${category}`,
  method: METHODS.post
}

export const GET_INVOICE_WITH_STATUS = {
  endpoint: (status, orgId, limit, page) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/invoices?status=${status}${orgId.length > 0
      ? `&org_id=${orgId}`
      : `&limit=${limit ? limit : 20}&page=${page ? page : 1}`
    }`,
  method: METHODS.get
}

//

export const POST_UPDATE_INOVICE_STATUS = {
  endpoint: (id, status) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/invoices/${id}/convert/${status}`,
  method: METHODS.post
}

export const POST_AMEND_SESSION = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/invoices/amend`,
  method: METHODS.post
}

export const GET_AMENDED_SESSIONS = {
  endpoint: id =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/invoices/amended?org_id=${id}`,
  method: METHODS.get
}

export const PATCH_APPROVE_AMENDED_SESSIONS = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/invoices/approve`,
  method: METHODS.patch
}

export const POST_REJECT_AMENDED_SESSION = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/invoices/reject`,
  method: METHODS.post
}

export const GET_INVOICE_PDF_LINK = {
  endpoint: id =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/invoices/invoiceLink?invoiceId=${id}`,
  method: METHODS.get
}

export const GET_EXPENSE_TYPES = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/invoices/expenseTypes`,
  method: METHODS.get
}

export const PATCH_BOOK_SEARCH_PREFERENCES = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/sessions/search-preference`,
  method: METHODS.patch
}

// ***************************************************ACCOUNT ENDPOINTS********************************************** //
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const PATCH_SAVE_USER_PROFILE_DETAILS = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/user-profile`,
  method: METHODS.patch
}

export const PATCH_PROFILE_PHOTO_UPLOAD = {
  endpoint: (category) => `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/user-profile/profile-photo?category=${category}`,
  method: METHODS.patch
}

export const GET_LANGUAGES = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/user-profile/language`,
  method: METHODS.get
}

export const GET_QUALIFICATIONS = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/user-profile/qualification`,
  method: METHODS.get
}

export const GET_USER_PROFILE_DETAILS = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/user-profile`,
  method: METHODS.get
}

export const GET_PROFILE_PHOTO = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/user-profile/profile-photo`,
  method: METHODS.get
}

export const GET_CLINICAL_DOCUMENTS_TYPES = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/user-profile/clinical-document-type`,
  method: METHODS.get
}

export const POST_UPLOAD_CLINICAL_DOCUMENTS = {
  endpoint: (docId, documentCategory) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/user-profile/clinical-document?documentId=${docId}&category=${documentCategory}`,
  method: METHODS.post
}

export const DELETE_CLINICAL_DOCUMENTS = {
  endpoint: docId =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/user-profile/clinical-document?documentId=${docId}`,
  method: METHODS.del
}

export const DOWNLOAD_DOCUMENT = {
  endpoint: (pdf_uuid, status) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/aws/fetch-pdf?pdf_uuid=${pdf_uuid}&status=${status}`,
  method: METHODS.get
}

export const GET_SEARCH_PREFERENCES = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/sessions/search-preference`,
  method: METHODS.get
}

////////////////////////////////Change Password////////////////////////////////////////////////////////////

export const PATCH_UPDATE_PASSWORD = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/auth/change-password`,
  method: METHODS.patch
}

////////////////////////////////////Bank Details/////////////////////////////////////////////////////////

export const POST_ADD_BANK_DETAILS = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/bank-details`,
  method: METHODS.post
}

export const GET_BANK_DETAILS = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/bank-details`,
  method: METHODS.get
}

export const PATCH_SET_AS_DEFAULT_BANK = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/bank-details`,
  method: METHODS.patch
}

export const DELETE_BANK_ACCOUNT = {
  endpoint: id =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/bank-details/${id}`,
  method: METHODS.del
}

export const GET_PENSION_REGIONS = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/pensions/pension-regions?limit=50`,
  method: METHODS.get
}

export const GET_PENSION_PCSE_REGIONS = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/pensions/pcse-regions?limit=50`,
  method: METHODS.get
}

export const GET_PENSION_TIER_CONTRIBUTION = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/pensions/tier-contribution-rates?limit=50`,
  method: METHODS.get
}

export const PUT_SAVE_PENSION_INFO = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/pensions`,
  method: METHODS.put
}

export const GET_PENSION_PREFERENCE = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/pensions`,
  method: METHODS.get
}

////////////////////////////////////////////////////////// Form A Endpoints /////////////////////////////////////////////////////////////

export const GET_FORMS_A_AND_B = {
  endpoint: (type, page, limit) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL
    }/api/v1/pensions/pension-form-a?status=${type}&page=${page ? page : 1
    }&limit=${limit ? limit : 20}`,
  method: METHODS.get
}

export const PATCH_ADD_SIGNATURE = {
  endpoint: (category) => `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/user-profile/signatures?category=${category}`,
  method: METHODS.patch
}

export const GET_ORG_WHERE_CLIENT_WORK = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/users/organisations`,
  method: METHODS.get
}

export const GET_CONVERSATION = {
  endpoint: (org_id, user_id) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/conversations/organisations/${org_id}/users/${user_id}`,
  method: METHODS.get
}

export const POST_SEND_MESSAGE = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/messages`,
  method: METHODS.post
}

export const GET_MESSAGES = {
  endpoint: (conversation_id, page) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL
    }/api/v1/messages/conversations/${conversation_id}?page=${page ? page : 1}`,
  method: METHODS.get
}

export const MARK_AS_READ_MESSAGES = {
  endpoint: (conversationId, uuid) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL
    }/api/v1/messages/mark_as_read/${uuid}?conversationId=${conversationId}`,
  method: METHODS.get
}

export const POST_Add_MANUAL_SIGN_ON_FORM_A = {
  endpoint: id =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/pensions/mark-signed-manually?form_a_id=${id}`,
  method: METHODS.post
}

export const GET_FORM_B = {
  endpoint: status =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/form-b?status=${status ? status : ''
    }`,
  method: METHODS.get
}

export const POST_GENERATE_FORM_B = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/form-b`,
  method: METHODS.post
}

export const GET_ARCHIVED_FORM_B = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/form-b/archive`,
  method: METHODS.get
}

export const GET_PENSION_LOGS = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/form-b/pension-log`,
  method: METHODS.get
}

export const GET_FORM_B_DATA = {
  endpoint: (path) => `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/form-b/form-b?path=${path}`,
  method: METHODS.get
}

export const GET_NOTIFICATIONS = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/notifications`,
  method: METHODS.get
}

export const PATCH_READ_NOTIFIATION = {
  endpoint: notification_id =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/notifications/${notification_id}`,
  method: METHODS.patch
}

export const PATCH_READ_ALL_NOTIFICATIONS = {
  endpoint: () => `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/notifications/mark`,
  method: METHODS.patch
}

export const PATCH_CLEAR_ALL_NOTIFICATIONS = {
  endpoint: () => `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/notifications/clear`,
  method: METHODS.patch
}

export const GET_TAX_DETAILS = {
  endpoint: year =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/sessions/tax?year=${year}`,
  method: METHODS.get
}

export const GET_ORGANISATIONS_PUBLIC = {
  endpoint: (page, limit, prefix) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/pm/organisations?page=${page ? page : 1
    }&limit=${limit ? limit : 20}&prefix=${prefix ? prefix : ''}`,
  method: METHODS.get
}

export const GET_TAX_YEARS = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/sessions/tax-year`,
  method: METHODS.get
}

export const GET_EMAIL_PREFERENCES = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/email-preferences`,
  method: METHODS.get
}

export const PATCH_EMAIL_PREFERENCES = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/email-preferences`,
  method: METHODS.patch
}

export const POST_DELETE_ACCOUNT = {
  endpoint: password =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/email-preferences/delete-account?password=${password}`,
  method: METHODS.post
}

export const GET_PERFORMER_INFO = {
  endpoint: id =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/invitation?performer_uuid=${id}`,
  method: METHODS.get
}

export const POST_EMAIL_VERIFICATION = {
  endpoint: id =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/auth/email-verification?verification_id=${id}`,
  method: METHODS.post
}

export const PATCH_ADD_FAVOURITE_ORGANISATION = {
  endpoint: (org_id, active) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/organisations/favorite?org_id=${org_id}&active=${active}`,
  method: METHODS.patch
}

export const GET_DOWNLOAD_ORGANISATION_DOCUMENTS = {
  endpoint: (documentId) =>
    `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/organisations/documents?documentId=${documentId}`,
  method: METHODS.get
}

export const GET_FORM_A_VERSIONS = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/pensions/form-a-versions`,
  method: METHODS.get
}

export const POST_UNARCHIVE_FORM_B = {
  endpoint: `${process.env.REACT_APP_LOCAL_BASE_URL}/api/v1/form-b/un-archive`,
  method: METHODS.post
}