import React, { useEffect, useState } from 'react'

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Collapse,
  Container,
  UncontrolledCollapse,
  Row,
  Card,
  CardHeader,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  CardBody
} from 'reactstrap'

import classnames from 'classnames'

import BreadCrumb from '../../Components/Common/BreadCrumb'

import Select from 'react-select'

import { toast, ToastContainer } from 'react-toastify'
import { scheduleServices } from '../../services/scheduleServices'
import { Link } from 'react-router-dom'

import { BOOK_PAGE_TABS, getCookie, tokenDecode } from '../../utils/constants'
import { useHistory } from 'react-router-dom'
import SuccessConfirmationModal from '../../Components/Common/SuccessConfirmationModal'
import PaginationV2 from '../../Components/Common/PaginationV2'
import SpinnerComponent from '../../Components/Common/Spinner'

import io from 'socket.io-client'
import BookingList from './BookingList'
import { socket } from '../../socket/socket'
import ConfirmationModal from '../../Components/Common/ConfirmationModal'
import { accountServices } from '../../services/accountServices'


// const socket = io.connect(process.env.REACT_APP_SOCKET_BASE_URL)

socket.on('connect_error', function (err) {
  // console.log('Error >>>>>>>>', err)
})

const SingleOptions = [
  { value: '1 Miles', label: '1 Miles' },
  { value: '2 Miles', label: '2 Miles' },
  { value: '3 Miles', label: '3 Miles' },
  { value: '4 Miles', label: '4 Miles' },
  { value: '5 Miles', label: '5 Miles' },
  { value: '6 Miles', label: '6 Miles' },
  { value: '7 Miles', label: '7 Miles' },
  { value: '8 Miles', label: '8 Miles' },
  { value: '9 Miles', label: '9 Miles' },
  { value: '10 Miles', label: '10 Miles' },
  { value: '12 Miles', label: '12 Miles' },
  { value: '15 Miles', label: '15 Miles' },
  { value: '20 Miles', label: '20 Miles' },
  { value: '25 Miles', label: '25 Miles' },
  { value: '50 Miles', label: '50 Miles' },
  { value: '100 Miles', label: '100 Miles' },
  { value: '1000 Miles', label: 'Nationwide' }
]

const LIMIT = 20

const BookPage = () => {
  const [milesSelect, setMilesSelect] = useState({
    value: '1000Miles',
    label: 'Nationwide'
  })
  const [postcode, setPostcode] = useState()
  const [sessionTypes, setSessionTypes] = useState([])
  const [itSystemTypes, setItSystemTypes] = useState([])
  const [featureTypes, setFeaturesType] = useState([])
  const [pmsessionsData, setPmsessionsData] = useState([])
  const [page, setPage] = useState(1)
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [showApplyModal, setShowApplyModal] = useState(false)
  const [CheckedFeatures, setCheckedFeatures] = useState([])
  const [renderFetchFeature, setrenderFetchFeature] = useState(false)
  const [checkedGpSoc, setCheckedGpSoc] = useState([])
  const [checkedSessionType, setCheckedSessionType] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [selectedId, setSelectedId] = useState('')
  const [renderFetchItSystems, setRenderFetchItSystems] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [unavailable, setUnavailable] = useState(false)
  const [instantBooking, setInstantBooking] = useState(false)
  const [bonusPercantage, setBonusPercantage] = useState()
  const [organisaitons, setOrganistions] = useState([])
  const [selectedOrg, setSelectedOrg] = useState({ value: '', label: '' })
  const [orgPage, setOrgPage] = useState(1)
  const [favoriteOrganisation, setFavoriteOrganisation] = useState(false)
  const [nextpage, setNextPage] = useState(false)

  const [updateLocation, setupdateLocation] = useState(false);

  const [showWithdrawn, setShowWithdrawnModal] = useState(false)
  const [selectedSessionId, setSelectedSessionId] = useState()

  const [user, setUser] = useState();


  const token = localStorage.getItem('token')
  const history = useHistory()

  const toggleTab = tab => {
    if (tab === 0) {
      setInstantBooking(false)
    } else if (tab == 1) {
      setInstantBooking(true)
    } else {
      setInstantBooking(false)
    }

    if (tab == 2) {
      setFavoriteOrganisation(true)
    } else {
      setFavoriteOrganisation(false)
    }

    if (activeTab !== tab) {
      setActiveTab(tab)
    }

    setNextPage(true)
    setPage(1)
  }

  useEffect(() => {
    if (renderFetchItSystems || renderFetchFeature) fetchGetBookSearchPreferences(token);
  }, [renderFetchItSystems, renderFetchFeature]);

  useEffect(() => { 
    fetchGetSessionTypes(token)
    fetchFeaturesTypes(token)
    fetchItSystemTypes()
    fetchOrganisations(orgPage, LIMIT)
    fetchUserProfileData(token)
  }, [])


  useEffect(() => {
    if (nextpage) {
      setNextPage(false)
      FetchGetBookSessions(
        checkedGpSoc,
        CheckedFeatures,
        checkedSessionType,
        postcode,
        milesSelect?.value,
        LIMIT,
        page,
        token,
        unavailable,
        instantBooking,
        '',
        favoriteOrganisation
      )
    }
  }, [page, activeTab, nextpage])

  useEffect(() => {
    if (updateLocation) {
      fetchBookSearchPreferences(
        postcode,
        milesSelect?.value,
        CheckedFeatures,
        checkedGpSoc,
        checkedSessionType,
        unavailable,
        token,
        selectedOrg?.value
      );
    }
  }, [updateLocation]);

  const fetchGetSessionTypes = token => {
    scheduleServices
      .getSessionTypes(token)
      .then(resp => {
        let sessionTypes = resp?.data?.map(_ => ({ ..._, checked: false }))
        setSessionTypes(sessionTypes)
      })
      .catch(err => console.log(err))
  }

  const fetchFeaturesTypes = token => {
    scheduleServices
      .getFeatures(token)
      .then(resp => {
        let featuresTypes = resp?.features?.map(_ => ({ ..._, checked: false }))
        setFeaturesType(featuresTypes)
        setrenderFetchFeature(true)
      })
      .catch(err => console.log(err))
  }

  const fetchItSystemTypes = () => {
    scheduleServices
      .getItSystems()
      .then(resp => {
        let itSystemTypes = resp?.it_systems.map(_ => ({
          ..._,
          checked: false
        }))
        setItSystemTypes(itSystemTypes)
        setRenderFetchItSystems(true)
      })
      .catch(err => console.log(err))
  }

  const FetchGetBookSessions = (
    it_system_id,
    features_id,
    session_id,
    zipcode,
    distance,
    limit,
    page,
    token,
    unavailable,
    instantBooking,
    org_uuid,
    favoriteOrganisation
  ) => {
    setIsLoading(true)
    scheduleServices
      .getBookSessions(
        it_system_id,
        features_id,
        session_id,
        zipcode,
        distance,
        limit,
        page,
        token,
        unavailable,
        instantBooking,
        org_uuid,
        favoriteOrganisation
      )
      .then(resp => {
        
        if (resp.success) {
          const filteredData = []
          const days = {}
          resp?.data?.forEach(entry => {
            if(entry.status === 'published'){
              const date = new Date(entry.start_time)
                const day = date.toISOString().slice(0, 10)
                const s_d = date.getDate()
                if (!days[day]) {
                  days[day] = true
                  filteredData.push({ timestamp: day, days: true })
                }
            } else {
              entry?.Session_clients?.some(session => {
                session?.Session_statuses.some(client => {
                 if(client?.status.toLowerCase() !== "rejected"){
                   const date = new Date(entry.start_time)
                   const day = date.toISOString().slice(0, 10) // date.toLocaleString('en-gb', { weekday: 'long' })
                   const s_d = date.getDate()
                   if (!days[day]) {
                     days[day] = true
                     filteredData.push({ timestamp: day, days: true }) //`${s_d}-${day}`
                   }
                 }
                })
               })
            }
            filteredData.push(entry)
          })

          setBonusPercantage(resp?.user_profile)
          setPmsessionsData(filteredData || [])
          setTotalPageCount(resp?.totalPages === 0 ? 1 : resp?.totalPages)
        } else {
          if(resp?.message === "Unauthorised"){
            localStorage.clear();
            history.push('/login')
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchUserProfileData = token => {
    accountServices
      .getUserProfileDetails(token)
      .then(resp => {
        if (resp.data && resp.data.user) {
          setUser({...(resp?.data?.user),message: resp?.message})
        }
      })
      .catch(err => console.log(err))
  }


  const fetchApplyBtnHandler = item => {
    if (item.user_id && user?.message !== 'Incomplete profile!' && user?.gp_soc?.length !== 0 && user?.qualification?.length !== 0) {
      socket.emit(
        'appliedToSession',
        {
          orgId: item.organisation.uuid,
          pm_id: item.user_id,
          client_id: tokenDecode(token).id,
          content: 'applied',
          sender: {
            firstName: tokenDecode(token).firstName,
            lastName: tokenDecode(token).lastName,
            title: tokenDecode(token).title
          },
          session: {
            start_time: item.start_time,
            end_time: item.end_time
          }
        },
        resp => {
          console.log('Applied to session')
        }
      )
    }
    scheduleServices
      .postApplySession(
        {
          session_uuid: item.uuid,
          org_id: item.organisation.uuid
        },
        token
      )
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          setShowApplyModal(false)
          FetchGetBookSessions(
            checkedGpSoc,
            CheckedFeatures,
            checkedSessionType,
            postcode,
            milesSelect.value,
            LIMIT,
            page,
            token,
            unavailable,
            instantBooking,
            '',
            favoriteOrganisation
          )
        } else {
          toast.error(resp.message)
          setShowApplyModal(false)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchWithdrawnSessionClient = item => {

      socket.emit('withdraw-session', {
        pm_id: item?.user_id,
        withdrawn_by_pm: false,
        withdrawn_by_client: true,
        content: 'withdrawn',
        sender: {
          firstName: tokenDecode(token).firstName,
          lastName: tokenDecode(token).lastName,
          title: tokenDecode(token).title
        },
        session: {
          start_time: item.start_time,
          end_time: item.end_time,
        },
        orgId: item.organisation ? item.organisation.uuid : null,
      })

      scheduleServices
        .deleteSessionClient({ sessionId : selectedSessionId}, token)
        .then(resp => {
          if(resp.success){
            toast.success(resp.message)
            // setOpenDetailsModal(false)
            setShowWithdrawnModal(false)
            FetchGetBookSessions(
              checkedGpSoc,
              CheckedFeatures,
              checkedSessionType,
              postcode,
              milesSelect.value,
              LIMIT,
              page,
              token,
              unavailable,
              instantBooking,
              '',
              favoriteOrganisation
            )
          }else{
            toast.error(resp.message)
            setShowWithdrawnModal(false)
          }
        })
        .catch(err => console.log(err))
    }  

  const fetchSessionClickHandler = (index, session, event) => {
    let sessionCloneArray = [...sessionTypes]
    sessionCloneArray[index].checked = event.target.checked

    setSessionTypes(sessionCloneArray)

    let sessionUuid = sessionTypes
      .filter(_ => _.checked === true)
      .map(__ => {
        return __.uuid
      })

    const id = sessionUuid.toString()
    FetchGetBookSessions(
      checkedGpSoc,
      CheckedFeatures,
      id,
      postcode,
      milesSelect.value,
      LIMIT,
      1,
      token,
      unavailable,
      instantBooking,
      '',
      favoriteOrganisation
    )
  }

  const fetchFeaturesClickHandler = (index, features, event) => {
    let featuresCloneArray = [...featureTypes]
    featuresCloneArray[index].checked = event.target.checked
    setFeaturesType(featuresCloneArray)

    let featuresUuid = featureTypes
      .filter(_ => _.checked === true)
      .map(__ => {
        return __.uuid
      })

    const id = featuresUuid.toString()
    FetchGetBookSessions(
      checkedGpSoc,
      id,
      checkedSessionType,
      postcode,
      milesSelect.value,
      LIMIT,
      1,
      token,
      unavailable,
      instantBooking,
      '',
      favoriteOrganisation
    )
  }

  const fetchItSystemClickHandler = (index, it, event) => {
    let itSystemCloneArray = [...itSystemTypes]
    itSystemCloneArray[index].checked = event.target.checked
    setItSystemTypes(itSystemCloneArray)

    let itSystemUuid = itSystemTypes
      .filter(_ => _.checked === true)
      .map(__ => {
        return __.uuid
      })

    const id = itSystemUuid.toString()
    FetchGetBookSessions(
      id,
      CheckedFeatures,
      checkedSessionType,
      postcode,
      milesSelect.value,
      LIMIT,
      1,
      token,
      unavailable,
      instantBooking,
      '',
      favoriteOrganisation
    )
  }

  const handleSessionChange = (index, session, event) => {
    let cSessionArray = [...sessionTypes]
    cSessionArray[index].checked = event.target.checked
    setSessionTypes(cSessionArray)

    let sessionFTypes = sessionTypes
      ?.filter(_ => _.checked === true)
      ?.map(__ => {
        return __.uuid
      })

    setCheckedSessionType(sessionFTypes)
  }

  const handleFeaturesChange = (index, session, event) => {
    let cSessionArray = [...featureTypes]
    cSessionArray[index].checked = event.target.checked
    setFeaturesType(cSessionArray)

    let sessionFTypes = featureTypes
      ?.filter(_ => _.checked === true)
      ?.map(__ => {
        return __.uuid
      })

    setCheckedFeatures(sessionFTypes)
  }

  const handleItSystemChange = (index, session, event) => {
    let cSessionArray = [...itSystemTypes]
    cSessionArray[index].checked = event.target.checked
    setItSystemTypes(cSessionArray)

    let sessionFTypes = itSystemTypes
      ?.filter(_ => _.checked === true)
      ?.map(__ => {
        return __.uuid
      })

    setCheckedGpSoc(sessionFTypes)
  }

  const fetchBookSearchPreferences = (
    postcode,
    milesSelect,
    CheckedFeatures,
    checkedGpSoc,
    checkedSessionType,
    unavailable,
    token,
    org_uuid
  ) => {
    setIsLoading(true)
    const data = {
      postcode: postcode,
      miles: milesSelect,
      features: CheckedFeatures,
      gpSoc: checkedGpSoc,
      sessionType: checkedSessionType,
      unavailable: unavailable,
      organisations: [] //org_uuid ? [org_uuid] :
    }

    if (updateLocation) {
      scheduleServices
      .patchBookSearchPrefenrences(data, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          fetchGetBookSearchPreferences(token)
        } else {
          toast.error(resp.message)
          fetchGetBookSearchPreferences(token)
        }
      })

      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
    }
    else{
      fetchGetBookSearchPreferences(token)
      setIsLoading(false)
    }
  }

  const fetchGetBookSearchPreferences = token => {
    setIsLoading(true)
    scheduleServices
      .getBookSearchPreferences(token)
      .then(resp => {
        if (resp.success) {
          const copiedItSystemArray = [...itSystemTypes]
          const copiedFeaturesArray = [...featureTypes]
          const copiedSessionArray = [...sessionTypes]
          const copiedCheckedSession = []
          const copiedCheckedFeatures = []
          const copiedCheckedGpSoc = []
          setPostcode(resp?.data?.postcode);
          setUnavailable(resp?.data?.unavailable)
          if (resp?.data?.miles) {
            setMilesSelect({
              value: resp?.data?.miles,
              label:
                resp?.data?.miles === '1000 Miles'
                  ? 'Nationwide'
                  : resp?.data?.miles
            })
          }
          setSelectedOrg({
            value:
              resp?.data?.SearchOrganisations[0]?.organisations?.uuid || '',
            label: resp?.data?.SearchOrganisations[0]?.organisations?.name || ''
          })

          if (itSystemTypes.length !== 0) {
            resp?.data?.Search_gp_socs?.map(type => {
              copiedItSystemArray.find((_item, index) => {
                if (_item.uuid === type.gp_soc.uuid) {
                  copiedItSystemArray[index].checked = true
                }
              })
              copiedCheckedGpSoc.push(type.gp_soc.uuid)
            })
            setItSystemTypes(copiedItSystemArray)
            setCheckedGpSoc(copiedCheckedGpSoc)
          }

          if (resp?.data?.Search_gp_socs?.length === 0) {
            let itSystemType = itSystemTypes.map(_ => ({
              ..._,
              checked: false
            }))
            setItSystemTypes(itSystemType)
          }

          if (featureTypes.length !== 0) {
            resp?.data?.Search_features?.map(type => {
              copiedFeaturesArray.find((_item, index) => {
                if (_item.uuid === type.feature.uuid) {
                  copiedFeaturesArray[index].checked = true
                }
              })
              copiedCheckedFeatures.push(type.feature.uuid)
            })
            setFeaturesType(copiedFeaturesArray)
            setCheckedFeatures(copiedCheckedFeatures)
          }
          if (resp?.data?.Search_features?.length === 0) {
            let featuresTypes = featureTypes.map(_ => ({
              ..._,
              checked: false
            }))
            setFeaturesType(featuresTypes)
          }

          if (sessionTypes.length !== 0) {
            resp?.data?.Search_session_types?.map(type => {
              copiedSessionArray.find((_item, index) => {
                if (_item.uuid === type.Session_type.uuid) {
                  copiedSessionArray[index].checked = true
                }
              })
              copiedCheckedSession.push(type.Session_type.uuid)
            })
            setSessionTypes(copiedSessionArray)
            if (resp?.data?.Search_session_types?.length === 0) {
              let sessionType = sessionTypes.map(_ => {
                return { ..._, checked: false }
              })
              setSessionTypes(sessionType)
            }
            setCheckedSessionType(copiedCheckedSession)
          }

          FetchGetBookSessions(
            copiedCheckedGpSoc,
            copiedCheckedFeatures,
            copiedCheckedSession,
            resp?.data?.postcode,
            resp?.data?.miles,
            LIMIT,
            1,
            token,
            resp?.data?.unavailable,
            instantBooking,
            resp?.data?.SearchOrganisations[0]?.organisations?.uuid,
            favoriteOrganisation
          )
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const handleClearFilterCall = () => {
    setupdateLocation(false);
    // Set It system to False
    let itSystemType = itSystemTypes.map(_ => ({ ..._, checked: false }))
    setItSystemTypes(itSystemType)

    //Set Features types to False
    let featuresTypes = featureTypes.map(_ => ({ ..._, checked: false }))
    setFeaturesType(featuresTypes)

    //Set Sessions types to False
    let sessionType = sessionTypes.map(_ => {
      return { ..._, checked: false }
    })

    setPage(1)
    setSessionTypes(sessionType)
    fetchBookSearchPreferences(
      postcode,
      milesSelect.value || '1000Miles',
      [],
      [],
      [],
      false,
      token,
      ''
    )
  }

  const fetchOrganisations = (page, limit, prefix) => {
    setIsLoading(true)
    scheduleServices
      .getOrganisationsPublically(page, limit, prefix)
      .then(resp => {
        if (resp.success) {
          if (prefix) {
            let set = new Set()
            let copiedArray = []
            if (resp?.data?.length !== 0) {
              resp?.data?.map(item => {
                if (!set.has(item?.uuid)) {
                  copiedArray.push({
                    value: item?.uuid,
                    label: item?.organisation_name + ' - ' + item?.postcode
                  })
                  set.add(item?.uuid)
                }
              })

              const filteredData = copiedArray.filter(item =>
                organisaitons.map(_ => {
                  return item.value !== _.value
                })
              )

              setOrganistions(filteredData)
            } else {
              setOrganistions([])
            }
          } else {
            let copiedArray = [...organisaitons]
            let set = new Set()
            if (resp?.data?.length !== 0) {
              resp?.data?.map(item => {
                if (!set.has(item?.uuid)) {
                  copiedArray.push({
                    value: item?.uuid,
                    label: item?.organisation_name
                  })
                  set.add(item?.uuid)
                }
              })

              const filteredData = copiedArray.filter(item =>
                organisaitons.map(_ => {
                  return item.value !== _.value
                })
              )

              setOrganistions(filteredData)
            } else {
              setOrganistions([])
            }
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const customFilter = (option, searchText) => {
    const text = searchText.trim().toLowerCase();
    const label = option.label.toLowerCase();
    let words = text.split(" ");
    if (words.length > 1 && words[0] === "the") {
        words = words.filter((word) => word !== "the");
    }
    return words.some((word) => label.includes(word));
  };

  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  const [col5, setcol5] = useState(false);
  const [col6, setcol6] = useState(false);

  const t_col1 = () => {
      setcol1(!col1);
      // setcol2(false);
      // setcol3(false);
      // setcol4(false);
      // setcol5(false);
      // setcol6(false);
  };

  const t_col2 = () => {
      setcol2(!col2);
      // setcol1(false);
      // setcol3(false);
      // setcol4(false);
      // setcol5(false);
      // setcol6(false);
  };

  const t_col3 = () => {
    setcol3(!col3);
    // setcol1(false);
    // setcol2(false);
    // setcol4(false);
    // setcol5(false);
    // setcol6(false);
};

const t_col4 = () => {
  setcol4(!col4);
  // setcol1(false);
  // setcol2(false);
  // setcol3(false);
  // setcol5(false);
  // setcol6(false);
};

const t_col5 = () => {
  setcol5(!col5);
  // setcol1(false);
  // setcol2(false);
  // setcol3(false);
  // setcol4(false);
  // setcol6(false);
};

const t_col6 = () => {
  setcol6(!col6);
  // setcol1(false);
  // setcol2(false);
  // setcol3(false);
  // setcol4(false);
  // setcol5(false);
};



  document.title = 'Book | airGP'

  return (
    <div className='page-content'>
      {isLoading && <SpinnerComponent />}
      <Container fluid>
        <BreadCrumb title='Book' pageTitle='Sessions' />

        <Row>
          <Col xxl={3}>
            <Card>
              <CardHeader className='mb-0 pb-0'>
                <div className='d-flex '>
                  <div className='flex-grow-1 mb-n2 mt-2 '>
                    <p className='text-white'>FILTERS</p>
                  </div>
                  <div className='flex-shrink-0 mt-2'>
                    <div className='hstack gap-2'>
                      <Link
                        to='#'
                        id='clearFilter'
                        className='text-white'
                        onClick={handleClearFilterCall}
                      >
                        Clear All
                      </Link>
                      <Link
                        to='#'
                        className='text-white'
                        id='saveFilter'
                        onClick={() => {
                          setupdateLocation(true)
                          fetchBookSearchPreferences(
                            postcode,
                            milesSelect?.value,
                            CheckedFeatures,
                            checkedGpSoc,
                            checkedSessionType,
                            unavailable,
                            token,
                            selectedOrg?.value
                          )
                        }}
                      >
                        <i className='ri-save-line'></i>
                      </Link>
                      <UncontrolledTooltip
                        placement='bottom'
                        target='saveFilter'
                      >
                        Save Filters
                      </UncontrolledTooltip>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody className='px-0 pt-2'>
              <Accordion flush>
                  <AccordionItem>
                      <h2 className="accordion-header">
                          <button
                              className={classnames("accordion-button text-muted  bg-transparent shadow-none text-uppercase fs-12", { collapsed: !col1 })} type="button" onClick={t_col1} style={{ cursor: "pointer" }} >
                               LOCATION
                          </button>
                      </h2>
                      <Collapse isOpen={col1} className="accordion-collapse">
                              <div className='accordion-body  pt-0'>
                                    <Input
                                      id='postcode-field'
                                      autoComplete='postal-code'
                                      className='form-input mb-2 text-uppercase'
                                      placeholder='Postcode'
                                      onChange={({ target: { value } }) =>
                                        setPostcode(value)
                                      }
                                      value={postcode || ''}
                                    />


                                    <Select
                                      value={milesSelect}
                                      isMulti={false}
                                      onChange={event => setMilesSelect(event)}
                                      options={SingleOptions}
                                      isSearchable={false}
                                    />
                              <button
                                type='button'
                                className='btn btn-sm btn-soft-primary mt-3 w-100'
                                onClick={() => {
                                  setPage(1)
                                  FetchGetBookSessions(
                                    checkedGpSoc,
                                    CheckedFeatures,
                                    checkedSessionType,
                                    postcode,
                                    milesSelect?.value,
                                    LIMIT,
                                    1,
                                    token,
                                    unavailable,
                                    instantBooking,
                                    '',
                                    favoriteOrganisation
                                  )
                                  setupdateLocation(true)
                                  fetchBookSearchPreferences(
                                    postcode,
                                    milesSelect?.value,
                                    CheckedFeatures,
                                    checkedGpSoc,
                                    checkedSessionType,
                                    unavailable,
                                    token,
                                    selectedOrg?.value
                                  )
                                }}
                              >
                                Update Location
                              </button>
                              </div>
                      </Collapse>
                  </AccordionItem>
                  <AccordionItem>
                      <h2 className="accordion-header">
                          <button
                              className={classnames("accordion-button text-muted bg-transparent shadow-none text-uppercase fs-12", { collapsed: !col2 })} type="button" onClick={t_col2} style={{ cursor: "pointer" }} >
                               ORGANISATION
                          </button>
                      </h2>
                      <Collapse isOpen={col2} className="accordion-collapse ">
                      <div className="accordion-body  pt-0">
                          <Select
                        value={selectedOrg || ''}
                        isClearable={true}
                        onChange={(event, triggeredAction) => {
                          setPage(1)
                          if (triggeredAction.action === 'clear') {
                            setSelectedOrg({ value: '', label: '' })
                            FetchGetBookSessions(
                              checkedGpSoc,
                              CheckedFeatures,
                              checkedSessionType,
                              postcode,
                              milesSelect.value,
                              LIMIT,
                              1,
                              token,
                              unavailable,
                              instantBooking,
                              '',
                              favoriteOrganisation
                            )
                          }
                          setSelectedOrg(event)
                          FetchGetBookSessions(
                            checkedGpSoc,
                            CheckedFeatures,
                            checkedSessionType,
                            postcode,
                            milesSelect.value,
                            LIMIT,
                            1,
                            token,
                            unavailable,
                            instantBooking,
                            event.value,
                            favoriteOrganisation
                          )
                        }}
                        options={organisaitons}
                        id='choices-single-default'
                        className='js-example-templating mb-0'
                        onMenuScrollToBottom={() => {
                          setOrgPage(orgPage + 1)
                          fetchOrganisations(orgPage, LIMIT)
                        }}
                        filterOption={customFilter}
                        onInputChange={e => {
                          setOrgPage(1)
                          fetchOrganisations(orgPage, LIMIT, e)
                        }}
                        classNamePrefix='dropdownStyles'
                      />
                      </div>
                      </Collapse>
                      </AccordionItem>
                      <AccordionItem>
                      <h2 className="accordion-header">
                          <button
                              className={classnames("accordion-button text-muted bg-transparent shadow-none text-uppercase fs-12", { collapsed: !col3 })} type="button" onClick={t_col3} style={{ cursor: "pointer" }} >
                               Session Types
                          </button>
                      </h2>
                      <Collapse isOpen={col3} className="accordion-collapse">
                      <div className="accordion-body pt-0">

                      <div className='d-flex flex-column gap-2'>
                          {sessionTypes?.map((_, index) => {
                            return (
                              <div key={_.uuid} className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id={`${_?.uuid}`}
                                  onChange={e => {
                                    setPage(1)
                                    fetchSessionClickHandler(index, _, e)
                                    handleSessionChange(index, _, e)
                                  }}
                                  checked={_?.checked || false}
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor={`${_?.uuid}`}
                                >
                                  {_.type}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                      <h2 className="accordion-header">
                          <button
                              className={classnames("accordion-button text-muted bg-transparent shadow-none text-uppercase fs-12", { collapsed: !col4 })} type="button" onClick={t_col4} style={{ cursor: "pointer" }} >
                               PRACTICE FEATURES
                          </button>
                      </h2>
                      <Collapse isOpen={col4} className="accordion-collapse">
                      <div className="accordion-body  pt-0">
                      <div className='d-flex flex-column gap-2'>
                          {featureTypes?.map((_, index) => {
                            return (
                              <div key={_.uuid} className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id={`${_?.uuid}`}
                                  onChange={e => {
                                    setPage(1)
                                    fetchFeaturesClickHandler(index, _, e)
                                    handleFeaturesChange(index, _, e)
                                  }}
                                  checked={_?.checked || false}
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor={`${_?.uuid}`}
                                >
                                  {_.name}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      </Collapse>
                      </AccordionItem>
                      <AccordionItem>
                      <h2 className="accordion-header">
                          <button
                              className={classnames("accordion-button text-muted bg-transparent shadow-none text-uppercase fs-12", { collapsed: !col5 })} type="button" onClick={t_col5} style={{ cursor: "pointer" }} >
                               IT SYSTEMS
                          </button>
                      </h2>
                      <Collapse isOpen={col5} className="accordion-collapse">
                      <div className="accordion-body  pt-0">
                      <div className='d-flex flex-column gap-2'>
                          {itSystemTypes?.map((_, index) => {
                            return (
                              <div key={_.uuid} className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id={`${_?.uuid}`}
                                  onChange={e => {
                                    setPage(1)
                                    fetchItSystemClickHandler(index, _, e)
                                    handleItSystemChange(index, _, e)
                                  }}
                                  checked={_?.checked || false}
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor={`${_?.uuid}`}
                                >
                                  {_.name}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      </Collapse>
                      </AccordionItem>
                      <AccordionItem>
                      <h2 className="accordion-header">
                          <button
                              className={classnames("accordion-button text-muted bg-transparent shadow-none text-uppercase fs-12", { collapsed: !col6 })} type="button" onClick={t_col6} style={{ cursor: "pointer" }} >
                               OTHER PREFERENCES
                          </button>
                      </h2>
                      <Collapse isOpen={col6} className="accordion-collapse">
                      <div className="accordion-body  py-0">
                      <div className='form-check'>
                      <input
                              className='form-check-input'
                              type='checkbox'
                              id='productBrandRadio5'
                              checked={unavailable || false}
                              onChange={e => {
                                setUnavailable(e.target.checked)
                                FetchGetBookSessions(
                                  checkedGpSoc,
                                  CheckedFeatures,
                                  checkedSessionType,
                                  postcode,
                                  milesSelect.value,
                                  LIMIT,
                                  page,
                                  token,
                                  e.target.checked,
                                  instantBooking,
                                  '',
                                  favoriteOrganisation
                                )
                                setPage(1)
                              }}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='productBrandRadio5'
                            >
                              Only when I'm available
                            </label>
                        </div>
                        </div>
                      </Collapse>
                      </AccordionItem>
              </Accordion>
              </CardBody>
              {/* <div className='accordion accordion-flush '>

                <div className='accordion-item rounded'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button bg-transparent shadow-none collapsed'
                      type='button'
                      id='flush-Other-preferences'
                    >
                      <span className='text-muted text-uppercase fs-12 fw-medium'>
                        Other preferences
                      </span>
                    </button>
                  </h2>

                  <UncontrolledCollapse toggler='#flush-Other-preferences'>
                    <div
                      id='flush-collapseRating'
                      className='accordion-collapse collapse show'
                      aria-labelledby='flush-Other-preferences'
                    >
                      <div className='accordion-body text-body pt-1'>
                        <div className='d-flex flex-column gap-2'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='productBrandRadio5'
                              checked={unavailable || false}
                              onChange={e => {
                                setUnavailable(e.target.checked)
                                FetchGetBookSessions(
                                  checkedGpSoc,
                                  CheckedFeatures,
                                  checkedSessionType,
                                  postcode,
                                  milesSelect.value,
                                  LIMIT,
                                  page,
                                  token,
                                  e.target.checked,
                                  instantBooking,
                                  '',
                                  favoriteOrganisation
                                )
                                setPage(1)
                              }}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='productBrandRadio5'
                            >
                              Only when I'm available
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div>
              </div> */}
            </Card>
          </Col>
          <Col xxl={9}>
            <div>
              <Card>
                <CardHeader className='mb-0 pb-0'>
                  {/* <div className='row align-items-center'> */}
                  <div className='col'>
                    <Nav className='nav-tabs' role='tablist'>
                      {BOOK_PAGE_TABS?.map((_, index) => (
                        <NavItem key={index}>
                          <NavLink
                            className={classnames(
                              { active: index === activeTab },
                              ''
                            )}
                            onClick={() => {
                              toggleTab(index, _)
                            }}
                            href='#'
                          >
                            {_}
                            {/* <span className='badge badge-soft-danger align-middle rounded-pill ms-1'>
                                {pmsessionsData?.length || 0}
                              </span> */}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </div>
                  {/* </div> */}
                </CardHeader>

                <div className='card-body'>
                  <BookingList
                    pmsessionsData={pmsessionsData}
                    setShowApplyModal={setShowApplyModal}
                    setShowWithdrawnModal={setShowWithdrawnModal}
                    setSelectedSessionId={setSelectedSessionId}
                    setSelectedId={setSelectedId}
                    bonusPercantage={bonusPercantage}
                    handleApplyClick={() => {}}
                    handleLikeClick={() => {
                      FetchGetBookSessions(
                        checkedGpSoc,
                        CheckedFeatures,
                        checkedSessionType,
                        postcode,
                        milesSelect?.value,
                        LIMIT,
                        page,
                        token,
                        unavailable,
                        instantBooking,
                        '',
                        favoriteOrganisation
                      )
                    }}
                  />
                </div>

                <PaginationV2
                  totalPageCount={totalPageCount === 0 ? 1 : totalPageCount}
                  previousDisabled={page === 1}
                  nextDisabled={totalPageCount === page ? true : false}
                  previousPageCall={() => {
                    setNextPage(true)
                    setPage(page - 1)
                  }}
                  currentPage={page ? page : 1}
                  nextPageCalls={() => {
                    setNextPage(true)
                    setPage(page + 1)
                  }}
                  onPageInputChange={({ target: { value } }) => {
                    var regex = new RegExp(`^[1-${totalPageCount}]$`)
                    if (value <= totalPageCount && value !== 0) {
                      setNextPage(true)
                      setPage(value)
                    }
                  }}
                />
              </Card>
            </div>
          </Col>
        </Row>

        <SuccessConfirmationModal
          show={showApplyModal}
          onDeleteClick={() => fetchApplyBtnHandler(selectedId)}
          onCloseClick={() => setShowApplyModal(false)}
          deleteMsg='Are you sure you want to apply for this session ?'
          btnName='Apply'
        />

        <ConfirmationModal
          show={showWithdrawn}
          onDeleteClick={() => fetchWithdrawnSessionClient(selectedId)}
          onCloseClick={() => setShowWithdrawnModal(false)}
          deleteMsg='Are you sure you want to withdraw this session ?'
          btnName='Yes' />
      </Container>
    </div>
  )
}

export default BookPage
