import { headerContent } from '.'
import { getCookie } from '../utils/constants'
import {
  GET_ARCHIVED_FORM_B,
  GET_FORMS_A_AND_B,
  GET_FORM_B,
  GET_PENSION_LOGS,
  GET_FORM_B_DATA,
  GET_PENSION_PCSE_REGIONS,
  GET_PENSION_PREFERENCE,
  GET_PENSION_REGIONS,
  GET_PENSION_TIER_CONTRIBUTION,
  PATCH_ADD_SIGNATURE,
  POST_Add_MANUAL_SIGN_ON_FORM_A,
  POST_GENERATE_FORM_B,
  PUT_SAVE_PENSION_INFO,
  GET_FORM_A_VERSIONS,
  POST_UNARCHIVE_FORM_B
} from './endpoints'
var token = localStorage.getItem('token')

export const headerContentWithAuthorization = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`
}

const customHeaders = token => {
  let copiedHeaderContentWithAuthorization = {
    ...headerContentWithAuthorization
  }
  copiedHeaderContentWithAuthorization['Authorization'] = `Bearer ${token}`
  return copiedHeaderContentWithAuthorization
}

export const pensionServices = {
  // Get pension regions
  getPensionRegionsList: token =>
    fetch(GET_PENSION_REGIONS.endpoint, {
      method: GET_PENSION_REGIONS.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get pension regions
  getPensionPCSERegionsList: token =>
    fetch(GET_PENSION_PCSE_REGIONS.endpoint, {
      method: GET_PENSION_PCSE_REGIONS.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get pension regions
  getPensionTierContribution: token =>
    fetch(GET_PENSION_TIER_CONTRIBUTION.endpoint, {
      method: GET_PENSION_TIER_CONTRIBUTION.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Save pension Information
  putSavePensionInformation: (data, token) =>
    fetch(PUT_SAVE_PENSION_INFO.endpoint, {
      method: PUT_SAVE_PENSION_INFO.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get pension preferences
  getPensionPreferences: token =>
    fetch(GET_PENSION_PREFERENCE.endpoint, {
      method: GET_PENSION_PREFERENCE.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get pension Forms A and B
  getPensionFormsAandB: (page, limit, type, token) =>
    fetch(GET_FORMS_A_AND_B.endpoint(type, page, limit), {
      method: GET_FORMS_A_AND_B.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Post Signature
  patchAddSignature: (data, token, category) =>
    fetch(PATCH_ADD_SIGNATURE.endpoint(category), {
      method: PATCH_ADD_SIGNATURE.method,
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: data
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Post mark as signed manually Signature
  postAddSignatureManually: (data, token) =>
    fetch(POST_Add_MANUAL_SIGN_ON_FORM_A.endpoint(data), {
      method: POST_Add_MANUAL_SIGN_ON_FORM_A.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // get form b
  getFormB: (status, token) =>
    fetch(GET_FORM_B.endpoint(status), {
      method: GET_FORM_B.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Post generate Form b
  postGeneratePensionFormB: (data, token) =>
    fetch(POST_GENERATE_FORM_B.endpoint, {
      method: POST_GENERATE_FORM_B.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // get form b
  getArchivedFormB: token =>
    fetch(GET_ARCHIVED_FORM_B.endpoint, {
      method: GET_ARCHIVED_FORM_B.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // get form b
  getPensionLogs: token =>
    fetch(GET_PENSION_LOGS.endpoint, {
      method: GET_PENSION_LOGS.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // get form b
  getFormBData: (path, token) =>
    fetch(GET_FORM_B_DATA.endpoint(path), {
      method: GET_FORM_B_DATA.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // get form b
  getFormAVersions: (token) =>
    fetch(GET_FORM_A_VERSIONS.endpoint, {
      method: GET_FORM_A_VERSIONS.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Post generate Form b
  postUnarchiveFormB: (data, token) =>
    fetch(POST_UNARCHIVE_FORM_B.endpoint, {
      method: POST_UNARCHIVE_FORM_B.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),
}
