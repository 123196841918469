import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Row, Table } from 'reactstrap'
import { accountServices } from '../../services/accountServices'
import fetchPDF from '../../common/fetchPDF'
import { useState } from 'react'
import SpinnerComponent from '../../Components/Common/Spinner'
import { pensionServices } from '../../services/pensionServices'
import { toast } from 'react-toastify'

const ArchieveFormBList = ({ archivedFormB, onFormArchive }) => {

  const [isLoading, setIsLoading] = useState(false)
  const token = localStorage.getItem('token')

  const handleDownloadDocument = (pdf_uuid, status, originalFileName) => {
    setIsLoading(true)
    accountServices.downloadDocument(pdf_uuid, status, token)
      .then(resp => fetchPDF(resp, originalFileName))
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const UnarchiveFormB = (id) => {
    pensionServices.postUnarchiveFormB({ form_b_uuid: id }, token).then(resp => {
      if (resp.success) {
        toast.success(resp.message)
        onFormArchive()
      } else {
        toast.error(resp.message)
      }
    }).catch(err => console.log(err))
  }
  return (
    <>
      {isLoading && <SpinnerComponent />}
      <Row>
        <Col xl={12}>
                  <div className='table-responsive table-card rounded-bottom'>
                    <Table className='table-hover align-middle table-nowrap table-sm'>
                      <thead className='text-muted'>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th scope='col'>ID</th>
                          <th scope='col'>Month</th>
                          <th scope='col'>Form B Status</th>
                          <th scope='col'>Download</th>
                          <th scope='col'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {archivedFormB.length !== 0 ? (
                          archivedFormB.map(
                            (data, index) => {
                              return (
                                <tr key={index}>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <Link
                                      to='#'
                                      className='fw-medium'
                                    >
                                      #
                                      {data?.uuid?.slice(
                                        0,
                                        5
                                      )}
                                    </Link>
                                  </td>
                                  <td>
                                    {moment(
                                      data?.month_year
                                    ).format('MMMM YYYY') ||
                                      'NA'}
                                  </td>

                                  <td className='text-success text-capitalize'>
                                    <i className='ri-checkbox-circle-line fs-17 align-middle'></i>{' '}
                                    {data?.status || 'NA'}
                                  </td>

                                  <td>
                                    <button
                                      type='button'
                                      className='btn btn-soft-success btn-sm'
                                      onClick={() =>
                                        handleDownloadDocument(data?.uuid, "formb", data?.file_path?.split('/')[2])
                                      }
                                    >
                                      Download Form B
                                    </button>
                                  </td>

                                  <td>
                                    <button
                                      type='button'
                                      className='btn btn-soft-danger  btn-sm'
                                      onClick={() =>
                                        UnarchiveFormB(data?.uuid)
                                      }
                                    >
                                      Unarchive Form B
                                    </button>
                                  </td>
                                </tr>
                              )
                            }
                          )
                        ) : (
                          <tr>
                            <td colSpan="11" className='text-center text-muted'>No data found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
        </Col>
      </Row>
    </>
  )
}

export default ArchieveFormBList