import React from 'react'
import { Redirect } from 'react-router-dom'

//Invoices
import InvoiceCreate from '../pages/Invoice/InvoiceCreate'

import BasicTwosVerify from '../pages/Authentication/TwoStepVerification/BasicTwosVerify'
import CoverTwosVerify from '../pages/Authentication/TwoStepVerification/CoverTwosVerify'
import Basic404 from '../pages/Authentication/Errors/Basic404'
import Cover404 from '../pages/Authentication/Errors/Cover404'
import Alt404 from '../pages/Authentication/Errors/Alt404'
import Error500 from '../pages/Authentication/Errors/Error500'

//login
import Login from '../pages/Authentication/Login'
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword'
// import Logout from '../pages/Authentication/client/logout'
import Register from '../pages/Authentication/Register'

// Landing Page
import OnePage from '../pages/Landing'
import Calendar from '../pages/Schedule/Calendar/index'

import BasicPasswReset from '../pages/Authentication/PasswordCreate/BasicPasswCreate'
import BasicSuccessMsg from '../pages/Authentication/SuccessMessage/BasicSuccessMsg'
import PasswordSuccess from '../pages/Authentication/SuccessMessage/PasswordSuccess'
import SessionList from '../pages/Schedule/List'
import SessionDetails from '../Components/Common/SessionDetailsPage'
import BookPage from '../pages/book/index'
import InvoiceList from '../pages/Invoice'
import ProfileInformationPage from '../pages/Profile/ProfileInformation/index'
import ProfileSettingPage from '../pages/Profile/ProfileSetting/Settings'
import FormA from '../pages/Pension/FormA'
import FormB from '../pages/Pension/FormB'
import Chat from '../pages/Chat'
import SessionDetailsPageNew from '../Components/Common/SessionDetailsPageNew'
import BookPageNew from '../Components/Common/BookPageNew'
import EmailVerificationSuccess from '../pages/Authentication/SuccessMessage/EmailVerifationSuccess'

//privacy policy and terms
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsCondition from '../pages/TermsCondition';
// import FAQ from '../pages/FAQ';
import Support from '../pages/Support';

const authProtectedRoutes = [
  { path: '/client/generate-invoice/:id', component: InvoiceCreate },
  { path: '/calendar', component: Calendar },
  { path: '/session-list', component: SessionList },
  { path: '/session-details/:id/:name', component: SessionDetails },

  { path: '/book', component: BookPage },
  { path: '/invoices', component: InvoiceList },
  { path: '/profile-info', component: ProfileInformationPage },
  { path: '/profile-settings', component: ProfileSettingPage },
  { path: '/form-a', component: FormA },
  { path: '/form-b', component: FormB },
  { path: '/chat', component: Chat },

  { path: '*', component: () => <Redirect to='/' /> },
]

const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPasswordPage },
  { path: '/success', component: BasicSuccessMsg },
  { path: '/password-reset-succeed', component: PasswordSuccess },
  { path: '/register', component: Register },
  {
    path: '/client/session-details-new/:id/:name',
    component: SessionDetailsPageNew
  },

  //AuthenticationInner pages
  { path: '/auth-twostep-basic', component: BasicTwosVerify },
  { path: '/auth-twostep-cover', component: CoverTwosVerify },
  { path: '/auth-404-basic', component: Basic404 },
  { path: '/auth-404-cover', component: Cover404 },
  { path: '/auth-404-alt', component: Alt404 },
  { path: '/auth-500', component: Error500 },
]

const openPublicRoute = [
  { path: '/auth/password-reset', component: BasicPasswReset },
  { path: '/email-verification', component: EmailVerificationSuccess },
]

const routes = [
   //privacy policy and terms
   { path: "/privacy", component: PrivacyPolicy },
   { path: "/terms", component: TermsCondition },
   // { path: "/faq", component: FAQ },
   { path: "/support", component: Support },
]

export { authProtectedRoutes, publicRoutes, routes, openPublicRoute }
