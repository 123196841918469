import PropTypes from 'prop-types'
import React from 'react'
import { Modal, ModalBody,ModalHeader } from 'reactstrap'

const SuccessConfirmationModal = ({
  show,
  onDeleteClick,
  onCloseClick,
  deleteMsg,
  btnName
}) => {
  const closeBtn = (
    <button
      className="btn-close  text-white"
      onClick={onCloseClick}
      type="button"
    ></button>
  );

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
    <ModalHeader

        className="p-3 bg-airgp modal-title"
        close={closeBtn}
      >
          <div className="modal-title text-white h5">
            Confirm
          </div>
      </ModalHeader>
      <ModalBody className='py-3 px-5'>
        <div className='mt-2 text-center'>
          <div className='fs-16'>
            {/* <h4>Please Confirm</h4> */}
            <p className='text-muted mb-0'>
              {deleteMsg || 'Are you sure you want to remove this record ?'}
            </p>
          </div>
        </div>
        <div className='d-flex gap-2 justify-content-center mt-4 mb-2'>
          <button
            type='button'
            className='btn btn-primary'
            onClick={onDeleteClick}
          >
            {btnName || 'OK'}
          </button>
          <button
            type='button'
            className='btn  btn-light'
            data-bs-dismiss='modal'
            onClick={onCloseClick}
          >
            Close
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

SuccessConfirmationModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default SuccessConfirmationModal
