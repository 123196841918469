import React, { useState, useEffect } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'

import { useHistory } from 'react-router-dom'
import { accountServices } from '../../services/accountServices'

const ProfileDropdown = () => {
  const [profileImg, setProfileImg] = useState('')
  const [isProfileDropdown, setIsProfileDropdown] = useState(false)
  const [userName, setUserName] = useState('')

  const token = localStorage.getItem('token')
  const history = useHistory()

  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown)
  }

  useEffect(() => {
    fetchUserProfileData(token)
  }, [])

  const fetchUserProfileData = token => {
    accountServices
      .getUserProfileDetails(token)
      .then(resp => {
        if (resp.success) {
          setProfileImg(resp?.data?.profile_photo_link)
          setUserName(resp?.data?.user?.firstname)
        }
      })
      .catch(err => console.log(err))
  }

  const handleLogOutClick = () => {
    localStorage.clear()
    history.push('/login')
  }
 
  return (
    <React.Fragment>
      <div className="ms-1 header-item">
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className='ms-sm-3 header-item topbar-user'
      >
        <DropdownToggle tag='button' type='button' className='btn btn-icon btn-topbar btn-ghost-secondary'>
          <span className='d-flex align-items-center'>
            <div className='fs-22'><i className="ri-account-circle-line"></i></div>
            {/* <div className='profile-user position-relative d-inline-block mx-auto'> */}
              {/* <img
                src={avatar1}
                className='rounded-circle avatar-sm img-thumbnail user-profile-image'
                alt='user-profile'
              /> */}
            {/* </div> */}
            {/* <span className='text-start ms-xl-2 text-capitalize'> */}
              {/* <span className='d-none d-xl-inline-block ms-1 fw-medium user-name-text'>
                {userName}
              </span> */}
              {/* <span className='d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text'>
                Founder
              </span> */}
            {/* </span> */}
          </span>
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-end'>
          {/* <h6 className='dropdown-header text-capitalize'>
            Welcome {userName}!
          </h6> */}
          <DropdownItem onClick={() => history.push('/profile-info')}>
            <i className='ri-account-circle-line text-muted fs-16 align-middle me-1'></i>
            <span className='align-middle'>Profile</span>
          </DropdownItem>
          <DropdownItem  onClick={handleLogOutClick}>
            <i className='ri-logout-circle-line text-muted fs-16 align-middle me-1'></i>
            <span className='align-middle'>
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      </div>
    </React.Fragment>
  )
}

export default ProfileDropdown
